import React from 'react';
import './AboutUs.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import {fetchApi} from "../../../fetchApi";
import {
    Container,
    Row,
    Col
} from 'reactstrap';

export default class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [{}]
        };
    }

    getAboutUs = async () => {
        const url = 'get_aboutus';
        const response = await fetchApi(url, 'POST', null);
        if (response.success) {
            this.setState({items: response.about_us_details})
        }
    };

    componentDidMount() {
        this.getAboutUs();
    }

    render() {
        return (
            <div className={ this.props.fromNavBarNormal === true ? this.props.classNameNavBar :  "AboutUs "}>
                <div className={this.props.fromNavBarNormal === true ? this.props.classNavBarContent : "  aboutUsContent"}>


                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <div className="AboutUsTitle">
                               <b>Zing</b> in 3 steps.</div>
                        </Col>
                    </Row>


               <Row>
               {this.state.items.map((item, index) => (
                   <Col xs={12} sm={6} lg={4}  key={index} className="item">
                       
                            <div className="stepsTexts">
                                <div className="itmTitle">{item.title}</div>
                                <div className="itmDesc">{item.description}</div>
                            </div>
                       
                   </Col>
                      ))}
               </Row>

                </Container>
                </div>
            </div>
        );
    }
}





