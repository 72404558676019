import React, { Component } from "react";
import { Input, Container, Col, Button } from "reactstrap";

import { fetchApi } from "../../../fetchApi.js";
import { userCredentials } from "../../../globalVariables";
import swal from 'sweetalert';
export default class MakeOfferEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: ""
    };
  }

  onChangeDescription = evt => {
    this.setState({ description: evt.target.value });
  };

  createOfferEvent = async () => {
    let authKey = userCredentials.authKey;
    let list = [];
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("user_type", 2);
    data.append("type", 2);
    data.append("description", this.state.description);
    data.append("video_url", "");
    const url = "addoffert";
    const response = await fetchApi(url, "POST", data, authKey);
    // console.log(response);
    if (!response.success) {
      swal("Error!", "Could not create promotion!", "error");
    } else {
      swal("Success!", "Promotion added with success", "success");
    }
  };

  render() {
    return (
      <Container className="rounded-lg py-1">
        <Col className="mt-4 mb-4">
          <Input
            type="text"
            onChange={this.onChangeDescription}
            placeholder="description"
            value={this.state.description}
            className="customInput"
          />
        </Col>
        <Col>
          <Button onClick={this.createOfferEvent} className="greenBtnEvt">Done</Button>
        </Col>
      </Container>
    );
  }
}
