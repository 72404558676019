import React from "react";
import {
    Button,
    Col,
    Row,
    Container,

} from "reactstrap";
import "./Voucher.scss";
import {userCredentials} from "../../../globalVariables";
import {
    faTimes,
    faPen,
    faShare,
    faShoppingCart,
    faPoundSign,
    faEuroSign,
    faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fetchApi} from "../../../fetchApi";
import swal from 'sweetalert';

let SLogo = require("../../../Assets/img/SLogo.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class Voucher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpenCategory: false,
            country: "",
            dropdownOpenCountry: false,
            openDialog: false,
            participantsList: [
                {key: 0},
                {key: 1},
                {key: 2},
                {key: 3},
                {key: 4},
                {key: 5}
            ],
            messageList: [
                {name: "Juana", message: "great!"},
                {name: "Jose", message: "amazing!"},
                {name: "Rafa", message: "wonderful!"}
            ],
            modal: false,
            ulr: "",
            category: "",
        };
    }

    // getCategories = async () => {
    //     const url = "get_categories";
    //     const response = await fetchApi(url, "POST", null);
    //     if (response.success && response.list_of_categories) {
    //         let catName = response.list_of_categories.filter(
    //             element => parseInt(element.id) === parseInt(this.props.category)
    //         );
    //
    //         if (catName[0] !== undefined) {
    //             this.setState({category: catName[0].name});
    //         } else {
    //             this.getCategories();
    //         }
    //     }
    // };

    applyToVoucher = async () => {
        let authKey = userCredentials.authKey;
        const url = "applytovoucher";
        const data = new FormData();
        data.append("voucher_id", this.props.voucherId);
        data.append("user_id", this.props.userId);
        data.append("comment", "");
        data.append("event_id", this.props.eventId);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal("Success!", "The request has been sent!", "success");
        } else {
            swal("Error", "Erorr ocurred!", "error");
        }
    };

    // componentDidMount = () => {
    //     this.getCategories();
    // };

    render() {
        
        return (
            <Container className="p-0 voucherContainer">
        {/* {  this.props.userCreatedId !== userCredentials.userId ?  */}
        {  this.props.updateVoucher !== undefined ? 

  <Col className="d-flex justify-content-end  edtButtons-voucher">
  <Button
      className="Close-pen-btn"
     //  onClick={()=>this.props.toggleModalMakeVoucher()}
      onClick={this.props.updateVoucher}
  >
      <FontAwesomeIcon icon={faPen} />
  </Button>
 
 </Col> :
 null

        }
               
                <Row className="">
                    <Col
                        xs="1"
                        sm={1}
                        md={1}
                        lg={1}
                        className="p-0 mobMargin centered"
                        onClick={this.handlerModalFriends}
                    >
                        {this.props.logo !== null &&
                        this.props.logo !== undefined &&
                        this.props.logo !== "" ? (
                            <img
                                src={this.props.logo}
                                className="eventProfilePic evtPic mobMargin"
                                alt="profilePic"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = profilePic;
                                }}
                            />
                        ) : (
                            <img
                                src={profilePic}
                                className="eventProfilePic evtPic"
                                alt="profilePic"
                            />
                        )}
                    </Col>
                    <Col xs="11" sm="9" lg="9" className="pt-3">
                        <Col className="name nameAndCategory p-0">
                            <Col xs="9" sm={7} md={7} lg={8} className="p-0 categText">
                                {this.props.titleVoucher !== null ? this.props.titleVoucher : "No title"} - {this.props.location !== null ? this.props.location : "All countries"}
                            </Col>

                            <div className="detailsBoxs">
                                <Row className="p-0 m-0">
                                    <div className="details">{this.props.nOf} Vouchers</div>
                                    <div className="details">
                                        {this.props.reserved} / {this.props.nOf} Reserved
                                    </div>
                                    <div className="details priceDetails">
                                        {this.props.price}{" "}
                                        {this.props.currency === "GBP" ? (
                                            <FontAwesomeIcon icon={faPoundSign}/>
                                        ) : this.props.currency === "lira" ? (
                                            <FontAwesomeIcon icon={faPoundSign}/>
                                        ) : this.props.currency === "euro" ? (
                                            <FontAwesomeIcon icon={faEuroSign}/>
                                        ) : this.props.currency === "EURO" ? (
                                            <FontAwesomeIcon icon={faEuroSign}/>
                                        ) : this.props.currency === "EUR" ? (
                                            <FontAwesomeIcon icon={faEuroSign}/>
                                        ) : this.props.currency === "USD" ? (
                                            <FontAwesomeIcon icon={faDollarSign}/>
                                        ) : this.props.currency === "RON" ? (
                                            "RON"
                                        ) : null}
                                    </div>
                                    {this.props.requests === 1 ? (
                                        <div className="details">{this.props.requests} Request</div>
                                    ) : (
                                        <div className="details">
                                            {this.props.requests} Requests
                                        </div>
                                    )}
                                </Row>
                            </div>
                            {this.props.conditions ? (
                                <Col className="p-0 m-0">
                                    <div className="voucherConditions">
                                        {this.props.conditions}
                                    </div>
                                </Col>
                            ) : null}
                        </Col>
                    </Col>
                    {this.props.showButton ? (
   

                        <Col sm="2" lg="2" className="p-0 m-0  ">
             
                            <div className=" parent-btn-grup">
                                <Button
                                    className="joinButton mb-2"
                                    onClick={this.props.toogleModalShowDetails}
                                >
                                    Approve/Reject
                                </Button>
                            </div>
                        </Col>
                    ) : (
                        <Col sm="2" lg="2" className="p-0 m-0 ">
                            <div className="parent-btn-grup">
                                <Button
                                    className="joinButton mb-2"
                                    onClick={this.applyToVoucher}
                                >
                                    Apply
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        );
    }
}
