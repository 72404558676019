import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import './PageNotFound.scss'
let SLogo = require("../../Assets/img/logo.png");
export default class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            sec: 5,
            inetervalid: -1
        }
    }

    componentDidMount() {
        let intervalid = setInterval((intervalid) => {
            this.setState({ intervalid: intervalid, redirect: this.state.sec === 1, sec: this.state.sec - 1 });
            if (this.state.redirect) {
                clearInterval(this.state.intervalid);
            }
        }, 1000);
    }

    render() {
        return this.state.redirect
            ? <Redirect to="/" />
            : <div className="notFoundMain">
                <img className="logoImg" src={SLogo} alt="my_logo" />
                <br></br>
      Page not found! You'll be redirected to home page in {this.state.sec} seconds!
  </div>

    }
}

