import React from "react";
import ReactDOM from "react-dom";
import {
    Navbar,
    NavbarBrand,
    Container,
    Button,
    Alert,
    Media,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";

import { slide as Menu } from "react-burger-menu";
import "./NavBar.scss";
import SignUpLogIn from "../SignUpLogIn/SignUpLogIn";
import { configureAnchors } from "react-scrollable-anchor";
import { fetchApi } from "../../../fetchApi";
import logo from "../../../Assets/img/footerLogo.png";
import logoTop from "../../../Assets/img/zing mic.png";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal.js";
import queryString from 'query-string'
import {sliderState} from "../../../globalVariables"
let SLogo = require("../../../Assets/img/SLogo.png");

export default class CustomNavbar extends React.Component {
    documentStyle = document.documentElement.style;
    initalNavbarBackgroundColor = "rgba(95, 108, 255, 0)";
    scrolledNavbarBackgroundColor = "#00b4b1";

    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
        this.handler = this.handler.bind(this);
        this.handlerModal = this.handlerModal.bind(this);

        this.state = {
            getLoggedIn: false,
            userType: "normal",
            menuOpen: false,
            mainSponsor: null,
            eventsList: [],
            currentEvent: null,
            currentMultiprice: null,
            eventLogo: '',
            toggleModalEvent: false,
            querysnId: this.getSocialNetworkEventId(),
            openModal:false,
            isLogedHome:true,
        };

        this.handleClick = this.handleClick.bind(this);
    }

  getMainSponsor = async () => {
    const url = "get_officialpartners";
    const response = await fetchApi(url, "POST", null);
    if (response.success) {
      this.setState({
        mainSponsor: response.list_of_partners[0].picture,
        mainSponsorLink: response.list_of_partners[0].video,
      });
    }
  };
  getSocialNetworkEventId = () => {
    const link = queryString.parse(window.location.search);
    if (link) {
      const snId = link.snid;
      if (snId) {
        if (TryParseInt(snId, null) !== null) {
          return snId;
        }
      }
    }
    return null;
  };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener("click", this.handleClick, false);
        this.getMainSponsor();


        var mySubString = window.location.href
            .split("eventId=")
            .pop()
            .split("?")[0];

        if (TryParseInt(mySubString, null) !== null) {

            this.props.signUpHandler();
        }

        if (this.state.querysnId) {
            this.getEventById(this.state.querysnId);
        }
    }

    getEventById = async (eventId) => {
        const url = "geteventbyid";
        const data = new FormData();
        data.append("event_id", eventId);
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            if (response.event && response.event.length > 0) {
                sliderState.canUseSlider = false;
                setTimeout(() => {
                    this.setState({
                        currentEvent: response.event[0],
                        toggleModalEvent: true
                    });
                }, 3500);

            }
        }

    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("scroll", this.trackScrolling);
        document.removeEventListener("click", this.handleClick, false);
    }

    handleClick = e => {
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.closeMenu();
        }
    };

    handleScroll = () => {
        this.closeMenu();
    };

    handleStateChange(state) {
        if (state.isOpen){
            sliderState.canUseSlider=false;
        }else {
            sliderState.canUseSlider=true;
        }

        this.setState({ menuOpen: state.isOpen });
    }

    closeMenu() {
        this.setState({ menuOpen: false });
    }

    handler(val) {
        this.setState({ getLoggedIn: true, userType: val });
        this.props.handleLog(val);
    }

    handlerModal(val) {
        this.setState({ openModal: !this.state.openModal });
        if (val !== undefined) {
            this.handler(val);
        }
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: state.isToggleOn
        }));
    }

    toggleModalEvent = () => {
        if (this.state.toggleModalEvent){
            sliderState.canUseSlider = true;
        }
        this.setState({
            toggleModalEvent: !this.state.toggleModalEvent
        });
        this.setState({ querysnId: null });
    };

    render() {
        configureAnchors({ offset: -150, scrollDuration: 1000 });
        return (
            <Navbar className="Navbar navbar-dark navbar-custom w-100">
                <Container fluid={true} className="">

                    <Col xs="3" sm={5} lg={4} className="butonNavTop">
                        <SignUpLogIn
              text="Sign in / Sign up"
                            class="button signUpBtn2 "
                            handlerModal={this.handlerModal}
                            openModal={this.state.openModal}
                            showSignUp={this.props.showSignUp}
                            setFalseSignUp={this.props.setFalseSignUp}

                        />
                       
                        <div ref={this.dropdown}>
              <Menu
              burgerButtonClassName={
                this.state.isLogedHome === true ?
                   "BtnClass" 
                    :
                    "BtnClassFalse"
                }
                className= {this.state.isLogedHome === true ? "BeforeLogIn" : ""}
                noOverlay
                isOpen={this.state.menuOpen}
                                onStateChange={state => this.handleStateChange(state)}
                                >
                                <a className="menu-item" href="#Search">
                                Search Event
                                </a>
                                <a className="menu-item" href="#HowItWorks">
                                    How it works
                                </a>
                                <a className="menu-item" href="#CreateEvent">
                                    Create Event
                                </a>
                                <a className="menu-item" href="#AboutUs">
                                    About Us
                                </a>
                                <a className="menu-item" href="#OurPartners">
                                    Partners
                                </a>
                                <a className="menu-item" href="#CreateAccount">
                                    Create Account
                                </a>
                            </Menu>
                        </div>
                    </Col>

                    
                    <Col xs="3" sm={4} lg={4} className="d-flex justify-content-center sponsorImage">
            <img src={this.state.mainSponsor} />
          </Col>


          
          <Col xs="3" sm={3} lg={4} className="logoParent">
            <img src={logoTop} className="logoNavbar" />
          </Col>

          
                    {this.state.currentEvent !== null ? (
                        <Modal
                            isOpen={this.state.toggleModalEvent}
                            // toggle={this.toggleModalEvent}
                            size="lg"
                        >
                            <div className="header-p">
                                <ModalHeader className="head-1  text-white">
                                    {"Event details"}
                                </ModalHeader>
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo" />
                                </div>
                                <ModalHeader className="head-2" toggle={this.toggleModalEvent} />
                            </div>
                            <ModalBody className="">
                                {this.state.currentEvent !== null ? (
                                    <BannerDetailsNormal
                                    categoryName={this.state.currentEvent.name_of_subcategory}
                                        eventId={this.state.currentEvent.id}
                                        currencyBannner={this.state.currentEvent.currency}
                                        openModal={this.state.openModal}
                                        showSignUp={this.props.showSignUp}
                                        setFalseSignUp={this.props.setFalseSignUp}
                                        eventHandler={this.props.eventHandler}
                                        handler={this.props.handleLog}
                                        endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                        endDate={
                                            this.state.currentEvent.end_date !== null
                                                ? this.state.currentEvent.end_date.substring(0, 11)
                                                : ""
                                        }
                                        name={this.state.currentEvent.name}
                                        gender={this.state.currentEvent.gender}
                                        ageCategory={this.state.currentEvent.age_category}
                                        startDate={this.state.currentEvent.date.substring(0, 11)}
                                        startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                        latitude={this.state.currentEvent.latitude}
                                        longitude={this.state.currentEvent.longitude}
                                        team_number={this.state.currentEvent.team_number}
                                        max_persons={this.state.currentEvent.max_persons}
                                        persons_left={this.state.currentEvent.persons_left}
                                        description={this.state.currentEvent.description}
                                        //multiprice={this.state.currentMultiprice}
                                        address={this.state.currentEvent.address}
                                        latitude={this.state.currentEvent.latitude}
                                        longitude={this.state.currentEvent.longitude}
                                        userLogo={this.state.eventLogo}
                                        eventLogo={this.state.currentEvent.logo}
                                        showSignUp={this.props.showSignUp}
                                        signUpHandler={this.signUpHandler}
                                    />
                                ) : null}
                            </ModalBody>
                        </Modal>
                    ) : null}
                </Container>
            </Navbar>
        );
    }
}

function TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}
