import React from "react";
import {
    Button,
    Input,
    Col, UncontrolledAlert,

} from "reactstrap";
import "./BookKeeper.scss";
import moment from "moment";
import { userCredentials } from "../../../globalVariables";
import { fetchApi } from "../../../fetchApi";

export default class BookKeeper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            month: "",// moment().month() + 1,
            year: new Date().getFullYear(),
            showAlert: false,
            months: [
                // { id: "", name: "month..." },
                { id: 1, name: "January" },
                { id: 2, name: "February" },
                { id: 3, name: "March" },
                { id: 4, name: "April" },
                { id: 5, name: "May" },
                { id: 6, name: "June" },
                { id: 7, name: "July" },
                { id: 8, name: "August" },
                { id: 9, name: "September" },
                { id: 10, name: "October" },
                { id: 11, name: "November" },
                { id: 12, name: "December" },
            ]

        };
    }

    componentDidMount() {
        this.getBookKeeperData(this.state.month, this.state.year);
    }

    async getBookKeeperData(month, year) {
        let authKey = userCredentials.authKey;
        const url = "bookkeeper";
        const data = new FormData();
        let monthString = month ? month : this.state.month;

        data.append("user_id", userCredentials.userId);
        data.append("month", monthString < 10 ? "0" + monthString.toString() : monthString.toString());
        data.append("year", year ? year : this.state.year);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            if (response.list_of_transactions.length !== 0) {
                this.setState({ dataList: response.list_of_transactions, showAlert: false })
            } else {
                this.setState({ dataList: [], showAlert: true })
            }

        } else {
            this.setState({ dataList: [], showAlert: true })
        }
    }

    handleMonthChange = evt => {
        this.setState({ month: evt.target.value });
    };

    handleYearChange = evt => {
        this.setState({ year: evt.target.value });
    };

    resetBookKeeper = () => {
        let currentDate = new Date().getFullYear();
        this.setState({ year: currentDate, showAlert: false });
        this.getBookKeeperData(this.state.month, currentDate)
    };

    render() {
        return (
            <div className="container Bookepeer">
                <div className="BookepeerContent">
                    <div className="col-lg-8 mx-auto">
                        <div className="row">
                            <Col lg={4}>
                                <Input
                                    className="monthsSelect"
                                    type="select"
                                    placeholder="month"
                                    value={this.state.month}
                                    onChange={(e) => { this.handleMonthChange(e); }}
                                >
                                    {this.state.months !== null &&
                                        this.state.months.map((month, index) => (
                                            <option value={month.id} key={month.id}>
                                                {month.name}
                                            </option>
                                        ))}
                                </Input>

                            </Col>
                            <Col lg={4}>
                                <Input
                                    className=""
                                    type="text"
                                    placeholder="year"
                                    value={this.state.year}
                                    onChange={(e) => { this.handleYearChange(e); }}
                                />
                            </Col>
                            <Col lg={4} className="justify-content-between text-center">

                                <Button className="btnGreen" onClick={() => this.getBookKeeperData()}>Find</Button>
                            </Col>


                        </div>
                        <div className="contents_book">


                            {this.state.dataList !== null && this.state.dataList.length !== 0 ?
                                this.state.dataList && this.state.dataList.map((item, index) => (
                                    <Data transactions={item} key={index} />
                                )) : <UncontrolledAlert
                                    isOpen={this.state.showAlert}
                                    toggle={this.resetBookKeeper}
                                >No history of transactions</UncontrolledAlert>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false
        };
    }

    handleClick = () => {
        this.setState({ showDetails: !this.state.showDetails });
    };

    render() {
        return (
            <div className="col-lg-12">
                <div className="row justify-content-between" onClick={this.handleClick}>
                    <div className="col-lg-8 YearParent mr-1">
                        {this.props.transactions.date} - {this.props.transactions.transaction_type == 1 ? "Events" : "Deals"}
                    </div>
                    <div
                        className="col-lg-3 PriceParent">{parseInt(this.props.transactions.total_amount).toFixed(2)} {this.props.transactions.currency.toUpperCase()}
                    </div>
                </div>
                {this.state.showDetails ? (
                    <div className="detailsHidd">
                        {this.props.transactions.list_of_events ? this.props.transactions.list_of_events.map((item, index) => (
                            <Details name={item.event_name} price={item.total_amount} key={index} currency={this.props.transactions.currency.toUpperCase()} />
                        )) : null}
                        {this.props.transactions.list_of_deals ? this.props.transactions.list_of_deals.map((item, index) => (
                            <Details key={index} name={item.deal_name} price={item.total_amount} currency={this.props.transactions.currency.toUpperCase()} />
                        )) : null}

                    </div>
                ) : null}
            </div>
        );
    }
}

class Details extends React.Component {

    render() {
        return (
            <Col lg={7}>
                <div className="hiddenValues">
                    <div className="row">
                        <div className="col-lg-7 hiddType mr-1">{this.props.name}</div>
                        <div className="col-lg-3 hiddPrice">{parseInt(this.props.price).toFixed(2)} {this.props.currency.toUpperCase()}</div>
                    </div>
                </div>

            </Col>

        );
    }
}
