//import {token} from '../constants/globalConstants';
export async function fetchApiLocation(type) {
  let url = "https://api.hostip.info/";
  if (type === "GET") {
    try {
      const response = await fetch(url, {
        method: "GET"
      });

      return await response;
    } catch (e) {
      return e;
    }
  }
}
