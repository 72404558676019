import React from 'react';
import termsStyle from './TermsAndConditions.scss'
let SLogo = require("../../Assets/img/logo.png");

const PrivacyScanner=(props)=>{
    let textToDisplay = '';
    let fileToDownload = 'PrivacyScanner.pdf';
    // if(props.isTerms === true){
        fileToDownload='PrivacyScanner.pdf';
        textToDisplay = (
            <div className="cookieMain">
            <img className="logoImg" src={SLogo} alt="my_logo" />

            <div className="fullCentered">
            ZING SCANNER
            </div>
            <div className="fullCentered">
                By S.C. WE ZING S.R.L.
            </div>
            <div className="fullCentered">
                <i>Headquarters – Petru Rares Street, N.68, 720012, Suceava, Romania</i>
            </div>
            <div className="fullCentered">
                <a href="mailto:contact@zing-tickets.com  ">contact@zing-tickets.com  </a>
            </div>
            <div className="row cookiesMargins">
            Effective: July 7, 2020
            </div>
            <div className="fullCentered cookiesMargins">
                <b>PRIVACY POLICY</b>
            </div>
            <p className="leftalign">
            PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE USE AND PROTECT YOUR PERSONAL INFORMATION.
            </p>
            <p className="leftalign">
            Welcome to ZING SCANNER. When you correspond with us, use or browse <a href="https://zing-party.com/" >www.zing-party.com</a> <a href="https://zing-sport.com/" >www.zing-sport.com</a> (our sites), our mobile applications, services or tool (collectively <b>Zing Scanner</b>) you are consenting to our processing of your personal data in accordance with this privacy policy (<b>Privacy Policy</b>). We are committed to protecting the privacy of all users of Zing Scanner.

            </p>
            <p className="leftalign">
            We may update this Privacy Policy from time to time. Whenever the changes are made in the Privacy Policy you will be notified by us to the email you provide, then you are aware of any variations or amendments. Your interactions with Zing Scanner will be subject to the version of Privacy Policy posted on the site at the time.
            </p>
            <p className="leftalign">
            We reserve the right to make changes to this privacy policy from time to time. Any such changes to our privacy policy will be posted to our site.

            </p>
            <div className="fullCenteredTerms cookiesMargins">
                <b> 1 - ABOUT ZING SCANNER AND US</b>
            </div>
            <p className="leftalign">
            1.1 – Zing Scanner is a mobile application designed to connect Event Hosts and Users, and facilitate the Event Host’s booking the Business User’s Services through Zing Sport and Zing Party website and mobile applications, and provide to the Event or Deal Host the files of tickets sold. 

            </p>
            <p className="leftalign">
            1.2 - We are a company registered in Romania under company number 41061633 and our registered office is located on Petru Rares Street, N.68, 720012, Suceava, Romania.
            </p>
            
            <p className="leftalign">
            1.3 - You can contact us as follows:
            </p>


            <div className="leftalignFollow" >

            
            <p className="leftalignFollow">
            FAO: &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;S.C. We Zing S.R.L.
            </p>
            <p className="leftalignFollow">
            Address: &nbsp;	&nbsp;Petru Rares Street, N.68, 720012, Suceava, Romania.
            </p>
            <p className="leftalignFollow">
            Email: &nbsp;	&nbsp;	&nbsp;	&nbsp; <a href="mailto:privicy@zing-tickets.com " >privicy@zing-tickets.com  </a>
            </p>
            </div>
            <div className="fullCenteredTerms cookiesMargins">
                <b> <b>2 - WHAT INFORMATION DO WE COLLECT?</b></b>
            </div>

            <p className="leftalign">
            2.1 - We collect any information that you provide to us trough Zing Sport and Zing Party website and mobile applications, when you:
            </p>
            <p className="leftalign">
            2.1.1 - Access or browse Zing Party or Zing Sport;
            </p>
            <p className="leftalign">
            2.1.2 - Use Zing Party or Zing Sport (for example when you register for an account with us, make an event booking or make or receive payment for services); and / or
            </p>
            <p className="leftalign">
            2.1.3 - Contact us;
            </p>
            <p className="leftalign">
            2.2 - We may use third parties’ plugins, such as Intercom or Hotjar, to provide our services including, but not limited to, customer support or user activity monitoring;

            </p>
            <p className="leftalign">
            2.3 - Such information may include:
            </p>
            <p className="leftalign">
            2.3.1 - information about the internet-enabled electronic device you use to access Zing Scanner (including, but not limited to, your internet-enabled device’s IP address, operating system and browser type);

            </p>

            <p className="leftalign">
               2.3.2 - Location you actively provide to us;
            </p>
            <p className="leftalign">
            2.3.3 - Information about your usage of Zing Scanner, including:
            </p>
            <p className="moreleftalign">
            (a) &nbsp; &nbsp; &nbsp; the amount of time you spend on Zing Scanner;
            </p>
            <p className="moreleftalign">
            (b) &nbsp; &nbsp; &nbsp; the amount of time you spend on and the number of times you visit any page or file on Zing Scanner; and
            </p>
            <p className="moreleftalign">
            (c) &nbsp; &nbsp; &nbsp; the search queries you make on Zing Scanner;
            </p>
            <p className="moreleftalign">
            (d) &nbsp; &nbsp; &nbsp; any download errors that may have occurred;
            </p>
            <p className="moreleftalign">
            (e) &nbsp; &nbsp; &nbsp; any information that you post on Zing Scanner,
            </p>

           




            
            <p className="leftalign">
            2.3.4 - any information you submit to us when you register for an account with us or complete a booking form, including through Zing Sport or Zing Party website and mobile application,
            </p>
            <p className="moreleftalign">
            (a) &nbsp; &nbsp; &nbsp; name;
            </p>
            <p className="moreleftalign">
            (b) &nbsp; &nbsp; &nbsp; username;
            </p>
            <p className="moreleftalign">
            (c) &nbsp; &nbsp; &nbsp; password;
            </p>
            <p className="moreleftalign">
            (d) &nbsp; &nbsp; &nbsp; email address;
            </p>
            <p className="moreleftalign">
            (e) &nbsp; &nbsp; &nbsp; phone number; and
            </p>
            <p className="moreleftalign">
            (f) &nbsp; &nbsp; &nbsp; birthday date; and
            </p>
            <p className="moreleftalign">
            (g) &nbsp; &nbsp; &nbsp; sex
            </p>
            <p className="moreleftalign">
            (h) &nbsp; &nbsp; &nbsp; logo company / user logo
            </p>
            <p className="moreleftalign">
            (i) &nbsp; &nbsp; &nbsp; registred company n` and VAT n`
            </p>
            <p className="moreleftalign">
            (j) &nbsp; &nbsp; &nbsp; address;and
            </p>

            <p className="leftalign">
            2.3.5 - Any information that you submit to us via any third-party account you use to register with us; and / or
            </p>
            <p className="leftalign">
            2.3.6 - Any other information that you submit to us when you contact us for any reason;
            </p>
            <p className="leftalign">
            2.3.7 - We store your data on our database for 10 years unless you delete your account. If you delete your account, all of your data is deleted immediately and cannot be restored;
            </p>
            <p className="leftalign">
            2.4 - We will only use any information that we collect from you in accordance with this Privacy Policy;
            </p>

            <div className="fullCenteredTerms cookiesMargins">
                <b> 3 - DISCLOSURE OF YOUR INFORMATION</b>
            </div>


            <p className="leftalign">
            3.1 - Other than as expressly set out in this Privacy Policy or as otherwise required or permitted by law, we will not share, sell or distribute any of the information you provide to us without your consent.
            </p>
            <p className="leftalign">
            3.2 - We may disclose your personal information:
            </p>
            <p className="leftalign">
            3.2.1 - To any affiliate or third party to whom disclosure is necessary to enable us to provide you with any services or products that you have requested through Zing Party and Zing Sport which may be provided by such affiliates or third parties (e.g. we use the help of third party payment providers such as Stripe (<a href="https://stripe.com/en-gb-ro" >www.stripe.com</a>) and Mangopay (<a href="http://www.mangopay.com" >www.mangopay.com</a>) to process and manage payments made through ZING PARTY / ZING SPORT see our <a href="/terms" > Terms of Use</a> for more information about this);
            </p>
            <p className="leftalign">
            3.2.2 - Third party advertisers in anonymous and aggregated form;
            </p>
            <p className="leftalign">
            3.2.3 - In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets; and
            </p>
            <p className="leftalign">
            3.2.4 - To any person to whom disclosure is necessary to enable us to enforce our rights under this Privacy Policy or under our <a href="/terms" >Terms of Use;</a>
            </p>

            <div className="fullCenteredTerms cookiesMargins">
                <b> 4 - THIRD PARTY CONTENT AND SITES</b>
            </div>

            <p className="leftalign">
            4.1 – Zing Scanner  contain content and links from Zing Sport and Zing Party website and mobile applications. Zing Scanner is directly connect with the business users accounts from Zing Sport and Zing Party website and mobile application.
            </p>

            <div className="fullCenteredTerms cookiesMargins">
                <b> 5 - HOW WE PROTECT YOUR INFORMATION</b>
            </div>

            <p className="leftalign">
            5.1 - All information that we hold about you will be collected and processed by us or by a data processor appointed by use to undertake processing on our behalf. Your personal data will be stored on servers located within the EU and controlled by us.
            </p>
            <p className="leftalign">
            5.2 - We have put in place appropriate technological and operational security measures to protect against any unauthorized access to or any unlawful processing of any personal information about you that we hold. However, you acknowledge that data transmission over the internet is inherently insecure and that we cannot guarantee the security of any data sent over the internet;
            </p>
            <p className="leftalign">
            5.3 - Your personal information will only be retained by us for a reasonable period of time or as long as the law requires or permits;
            </p>


            <div className="fullCenteredTerms cookiesMargins">
                <b> 6 - YOUR RIGHTS</b>
            </div>



            <p className="leftalign">
            6.1 - You are entitled to know what personal data we hold about you and you may ask us to make any necessary changes to ensure that it is accurate and kept up to date. You can delete, or ask us to delete, your account freely. Deleting your account is definite and irreversible action. If you have a paid booking, you have to cancel it in your Bookings section of Zing Party or Zing Sport only then it is possible to delete the account.
            </p>
            
            <p className="leftalign">
            6.2 - If you have any questions about the personal information that we hold about you or would like to make any requests in relation to it, please contact us using the contact information provided above. You can also access all information we have on you in machine- readable format in your Account Settings through Zing Sport or Zing Party website and mobile applications.
            </p>
            <p className="leftalign">
           
            6.3 - We reserve the right to request sufficient proof of your identify to validate your  Account. Failure to provide sufficient proof of identity may result in your Account being deleted.
            </p>
           
            <p className="leftalign">
                <a target='_blank' href={process.env.PUBLIC_URL + fileToDownload}>Save as PDF</a>
            </p>
        </div>
);
    // }
    // else if(props.isTerms === true){
    //     fileToDownload='dataprotection.pdf';
    //     textToDisplay=(<p>data info text</p>);
    // }
    

    return(
            <div className="terms">
                <a target='_blank' href={process.env.PUBLIC_URL + fileToDownload}>Save as PDF</a>
                {textToDisplay}
            </div>
    );
}

export default PrivacyScanner;