import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./ErrorBoundary.scss";
import { fetchApi } from "../../fetchApi";

let SLogo = require("../../Assets/img/SLogo.png");

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      errorModal: true,
      firstTime: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  toggleErrorModal = () => {
    this.setState({ errorModal: !this.state.errorModal });
  };

  writeToFile = async () => {
    if (this.state.firstTime) {
      const url = "writetolog";
      const data = new FormData();
      data.append("message", this.state.error);
      const response = await fetchApi(url, "POST", data);
      this.setState({ firstTime: false });
    }
  };

  render() {
    if (this.state.errorInfo) {
      this.writeToFile();
      return (
        <div>
          <Modal
            isOpen={this.state.errorModal}
            // toggle={this.toggleErrorModal}
            size="lg"
          >
            <div className="header-p">
              <ModalHeader className="head-1  text-white">
                Error modal
              </ModalHeader>
              <div className="logo_image">
                <img className="my_logo" src={SLogo} alt="my_logo" />
              </div>
              <ModalHeader className="head-2" toggle={this.toggleErrorModal} />
            </div>
            <ModalBody>
              <div className="errorText">Something went wrong.</div>
            </ModalBody>
          </Modal>
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> */}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
