import React, {Component} from "react";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import "./SelectLocation.scss"

export default class SelectLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {location: ""};

    }


    handleChange = (address) => {
        if (this.props.enabledText) {
            this.setState({location: address});
            if (address.length > 1 || address.length === 0) {
                if (address.length === 0 && this.props.selectCountry) {
                    this.props.selectCountry("", null, null, "");
                }
                if (address.length === 0 && this.props.selectCity) {
                    this.props.selectCity("", null, null, "");
                }
            }
        }

    };

    handleSelect = (address) => {
        this.setState({location: address});
        if (address !== undefined) {
            if (address.length > 2) {
                geocodeByAddress(address)
                    .then((results) => {
                        getLatLng(results[0]);
                        let countryCode = results[0].address_components[0].short_name;
                        const latval = results[0].geometry.location.lat()
                            ? results[0].geometry.location.lat()
                            : 0;
                        const lngval = results[0].geometry.location.lng()
                            ? results[0].geometry.location.lng()
                            : 0;
                        if (this.props.cityType) {
                            this.props.selectCity(address, latval, lngval);
                        } else {
                            this.props.selectCountry(
                                address,
                                latval,
                                lngval,
                                countryCode.toLocaleLowerCase()
                            );
                        }
                    })
                    .catch((error) => console.error("Error", error));
            }
        }
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.location}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={this.props.searchOptions}

                shouldFetchSuggestions={this.state.location.length > 1}
            >
                {({getInputProps, suggestions, getSuggestionItemProps}) => (
                    <div style={{width: "100%"}}>
                        <input
                            placeholder={
                                this.props.placeHolder
                            }
                            {...getInputProps({
                                className: "locations_promotions_voucher",
                                autoComplete: "new-password"
                            })}
                        />
                        <div className="autocomplete-dropdown-promotions">
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                const style = suggestion.active
                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                    : {backgroundColor: "#ffffff", cursor: "pointer"};

                                if (
                                    !this.props.cityType &&
                                    suggestion.types.includes("country")
                                ) {
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                } else if (
                                    this.props.cityType &&
                                    this.props.selectedZone !== ""
                                ) {
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.formattedSuggestion.mainText}</span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>

        );
    }
}
