import React from "react";
import {
    Button,
    Input,
    Container,
    Col,
    Row,
    Label,
    UncontrolledPopover,
    PopoverHeader,
    FormGroup,
    PopoverBody,
    ModalHeader,
    ModalBody,
    Modal,
    Tooltip,
} from "reactstrap";
import "./ManagerProfile.scss";
import ResponsiveEmbed from "react-responsive-embed";
import ReactFileReader from "react-file-reader";
import {fetchApi} from "../../../fetchApi.js";
import {userCredentials} from "../../../globalVariables";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ZingMap from "../../ZingMap/ZingMap";
import swal from "sweetalert";
import Promotions from "../Promotions/Promotions";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../../index.css";
import {
    faCreditCard,
    faLock,
    faCheck, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import ReactCrop from "react-image-crop";

let SLogo = require("../../../Assets/img/SLogo.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class ManagerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileModal: false,
            selectedFile: null,
            videoUrl: "",
            secondImage: null,
            secondImageToUpload: null,
            companyName: "",
            companyRegistration: "",
            address: "",
            town: "",
            email: "",
            phoneNumber: "",
            category: "",
            description: "",
            website: "",
            imagePreviewUrl: "",
            imagesFromServer: [],
            country: "",
            allCategories: [],
            chooseCateg: "",
            categoryName: "",
            categId: "",
            modal: false,
            zingMapList: [],
            openOffers: false,
            seeAll: false,
            openBackModal: false,
            cardsList: [],
            showAddCard: false,
            userDeactivated: false,
            openTags: false,
            currentTags: "",
            tagValue: "",
            vat: "",
            makeitheader: false,
            countries: [],
            countryId: 0,
            showTerms: false,
            fileToUpload: null,
            tooltipOpenTags: false,
            tooltipOpen: false,
            alliasId: "",
            croppedImageUrl: null,
            stripeCountries:
            [

                {id:1, CountryName:"AUSTRALIA, AUSTRIA. BELGIUM, BULGARIA, CANADA, CYPRUS, CZECH REPUBLIC, DANEMARK,ESTONIA, FINLAND, FRANCE, GERMANY, GREECE, HONG KONG SAR CHINA, IRELAND, ITALY, JAPAN, LATVIA, LITHUANIA, LUXEMBOURG, MALTA, MEXICO, NETHERLANDS, NEW ZEALAND, NORWAY, POLAND, PORTUGAL, Romania, SINGAPORE, SLOVAKIA, SLOVENIA, SPAIN, SWEDEN, SWITZERLAND, UNITED KINGDOM, UNITED STATES. "}


            ],
            crop: {
                unit: "%",
                width: 30,
                aspect: 9 / 9,
            },
        };
        this.toggleOffers = this.toggleOffers.bind(this);
    }

    fetchCountry = async () => {
        const url = "listofcountries";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({
                countries: response.listofcountries,
                //country: response.listoflanguages[0].flag,
            });
        }
    };
    toggleMyModal = (open) => {
        if (open) {
            this.setState({
                openBackModal: !this.state.openBackModal,
                myModal: open,
            });
        } else {
            this.setState({myModal: open, openBackModal: false});
        }
    };


    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({myModal: true, showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };
    toggleAddCard = (needRefresh) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            this.getUserCards();
        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    delete_user = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "delete_user";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            if (localStorage.getItem("react-cookie-consent-notification")){
                localStorage.clear();
                localStorage.setItem("react-cookie-consent-notification", true);
            }else {
                localStorage.clear();
            }
            if (this.props.handleLogOut) {
                this.props.handleLogOut(false, null);
            }
            if (this.props.handleLogOutBus) {
                this.props.handleLogOutBus(false, null);
            }
        } else {
            swal("Error", response.message, "error");
        }
    };


    getCountry = async id => {
        const url = "listofcountries";
        const response = await fetchApi(url, "POST");
        if (response.success) {
            let country = response.listofcountries.filter(
                item => parseInt(item.id) === parseInt(id)
            );

            if (country && country.length > 0) {
                this.setState({country: country[0].en_name});
                this.setState({countryId: country[0].id});
            }
        }
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);

        if (
            response.success &&
            response.userprofile != null &&
            response.userprofile[0] !== undefined
        ) {
            if (response.user_informations.address !== null) {
                this.setState({
                    imagePreviewUrl:
                        response.userprofile[0].img_makeitheader == 2
                            ? response.userprofile[0].logo
                            : response.userprofile[0].image,
                    imagesFromServer: response.userprofile_images,
                    address: response.user_informations.address,
                    secondImage: response.userprofile[0].image,
                    //videoUrl: response.userprofile[0].video_url,
                    language:
                        response.userprofile[0].language !== null &&
                        response.userprofile[0].language !== "null"
                            ? response.userprofile[0].language
                            : "",
                });
                if (response.userprofile_images.length !== 0) {
                    this.setState({showImagesFromServer: true});
                }
            } else {
                this.setState({images: response.message});
            }
            // console.log(response.user_informations)

            this.setState({
                currentTags: response.user_informations.search_words,
                companyName: response.user_informations.company_name,
                email: response.user_informations.email,
                companyRegistration: response.user_informations.company_registration_number,
                categoryName: response.category_name,
                // phoneNumber: response.userprofile[0].phone_number,s
                // website: response.userprofile[0].website_url,
                categId: response.user_informations.category,
                vat: response.user_informations.tva,
                makeitheader: response.userprofile[0].img_makeitheader ? (response.userprofile[0].img_makeitheader == "1" ? true : false) : false
            });

            if (response.userprofile[0].video_url !== null && response.userprofile[0].video_url !== "" && response.userprofile[0].video_url !== "null") {
                this.setState({videoUrl: response.userprofile[0].video_url})
            } else {
                this.setState({videoUrl: ""})
            }
            if (response.userprofile[0].phone_number !== null) {
                this.setState({phoneNumber: response.userprofile[0].phone_number})
            } else {
                this.setState({phoneNumber: ""})
            }
            if (response.userprofile[0].website_url !== null && response.userprofile[0].website_url !== "" && response.userprofile[0].website_url !== "null") {
                this.setState({website: response.userprofile[0].website_url})
            } else {
                this.setState({website: ""})
            }
            if (this.state.description !== null && this.state.description !== "" && this.state.description !== "null") {
                this.setState({description: response.userprofile[0].description})
            } else {
                this.setState({description: response.userprofile[0].description})
            }
            this.getCountry(response.user_informations.country);
        } else {
            this.closePopUp();
            swal("Error!", "Invalid request", "error");
        }
    };

    getForbidenWords = async () => {
        let data = new FormData();
        let url = "forbidenwords";
        const response = await fetchApi(url, "GET", data);
        if (response.success) {
            this.setState({forbidenWords: response.forbiden_words});
        }
    };

    getMyLocations = async () => {
        const data = new FormData();
        const url = "getbusinesslocationperbusiness";
        data.append("business_user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success && response.locations && response.locations.length > 0) {
            this.setState({zingMapList: response.locations});
        }
    };

    componentDidMount = () => {
        this.getMyLocations();
        this.fetchCountry();
        this.getUserCards();
        this.getProfile();
        this.getCategories();
        this.getForbidenWords();

    };

    onChangeWebsite = evt => {
        this.setState({website: evt.target.value});
    };

    onChangeDescription = evt => {
        this.setState({description: evt.target.value});
    };


    onChangePhoneNumber = evt => {
        this.setState({phoneNumber: evt.target.value});
    };

    onChangeEmail = evt => {
        this.setState({email: evt.target.value});
    };
    onChangeVAT = evt => {
        this.setState({vat: evt.target.value});
    }

    onChangeTown = evt => {
        this.setState({town: evt.target.value});
    };


    onChangeCompanyRegistration = evt => {
        this.setState({companyRegistration: evt.target.value});
    };

    onChangeCompanyName = evt => {
        this.setState({companyName: evt.target.value});
    };

    onChangeVideourl = evt => {
        this.setState({videoUrl: evt.target.value});
    };


    handleSecondImg = file => {
        this.setState({secondImage: file.base64, secondImageToUpload: file});
    };



  onChangeCategory = (evt, value) => {

    if (value) {
      this.setState({
        categoryName: value.name !== undefined ? value.name : value.name_of_subcategory,
        categId: value.id,
      });
      // console.log(value,"hello")
    }
  };
    // getCategories = async () => {
    //     const url = "get_categories";
    //     const response = await fetchApi(url, "POST", null);
    //     if (response.success) {
    //         this.setState({allCategories: response.list_of_categories});
    //     }
    // };
    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        let list = [],
            alias = [],
            categs;
        if (response.success && response.list_of_categories) {

            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({allCategories: list});
        }
    };
    makeitheaderChange = (evt) => {
        this.setState({makeitheader: evt.target.checked});
    }

  saveHandler = () => {
    if (this.verifyForBadWords(this.state.description)) {
      this.updateProfile();
      this.closePopUp();
    } else {
      swal("Error!", "The description contains bad words!", "error");
    }
  };

    closePopUp = () => {
        this.setState(this.props.toggleSelfModal(false))
    };

  updateProfile = async (searchWords) => {
    let authKey = userCredentials.authKey;
    let data = new FormData();
    let url = "updateprofile";

    data.append(
      "image",
      this.state.secondImageToUpload !== null
        ? this.state.secondImageToUpload.fileList[0]
        : ""
    );
    // if (this.state.fileToUpload !== "") {
    data.append("logo", this.state.fileToUpload);
     
    // }
    data.append("company_registration_number", this.state.companyRegistration);
    data.append("company_name", this.state.companyName);
    data.append("user_id", userCredentials.userId);
    data.append("tva", this.state.vat);
    data.append("language", this.state.language);
    data.append("category_id", this.state.categId);
    // if (this.state.alliasId) {
    data.append("alias_category_id", this.state.categId);
    // }
    data.append(
      "video_url",
      this.state.videoUrl !== null ? this.state.videoUrl : ""
    );

    data.append(
      "address",
      this.state.address !== null ? this.state.address : ""
    );
    if (searchWords !== undefined) {
      data.append("search_words", searchWords);
    }
    data.append("phone_number", this.state.phoneNumber);

    data.append("website_url", this.state.website);
    if (this.state.description !== null && this.state.description !== "") {
      data.append("description", this.state.description);
    } else {
      data.append("description", "");
    }
    // data.append("description", this.state.description !== null ?this.state.description  : "" );

    data.append("email", this.state.email);
    data.append("country_id", this.state.countryId);
    data.append("img_makeitheader", this.state.makeitheader ? 1 : 2);

    const response = await fetchApi(url, "POST", data, authKey);
    //console.log(response);
    if (response.success) {
      this.getProfile();
      if (this.props.refreshProfile) {
        this.props.refreshProfile();
      }
      swal("Success!", "Profile has been updated!", "success");
    } else {
      this.setState({ images: response.message });
    }
  };

    toggle = () => {
        this.setState({modal: !this.state.modal});
    };

    changeLocationHandler = (address) => {
        this.setState({address: address});
    };

    verifyForBadWords = (text) => {
        let badWords = this.state.forbidenWords.split(";");
        let count = 0;
        if (text && text.length !== 0) {
            badWords.forEach((element) => {
                if (text.includes(element)) {
                    count++;
                }
            });

        }
        return count === 0;
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal("Success!", "Card successfully set as default!", "success");
        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    toggleOffers(typeOpen, handlerPromotion) {
        if (handlerPromotion) {
            this.setState({
                seeAll: typeOpen,
                openOffers: false,
            });
        } else {
            this.setState({
                seeAll: typeOpen,
                openOffers: !this.state.openOffers,
            });
        }
    }

    toggleTags() {
        this.setState({openTags: !this.state.openTags})
    }

    addTag() {
        if (this.state.currentTags === null || this.state.currentTags === "null" || this.state.currentTags === "" || this.state.currentTags === " ") {
            let searchWords = this.state.tagValue;
            this.setState({tagValue: ""});
            this.updateProfile(searchWords);
        } else {
            let searchWords = this.state.currentTags + ";" + this.state.tagValue;
            this.setState({tagValue: ""});
            this.updateProfile(searchWords);
        }
    }

  removeTag(index) {
    let tags = this.state.currentTags.split(";");

    if (tags.length > 1) {
      tags.splice(index, 1);

      let newTagString = "";
      for (let i = 0; i < tags.length; i++) {
        if (i === tags.length - 1) {
          newTagString += tags[i];
        } else {
          newTagString += tags[i] + ";";
        }
      }

      this.updateProfile(newTagString);
    } else {
      this.setState({ currentTags: "" });
      this.updateProfile("");
    }
  }

    toggleTooltipTags = () => {
        this.setState({tooltipOpenTags: !this.state.tooltipOpenTags})
    }

    renderTooltipTag() {
        return (
            <div>
                <div className="justify-content-center align-bottom">
                    <FontAwesomeIcon className="iconTooltip" icon={faExclamationTriangle} id="toolTipTags"
                                     color={"#f5b91c"}/>
                </div>
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpenTags}
                    target="toolTipTags"
                    toggle={this.toggleTooltipTags}
                    hideArrow
                >
                    <div className="toolTipGeneral">
                        Poti adauga pana la 4 tag-uri in orice limba si asa le va citi sistemul
                        de search in app.
                    </div>
                </Tooltip>
            </div>
        )
    }

    renderTags() {
        if (this.state.currentTags) {
            let tags = this.state.currentTags.split(";");
            return (<div className="d-block w-100">
                <div className="tag-class">
                    <Label>Add a new tag:</Label>
                    <div className="d-flex justify-content-center">
                        <Input placeholder="Tags " id="inputID" className="tagText" type="text"
                               value={this.state.tagValue}
                               onChange={(e) => this.setState({tagValue: e.target.value})}/>
                        <Button
                            className={
                                // this.state.tagValue &&
                                // this.state.tagValue.length >= 2 &&
                                // tags.length < 4 ?
                                "addBtn enabledBtn"
                                // :
                                // "addBtn disabledBtn"
                            }
                            onClick={() => {
                                if (this.state.tagValue.length >= 2 && tags.length < 4) {
                                    this.addTag()
                                } else if (this.state.tagValue.length <= 2) {
                                    swal(
                                        "Warning!",
                                        "Tag must be at least 2 characters long!",
                                        "warning"
                                    );

                                } else {
                                    swal(
                                        "Warning!",
                                        "You can't have more then 4 tags!!",
                                        "warning"
                                    );
                                }
                            }}>Add</Button>
                        {this.renderTooltipTag()}

                    </div>
                </div>
                <div className="tagContainer">
                    {
                        tags.map((tag, index) => (
                            <div key={index} className="tag-x">

                                <a
                                    key={index}
                                    className="tag-input"
                                    onClick={() => {
                                        this.removeTag(index)

                                    }}
                                > X </a>
                                <Label className="main-tag">{tag}
                                </Label>
                            </div>

                        ))
                    }
                </div>

            </div>)
        } else {

            return (<div className="d-block w-100">
                <div className="tag-class">
                    <Label>Add a new tag:</Label>
                    <div className="d-flex justify-content-center">
                        <Input placeholder="Tags" className="tagText" type="text" value={this.state.tagValue}
                               onChange={(e) => this.setState({tagValue: e.target.value})}/>
                        <Button
                            className={this.state.tagValue && this.state.tagValue.length >= 2 ? "addBtn enabledBtn" : "addBtn disabledBtn"}
                            onClick={() => {
                                if (this.state.tagValue.length >= 2) {
                                    this.addTag()
                                }
                            }}>Add</Button>
                        {this.renderTooltipTag()}
                    </div>
                </div>
            </div>)
        }

    }

    connectStripe() {
      
        if(this.props.fromWrapperProp === true){

        // const currentLink = window.location.href;
            // let stripeLink = "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_Gpo6YdoF9rbwXwrMVMUdLh872ktQ3Xrm&scope=read_write&redirect_uri=https://zing-party.com" + currentLink;
            let stripeLink = "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_Gpo66lMTOjxDohq3rcsIp4OGpI4TGZKZ&scope=read_write&redirect_uri=https://zing-party.com/" ;

            window.location.replace(stripeLink);

        }else{
            // const currentLink = window.location.href;
            // let stripeLink = "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_Gpo6YdoF9rbwXwrMVMUdLh872ktQ3Xrm&scope=read_write&redirect_uri=https://zing-party.com/?from=wrapper" + currentLink;
            let stripeLink = "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_Gpo66lMTOjxDohq3rcsIp4OGpI4TGZKZ&scope=read_write&redirect_uri=https://zing-party.com/?from=wrapper";
            window.location.replace(stripeLink);
        }

    }

    showTermsAndCondition = (isTermsWindow) => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };

    toggleProfileModal = () => {
        //console.log(this.state.modalProfileImg);
        this.setState({modalProfileImg: !this.state.modalProfileImg});
    };

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({src: reader.result})
            );

            reader.readAsDataURL(e.target.files[0]);
            let fileName = userCredentials.userId + "_" + e.target.files[0].name;
            this.setState({logoName: fileName.toString()});
        }
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                var file = new File([blob], fileName, {type: "image.*"});
                //let file = this.blobToFile(blob, "img.png");
                this.setState({fileToUpload: file});

                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.logoName
            );
            this.setState({croppedImageUrl, showCropImage: true});
        }
    }

    fromCroppedToProfile = () => {
        if (this.state.croppedImageUrl != null) {
            this.setState({imagePreviewUrl: this.state.croppedImageUrl});
        }
        this.toggleProfileModal();
    };

    onChangeLanguage = (evt) => {
        this.setState({language: evt.target.value});
    };
    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }
    renderToolTip() {
        return (<div>
            <div className="justify-content-center align-bottom">
                <FontAwesomeIcon className="iconTooltipManager" icon={faExclamationTriangle} id="toolTipCategory"
                color={"#f5b91c"}/>
            </div>
            <Tooltip
                placement="top"
                isOpen={this.state.tooltipOpen}
                target="toolTipCategory"
                toggle={this.toggleTooltip}
                hideArrow
            >
                <div className="toolTipGeneral">
                    <h6>
                        Connect Stripe
                    </h6>
                    { this.state.country !== "Romania" &&
                    this.state.country !== " United States" &&
                    this.state.country !== "United Kingdom"
                            ?
                            <div>
                                  <p className="tooltipC">
                                UNFORTUNATELY, STRIPE FOR BUSINESS IS AVAILABLE JUST IN A FEW COUNTRIES. SEE THE LIST
                                BELOW. FOR THE REST OF THE COUNTRIES YOU CAN USE A BUSINESS USER ACCOUNT TO MAKE
                                RESERVATIONS AND THIS IS A FREE SERVICE. (YOU CANNOT SELL TICKETS AND DEALS. WE
                                APOLOGISE FOR THIS

                            </p>

                                </div>
                            :
                            <p className="tooltipC">
                                JUST FOR BUSINESSES USERS WITH COMPANIES REGISTER IN THE {this.state.country}
                            </p>
                    }
                      {this.state.stripeCountries.map(item => (
                                <div className="listCountryName" >

                            {item.CountryName}

                                     </div>
                            )) }
                </div>
            </Tooltip>
        </div>)
    }

    render() {
        return (
            <Container className="business myManagerPopup">
                <Modal
                    isOpen={this.state.openOffers}
                    //   toggle={() => this.toggleOffers(this.state.seeAll)}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.seeAll
                                ? "Your current promotions"
                                : "Create promotion"}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toggleOffers(this.state.seeAll)}
                        />
                    </div>
                    <ModalBody>
                        <Promotions
                            seeAll={this.state.seeAll}
                            closePromotion={this.toggleOffers}
                            is_deal={1}
                        />
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.openTags}
                    // toggle={() => this.toggleTags()}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Your current tags
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toggleTags()}
                        />
                    </div>
                    <ModalBody>

                        {this.renderTags()}
                    </ModalBody>
                </Modal>
                <div className="d-flex justify-content-between">
                    <div className="col-12 p-0">
                        <div className="row">
                        <div className="col-12 col-lg-6 d-flex align-items-center">


                            <div className="imgPreview-manager">
                                {this.state.croppedImageUrl !== null && this.state.showCropImage ? (
                                    <img
                                        id="PopoverLegacy"
                                        className="myProfileImage-manager ml-3"
                                        src={this.state.croppedImageUrl}
                                    />
                                ) : (
                                    <img
                                        id="PopoverLegacy"
                                        className="myProfileImage-manager ml-3"
                                        src={
                                            this.state.imagePreviewUrl !== null &&
                                            this.state.imagePreviewUrl !== "" &&
                                            this.state.imagePreviewUrl !== undefined
                                                ? this.state.imagePreviewUrl
                                                : profilePic
                                        }
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = profilePic;
                                        }}
                                    />
                                )}

                                {/* <UncontrolledPopover
                                    className="pop-over-block"
                                    trigger="legacy"
                                    placement="bottom"
                                    target="PopoverLegacy"
                                >
                                    <PopoverHeader>
                                        {" "}
                                        You can change your logo from your profile
                                    </PopoverHeader>
                                </UncontrolledPopover> */}
                                <Col className="d-flex buttonUpload-parrent justify-content-center">
                                    <Button
                                        onClick={this.toggleProfileModal}
                                        className="buttonUpload-manager"
                                    >
                                        Upload
                                    </Button>
                                </Col>
                            </div>

                            <div className="tags">
                                <Button className="tags_button" onClick={() => this.toggleTags()}> Tags
                                    Management</Button>
                            </div>
                        </div>


                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center"  id="submit">
                            <div className="connectToolKid" >
                            <img 
                            src={require("../../../Assets/img/stripeBtn.png")} 
                            className="stripeButton" 
                            onClick={() => {
                                this.connectStripe()
                            }}/>

                                {this.renderToolTip()}
                            </div>


                        </div>
                        </div>
                    </div>
                </div>

                <Row className="">
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Company Name:</Label>
                            <Input
                                type="text"
                                className="input-manager"
                                value={this.state.companyName ? this.state.companyName : ""}
                                onChange={this.onChangeCompanyName}
                            />
                        </Col>
                    </Col>
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">
                                Company No. Registration:
                            </Label>
                            <Input
                                className="input-manager"
                                type="text"
                                placeholder=""
                                value={this.state.companyRegistration ? this.state.companyRegistration : ""}
                                onChange={this.onChangeCompanyRegistration}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Address:</Label>
              <div className="input-manager search-bar-div-manager location-search-input-manager ">
                                <ZingMap
                                    initialAddress={this.state.address}
                                    showSearchTextBox={true}
                                    showOpenMapButton={false}
                                    inputClassName="hpSearchLocation"
                                    buttonClassName="hpBtnLocationOpen"
                                    enableMarkerAdd={false}
                                    selectedLocationHandler={this.changeLocationHandler}
                                />
                            </div>
                        </Col>
                    </Col>
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Country:</Label>
                            <Autocomplete
                                options={this.state.countries}
                                getOptionLabel={(option) => option.name}
                                className="drop-down-stl-manager text-white autoSearch-manager"
                                onChange={(e, val) => {
                                    this.setState({
                                        country: val && val.en_name ? val.en_name : "",
                                        countryId: val ? val.id : -1
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                className="  text-white "
                                        {...params}
                                        value={this.state.country}
                                        variant="outlined"
                                        placeholder={this.state.country}
                                    />
                                )}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Email:</Label>
                            <Input
                                className="input-manager"
                                type="text"
                                value={this.state.email ? this.state.email : ""}
                                onChange={this.onChangeEmail}
                            />
                        </Col>
                    </Col>
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Phone Number:</Label>
                            <Input
                                className="input-manager"
                                type="text"
                                value={this.state.phoneNumber ? this.state.phoneNumber : ""}
                                onChange={this.onChangePhoneNumber}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">VAT:</Label>
                            <Input
                                className="input-manager"
                                type="number"
                                value={this.state.vat ? this.state.vat : ""}
                                onChange={this.onChangeVAT}
                            />
                        </Col>
                    </Col>
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">language:</Label>
                            <Input
                                className="input-manager"
                                value={this.state.language ? this.state.language : ""}
                                onChange={this.onChangeLanguage}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Upload video url:</Label>
                            <Input
                                className="input-manager"
                                placeholder="Upload new video"
                                type="text"
                                value={this.state.videoUrl}
                                onChange={this.onChangeVideourl}
                            />
                        </Col>
                    </Col>
                    <Col lg="6" className="">
                        <Col className="">
                            <Label className="Label-text-manager">Upload website:</Label>
                            <Input
                                className="input-manager"
                                type="text"
                                placeholder="Your website"
                                value={this.state.website ? this.state.website : ""}
                                onChange={this.onChangeWebsite}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="6" className="">
                        <Row className="">
                            <Col lg="8" className="">
                                <ResponsiveEmbed
                                    className="video-size"
                                    src={
                                        this.state.videoUrl && this.state.videoUrl !== ""
                                            ? this.state.videoUrl.substring(0, 23) +
                                            "/" +
                                            "embed/" +
                                            this.state.videoUrl.substring(
                                                32,
                                                this.state.videoUrl.length
                                            )
                                            : ""
                                    }
                                    allowFullScreen
                                />
                            </Col>
                        </Row>

                    </Col>
                    <Col lg="6" className="">
                        <Row className="">
                            <ReactFileReader
                                fileTypes="image/*"
                                base64
                                handleFiles={this.handleSecondImg}
                                className="w-100"
                            >
                                {this.state.secondImage !== null ? (
                                    <div className="divImage">
                                        <img
                                        className="imageBoxMyProfile"
                                            src={this.state.secondImage}
                                        />
                                    </div>

                                ) : (
                  <Button className="upload-manager" type="file">Upload file</Button>
                                )}
                            </ReactFileReader>
                        </Row>
                        {this.state.secondImage !== null ? (
                            <Row className=" center-checkBox ">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" onChange={this.makeitheaderChange}
                                               checked={this.state.makeitheader}/>
                                        Make it header
                                    </Label>
                                </FormGroup>
                            </Row>
                        ) : (null)}

                    </Col>
                </Row>
                <Row>
                    <Col lg="6" className="">
                        <Label className="">Category</Label>
                        <Autocomplete
                            id=""
                            options={this.state.allCategories}
                            // getOptionLabel={(option) => option.name}
                            getOptionLabel={(option) =>
                                option.name_of_subcategory
                                    ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                    : option.name
                            }
                            // className="drop-down-stl  autoSearch"
                            className="drop-down-stl-manager text-white autoSearch-manager"

                            onChange={(e, val) => this.onChangeCategory(e, val)}
                            renderInput={(params) => (
                                <TextField
                                className="categoryAC-manager text-white"
                                    {...params}
                                    value={this.state.categoryName}
                                    variant="outlined"
                                    placeholder={
                                        this.state.categoryName !== null ?
                                            this.state.categoryName :
                                            'Choose Category'

                                    }
                                />
                            )}
                        />
                    </Col>
                    <Col className="">
                        <Label className="">Description</Label>
                        <Input
                        id="inputIdText"
                        className="textarea-style"
                            type="textarea"
                            placeholder={"Add a description"}
                            value={this.state.description ? this.state.description : ""}
                            onChange={this.onChangeDescription}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="promotion_buttons">
                        <ZingMap
                            showSearchTextBox={false}
                            showOpenMapButton={true}
                            inputClassName=""
                            buttonClassName="nrmgreenBtnEvt iconBtnSpace"
                            buttonText="Promote in z-Map"
                            enableMarkerAdd={true}
                            selectedLocationHandler={(address, lat, lng) => {
                                this.onChangeLocation(address, lat, lng);
                            }}
                            list={this.state.zingMapList}
                            disableSelect={true}
                            toggleMyModal={this.toggleMyModal}
                            openBackModal={this.state.openBackModal}
                        />


                        {/* <Col  > */}
                        <Button className="nrmredBt" color="danger" id="PopoverClick" type="button">
                            Delete Account
                        </Button>

                        <UncontrolledPopover
                            trigger="legacy"
                            placement="bottom"
                            target="PopoverClick"
                        >
                            <PopoverHeader className="delete-text">
                                Are you sure you want to delete your account ?{" "}
                            </PopoverHeader>
                            <PopoverBody className="DeleteBtn-P">
                                <Container>
                                    <Row className="DeleteBtn-c">
                                        <Col sm={{size: "auto", offset: 1}}>
                                            <Button
                                                className="DeleteBtn-B"
                                                color="danger"
                                                onClick={() => this.delete_user()}
                                            >
                                                Delete{" "}
                                            </Button>{" "}
                                        </Col>
                                    </Row>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                    {/* </Col> */}
                </Row>
                <Col className="promotion_buttons">
                    <Button
                        className="nrmgreenBtnEvt promotion_space"
                        onClick={() => this.toggleOffers(false)}
                    >
                        Promote business
                    </Button>
                    <Button
                        className="nrmgreenBtnEvt promotion_space"
                        onClick={() => this.toggleOffers(true)}
                    >
                        See all offers
                    </Button>
                </Col>
                <div className="row-lg-12 m-3 d-flex justify-content-center">
                    <Button onClick={this.saveHandler} className="myMngBtn">
                        Save changes
                    </Button>
                </div>
                <Modal
                    isOpen={this.state.myModal}
                    size="lg"
                    // toggle={this.toggleMyModal}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Select card
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleMyModal}/>
                    </div>
                    <ModalBody>
                        <div className="cardDetailsParent">
                            <Row>
                                <Col>
                                    {this.state.cardsList.length > 0 ? (
                                        this.state.cardsList.map((item) => (
                                            <Row
                                                key={item.id}
                                                className="d-flex align-items-center voucherContainer cardsListContainer"
                                                onClick={() => {
                                                    this.setDefaultCard(item.id);
                                                }}
                                            >
                                                <Col xs="6">
                                                    <div>{item.name}</div>
                                                </Col>
                                                <Col xs="2">
                                                    {item.exp_month}/{item.exp_year}
                                                </Col>
                                                <Col xs="2">{item.last4}</Col>
                                                <Col xs="2">
                                                    {item.default ? (
                                                        <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div>No cards saved</div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        <Button
                                            className="addCardBtn"
                                            onClick={() => {
                                                this.showAddCard();
                                            }}
                                        >
                                            + Add card
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                        </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                                        </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions isTerms={this.state.isTerms}/>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard}/>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.modalProfileImg}
                    //   toggle={this.toggleProfileModal}
                    centered={true}
                    size="lg"
                >
                    <ModalBody className="d-flex flex-column">
                        <div>
                            <input
                                className="fileChoose"
                                type="file"
                                accept="image/*"
                                onChange={this.onSelectFile}
                            />
                        </div>
                        {this.state.src && (
                            <ReactCrop
                                src={this.state.src}
                                crop={this.state.crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        )}
                        <Button className="doneBtn" onClick={this.fromCroppedToProfile}>
                            Done
                        </Button>
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}
