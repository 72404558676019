import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import "./LocationSave.scss";
import {Container, Row,Label, Col, ModalHeader} from "reactstrap";
import swal from "sweetalert";
import { Animated } from "react-animated-css";

import {
    faEuroSign
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
let SLogo = require("../../../Assets/img/SLogo.png");

const LocationSave = props => {
    let locationInput = React.createRef();
    let monthsNoInput = React.createRef();
    let costInput = React.createRef();

    const [disabledLocation, setDisabledLocation] = useState(null);

    const [disabledCosts, setDisabledCosts] = useState(true);

    const [errorMesssage, setErrorMesssage] = useState(false);


    const [locationName, changeLocation] = useState("");

    const onChangeLocation = (evt) => {
        if (evt.key === "Delete" || evt.key === "Backspace") {
            // alert(evt.key);
            setErrorMesssage(true)

        }
        if (evt.target.value.length !== 0 ) {
            setDisabledLocation(false);
            changeLocation(evt.target.value)
            setErrorMesssage(true)

        } else {
            setDisabledLocation(true);
            changeLocation(evt.target.value)
            setErrorMesssage(true)

        }
    };


 
    const toPayCalculate = (evt) => {
        let monthsNo = monthsNoInput.current.value;
        let total = 0;
        if (monthsNo) {
            total = monthsNo * 1;
        }

        if (total !== 0) {
            setDisabledCosts(false);
        } else {
            setDisabledCosts(true);
        }
        costInput.current.value = total;
    }
    const onCero = (e) => {
        console.log(e.key);
        e.preventDefault();
        if (e.key === "Delete" || e.key === "Backspace") {
            alert(e.key);
        }
    };
    return (

        <div>
            <Dialog open={props.showSaveLocation ? props.showSaveLocation : false}>
                <div className="header-p">
                    <ModalHeader className="head-1  text-white"/>
                    <div className="logo_image">
                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                    </div>
                    <ModalHeader
                        className="head-2"
                        toggle={props.cancelLocationHandler}
                    />
                </div>
                <DialogContent>
                    <Container className="locSaveCnt">


                        <Row>
                            <Col xs="6" className="placeNameLabel">
                                Location name:
                            </Col>
                            <Col xs="6">
                                <input
                                    // value={locationName.length === 0 && props.currentSelectedAddress ? props.currentSelectedAddress : locationName}
                                    value={ errorMesssage !== true ? props.currentSelectedAddress : locationName}

                                    // value={locationName}
                                    onKeyDown ={((text) => onChangeLocation(text))}
                                    className="placeName"
                                    ref={locationInput}
                                    onChange={(text) =>  onChangeLocation(text)}
                                />
                            </Col>
                            {/* {errorMesssage !== false ?

                   <Label className="errortxt" > You cannot change this adress. Please find your adress on the map click on it then name it whatever you like. </Label>
                   :null

                            } */}
                           
                        </Row>
                        <Row>
                            <Col xs="6" className="placeNameLabel">
                                Months no:
                            </Col>
                            <Col xs="6">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="placeName"
                                    ref={monthsNoInput}
                                    onChange={() => {
                                        toPayCalculate()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="placeNameLabel">
                                Total costs:
                            </Col>
                            <Col xs="6" className=" ESignLocatio " >
                                <input readOnly
                                       className="placeNameCosts"
                                       ref={costInput}
                                />
                                <div className="euroSignParent">
                                <FontAwesomeIcon className="euroSign" icon={faEuroSign}  />
                                {/* <Label className="euroSign">Euro</Label> */}

                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="text-xs-center">
                                <Row>
                                    <Col xs="5" className="text-xs-center">
                                        <button
                                            disabled={ disabledLocation || disabledCosts}
                                            onClick={() => {
                                                props.saveLocationHandler(locationInput.current.value, monthsNoInput.current.value, costInput.current.value);
                                            }}
                                            className="locationButtons btn"
                                        >
                                            Save
                                        </button>
                                    </Col>
                                    <Col xs="5" className="text-xs-center">
                                        <button
                                            onClick={props.cancelLocationHandler}
                                            className="locationButtons btn"
                                        >
                                            Cancel
                                        </button>
                                    </Col>
                                    <Col xs="5" className="text-xs-center">
                                        {/*<button*/}
                                        {/*  onClick={props.openModal}*/}
                                        {/*  className="locationButtons btn"*/}
                                        {/*>*/}
                                        {/*  Card*/}
                                        {/*</button>*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default LocationSave;