import React from "react";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalBody,
    FormFeedback,
    InputGroupAddon,
    InputGroupText,
    PopoverBody,
    Container,
    Row,
    Col,
    UncontrolledPopover,
    PopoverHeader, InputGroup
} from "reactstrap";
import "./AccountSettings.scss";
import Checkbox from "react-simple-checkbox";
import { fetchApi } from "../../../fetchApi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-modern-calendar-datepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { userCredentials } from "../../../globalVariables";
import moment from "moment";
import swal from "sweetalert";
let women = require("../../../Assets/img/women.png");
let man = require("../../../Assets/img/man.png");

export default class AccountSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkbox: false,
            modal: false,
            genderChangeF: false,
            genderChangeM: false,
            selectedGender: "1",
            id: "",
            user_id: "",
            email: "",
            firstName: "",
            lastName: "",
            birthDay: null,
            gender: "",
            userNormal: true,
            oldPassword: "",
            password: "",
            confirmPassword: "",
            passwordError: "",
            validNewPassword: "",
            validConfirmPassword: "",
            userId: "",
            checkEvents: false,
            checkEventsBus: false,
            updateDetails: false,
            logInEmail:""
        };
    }

    validateNewPassword = e => {
        this.setState({ password: e.target.value });
        const passRegex = /(?=.*\d)(?=.*[A-Za-z]).{6,}/;
        if (passRegex.test(e.target.value)) {
            this.setState({ validNewPassword: "valid" });
        } else {
            this.setState({ validNewPassword: "invalid" });
        }
    };

    validateConfirmPassword = e => {
        this.setState({ confirmPassword: e.target.value });
        const passRegex = /(?=.*\d)(?=.*[A-Za-z]).{6,}/;
        if (passRegex.test(e.target.value)) {
            this.setState({ validConfirmPassword: "valid" });
        } else {
            this.setState({ validConfirmPassword: "invalid" });
        }
    };

    validateEmail = e => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({ email: e.target.value, updateDetails: true });
        if (emailRegex.test(e.target.value)) {
            this.setState({ validEmail: "valid" });
        } else {
            this.setState({ validEmail: "invalid" });
        }
    };

    validateFirstName = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({ firstName: e.target.value, updateDetails: true });
        if (regName.test(e.target.value)) {
            this.setState({ validFirstName: "valid" });
        } else {
            this.setState({ validFirstName: "invalid" });
        }
    };

    validateLastName = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({ lastName: e.target.value, updateDetails: true });
        if (regName.test(e.target.value)) {
            this.setState({ validLastName: "valid" });
        } else {
            this.setState({ validLastName: "invalid" });
        }
    };

    componentDidMount() {
        // console.log(this.props.handleInput,"handleInputbkjhdesahblkajwbed")
        this.getaccountinformations();
    }

    async getaccountinformations() {
        //console.log( auth)
        // let authKey = auth;
        let authKey = userCredentials.authKey;
        let data = new FormData();
        // data.append("user_id", id);
        data.append("user_id", userCredentials.userId);
        const url = "getaccountinformations";
        const response = await fetchApi(url, "POST", data, authKey);
        //.log(response)
        if (response.success) {
            let info = response.account_info[0];


            let year = moment(new Date(info.birthday)).format("YYYY");
            let month = moment(new Date(info.birthday)).format("MM");
            let day = moment(new Date(info.birthday)).format("DD");

            let birthDay = { day: parseInt(day), month: parseInt(month), year: parseInt(year) };
                if(response.account_info[0].birthday === null ){
                this.setState({birthDay: "",})
                    
                }else{
                this.setState({birthDay: birthDay,})
                }

            this.setState({
                logInEmail: response.email ,
                id: info.id,
                userId: info.user_id,
                email: info.email,
                firstName: info.first_name,
                lastName: info.last_name,
                genderChangeM: info.gender === "1",
                genderChangeF: info.gender === "2",
                selectedGender: info.gender === "1" ? ("1") : info.gender === "2" ? ("2") : (null)
            });

            localStorage.setItem("auth_key", response.auth_key);
            localStorage.setItem("userLogged", "true");
        }
    }

    updateaccountinformations = async () => {
        let accountBirthDay = new Date(this.state.birthDay.year + "-" + this.state.birthDay.month + "-" + this.state.birthDay.day);
        accountBirthDay = moment(accountBirthDay, "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD");

        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "updateaccountinformations";
        data.append("user_id", userCredentials.userId);
        // data.append("email", this.state.email);
        data.append("first_name", this.state.firstName);
        data.append("last_name", this.state.lastName);
        data.append("birthday", accountBirthDay);
        data.append("gender", this.state.selectedGender);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            swal("Success!", "Account has been successfully updated!", "success");

            this.setState({updateDetails: false})
        } else {
            swal("Error", "Invalid request!", "error");
        }

        // else {

        //     swal("Info!", "Account has been succesfully updated!", "Info");
        //     // this.setState({
        //     //     passwordError: response.message,
        //     //     validPassword: "invalid"
        //     // });
        // }
    };

    updatepassword = async () => {
        let authKey = userCredentials.authKey;

        let data = new FormData();
        let url = "updatepassword";
        data.append("user_id", userCredentials.userId);
        data.append("old_password", this.state.oldPassword);
        data.append("new_password", this.state.password);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            swal("Success!", "Password updated successfully!", "success");

            if (localStorage.getItem("react-cookie-consent-notification")) {
                localStorage.clear();
                localStorage.setItem("react-cookie-consent-notification", true);
            } else {
                localStorage.clear();
            }


            if (this.props.handleLogOut) {
                this.props.handleLogOut(false, null);
            }
            if (this.props.handleLogOutBus) {
                this.props.handleLogOutBus(false, null);
            }
        } else {
            this.setState({
                passwordError: response.message,
                validPassword: "invalid"
            });
        }
    };

    delete_user = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "delete_user";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            if (localStorage.getItem("react-cookie-consent-notification") && localStorage.getItem("react-cookie-consent-notification") === true) {
                localStorage.clear();
                localStorage.setItem("react-cookie-consent-notification", true);
            } else {
                localStorage.clear();
            }
            if (this.props.handleLogOut) {
                this.props.handleLogOut(false, null);
            }
            if (this.props.handleLogOutBus) {
                this.props.handleLogOutBus(false, null);
            }
        } else {
            //console.log("Something went wrong!!!! my txt")
            swal("Error", response.message,"error");
        }
    };

    toggleUserType(M, F) {
        let gender = M ? "1" : "2";
        this.setState({
            genderChangeF: F,
            genderChangeM: M,
            selectedGender: gender,
            updateDetails: true
        });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    onCheckboxClick = () => {
        this.setState({ checkbox: !this.state.checkbox });
    };

    changeBirthDay(date) {
        this.setState({ birthDay: date, updateDetails: true })

    }
    // checkAccount = () => {
    //         if (this.props.handleInput!== 0) {
    //             this.setState({ checkEvents: this.state.checkEvents  })
    //         } else {
    //             this.setState({ checkEvents: !this.state.checkEvents })
    //         }
    //     }

    // checkAccountBus = () => {
    //     if ( this.props.handleInputBus !==0 ) {
    //             this.setState({ checkEventsBus: this.state.checkEventsBus })
    //         } else {
    //             this.setState({ checkEventsBus: !this.state.checkEventsBus })
    //     }
    // }



    render() {
        return (
            <div className="container">
                <div className="row body-Account ">
                    <div className="col-lg-6 col-md-6 col-sm-12 lside">
                        <div className="d-flex justify-content-center accTitle">
                            Account Info
                        </div>
                        <Form>
                            <FormGroup>
                                <Label for="exampleEmail">Email</Label>
                                <Input
                                disabled={true}
                                    onChange={e => {
                                        this.validateEmail(e);
                                    }}
                                    // value={this.state.email}
                                    value={this.state.logInEmail}
                                    type="email"
                                    name="email"
                                    id="4"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleFName">First Name</Label>
                                <Input
                                    onChange={e => {
                                        this.validateFirstName(e);
                                    }}
                                    value={this.state.firstName}
                                    type="text"
                                    name="text"
                                    id="3"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleLName">Last Name</Label>
                                <Input
                                    onChange={e => {
                                        this.validateLastName(e);
                                    }}
                                    value={this.state.lastName}
                                    type="text"
                                    name="text"
                                    id="2"
                                />
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0 name-1 date-picker-mine datePick">

                                <DatePicker
                                    dateFormat={"YYYY-MM-DD"}
                                    value={this.state.birthDay}
                                    onChange={(date) => this.changeBirthDay(date)}
                                    inputPlaceholder="When"
                                    shouldHighlightWeekends
                                    colorPrimary="#007bff"
                                //   wrapperClassName="calendarHeader"
                                //  inputClassName="calendarHeaderInput"
                                />
                                {/*<DatePicker*/}
                                {/*  popperPlacement="top-start"*/}
                                {/*  placeholderText={this.state.birthDay}*/}
                                {/*  required*/}
                                {/*  selected={this.state.date}*/}
                                {/*  dateFormat={"yyyy-MM-dd"}*/}
                                {/*  onChange={e => {this.setState({ date: e }); }}*/}
                                {/*  showYearDropdown*/}
                                {/*  showMonthDropdown*/}
                                {/*  className="noBorder-modal"*/}
                                {/*></DatePicker>*/}
                                <InputGroupAddon addonType="append" className="append-image">
                                    <InputGroupText>
                                        {" "}
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </FormGroup>
                            <div className="mb-3 mt-3">
                                <h6> Gender</h6>
                                <div className="gender-buttons ">
                                    <div
                                        onClick={() => {
                                            this.toggleUserType(true, false);
                                        }}
                                        className={
                                            this.state.selectedGender === "1"
                                                ? " masculin-btn "
                                                : "selected-gender1 "
                                        }
                                    >
                                        <img className="gender-img" src={man} />
                                    </div>
                                    <div
                                        onClick={e => {
                                            this.toggleUserType(false, true);
                                        }}
                                        className={
                                            this.state.selectedGender === "2"
                                                ? " feminin-btn "
                                                : "selected-gender2 "
                                        }
                                    >
                                        <img className="gender-img" src={women} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                            <Button
                                onClick={() =>
                                    this.state.updateDetails === false ?
                                        swal("Info!", "You don't have new characters added", "Info")
                                        :
                                        this.updateaccountinformations()
                                }
                                className="gBtn"
                            >
                                Update
                            </Button>
                            </div>
                        </Form>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 rside">
                        <div className="d-flex justify-content-center accTitle">
                            Change password
                        </div>
                        <Form>
                            <FormGroup>
                                <Label for="examplePassword">Old Password</Label>
                                <Input
                                    onChange={e =>
                                        this.setState({
                                            oldPassword: e.target.value,
                                            validPassword: ""
                                        })
                                    }
                                    type="password"
                                    name="password"
                                    id="6"
                                    valid={this.state.validPassword === "valid"}
                                    invalid={this.state.validPassword === "invalid"}
                                />
                                <FormFeedback>{this.state.passwordError}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="examplePassword">New Password</Label>
                                <Input
                                    name="password"
                                    type="password"
                                    onChange={e => {
                                        this.validateNewPassword(e);
                                    }}
                                    valid={this.state.validNewPassword === "valid"}
                                    invalid={this.state.validNewPassword === "invalid"}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="examplePassword">Confirm Password</Label>
                                <Input
                                    name="passwordvld_repeat"
                                    valid={
                                        this.state.confirmPassword.length > 5 &&
                                        this.state.password === this.state.confirmPassword
                                    }
                                    invalid={
                                        this.state.confirmPassword.length > 0 &&
                                        !(this.state.password === this.state.confirmPassword)
                                    }
                                    onChange={e => {
                                        this.validateConfirmPassword(e);
                                    }}
                                    type="password"
                                    id="8"
                                />
                                <FormFeedback>Passwords don't match! </FormFeedback>
                            </FormGroup>
                            <div className="d-flex justify-content-center">
                                <Button onClick={() => this.updatepassword()} className="gBtn">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="footer-margin">
                    <div className="ChekBox-text mt-3 mb-3">
                        <div>
                            <Checkbox
                                checked={this.state.checkbox}
                                onChange={this.onCheckboxClick}
                                size={2}
                            />
                        </div>
                        <div className="recive-text">
                            I want to recive promotions, updates or news from Zing by email
                        </div>
                    </div>

                    <div className="row-lg-12  btn-text-dlt ">
                        <div>
                            <Button color="danger" id="PopoverClick" type="button">
                                Delete Account
              </Button>
                        </div>
                    </div>
                </div>
                <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target="PopoverClick"
                >
                    <PopoverHeader className="delete-text">
                        Are you sure you want to delete your account ?{" "}
                    </PopoverHeader>
                    <PopoverBody className="DeleteBtn-P">
                        <Container>
                            <Row className="DeleteBtn-c">
                                <Col sm={{ size: "auto", offset: 1 }}>

                                        <Button
                                            className="DeleteBtn-B"
                                            color="danger"
                                            onClick={() =>
                                                this.delete_user()

                                            }>
                                            Delete
                                     </Button>

                                </Col>
                            </Row>
                        </Container>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
}
