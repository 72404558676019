import React, {Component} from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Animated} from "react-animated-css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import backDark from "../../../Assets/img/backDark.png";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import PrivacyPolicy from "../../TermsAndConditions/PrivacyPolicy.js"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ZingMap from "../../ZingMap/ZingMap";
import {
    FormFeedback,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Spinner
} from "reactstrap";
import "./SignUpLogIn.scss";
import "react-datepicker/dist/react-datepicker.css";
import {fetchApi} from "../../../fetchApi";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {userCredentials, sliderState} from "../../../globalVariables";
import moment from "moment";
import ModernDatepicker from "react-modern-datepicker";
import swal from 'sweetalert';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';

let SLogo = require("../../../Assets/img/SLogo.png");
let momentFormat = require("moment");

export default class SignUpLogIn extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            modal: this.props.openModal,
            showSignUp: true,
            showEmailPassword: false,
            hideCreateAccount: false,
            showTerms: false,
            isTerms: true,
            isPrivacyPolicy: false
        };
    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    toggle(e) {


        this.setState({modal: !this.state.modal});
        if (this.props.showSignUp) {
            this.props.setFalseSignUp();
        }

        if (this.state.modal) {
            sliderState.canUseSlider = true
            this.setState({showSignUp: true, hideCreateAccount: false});
        } else {
            sliderState.canUseSlider = false
        }

    }

    toggleSignUpLogin = show => {

        if (show) {
            this.setState({showSignUp: show, hideCreateAccount: false});
        } else {
            this.setState({showSignUp: show, hideCreateAccount: true});
        }
    };

    setEmailPassword() {
        this.setState({showEmailPassword: this.state.showEmailPassword});
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.showSignUp !== prevProps.showSignUp) {
            this.setState({modal: this.props.showSignUp});
        }
    };

    render() {

        return (
            <div className="container2">
                <Button
                    
                    className={this.props.class}
                    onClick={() => this.toggle()}
                >
                    {this.props.text}
                </Button>

                <Animated
                    animationIn="slideInUp"
                    animationOut="slideOutUp"
                    isVisible={true}
                >
                    <Modal isOpen={this.state.modal}
                        // toggle={() => this.toggle()}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1"/>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader className="head-2" toggle={() => this.toggle()}/>
                        </div>
                        {!this.state.showSignUp ? (
                            <SignUp
                                eventHandler={this.props.eventHandler}
                                eventId={this.props.eventId}
                                handler={this.props.handlerModal}
                                toggle={this.toggleSignUpLogin}

                            />
                        ) : (
                            <Login
                                eventId={this.props.eventId}
                                eventHandler={this.props.eventHandler}
                                handler={this.props.handlerModal}

                            />
                        )}
                        {/* <div className="footer-qua"> */}
                        {this.state.hideCreateAccount ? null :
                            <Button
                                className=" order-12 justify-content-md-center newAcc"
                                onClick={() => {
                                    this.toggleSignUpLogin(!this.state.showSignUp);
                                }}
                            >
                                {this.state.showSignUp
                                    ? " Create a new account "
                                    : "Or Login"}

                            </Button>
                        }
                        {/* </div> */}
                    </Modal>
                </Animated>
            </div>
        );
    }
}

class SignUp extends React.Component {
    constructor(props) {

        let nowYear = moment(new Date()).format("YYYY");
        let nowMonth = moment(new Date()).format("MM");
        let nowDay = moment(new Date()).format("DD");
        let today = {day: parseInt(nowDay), month: parseInt(nowMonth), year: parseInt(nowYear)};
        super(props);

        this.state = {
            userNormal: false,
            userBusiness: false,
            countries: [],

            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            country: "",
            gender1: null,
            gender2: null,
            gender: null,
            startDateToday: today,
            date: null,
            agreement1: false,
            agreement2: false,
            companyName: "",
            companyNumber: "",
            companyTVA: "",
            companyCategory: "",
            validFirstName: "",
            validLastName: "",
            validEmail: "",
            validPassword: "",
            validConfirmPassword: "",
            validCompanyName: "",
            validCompanyNumber: "",
            validCompanyTVA: "",
            validCompanyCategory: "",
            position: 0,
            allCategories: [],
            categoryName: '',
            categId: '',
            textPassword: "Passwords don't match!",
            isChecked1: false,
            isChecked2: false,
            // genderSelect:false,
            address: "",
            latitude: "",
            longitude: "",
            fetchInProgress: false,
            isPrivacyPolicy: false
        };
        this.handleChecked1 = this.handleChecked1.bind(this);
        this.handleChecked2 = this.handleChecked2.bind(this);
        // this.genderSelected1 = this.genderSelected1.bind(this);
        // this.genderSelected2 = this.genderSelected2.bind(this)
    }

    // genderSelected1 () {
    //   this.setState({gender: !this.state.gender});
    //   console.log("checked1")
    // }
    // genderSelected2 () {
    //   this.setState({gender: this.state.gender});
    //   console.log("checked1")
    // }

    handleChecked1() {
        this.setState({isChecked1: !this.state.isChecked1});
        // console.log("checked1")
    }

    handleChecked2() {
        this.setState({isChecked2: !this.state.isChecked2});
        // console.log("checked2")
    }

    // handleCheckeGender(){

    // }

    onChangeCategory = (evt, value) => {
        if (value !== null) {
            this.setState({
                categoryName: value.name,
                categId: value.id
            });
        }
    };
    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({allCategories: response.list_of_categories});
        }
    };

    handleChange(date) {
        this.setState({
            date: date
        });
    }

    componentDidMount() {
        this.fetchCountry();
        this.getCategories();
    }

    fetchCountry = async () => {
        const url = "listofcountries";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({
                countries: response.listofcountries,
                //country: response.listoflanguages[0].flag,
            });
        }
    };

    toggleUserType(N, B) {
        this.setState({userNormal: N});
        this.setState({userBusiness: B});
    }

    validateFirstName = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({firstName: e.target.value});
        if (regName.test(e.target.value)) {
            this.setState({validFirstName: "valid"});
        } else {
            this.setState({validFirstName: "invalid"});
        }
    };

    validateLastName = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({lastName: e.target.value});
        if (regName.test(e.target.value)) {
            this.setState({validLastName: "valid"});
        } else {
            this.setState({validLastName: "invalid"});
        }
    };

    validateEmail = e => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({email: e.target.value});
        if (emailRegex.test(e.target.value)) {
            this.setState({validEmail: "valid"});
        } else {
            this.setState({validEmail: "invalid"});
        }
    };

    validatePassword = e => {
        this.setState({password: e.target.value});
        const passRegex = /(?=.*\d)(?=.*[A-Za-z]).{6,}/;
        if (passRegex.test(e.target.value)) {
            this.setState({validPassword: "valid"});
        } else {
            this.setState({validPassword: "invalid"});
        }
    };

    validateConfirmPassword = e => {
        // if ( this.state.confirmPassword === this.state.password ) {
        this.setState({confirmPassword: e.target.value});
        const passRegex = /(?=.*\d)(?=.*[A-Za-z]).{6,}/;
        if (passRegex.test(e.target.value)) {
            this.setState({validConfirmPassword: "valid"});
        } else {
            this.setState({validConfirmPassword: "invalid"});
        }
        // }else{
        //   console.log('password doesn')
        // }

    };

    validCompanyName = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({companyName: e.target.value});
        if (regName.test(e.target.value)) {
            this.setState({validCompanyName: "valid"});
        } else {
            this.setState({validCompanyName: "invalid"});
        }
    };
    validCompanyNumber = e => {
        const regName = /(?!=.*[A-Za-z]).{3,}/;
        this.setState({companyNumber: e.target.value});
        if (regName.test(e.target.value)) {
            this.setState({validCompanyNumber: "valid"});
        } else {
            this.setState({validCompanyNumber: "invalid"});
        }
    };

    validCompanyTVA = e => {
        this.setState({companyTVA: e.target.value});
        if (e.target.value.length > 0) {
            this.setState({validCompanyTVA: "valid"});
        } else {
            this.setState({validCompanyTVA: "invalid"});
        }
    };

    validCompanyCategory = e => {
        this.setState({companyCategory: e.target.value});

        if (e.target.value.length > 0) {
            this.setState({validCompanyCategory: "valid"});
        } else {
            this.setState({validCompanyCategory: "invalid"});
        }
    };

    signUp = async () => {
        let data = new FormData();
        let url = "";
        let userType;
        this.fetchInProgressFunction();
        if (this.state.address === "") {
            this.setState({
                fetchInProgress: false,
                isValidLocation: true,
            });
        } else {
            this.setState({
                isValidLocation: false,
            });
        }
        if (this.state.address !== "") {
            let currentDate = momentFormat(this.state.date).format("YYYY-MM-DD ");
            data.append("email", this.state.email);
            data.append("password", this.state.password);
            data.append("first_name", this.state.firstName);
            data.append("last_name", this.state.lastName);
            if(this.state.gender === null){
                data.append("gender", "");
            }
            else{
                data.append("gender", this.state.gender);
            }
            // 2020-10-01
            if(this.state.date === null){
                data.append("birthday",  "");
            }else{
            data.append("birthday",  currentDate);
            }
            data.append("country_id", this.state.country);
            data.append("address", this.state.address);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            if (this.state.userNormal) {
                url = "signup_as_normaluser";
            } else {
                url = "signup_as_businessuser";
                data.append("company_name", this.state.companyName);
                data.append("company_registration_number", this.state.companyNumber);
                data.append("tva", this.state.companyTVA);
                data.append("category", this.state.categId);
            }
            const response = await fetchApi(url, "POST", data, null);
            
            if (this.IsJsonString(response)) {
                let NewResponse = JSON.parse(response);
                if (NewResponse.success) {
                    this.Login(NewResponse.email, NewResponse.password);
                } else {
                    swal("Error!", NewResponse.message, "error");
                    this.setState({fetchInProgress: false});
                    // this.fetchInProgressFunction(false)
                }
            } else {
                swal("Error!", response.message, "error");
                this.setState({fetchInProgress: false});
            }
        }
    };

    fetchInProgressFunction = () => {
        this.setState({fetchInProgress: !this.state.fetchInProgress})
    };

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async Login(email, password) {
        let data = new FormData();
        data.append("email", email);
        data.append("password", password);
        const url = "login";
        const response = await fetchApi(url, "POST", data, null);
        if (response.success) {

            if (response.is_deactivated === 0) {
                let userType;
                let userInfo = {
                    auth_key: response.auth_key,
                    user_id: response.user_id,
                    user_type: response.type
                };

                localStorage.setItem("userIsLogged", "true");
                localStorage.setItem("userCredentials", JSON.stringify(userInfo));
                userCredentials.authKey = userInfo.auth_key;
                userCredentials.userId = userInfo.user_id;
                userCredentials.userType = userInfo.user_type;
                if (response.type === 3) {
                    userType = "normal";
                } else if (response.type === 2) {
                    userType = "buss";
                } else {
                    userType = "admin";
                }

                // this.props.handler(userType);
                // this.props.eventHandler(userType);
                if (this.props.eventHandler && this.props.eventId) {
                    this.props.eventHandler(userType, true, this.props.eventId);
                } else {
                    this.props.handler(userType, true);
                }
            } else {
                swal("Error!", "User inactive", "error");
            }
        } else {
            swal("Error!", "Could not login to system. Please check username and password!", "error");
        }
    }

    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };
    showPrivacyPolicy = () => {
        this.setState({isPrivacyPolicy: !this.state.isPrivacyPolicy})
        // const doesShow = this.state.showTerms;
        // this.setState({showTerms: !doesShow});
        // this.setState({isTerms: isTermsWindow});
    };

    onWhereChange = (address, lat, lng) => {
        this.setState({address: address, latitude: lat, longitude: lng});
        // console.log(address, lat, lng);
    };
    octaTestDate = () => {
        // if (
        //     this.state.date.day >= this.state.startDateToday.day &&
        //     this.state.date.month >= this.state.startDateToday.month &&
        //     this.state.date.year >= this.state.startDateToday.year
        // ) {
        //     swal("Info!", "Date Of Birth need to be valid", "info")
        // } else (
            this.signUp()
        // )
    }

    render() {
        let emailPassword = (
            <Form
                onSubmit={e => {
                    if (this.state.password === this.state.confirmPassword) {
                        e.preventDefault();
                        this.setState({position: this.state.position + 1});
                    } else {
                        swal("Info!", "Please check all fields.", "info");
                    }


                }}
            >
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                    <Input
                        className="inputs-stl"
                        type="email"
                        name="email"
                        id="exampleEmail"
                        value={this.state.email}
                        placeholder="Email"
                        required
                        valid={this.state.validEmail === "valid"}
                        invalid={this.state.validEmail === "invalid"}
                        onChange={e => {
                            this.validateEmail(e);
                        }}
                    />
                    <FormFeedback>Email is not valid!</FormFeedback>
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="examplePassword" className="mr-sm-2"/>
                    <Input
                        className="inputs-stl"
                        type="password"
                        name="password1"
                        value={this.state.password}
                        id="examplePassword"
                        placeholder="Password"
                        required
                        valid={this.state.validPassword === "valid"}
                        invalid={this.state.validPassword === "invalid"}
                        onChange={e => {
                            this.validatePassword(e);
                        }}
                    />

                    <FormFeedback>
                        Password must contain at least 5 characters & one number!
                    </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="examplePassword" className="mr-sm-2"/>
                    <Input
                        className="inputs-stl"
                        type="password"
                        name="passwordvld_repeat"
                        value={this.state.confirmPassword}
                        id="exampleCP"
                        placeholder="Confirm Password"
                        required
                        valid={
                            this.state.confirmPassword.length > 5 &&
                            this.state.password === this.state.confirmPassword
                        }
                        invalid={
                            this.state.confirmPassword.length > 5 &&
                            !(this.state.password === this.state.confirmPassword)
                        }
                        onChange={e => {
                            this.validateConfirmPassword(e);
                        }}
                    />
                    <FormFeedback>{this.state.textPassword}</FormFeedback>
                </FormGroup>

                <Col className="log-btn" sm={{size: 12}}>
                    <Button
                        // color="primary"s
                        className=" justify-content-md-center btnContinue"
                        type="submit"
                        onClick={() => {
                            // this.setState({ position: this.state.position + 1 });

                        }}
                    >
                        Continue
                    </Button>

                </Col>
            </Form>
        );

        let signUpType = (
            <div>
                <FormGroup className="LogIn-top-text">
                    <h3 className="header-txt"> Sign Up As </h3>
                </FormGroup>
                <div className="adding-input-btn btnAccout">
                    <Button
                        onClick={() => {
                            this.toggleUserType(true)
                                ? this.setState({position: this.state.position + 1})
                                : this.setState({position: this.state.position + 1});
                        }}
                        className=" change-btn1 "
                    >
                        Normal
                    </Button>

                    <Button
                        onClick={e => {
                            this.toggleUserType(false)
                                ? this.setState({position: this.state.position + 1})
                                : this.setState({position: this.state.position + 1});
                        }}
                        className=" change-btn1 "
                    >
                        Business
                    </Button>
                </div>
                {/* <Button
                                    color="primary" className="col order-12 justify-content-md-center"
                                        onClick={() => {this.setState({ position: this.state.position + 1 })
                                        }}>Continua</Button>  */}
            </div>
        );

        let userDetails = (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();

                    // {!this.state.userNormal === false  ? this.setState({ position: this.state.position + 1 }) : null}
                    if (
                        this.state.userNormal === false 
                        // &&
                        // new Date(
                        //     this.state.date.year,
                        //     this.state.date.month - 1,
                        //     this.state.date.day
                        // ) < new Date()
                    ) {
                        this.setState({position: this.state.position + 1});
                    } else {
                        if (this.state.userNormal) {
                            swal("Info!", "All fields are required", "info");
                        } else {
                            swal("Info!", "Date Of Birth need to be valid", "info");
                        }
                    }
                }}
            >
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0  ">
                    <Label htmlFor="SetFName" className="mr-sm-2"/>
                    <Input
                        className="inputs-stl"
                        type="text"
                        name="SetFName"
                        id="exampleFN"
                        value={this.state.firstName}
                        placeholder="First Name"
                        required
                        valid={this.state.validFirstName === "valid"}
                        invalid={this.state.validFirstName === "invalid"}
                        onChange={(e) => {
                            this.validateFirstName(e);
                        }}
                    />
                    <FormFeedback>First name is to short!</FormFeedback>
                </FormGroup>

                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                    <Input
                        value={this.state.lastName}
                        className="inputs-stl"
                        type="text"
                        name="email"
                        id="exampleLN"
                        placeholder="Last Name"
                        required
                        bscolor="red"
                        valid={this.state.validLastName === "valid"}
                        invalid={this.state.validLastName === "invalid"}
                        onChange={(e) => {
                            this.validateLastName(e);
                        }}
                    />
                    <FormFeedback>Last name is to short!</FormFeedback>
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0 adressInput">
                    <Label className="mr-sm-2"/>
                    <Input
                        value={this.state.country}
                        placeholder="Choose Country"
                        required
                        className="inputs-stl"
                        type="select"
                        onChange={(e) => {
                            this.setState({
                                country: e.target.value,
                            });
                        }}
                        id="exampleSelect"
                    >
                        {this.state.countries !== null &&
                        this.state.countries.map((country, index) => (
                            <option value={country.id} key={index}>
                                {country.name}
                            </option>
                        ))}
                    </Input>
                    <ZingMap
                        showSearchTextBox={true}
                        showOpenMapButton={false}
                        inputClassName="borderSearchBar text-white"
                        buttonClassName=""
                        enableMarkerAdd={false}
                        selectedLocationHandler={(address, lat, lng) => {
                            this.onWhereChange(address, lat, lng);
                        }}
                    ></ZingMap>
                    <Input
                        className="invisible"
                        valid={this.state.isValidLocation}
                        invalid={this.state.isValidLocation}
                    />
                    <FormFeedback>This field is required</FormFeedback>
                </FormGroup>
                <div className="calendarLabel">Gender (optional) </div>

                <div className="mf">
                    <FormGroup check className="Check-M">
                        <Label check className="gender-latter">
                            <Input
                                className="blue-f-color"
                                type="radio"
                                name="radio1"
                                required={false}
                                onChange={() => {
                                    this.setState({gender: 1});
                                }}
                                // onChange={() => this.genderSelected1 }
                                checked={this.state.gender1}
                            />
                            M
                        </Label>
                    </FormGroup>

                    <FormGroup check className="Check-M">
                        <Label check className="gender-latter">
                            <Input
                                className="blue-f-color"
                                type="radio"
                                name="radio1"
                                required={false}
                                onChange={() => {
                                    this.setState({gender: 2});
                                }}
                                // onChange={() => this.genderSelected2 }
                                checked={this.state.gender2}
                            />
                            F
                        </Label>
                    </FormGroup>
                </div>
                <div className="calendarLabel"> Date of Birth (optional) </div>
                <Label className="labelCalendar">
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0 name-1 date-picker-mine ">
                        <div className="datePickerSignUp ">
                            <DatePicker
                                required={false}
                                id="calendar"
                                value={this.state.date}
                                onChange={(date) => this.handleChange(date)}
                                inputPlaceholder="Select a date"
                                shouldHighlightWeekends
                                colorPrimary="#007bff"
                                inputClassName="datePickerSignUp-chld"
                            />
                        </div>
                        <InputGroupAddon
                            addonType="append"
                            className="append-image calendarImg"
                        >
                            <InputGroupText>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputGroupText>
                        </InputGroupAddon>
                    </FormGroup>
                </Label>

                <div className="recapcha-agreenments">
                    <div className="agree">
                        <div>
                            <FormGroup check>
                                <Label check className="text-ageenments">
                                    <Input type="checkbox" id="checkbox1" required checked={this.state.isChecked1}
                                           onChange={this.handleChecked1}/>
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms & Conditions
                                    </a>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label

                                    check className="text-ageenments">
                                    <Input type="checkbox" id="checkbox2" required checked={this.state.isChecked2}
                                           onChange={this.handleChecked2}/>
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showPrivacyPolicy(false)}
                                    >
                                        Data Protection
                                    </a>
                                </Label>
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.showTerms}
                    // toggle={() => this.showTermsAndCondition(false)}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.showTermsAndCondition(false)}
                        />
                    </div>
                    <ModalBody>
                        <TermsAndConditions
                            isTerms={this.state.isTerms}
                        ></TermsAndConditions>
                    </ModalBody>
                </Modal>
                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.isPrivacyPolicy}
                    // toggle={() => this.showPrivacyPolicy(false)}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.showPrivacyPolicy(false)}
                        />
                    </div>
                    <ModalBody>
                        <PrivacyPolicy
                            // isTerms={this.state.isTerms}
                        >
                            <div>
                                heeloefewlnfkejnfkcvhbekfjhvbehk
                            </div>

                        </PrivacyPolicy>
                    </ModalBody>
                </Modal>

                {!this.state.userNormal === false ? (
                    <FormGroup check row className="log-btn-p">
                        <Col className="log-btn" sm={{size: 12}}>

                            {!this.state.fetchInProgress ?
                                <Button
                                    onClick={() => {
                                        this.signUp()
                                        // this.state.isChecked1 !==
                                        // false && this.state.isChecked2 !==
                                        // false
                                        // //  && this.state.gender !== null
                                        //  &&
                                        // this.state.date !== null
                                        //     ?
                                        //     this.signUp()
                                        //     :
                                        //     swal("Info!", "All fields are required", "info")
                                    }}
                                    type="submit"
                                    className="btnContinue"
                                >
                                    Submit
                                </Button>
                                :
                                <Button
                                    disabled
                                    className="btnContinue"
                                >
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>

                                </Button>
                            }
                        </Col>
                    </FormGroup>
                ) : (

                    <Col className="log-btn" sm={{size: 12}}>
                        {/* {this.state.date !== null ? */}
                            <Button
                                className="justify-content-md-center btnContinue"
                                type="submit"
                            >
                                Continue
                            </Button>
                            {/* :
                            <Button
                                className="justify-content-md-center btnContinue"
                                disabled>
                                Continue
                            </Button>

                        } */}

                    </Col>
                )}
            </Form>
        );

        let businessUserDetainls = (
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    // this.setState({ position: this.state.position + 1 });

                }}
            >
                <Animated animationIn="zoomIn" animationOut="zoomOut" isVisible={true}>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label className="mr-sm-2"/>
                        <Input
                            className="inputs-stl"
                            placeholder="Company Name"
                            required
                            valid={this.state.validCompanyName === "valid"}
                            invalid={this.state.validCompanyName === "invalid"}
                            onChange={e => {
                                this.validCompanyName(e);
                            }}
                            value={this.state.companyName}
                        />
                        <FormFeedback>Company name is to short!</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                        <Input
                            className="inputs-stl"
                            placeholder="Company Registration Number"
                            valid={this.state.validCompanyNumber === "valid"}
                            invalid={this.state.validCompanyNumber === "invalid"}
                            onChange={(e) => {
                                this.validCompanyNumber(e);
                            }}
                            value={this.state.companyNumber}
                            required
                        />
                        <FormFeedback>Company number is to short!</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                        <Input
                            className="inputs-stl"
                            placeholder="VAT"
                            valid={this.state.validCompanyTVA === "valid"}
                            invalid={this.state.validCompanyTVA === "invalid"}
                            onChange={(e) => {
                                this.validCompanyTVA(e);
                            }}
                            value={this.state.companyTVA}
                            required
                            type="number"
                        />
                        <FormFeedback>Please add your company TVA!</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 sign-up-Autocomplite mb-sm-0">
                        <Label htmlFor="exampleEmail" className="mr-sm-2"/>

                        <Autocomplete
                            required
                            // defaultValue={this.state.allCategories[0]}
                            options={this.state.allCategories}
                            getOptionLabel={option => option.name}
                            // style={{ width: 300, border:"none" }}
                            className="drop-down-stl-SignUp autoSearch-SignUp"
                            onChange={(e, val) => this.onChangeCategory(e, val)}

                            renderInput={params => (
                                <TextField
                                    {...params}
                                    // value={this.state.chooseCateg}
                                     value={this.state.categoryName}
                                    className="categoryAC-sign"
                                    variant="outlined"
                                    placeholder={"Choose your category"}
                                    valid={this.state.validCompanyCategory === "valid"}
                                    invalid={this.state.validCompanyCategory === "invalid"}
                                />
                            )}
                        />

                        <FormFeedback>Please select one category!</FormFeedback>
                    </FormGroup>
                </Animated>
                <FormGroup check row className="log-btn-p">
                    <Col className="log-btn" sm={{size: 12}}>

                        {!this.state.fetchInProgress ?
                            <Button
                                onClick={() => {
                                    this.state.date === null &&
                                    this.state.date >= this.state.startDateToday
                                        ?
                                        swal("Info!", "All fields are required", "info")

                                        :
                                        this.octaTestDate()


                                }
                                }
                                type="submit"
                                className="btnContinue"
                            >
                                {" "}
                                Submit
                            </Button>
                            :
                            <Button
                                disabled
                                className="btnContinue"
                            >
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>

                            </Button>
                        }

                    </Col>
                </FormGroup>
            </Form>
        );

        return (
            <div>
                <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    isVisible={true}
                    animate
                >
                    <div className="transition-1">
                        <a
                            onClick={() => {
                                if (this.state.position === 0) {
                                    this.props.toggle(true);
                                } else {
                                    this.setState({position: this.state.position - 1});
                                }
                            }}
                            className="back-Btnn"
                        >
                            <img src={backDark} alt="my_logo"/>{" "}
                        </a>
                        <ModalBody className="modal-Body">
                            <Form
                                className="form-index Fform"
                                onSubmit={e => {
                                    e.preventDefault();
                                }}
                            >
                                {this.state.position === 0 ? signUpType : null}
                                {this.state.position === 1 ? emailPassword : null}
                                {this.state.position === 2 ? userDetails : null}

                                {this.state.userNormal === false && this.state.position === 3
                                    ? businessUserDetainls
                                    : null}
                                {/*
                                { this.state.userNormal === false && this.state.position !== 3 || this.state.userNormal === true && this.state.position !== 2 ?

                                     <Button
                                    color="primary" className="col order-12 justify-content-md-center"
                                        onClick={() => {this.setState({ position: this.state.position + 1 })
                                        }}>Continua</Button>

                                    : null} */}
                            </Form>
                        </ModalBody>
                    </div>
                </Animated>
            </div>
        );
    }
}

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            validEmail: "",
            validPassword: "",
            forgotPasswordForm: false
        };
    }

    validateEmail = e => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({email: e.target.value});
        if (emailRegex.test(e.target.value)) {
            this.setState({validEmail: "valid"});
        } else {
            this.setState({validEmail: "invalid"});
        }
    };

    validatePassword = e => {
        this.setState({password: e.target.value});
        const passRegex = /(?=.*\d)(?=.*[A-Za-z]).{6,}/;
        if (passRegex.test(e.target.value)) {
            this.setState({validPassword: "valid"});
        } else {
            this.setState({validPassword: "invalid"});
        }
    };

    async Login() {
        let data = new FormData();
        data.append("email", this.state.email);
        data.append("password", this.state.password);
        const url = "login";

        const response = await fetchApi(url, "POST", data, null);
        //console.log(response);
        if (response.success) {
            if (response.is_deactivated === 0) {
                let userType;
                let userInfo = {
                    auth_key: response.auth_key,
                    user_id: response.user_id,
                    user_type: response.type
                };
                localStorage.setItem("userIsLogged", "true");
                localStorage.setItem("userCredentials", JSON.stringify(userInfo));
                userCredentials.authKey = userInfo.auth_key;
                userCredentials.userId = userInfo.user_id;
                userCredentials.userType = userInfo.user_type;
                if (response.type === 3) {
                    userType = "normal";
                } else if (response.type === 2) {

                    userType = "buss";
                } else {
                    userType = "admin";
                }
                if (this.props.eventHandler && this.props.eventId) {
                    this.props.eventHandler(userType, true, this.props.eventId);
                } else {
                    this.props.handler(userType, true);
                }


            } else {
                swal("Error!", "User inactive", "error");

            }
        } else {
            swal("Error!", "Could not login to system. Please check username and password!", "error");
        }
    }

    showForgotPasswordForm = () => {
        this.setState({forgotPasswordForm: true});
    };

    sendFrogotPassword = async () => {
        const url = "fpass";
        let data = new FormData();

        data.append("email", this.state.email);
        const response = await fetchApi(url, "POST", data, null);

        // console.log(response);
        if (response.success) {
            this.setState({forgotPasswordForm: false});
            swal(
                "Success!",
                "A email with the new password has been sent to " + this.state.email,
                "success"
            );
        } else {
            swal("Error!", "Something went wrong", "error");
        }
    };

    render() {
        return (
            <div className="transition-2">
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        if (!this.state.forgotPasswordForm) {
                            this.Login();
                        }
                    }}
                >
                    <ModalBody className="modal-Body">
                        <ReactCSSTransitionGroup
                            transitionName="example"
                            transitionAppear={true}
                            transitionAppearTimeout={500}
                            transitionEnter={false}
                            transitionLeave={false}
                        >
                            <FormGroup className="LogIn-top-text">
                                <Label htmlFor="exampleEmail" className=" header-txt">
                                    {this.state.forgotPasswordForm ? "Forgot password" : "LogIn"}
                                </Label>
                            </FormGroup>

                            {this.state.forgotPasswordForm ? (
                                <div>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                                        <Input
                                            className="inputs-stl"
                                            type="email"
                                            name="email"
                                            id="exampleEmail"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={e => this.validateEmail(e)}
                                            valid={this.state.validEmail === "valid"}
                                            invalid={this.state.validEmail === "invalid"}
                                            required
                                        />
                                        <FormFeedback>Email is not valid!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup check row className="log-btn-p">
                                        <Col className="log-btn" sm={{size: 12}}>
                                            <Button
                                                onClick={this.sendFrogotPassword}
                                                type={"submit"}
                                                className="modalBtn"
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </div>
                            ) : (
                                <div>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label htmlFor="exampleEmail" className="mr-sm-2"/>
                                        <Input
                                            className="inputs-stl"
                                            type="email"
                                            name="email"
                                            id="exampleEmail"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={e => this.validateEmail(e)}
                                            valid={this.state.validEmail === "valid"}
                                            invalid={this.state.validEmail === "invalid"}
                                            required
                                        />
                                        <FormFeedback>Email is not valid!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label htmlFor="examplePassword" className="mr-sm-2"/>
                                        <Input
                                            className="inputs-stl"
                                            type="password"
                                            name="password"
                                            id="examplePassword"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={e => this.validatePassword(e)}
                                            valid={this.state.validPassword === "valid"}
                                            invalid={this.state.validPassword === "invalid"}
                                            required
                                        />
                                        <FormFeedback>
                                            Password must contain at least 5 characters & one number!
                                        </FormFeedback>
                                    </FormGroup>

                                    <FormGroup className="forgot-parinte">
                                        <Button
                                            color="link"
                                            size="sm"
                                            className="forgot-pass"
                                            onClick={this.showForgotPasswordForm}
                                        >
                                            Forgot Your Password
                                        </Button>
                                    </FormGroup>

                                    <FormGroup check row className="log-btn-p">
                                        <Col className="log-btn" sm={{size: 12}}>
                                            <Button
                                                //onClick={() => this.Login()}
                                                type={"submit"}
                                                // color="primary"
                                                className="modalBtn"
                                            >
                                                {" "}
                                                LogIn
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </div>
                            )}
                        </ReactCSSTransitionGroup>
                    </ModalBody>
                </Form>
            </div>
        );
    }
}
