import React from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import "./Promotions.scss"
import DatePicker from "react-modern-calendar-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faCreditCard,
    faLock,
    faCheck, faPoundSign, faEuroSign, faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {userCredentials} from "../../../globalVariables";
import {fetchApi} from "../../../fetchApi";
import swal from "sweetalert";
import ResponsiveEmbed from "react-responsive-embed";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import ReactDOM from "react-dom";

let SLogo = require("../../../Assets/img/SLogo.png");

export default class Promotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return this.props.seeAll ? (
            <RenderSeeAllPromotions/>
        ) : this.props.openPromoteEvent ? (
            <RenderPromoteEvent
                openPromoteEvent={this.props.openPromoteEvent}
                closePromoteEvent={this.props.closePromoteEvent}
                promoteEventId={this.props.promoteEventId}
            />
        ) : (
            <RenderCreatePromotion closePromotion={this.props.closePromotion} is_deal={this.props.is_deal}/>
        );
    }
}


class RenderCreatePromotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoOffer: false,
            videoUrl: "",
            description: "",
            promotionType: 0,
            latitude: 0,
            longitude: 0,
            countryLocation: "",
            cityLocation: "",
            startDate: null,
            endDate: null,
            selectedZone: "",
            promotionTypes: [],
            showSelectCard: false,
            cardsList: [],
            showAddCard: false,
            promotionPrice: 0,
            userDeactivated: false,
            cardMultiPrice: "",
            priceId: "",
            countryId: 0,


        };

    }

    showSelectCard = () => {
        this.setState({showSelectCard: true});
    };

    toggleSelectCard = () => {
        this.setState({showSelectCard: false});
    };

    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };

    toggleAddCard = (needRefresh, cardId) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            // this.getUserCards();
            this.promoteHandler(cardId);

        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    componentDidMount() {
        this.getUserCards();
        this.getPromotionTypes();
    }

    async getPromotionTypes() {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "promotedtype";
        // data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {

            this.setState({promotionTypes: response.promotions_type});
        }

    }

    onChangeUrl = (evt) => {
        this.setState({videoUrl: evt.target.value});
    };
    onChangeDescription = (evt) => {
        this.setState({description: evt.target.value});
    };

    getCountryIdByName = async (name) => {
        const url = "listofcountries";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        const response = await fetchApi(url, "POST", data, null);
        if (response.success) {
            if (response.listofcountries.length > 0) {
                let country = response.listofcountries.filter(
                    (element) => element.name === name
                );
                if (country) {
                    if (country[0]) {
                        this.setState({countryId: country[0].id});
                    }
                }
            }
        } else {
            swal("Error!", "Invalid country selected!", "error");
        }
    };
    selectCountryLocation = (location, lat, lng, countryCOde) => {
        this.getCountryIdByName(location).then(() => {
            this.setState({
                countryLocation: location,
                selectedZone: countryCOde,
                cityLocation: "",
                latitude: lat,
                longitude: lng,
            });
        });
    };

    selectCityLocation = (location, lat, lng) => {
        this.setState({cityLocation: location, latitude: lat, longitude: lng});
    };

    selectStartDate(date) {

        let selectedDate = new Date(date.year + "-" + date.month + "-" + date.day);
        selectedDate = moment(selectedDate, "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD");
        selectedDate = new Date(selectedDate);

        let days = this.state.promotionType === 1 || this.state.promotionType === 2 ? 7 : 30;
        let last = new Date(selectedDate.getTime() + (days * 24 * 60 * 60 * 1000));
        let day = last.getDate();
        let month = last.getMonth() + 1;
        let year = last.getFullYear();

        let endDate = {day: day, month: month, year: year};
        this.setState({startDate: date, endDate: endDate});
    }


    getCardFirst() {


        let card = this.state.cardsList;

        if (card && card.length > 0) {
            swal({
                title: "Do you want to use an existing card?",
                text: "Total amount to pay: " + parseInt(this.state.promotionPrice),
                icon: "warning",
                buttons: {
                    add: {
                        text: "Yes",
                        value: 2
                    },
                    select: {
                        text: "No",
                        value: 1
                    }
                }
            }).then(val => {
                if (val === 1) {
                    this.showAddCard();
                } else if (val === 2) {
                    this.showSelectCard();
                }
            });
        } else {
            this.showAddCard();
        }


    }

    promoteHandler = async (cardId) => {
        let card = this.state.cardsList.filter(
            (element) => element.default === true
        );

        let authKey = userCredentials.authKey;

        let startDate = new Date(
            this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );
        startDate = moment(startDate, "DD-MM-YYYY")
            .startOf("day")
            .format("YYYY-MM-DD");

        let endDate = new Date(
            this.state.endDate.year +
            "-" +
            this.state.endDate.month +
            "-" +
            this.state.endDate.day
        );

        endDate = moment(endDate, "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD");

        const url = "addpromotion";
        let data = new FormData();
        data.append("card_id", cardId ? cardId : card[0].id);
        data.append("country_id", this.state.countryId ? this.state.countryId : 0);
        data.append("amount", parseInt(this.state.promotionPrice));
        data.append("currency", "USD");
        data.append("user_id", userCredentials.userId);
        data.append("business_id", 1);
        data.append("offert_id", 0);
        data.append("event_id", 0);
        data.append("multiprice_event_id", 0);
        data.append("deal_id", 0);
        data.append("deal_calendar_id", 0);
        data.append("deal_multiprice_id", 0);
        data.append("tickets_number", 0);
        data.append("type_transaction", 5);
        data.append("is_deal", this.props.is_deal ? this.props.is_deal : 2);
        data.append("user_type", 2);
        if (this.state.videoOffer) {
            data.append("type", 1);
            data.append("video_url", this.state.videoUrl);
            data.append("description", "");
        } else {
            data.append("type", 2);
            data.append("video_url", "");
            data.append("description", this.state.description);
        }
        data.append("promotion_type", this.state.promotionType);
        data.append("latitude", this.state.latitude);
        data.append("longitude", this.state.longitude);
        if (this.state.cityLocation !== "") {
            data.append("location", this.state.cityLocation);
        } else {
            data.append("location", this.state.countryLocation);
        }
        data.append("start_to", startDate);
        data.append("end_to", endDate);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal("Success!", "Promotion successfully created", "success");
            this.props.closePromotion(false, true);
        } else {
            swal("Error!", response.message, "error");
        }
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal({
                title: "Card successfully set as default!",
                icon: "success",
                buttons: {
                    Ok: {
                        text: "Yes",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    //  this.toggleSelectCard();

                }
            });

        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };
    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };

    render() {
        const searchCountry = {
            types: ["(regions)"],
        };

        const searchCity = {
            types: ["(cities)"],
            componentRestrictions: {country: [this.state.selectedZone]},
        };

        return (
            <div>
                <Col className="text-center">
                    Choose your method to create a promotion
                    <ButtonGroup className="w-100">
                        {/* <Button
                            className={
                                this.state.videoOffer
                                    ? "BtnPromotions greenButton"
                                    : "BtnPromotions blueButton"
                            }
                            onClick={() => this.setState({ videoOffer: true })}
                            active={this.state.videoOffer}
                        >
                            Video
                        </Button> */}

                        <Button
                            className={
                                this.state.videoOffer
                                    ? "BtnPromotions blueButton"
                                    : "BtnPromotions greenButton"
                            }
                            onClick={() => this.setState({videoOffer: false})}
                            active={!this.state.videoOffer}
                        >
                            Description
                        </Button>
                    </ButtonGroup>
                </Col>
                {/* {this.state.videoOffer ? (
                    <Col className="mt-4 mb-4">
                        <Input
                            type="text"
                            onChange={this.onChangeUrl}
                            placeholder="Video url..."
                            value={this.state.videoUrl}
                            className="videoDescriptionInput videoUrlHeight"
                        />
                    </Col>
                ) : ( */}
                <Col className="mt-4 mb-4">
                    <Input
                        type="textarea"
                        onChange={this.onChangeDescription}
                        placeholder="Description..."
                        value={this.state.description}
                        className="videoDescriptionInput descriptionHeight"
                    />
                </Col>
                {/* )} */}

                <div className="text-center">Select type of promotion</div>
                <Col>
                    <div className="row">
                        <div className="col-sm-12">
                            <Form>
                                {this.state.promotionTypes.map((promotion) => (
                                    <div className="radio">
                                        <Label>
                                            <Input
                                                type="radio"
                                                value="option1"
                                                onChange={() => {
                                                    this.setState({
                                                        promotionType: parseInt(promotion.id),
                                                        countryLocation: "",
                                                        cityLocation: "",
                                                        startDate: null,
                                                        endDate: null,
                                                        selectedZone: "",
                                                        promotionPrice: promotion.name.split(" ")[0],
                                                        promotionName: promotion.name
                                                    });
                                                }}
                                                checked={
                                                    this.state.promotionType === parseInt(promotion.id)
                                                }
                                            />
                                            {promotion.name}
                                        </Label>
                                    </div>
                                ))}
                                {/*<div className="radio">*/}
                                {/*    <Label>*/}
                                {/*        <Input type="radio" value="option2" onChange={() => {*/}
                                {/*            this.setState({*/}
                                {/*                promotionType: 2,*/}
                                {/*                countryLocation: "",*/}
                                {/*                cityLocation: "",*/}
                                {/*                startDate: null,*/}
                                {/*                endDate: null,*/}
                                {/*                selectedZone: ""*/}
                                {/*            })*/}
                                {/*        }} checked={this.state.promotionType === 2}/>*/}
                                {/*        50 Euro / Week - Promotion / COUNTRY*/}
                                {/*    </Label>*/}
                                {/*</div>*/}
                                {/*<div className="radio">*/}
                                {/*    <Label>*/}
                                {/*        <Input type="radio" value="option3" onChange={() => {*/}
                                {/*            this.setState({*/}
                                {/*                promotionType: 3,*/}
                                {/*                countryLocation: "",*/}
                                {/*                cityLocation: "",*/}
                                {/*                startDate: null,*/}
                                {/*                endDate: null,*/}
                                {/*                selectedZone: ""*/}
                                {/*            })*/}
                                {/*        }} checked={this.state.promotionType === 3}/>*/}
                                {/*        30 Euro / Month - Promotion / CITY*/}
                                {/*    </Label>*/}
                                {/*</div>*/}
                                {/*<div className="radio">*/}
                                {/*    <Label>*/}
                                {/*        <Input type="radio" value="option3" onChange={() => {*/}
                                {/*            this.setState({*/}
                                {/*                promotionType: 4,*/}
                                {/*                countryLocation: "",*/}
                                {/*                cityLocation: "",*/}
                                {/*                startDate: null,*/}
                                {/*                endDate: null,*/}
                                {/*                selectedZone: ""*/}
                                {/*            })*/}
                                {/*        }} checked={this.state.promotionType === 4}/>*/}
                                {/*        150 Euro / Month - Promotion / COUNTRY*/}
                                {/*    </Label>*/}
                                {/*</div>*/}
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col>
                    {this.state.promotionType === 1 || this.state.promotionType === 3 ? (
                        <div className="w-100 d-flex">
                            <SelectLocation
                                cityType={false}
                                selectCountry={this.selectCountryLocation}
                                searchOptions={searchCountry}
                            />
                            {this.state.selectedZone !== "" ? (
                                <SelectLocation
                                    cityType={true}
                                    selectCity={this.selectCityLocation}
                                    selectedZone={this.state.selectedZone}
                                    searchOptions={searchCity}
                                />
                            ) : (
                                <div className="w-100"/>
                            )}
                        </div>
                    ) : null}
                    {this.state.promotionType === 2 || this.state.promotionType === 4 ? (
                        <div className="w-50">
                            <SelectLocation
                                cityType={false}
                                selectCountry={this.selectCountryLocation}
                                searchOptions={searchCountry}
                            />
                        </div>
                    ) : null}
                </Col>
                {((this.state.promotionType === 1 || this.state.promotionType === 3) &&
                    this.state.cityLocation.length > 0 &&
                    this.state.countryLocation.length > 0) ||
                ((this.state.promotionType === 2 || this.state.promotionType === 4) &&
                    this.state.countryLocation.length > 0) ? (
                    <div>
                        <Row>
                            <Col lg="6" className="mt-0 px-3 py-0">
                                <FormGroup className="px-0 py-0">
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0 name-1 calendarPromotions">
                                        <DatePicker

                                            inputClassName={"datepickerPromotions"}
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.startDate}
                                            onChange={(date) => this.selectStartDate(date)}
                                            inputPlaceholder="Select start date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                        />
                                        <InputGroupAddon
                                            addonType="append"
                                            className="append-image"
                                        >
                                            <InputGroupText>
                                                {" "}
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col lg="6" className="mt-0 px-3 py-0">
                                <FormGroup className="px-0 py-0">
                                    <FormGroup
                                        className="mb-2 mr-sm-2 mb-sm-0 name-1 calendarPromotions disabledCalendar">
                                        <DatePicker
                                            inputClassName={"datepickerPromotions"}
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.endDate}
                                            onChange={(date) => console.log(date)}
                                            inputPlaceholder="End date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                        />
                                        <InputGroupAddon
                                            addonType="append"
                                            className="append-image"
                                        >
                                            <InputGroupText>
                                                {" "}
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                ) : null}
                {((this.state.promotionType === 1 || this.state.promotionType === 3) &&
                    this.state.cityLocation.length > 0 &&
                    this.state.countryLocation.length > 0 &&
                    this.state.startDate &&
                    (this.state.videoUrl.length > 5 ||
                        this.state.description.length >= 0)) ||
                ((this.state.promotionType === 2 || this.state.promotionType === 4) &&
                    this.state.countryLocation.length > 0 &&
                    this.state.startDate &&
                    (this.state.videoUrl.length > 5 ||
                        this.state.description.length >= 0))}
                {this.state.startDate &&
                this.state.countryLocation !== "" &&
                (this.state.videoUrl.length >= 0 ||
                    this.state.description.length >= 0) ? (
                    <Col className="w-100 justify-content-center text-center">
                        <Button
                            className="promoteButton"
                            onClick={() => {
                                if (this.state.description.length > 5 || this.state.videoUrl.length > 5) {
                                    this.getCardFirst();
                                } else {
                                    swal("Warning!", this.state.videoOffer ? "Video url missing!  Please add a proper video url for your event" : "Description is to short! Please add a proper description for your event", "warning");
                                }
                            }}
                        >
                            Promote
                        </Button>
                        {/*<Button onClick={this.showSelectCard} className="promoteButton">*/}
                        {/*    Select card*/}
                        {/*</Button>*/}
                    </Col>
                ) : null}
                <Modal
                    isOpen={this.state.showSelectCard}
                    size="lg"
                    // toggle={this.toggleSelectCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Select card
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleSelectCard}/>
                    </div>
                    <ModalBody>
                        <div className="cardDetailsParent">
                            <Row>
                                <Col>
                                    {this.state.cardsList.length > 0 ? (
                                        this.state.cardsList.map((item) => (
                                            <Row
                                                key={item.id}
                                                className="d-flex align-items-center voucherContainer cardsListContainer"
                                                onClick={() => {
                                                    this.setDefaultCard(item.id);
                                                }}
                                            >
                                                <Col xs="6">
                                                    <div>{item.name}</div>
                                                </Col>
                                                <Col xs="2">
                                                    {item.exp_month}/{item.exp_year}
                                                </Col>
                                                <Col xs="2">{item.last4}</Col>
                                                <Col xs="2">
                                                    {item.default ? (
                                                        <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div>No cards saved</div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        <Button
                                            className="addCardBtn"
                                            onClick={() => {
                                                this.toggleSelectCard();
                                                this.promoteHandler();
                                            }}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                        </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                                        </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions
                                        isTerms={this.state.isTerms}
                                    ></TermsAndConditions>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard}></AddCard>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

class RenderSeeAllPromotions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
            showTerms: false
        }
    }

    componentDidMount() {
        this.getAllPromotions()
    }

    async getAllPromotions() {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "listofpromotionsperuser";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {

            this.setState({promotions: response.list_of_promotions});

        } else {
            swal("Error!", "Could not load your promotions", "error");
        }
    }

    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };

    render() {
        return (
            <div>
                {this.state.promotions && this.state.promotions.length > 0 ? this.state.promotions.map((items) => (
                    <div>
                        {items.type === "1" ? (
                            <div className="promotionVideo">
                                <ResponsiveEmbed
                                    src={
                                        items.video_url && items.video_url !== ""
                                            ? items.video_url.substring(0, 23) +
                                            "/" +
                                            "embed/" +
                                            items.video_url.substring(32, items.video_url.length)
                                            : "https://www.youtube.com/embed/2yqz9zgoC-U"
                                    }
                                    allowFullScreen
                                />
                            </div>
                        ) : (
                            <Label className="descriptionStyle">{items.description}</Label>
                        )}

                        <div className="borderRound">
                            <div>
                                From: <Label className="colorBlue"> {items.start_to} </Label> to{" "}
                                <Label className="colorBlue">{items.end_to}</Label>
                            </div>

                            <div>
                                Location:{" "}
                                <Label className="colorBlue"> {items.location} </Label>
                            </div>
                        </div>
                    </div>
                )) : <div>No promotions created</div>}
            </div>
        );
    }
}


class RenderPromoteEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promotionTypes: [],
            description: "",
            promotionType: 0,
            startDate: null,
            endDate: null,
            showSelectCard: false,
            cardsList: [],
            showAddCard: false,
            promotionPrice: 0,
            userDeactivated: false
        };
    }

    showSelectCard = () => {
        this.setState({showSelectCard: true});
    };
    toggleSelectCard = () => {
        this.setState({showSelectCard: false});
    };
    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };
    toggleAddCard = (needRefresh, cardId) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            // this.getUserCards();
            this.promoteHandler(cardId);

        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    componentDidMount() {
        this.getUserCards();
        this.getEventPromotions();
    }

    async getEventPromotions() {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "eventpromotedtype";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({promotionTypes: response.event_promoted_type});

        } else {
            swal("Error!", "Could not load your promotions", "error");
        }
    }


    onChangeDescription = evt => {
        this.setState({description: evt.target.value});
    };

    selectStartDate(date) {
        let selectedDate = new Date(date.year + "-" + date.month + "-" + date.day);
        selectedDate = moment(selectedDate, "DD-MM-YYYY")
            .startOf("day")
            .format("YYYY-MM-DD");
        selectedDate = new Date(selectedDate);

        let days = this.state.promotionName.toLowerCase().includes("week") ? 7 : 30;
        let last = new Date(selectedDate.getTime() + days * 24 * 60 * 60 * 1000);
        let day = last.getDate();
        let month = last.getMonth() + 1;
        let year = last.getFullYear();

        let endDate = {day: day, month: month, year: year};
        this.setState({startDate: date, endDate: endDate});
    }


    getCardFirst() {

        const moneySign = (
            <div>
                {this.state.promotionName.includes("gbp") ? <FontAwesomeIcon icon={faPoundSign}/> :
                    this.state.promotionName.includes("lira") ? <FontAwesomeIcon icon={faPoundSign}/> :
                        this.state.promotionName.includes("eur") ? <FontAwesomeIcon icon={faEuroSign}/> :
                            this.state.promotionName.includes("usd") ? <FontAwesomeIcon icon={faDollarSign}/> :
                                this.state.promotionName.includes("ron") ?
                                    <div className="RonSign"> RON </div> : (null)}

            </div>
        );


        let card = this.state.cardsList;

        let contentObj = (
            <div className="swalElement">Total amount to pay:&nbsp; {moneySign} {this.state.promotionPrice}  </div>);
        let wrapper = document.createElement('div');
        ReactDOM.render(contentObj, wrapper);
        let element = wrapper.firstChild;

        if (card && card.length > 0) {
            swal({
                title: "Do you want to use an existing card?",
                content: element,
                icon: "warning",
                buttons: {
                    add: {
                        text: "Yes",
                        value: 2
                    },
                    select: {
                        text: "No",
                        value: 1
                    }
                }
            }).then(val => {
                if (val === 1) {
                    this.showAddCard();
                } else if (val === 2) {
                    this.showSelectCard();
                }
            });
        } else {
            this.showAddCard();
        }


    }


    promoteHandler = async (cardId) => {
        let card = this.state.cardsList.filter(
            (element) => element.default === true
        );

        const url = "promoteevent";

        let currency = this.state.promotionName.includes("gbp")
            ? "GBP"
            : this.state.promotionName.includes("lira")
                ? "GBP"
                : this.state.promotionName.includes("eur")
                    ? "EUR"
                    : this.state.promotionName.includes("usd")
                        ? "USD"
                        : this.state.promotionName.includes("ron")
                            ? "RON"
                            : "EUR";

        let authKey = userCredentials.authKey;
        let data = new FormData();

        let startDate = new Date(
            this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );
        startDate = moment(startDate, "DD-MM-YYYY")
            .startOf("day")
            .format("YYYY-MM-DD");

        let endDate = new Date(
            this.state.endDate.year +
            "-" +
            this.state.endDate.month +
            "-" +
            this.state.endDate.day
        );
        endDate = moment(endDate, "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD");


        data.append("card_id", cardId ? cardId : card[0].id);
        data.append("amount", parseInt(this.state.promotionPrice));
        data.append("currency", currency);
        data.append("user_id", userCredentials.userId);
        data.append("business_id", 1);
        data.append("offert_id", 0);
        data.append("event_id", this.props.promoteEventId);
        data.append("multiprice_event_id", 0);
        data.append("deal_id", 0);
        data.append("deal_calendar_id", 0);
        data.append("deal_multiprice_id", 0);
        data.append("tickets_number", 0);
        data.append("type_transaction", 3);


        data.append("event_id", this.props.promoteEventId);
        data.append("promoted_comment", this.state.description);
        data.append("promoted_type", this.state.promotionType);
        data.append("start_promoted_date", startDate);
        data.append("end_promoted_date", endDate);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal("Success!", "Event successfully promoted", "success");
            this.props.closePromoteEvent();
        } else {
            swal("Error!", response.message, "error");
        }
    }

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal({
                title: "Card successfully set as default!",
                icon: "success",
                buttons: {
                    Ok: {
                        text: "Yes",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    //  this.toggleSelectCard();

                }
            });
        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };
    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };

    render() {

        return (
            <div>
                <Col className="text-center">Add a description about your event</Col>
                <Col className="mt-4 mb-4">
                    <Input
                        type="textarea"
                        onChange={this.onChangeDescription}
                        placeholder="Description..."
                        value={this.state.description}
                        className="videoDescriptionInput videoUrlHeight"
                    />
                </Col>

                <div className="text-center">Select type of promotion</div>
                <Col>
                    <div className="row">
                        <div className="col-sm-12">
                            <Form>
                                {this.state.promotionTypes.map((promotion) => (
                                    <div className="radio">
                                        <Label>
                                            <Input
                                                type="radio"
                                                value="option1"
                                                onChange={() => {
                                                    this.setState({
                                                        promotionType: parseInt(promotion.id),
                                                        startDate: null,
                                                        endDate: null,
                                                        promotionPrice: promotion.name.split(" ")[0],
                                                        promotionName: promotion.name.toLowerCase()
                                                    });
                                                }}
                                                checked={
                                                    this.state.promotionType === parseInt(promotion.id)
                                                }
                                            />
                                            {promotion.name}
                                        </Label>
                                    </div>
                                ))}
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col></Col>
                {this.state.promotionType !== 0 ? (
                    <div>
                        <Row>
                            <Col lg="6" className="mt-0 px-3 py-0">
                                <FormGroup className="px-0 py-0">
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0 name-1 calendarPromotions">
                                        <DatePicker
                                            inputClassName={"datepickerPromotions"}
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.startDate}
                                            onChange={(date) => this.selectStartDate(date)}
                                            inputPlaceholder="Select start date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                        />
                                        <InputGroupAddon
                                            addonType="append"
                                            className="append-image"
                                        >
                                            <InputGroupText>
                                                {" "}
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col lg="6" className="mt-0 px-3 py-0">
                                <FormGroup className="px-0 py-0">
                                    <FormGroup
                                        className="mb-2 mr-sm-2 mb-sm-0 name-1 calendarPromotions disabledCalendar">
                                        <DatePicker
                                            inputClassName={"datepickerPromotions"}
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.endDate}
                                            onChange={(date) => console.log(date)}
                                            inputPlaceholder="End date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                        />
                                        <InputGroupAddon
                                            addonType="append"
                                            className="append-image"
                                        >
                                            <InputGroupText>
                                                {" "}
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                ) : null}
                {(this.state.promotionType !== 0) &&
                this.state.startDate &&
                this.state.description.length >= 0 ? (
                    <Col className="w-100 justify-content-center text-center">
                        <Button
                            className="promoteButton"
                            onClick={() => {
                                if (this.state.description.length > 5) {
                                    this.getCardFirst()
                                } else {
                                    swal("Warning!", "Description is to short! Please an a proper description for your event", "warning");
                                }
                            }}
                        >
                            Promote
                        </Button>
                    </Col>
                ) : null}
                <Modal
                    isOpen={this.state.showSelectCard}
                    size="lg"
                    // toggle={this.toggleSelectCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Select card
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleSelectCard}/>
                    </div>
                    <ModalBody>
                        <div className="cardDetailsParent">
                            <Row>
                                <Col>
                                    {this.state.cardsList.length > 0 ? (
                                        this.state.cardsList.map((item) => (
                                            <Row
                                                key={item.id}
                                                className="d-flex align-items-center voucherContainer cardsListContainer"
                                                onClick={() => {
                                                    this.setDefaultCard(item.id);
                                                }}
                                            >
                                                <Col xs="6">
                                                    <div>{item.name}</div>
                                                </Col>
                                                <Col xs="2">
                                                    {item.exp_month}/{item.exp_year}
                                                </Col>
                                                <Col xs="2">{item.last4}</Col>
                                                <Col xs="2">
                                                    {item.default ? (
                                                        <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div>No cards saved</div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        <Button
                                            className="addCardBtn"
                                            onClick={() => {
                                                this.toggleSelectCard();
                                                this.promoteHandler();
                                            }}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                        </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                                        </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions
                                        isTerms={this.state.isTerms}
                                    ></TermsAndConditions>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard}></AddCard>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

class SelectLocation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {location: ""};

    }

    handleChange = (address) => {
        this.setState({location: address});
        if (address.length > 1 || address.length === 0) {
            // if (this.props.cityType) {
            //       this.props.selectCity(address)
            // } else {
            //      this.props.selectCountry(address)
            // }
            if (address.length === 0 && this.props.selectCountry) {
                this.props.selectCountry("", null, null, "");
            }
            if (address.length === 0 && this.props.selectCity) {
                this.props.selectCity("", null, null, "");
            }
        }
    };

    handleSelect = (address) => {
        this.setState({location: address});
        if (address !== undefined) {
            if (address.length > 2) {
                geocodeByAddress(address)
                    .then((results) => {
                        getLatLng(results[0]);
                        let countryCode = results[0].address_components[0].short_name;
                        const latval = results[0].geometry.location.lat()
                            ? results[0].geometry.location.lat()
                            : 0;
                        const lngval = results[0].geometry.location.lng()
                            ? results[0].geometry.location.lng()
                            : 0;
                        if (this.props.cityType) {
                            this.props.selectCity(address, latval, lngval);
                        } else {
                            this.props.selectCountry(
                                address,
                                latval,
                                lngval,
                                countryCode.toLocaleLowerCase()
                            );
                        }
                    })
                    .catch((error) => console.error("Error", error));
            }
        }
    };

    render() {
        return (
            <div className="locations_promotions_container">
                <PlacesAutocomplete
                    value={this.state.location}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={this.props.searchOptions}
                    shouldFetchSuggestions={this.state.location.length > 1}
                >
                    {({getInputProps, suggestions, getSuggestionItemProps}) => (
                        <div style={{width: "100%"}}>
                            <input
                                placeholder={
                                    this.props.cityType ? "Select City" : "Select Country"
                                }
                                {...getInputProps({
                                    className: "locations_promotions",
                                })}
                            />
                            <div className="autocomplete-dropdown-promotions">
                                {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    const style = suggestion.active
                                        ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                        : {backgroundColor: "#ffffff", cursor: "pointer"};

                                    if (
                                        !this.props.cityType &&
                                        suggestion.types.includes("country")
                                    ) {
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    } else if (
                                        this.props.cityType &&
                                        this.props.selectedZone !== ""
                                    ) {
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.formattedSuggestion.mainText}</span>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        );
    }
}
