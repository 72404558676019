import React from "react";
import FirstPage from "./Components/FirstPage/FirstPage";
import HPnormal from "./Components/NormalUser/HPnormal";
import HPbusiness from "./Components/BusinessUser/HPbusiness";
import "loaders.css/src/animations/line-scale-pulse-out.scss";
import Loader from "react-loaders";
import { userCredentials, businessUserId } from "./globalVariables";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.userLoggedIn = this.userLoggedIn.bind(this);
        this.userLoggedInEvents = this.userLoggedInEvents.bind(this)
        this.state = {
            userLogged: false,
            userType: "normal",
            mounted: false,
            show: true,
            fromPromo: false,
            eventIdModal: false,
            selectedEventId: ""
        };
    }

  userLoggedIn(log, type, promo) {
    this.getAsyncUser();
    if (promo) {
      this.setState({
        userLogged: log,
        userType: log,
        fromPromo: true,
        // eventIdModal:true
      });
    } else {
      this.setState({
        userLogged: log,
        userType: log,
        fromPromo: false,
        selectedEventId: "",
      });
    }
  }
  userLoggedInEvents(userType, userLogged, EventId) {
    if (EventId) {
      this.setState({
        userLogged: userLogged,
        userType: userType,
        eventIdModal: true,
        selectedEventId: EventId,
      });
    } else {
      this.setState({
        userLogged: userLogged,
        userType: userType,
        eventIdModal: false,
      });
    }
  }

    getAsyncUser = async () => {
        let userIsLogged = await localStorage.getItem("userIsLogged");

        if (userIsLogged === "true") {
            let userInfo = await localStorage.getItem("userCredentials");
            userInfo = JSON.parse(userInfo);
            userCredentials.authKey = userInfo.auth_key;
            userCredentials.userId = userInfo.user_id;
            userCredentials.userType = userInfo.user_type;
            if (userInfo.user_type === 3) {
                this.setState({ userType: "normal", userLogged: true, mounted: true });
            } else {
                this.setState({
                    userType: "business",
                    userLogged: true,
                    mounted: true
                });
            }
        } else {
            this.setState({ userLogged: false, mounted: true });
        }
    };

    componentDidMount() {
        //localStorage.clear();

        var orig = document.body.className;

    setTimeout(() => {
      this.setState(() => ({ show: false }));
      document.body.className = orig + (orig ? " " : "") + "noOverflow";
    }, 3000);
    this.getAsyncUser();
  }

    componentWillUnmount() {
        var orig = document.body.className;
        document.body.className = orig;
    }

    renderApp() {

        let component = null;
        if (!this.state.userLogged) {
            component = <FirstPage
                eventHandler={this.userLoggedInEvents}
                handler={this.userLoggedIn}

            />;
        } else if (this.state.userType === "normal") {
            component = (
                <HPnormal

                    selectedEventId={this.state.selectedEventId}
                    userLogged={this.state.userLogged}
                    handler={this.userLoggedIn}
                    promo={this.state.fromPromo}
                    mounted={this.state.mounted}
                    eventIdProps={this.state.eventIdModal}
                    userBusinessId={businessUserId.value}

                // eventModal={this.state.eventId}
                />
            );
        } else {

            component = (
                <HPbusiness
                selectedEventId={this.state.selectedEventId}
                    handler={this.userLoggedIn}
                    promo={this.state.fromPromo}
                    userBusinessId={businessUserId.value}
                />
            );
        }
        return component;
    }

    render() {
        const style = {
            height: "100%"
        };

        let mainClass = '';
        if (!this.state.userLogged) {
            mainClass = 'App nlgUsr';
        } else if (this.state.userType === "normal") {
            mainClass = 'App lgUsr normalUsr';
        } else {
            mainClass = 'App lgUsr busUsr';
        }

        return (
            <div className={mainClass} style={style}>
                {this.state.show ? (
                    <div className="load">
                        <Loader type="line-scale-pulse-out" />
                    </div>
                ) : (
                        ""
                    )}

                {this.state.mounted ? this.renderApp() : null}
            </div>
        );
    }
}
