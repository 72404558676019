import React, { Children } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Checkbox from "react-simple-checkbox";
import "./MultiplyCalendar.scss";
import { Button, Container, Col, Row, Input,Tooltip, Label } from "reactstrap";
import ModernDatepicker from "react-modern-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import TimePicker from "react-times";
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const localizer = momentLocalizer(moment);
const monthsArray = [
  { id: 1, name: "January" },
  { id: 2, name: "Febroary" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];
const allDayArray = [
  { id: 0, name: "Monday" },
  { id: 1, name: "Tuesday" },
  { id: 2, name: "Wednesday" },
  { id: 3, name: "Thursday" },
  { id: 4, name: "Friday" },
  { id: 5, name: "Saturday" },
  { id: 6, name: "Sunday" },
];
export default class MultiplyCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      events: [],
      checkMonday: false,
      checkTuesday: false,
      checkMonday: false,
      checkWednesday: false,
      checkThursday: false,
      checkFriday: false,
      checkSaturday: false,
      checkSunday: false,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      inputType: "weekly",
      dailyInput: "",
      dayArray: [],
      monthlyInput: new Date().getDate(),
      monthInput: new Date().getMonth() + 1,
      startTime: "00:00",
      endTime: "00:00",
      dayY: new Date().getDate(),
      toolTipCalendar:false,
      monthY: monthsArray[new Date().getMonth()],
      dayInMonthsArray: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],

      monthY: monthsArray[new Date().getMonth()].name,
      dp1: false,
      dp2: false,
      currentMonthList: [],
      updateDealSt: null,
    };
  }

  navigateThroughCalendar = (date) => {
    let currentMonthList = [];

    for (let el of this.state.events) {
      let d1 = new Date(el.start);
      let d2 = new Date(date);
      d2.setHours(0, 0, 0, 0);
      d1.setHours(0, 0, 0, 0);
      d2.setDate(0);
      let nd1 = new Date(d2);
      d2.setDate(d2.getDate() + 1);
      d2.setMonth(d2.getMonth() + 1);

      let d3 = new Date(d2);
      d3.setDate(d3.getDate() + 1);
      let d4 = new Date(d3);
      d4.setDate(d4.getDate() + 1);
      let d5 = new Date(d4);
      d5.setDate(d5.getDate() + 1);
      let d6 = new Date(d5);
      d6.setDate(d6.getDate() + 1);
      let d7 = new Date(d6);
      d7.setDate(d7.getDate() + 1);

      let nd2 = new Date(nd1);
      nd2.setDate(nd2.getDate() - 1);
      let nd3 = new Date(nd2);
      nd3.setDate(nd3.getDate() - 1);
      let nd4 = new Date(nd3);
      nd4.setDate(nd4.getDate() - 1);
      let nd5 = new Date(nd4);
      nd5.setDate(nd5.getDate() - 1);
      let nd6 = new Date(nd5);
      nd6.setDate(nd6.getDate() - 1);

      if (
        (new Date(el.start).getMonth() === new Date(date).getMonth() &&
          new Date(el.start).getFullYear() === new Date(date).getFullYear()) ||
        +d1 === +d2 ||
        +d1 === +d3 ||
        +d1 === +d4 ||
        +d1 === +d5 ||
        +d1 === +d6 ||
        +d1 === +d7 ||
        +d1 === +nd1 ||
        +d1 === +nd2 ||
        +d1 === +nd3 ||
        +d1 === +nd4 ||
        +d1 === +nd5 ||
        +d1 === +nd6
      ) {
        currentMonthList.push(el);
        // console.log(
        //   new Date(el.start).getFullYear(),
        //   new Date(date).getFullYear(),
        //   yearIncrement
        // );
      }
    }

    this.setState({
      currentMonthList: currentMonthList,
    });
  };

  onFocusChange = (focusStatue) => {
    // do something
  };

  onStartTimeChange = (options) => {
    const { hour, minute, meridiem } = options;
    this.setState({ startTime: options.hour + ":" + options.minute });
  };

  onEndTimeChange = (options) => {
    const { hour, minute, meridiem } = options;
    this.setState({ endTime: options.hour + ":" + options.minute });
  };

  onCheckMonday = () => {
    this.setState({ checkMonday: !this.state.checkMonday });
  };

  onCheckTuesday = () => {
    this.setState({ checkTuesday: !this.state.checkTuesday });
  };

  onCheckWednesday = () => {
    this.setState({ checkWednesday: !this.state.checkWednesday });
  };

  onCheckThursday = () => {
    this.setState({ checkThursday: !this.state.checkThursday });
  };

  onCheckFriday = () => {
    this.setState({ checkFriday: !this.state.checkFriday });
  };

  onCheckSaturday = () => {
    this.setState({ checkSaturday: !this.state.checkSaturday });
  };

  onCheckSunday = () => {
    this.setState({ checkSunday: !this.state.checkSunday });
  };

  applyChanges = () => {
    if (this.state.startDate !== null && this.state.endDate !== null) {
      this.clearCalendar()
      this.setState({ events: null });
      let arr = [];
      let arr2 = [];
      if (this.state.checkMonday) {
        arr.push(0);
        arr2.push("Monday");
      }
      if (this.state.checkTuesday) {
        arr.push(1);
        arr2.push("Tuesday");
      }
      if (this.state.checkWednesday) {
        arr.push(2);
        arr2.push("Wednesday");
      }
      if (this.state.checkThursday) {
        arr.push(3);
        arr2.push("Thursday");
      }
      if (this.state.checkFriday) {
        arr.push(4);
        arr2.push("Friday");
      }
      if (this.state.checkSaturday) {
        arr.push(5);
        arr2.push("Saturday");
      }
      if (this.state.checkSunday) {
        arr.push(6);
        arr2.push("Sunday");
      }
      this.setState({ dayArray: arr2 });
      this.modifyCalendar(arr).then(() => {
        this.done();
      });

    }
  };

  //   applyChanges = () => {
  //     this.setState({ events: [] });
  //     if (this.state.checkMonday) {
  //       this.modifyCalendar(0);
  //     }
  //     if (this.state.checkTuesday) {
  //       this.modifyCalendar(6);
  //     }
  //     if (this.state.checkWednesday) {
  //       this.modifyCalendar(5);
  //     }
  //     if (this.state.checkThursday) {
  //       this.modifyCalendar(4);
  //     }
  //     if (this.state.checkFriday) {
  //       this.modifyCalendar(3);
  //     }
  //     if (this.state.checkSaturday) {
  //       this.modifyCalendar(2);
  //     }
  //     if (this.state.checkSunday) {
  //       this.modifyCalendar(1);
  //     }
  //   };

  compareDate = (date1, date2) => {
    // let cd = new Date(moment().format("YYYY-MM-DD"));
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    if (d1 <= d2) {
      return true;
    } else {
      return false;
    }
  };

  dateRange = (startDate, endDate) => {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    return dates;
  };

  getFirstMonday(startDate) {
    var startOfMonth = moment(startDate)
      .utc()
      .startOf("month")
      .startOf("isoweek");
    var studyDate = moment(startDate)
      .utc()
      .startOf("month")
      .startOf("isoweek")
      .add(1, "w");

    if (studyDate.month() == startOfMonth.month()) {
      studyDate = studyDate.subtract(1, "w");
    }
    return studyDate;
  }

  modifyCalendar = async (array) => {
    let startDay = parseInt(this.state.startDate.day);
    let startMonth = parseInt(this.state.startDate.month);
    let startYear = parseInt(this.state.startDate.year);

    let endDay = parseInt(this.state.endDate.day);
    let endMonth = parseInt(this.state.endDate.month);
    let endYear = parseInt(this.state.endDate.year);

    let monthArray = [];
    let yearArray = [];
    let d1 =
      this.state.startDate.year +
      "-" +
      this.state.startDate.month +
      "-" +
      this.state.startDate.day;
    let d2 =
      this.state.endDate.year +
      "-" +
      this.state.endDate.month +
      "-" +
      this.state.endDate.day;
    let dates = this.dateRange(d1, d2);
    //console.log(dates);
    let arr = this.state.events;
    if (this.state.inputType === "weekly") {
      //console.log(startYear, endYear);
      while (startYear <= endYear) {
        yearArray.push(startYear);
        startYear++;
      }

      let dummyMonth = startMonth;
      while (dummyMonth <= endMonth) {
        monthArray.push(dummyMonth);
        dummyMonth++;
      }

      for (let i = 0; i < dates.length; i++) {
        //console.log("aici");
        let startOfMonth = moment(new Date(dates[i]));
        let currentMonth = dates[i].split("-")[1];
        let currentYear = dates[i].split("-")[0];
        // .utc()
        // .add(i, "M")
        // .add(0, "Y");

        let x = this.getFirstMonday(startOfMonth)
          .toISOString()
          .substring(8, 10);

        let z = 31;
        if (parseInt(currentMonth) === 2) {
          z = 28;
        } else if (
          parseInt(currentMonth) === 4 ||
          parseInt(currentMonth) === 6 ||
          parseInt(currentMonth) === 9 ||
          parseInt(currentMonth) === 11
        ) {
          z = 30;
        }
        for (let index = 0; index < array.length; index++) {
          let y = (parseInt(x) + array[index]) % 7;
          while (y <= z) {
            if (parseInt(currentMonth) === startMonth) {
              if (y >= startDay) {
                let today = new Date(
                  currentMonth + "/" + y + "/" + currentYear
                );
                if (
                  this.compareDate(
                    new Date(currentMonth + "/" + y + "/" + currentYear),
                    new Date(
                      this.state.endDate.month +
                      "/" +
                      this.state.endDate.day +
                      "/" +
                      this.state.endDate.year
                    )
                  )
                ) {
                  arr.push({
                    start: moment(today).toDate(),
                    end: moment(today).toDate(),
                    title: y,
                  });
                }
              }
            } else {
              let today = new Date(currentMonth + "/" + y + "/" + currentYear);
              if (
                this.compareDate(
                  new Date(currentMonth + "/" + y + "/" + currentYear),
                  new Date(
                    this.state.endDate.month +
                    "/" +
                    this.state.endDate.day +
                    "/" +
                    this.state.endDate.year
                  )
                )
              ) {
                arr.push({
                  start: moment(today).toDate(),
                  end: moment(today).toDate(),
                  title: y,
                });
              }
            }
            y = parseInt(y);
            y = y + 7;
          }
        }
      }
      let text = "Every ";

      if (array.length > 0) {
        array.forEach((element) => {
          text = text + allDayArray[element].name + ", ";
        });
        text = text.slice(0, -2);
        text =
          text +
          " from " +
          this.state.startDate.year +
          "-" +
          this.state.startDate.month +
          "-" +
          this.state.startDate.day +
          " to " +
          this.state.endDate.year +
          "-" +
          this.state.endDate.month +
          "-" +
          this.state.endDate.day;
      } else {
        text = "";
      }
      this.setState({ text: text });
    } else if (this.state.inputType === "monthly") {
      for (let i = 0; i < dates.length; i++) {
        let month = new Date(dates[i]).getMonth();
        let year = new Date(dates[i]).getFullYear();
        let today = new Date(
          month + 1 + "/" + this.state.monthlyInput + "/" + year
        );
        // console.log(today);
        if (
          this.compareDate(
            new Date(month + 1 + "/" + this.state.monthlyInput + "/" + year),

            new Date(
              this.state.endDate.month +
              "/" +
              this.state.endDate.day +
              "/" +
              this.state.endDate.year
            )
          ) &&
          this.compareDate(
            new Date(
              this.state.startDate.month +
              "/" +
              this.state.startDate.day +
              "/" +
              this.state.startDate.year
            ),
            new Date(month + 1 + "/" + this.state.monthlyInput + "/" + year)
          )
        ) {
          arr.push({
            start: moment(today).toDate(),
            end: moment(today).toDate(),
            title: this.state.monthlyInput,
          });
        }
      }
      let text =
        "Every month on " +
        this.state.monthlyInput +
        " from " +
        this.state.startDate.year +
        "-" +
        this.state.startDate.month +
        "-" +
        this.state.startDate.day +
        " to " +
        this.state.endDate.year +
        "-" +
        this.state.endDate.month +
        "-" +
        this.state.endDate.day;
      this.setState({ text });
    } else if (this.state.inputType === "yearly") {
      for (let i = 0; i < dates.length; i++) {
        let month = this.state.monthInput;
        if (month === new Date(dates[i]).getMonth() + 1) {
          let year = new Date(dates[i]).getFullYear();
          let day = this.state.dayY;
          let today = new Date(month + "/" + day + "/" + year);
          //console.log(today);
          if (
            this.compareDate(
              new Date(month + "/" + day + "/" + year),
              new Date(
                this.state.endDate.month +
                "/" +
                this.state.endDate.day +
                "/" +
                this.state.endDate.year
              )
            ) &&
            this.compareDate(
              new Date(
                this.state.startDate.month +
                "/" +
                this.state.startDate.day +
                "/" +
                this.state.startDate.year
              ),
              new Date(month + "/" + day + "/" + year)
            )
          ) {
            arr.push({
              start: moment(today).toDate(),
              end: moment(today).toDate(),
              title: day,
            });
          }
        }
      }
      let text =
        "Every year on " +
        this.state.dayY +
        " " +
        this.state.monthY +
        " from " +
        this.state.startDate.year +
        "-" +
        this.state.startDate.month +
        "-" +
        this.state.startDate.day +
        " to " +
        this.state.endDate.year +
        "-" +
        this.state.endDate.month +
        "-" +
        this.state.endDate.day;
      this.setState({ text });
    }
    this.setState({ events: arr });
    //console.log(arr);

    let currentMonthList = [];

    for (let el of arr) {
      let d1 = new Date(el.start);
      let d2 = new Date();
      d2.setHours(0, 0, 0, 0);
      d1.setHours(0, 0, 0, 0);
      d2.setDate(0);
      let nd1 = new Date(d2);
      d2.setDate(d2.getDate() + 1);
      d2.setMonth(d2.getMonth() + 1);

      let d3 = new Date(d2);
      d3.setDate(d3.getDate() + 1);
      let d4 = new Date(d3);
      d4.setDate(d4.getDate() + 1);
      let d5 = new Date(d4);
      d5.setDate(d5.getDate() + 1);
      let d6 = new Date(d5);
      d6.setDate(d6.getDate() + 1);
      let d7 = new Date(d6);
      d7.setDate(d7.getDate() + 1);

      let nd2 = new Date(nd1);
      nd2.setDate(nd2.getDate() - 1);
      let nd3 = new Date(nd2);
      nd3.setDate(nd3.getDate() - 1);
      let nd4 = new Date(nd3);
      nd4.setDate(nd4.getDate() - 1);
      let nd5 = new Date(nd4);
      nd5.setDate(nd5.getDate() - 1);
      let nd6 = new Date(nd5);
      nd6.setDate(nd6.getDate() - 1);

      if (
        (new Date(el.start).getMonth() === new Date().getMonth() &&
          new Date(el.start).getFullYear() === new Date().getFullYear()) ||
        +d1 === +d2 ||
        +d1 === +d3 ||
        +d1 === +d4 ||
        +d1 === +d5 ||
        +d1 === +d6 ||
        +d1 === +d7 ||
        +d1 === +nd1 ||
        +d1 === +nd2 ||
        +d1 === +nd3 ||
        +d1 === +nd4 ||
        +d1 === +nd5 ||
        +d1 === +nd6
      ) {
        currentMonthList.push(el);
        // console.log(
        //   new Date(el.start).getFullYear(),
        //   new Date(date).getFullYear(),
        //   yearIncrement
        // );
      }
    }

    this.setState({
      currentMonthList: currentMonthList,
    });
  };

  handleChangeS = (date) => {
    // value={ this.props.updateDeal ? 
    //   this.props.startDate 
    //   :
    //   this.state.startDate 
    // }


    let updateDealC = this.props.updateDeal
    if (this.props.updateDeal === true) {
      this.setState({
        updateDealSt: updateDealC,
      });
    } else {
      this.setState({ updateDealSt: false })
    }
    this.setState({
      startDate: date,
    });
  };

  handleChangeE = (date) => {
    let updateDealC = this.props.updateDeal
    if (this.props.updateDeal === true) {
      this.setState({
        updateDealSt: updateDealC,
      });
    } else {
      this.setState({ updateDealSt: false })
    }
    this.setState({
      endDate: date,
    });
  };

  selectSlot = (slot) => {
    let list = this.state.events;
    list.push({
      start: slot.start,
      end: slot.start,
      title: "",
    });

    this.setState({ events: list });
  };

  onChangeInputType = (evt) => {
    this.setState({ inputType: evt.target.value });
  };

  onChangeDailyInput = (evt) => {
    this.setState({ monthlyInput: evt.target.value });
  };

  onChangeDailyInputY = (evt) => {
    // console.log("klk");
    this.setState({ dayY: evt.target.value });
  };

  onChangeMonth = (evt) => {
    //console.log(evt.target.value);
    let month = monthsArray.filter(
      (element) => element.name === evt.target.value
    );
    this.setState({ monthInput: month[0].id, monthY: evt.target.value });
  };

  done = () => {
    let events = this.state.events;
    let eventsArray = [];
    events.forEach((element) => {
      let startDate = element.start;
      let startTime = this.state.startTime.split(":");
      startDate.setHours(startTime[0], startTime[1]);

      let endDate = element.end;
      let endTime = this.state.endTime.split(":");
      endDate.setHours(endTime[0], endTime[1]);

      eventsArray.push({
        start: startDate,
        end: endDate,
        title: element.title,
      });
    });

    this.props.doneCallback(
      eventsArray,
      this.state.text,
      this.state.startDate,
      this.state.endDate,
      this.state.startTime,
      this.state.endTime
    );
  };

  clearCalendar = () => {
    this.setState({ events: [] });
  };

  componentDidMount() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    month = month + 1;
    let years = [];
    for (let i = 0; i < 10; i++) {
      years[i] = { name: year + i + "", id: i };
    }

        this.setState({
            currentYear: year,
            yearOptions: years,
            currentMonth: month,
            currentDay: day,
        });
    }

    toggleTooltipCalendar = () => {
        this.setState({toolTipCalendar: !this.state.toolTipCalendar})
    }

    renderToolTipTickets() {
        return (<div>
            <div className="justify-content-center align-bottom">
                <FontAwesomeIcon className="iconTooltipBanner" icon={faExclamationTriangle} id="toolTipCategory"
                                 color={"#f5b91c"}/>
            </div>
            <Tooltip
                placement="top"
                isOpen={this.state.toolTipCalendar}
                target="toolTipCategory"
                toggle={this.toggleTooltipCalendar}
                hideArrow
            >
                <div className="toolTipGeneral">
                    <h6>
                        Deals
                    </h6>
                    <p className="tooltipC">
                        THIS IS DEALS MULTIPLIER. YOU CAN FIX A DEAL IN "WEEKLY" ON THE WANTED DAYS; AND, FROM "START
                        DATE / END DATE" SET FROM / TO, MONTH / YEAR WANTED AND THE SYSTEM WILL AUTOMATICALLY MULTIPLY A
                        "DEAL" FOR ALL THE WEEKS OF THE CHOSEN YEARS, AS YOU FIXED THEM IN THE FIRST WEEK. PROCEED IN
                        THE SAME WAY IN THE CASE OF A MONTHLY OR ANNUAL DEAL
                    </p>
                </div>
            </Tooltip>
        </div>)
    }

    render() {
        const CURRENT_DATE = moment().toDate();

        const ColoredDateCellWrapper = ({children, value}) => {
            let evtPresent = this.state.currentMonthList.filter((x) => {
                let curDate = new Date(value);
                curDate.setHours(0, 0, 0, 0);
                let dealDateStart = new Date(x.start);
                dealDateStart.setHours(0, 0, 0, 0);
                let dealDateEnd = moment(x.end);
                if (+curDate === +dealDateStart) {
                    return x;
                }
            });
            return React.cloneElement(Children.only(children), {
                style: {
                    ...children.style,
                    backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                },
            });
        };

        const style1 = {
            zIndex: this.state.dp1 ? 999 : 10,
        };
        const style2 = {
            zIndex: this.state.dp2 ? 999 : 10,
        };
        return (
            <Container className="muliplyContainer">
                <Row>
                    <Col lg="6">
            {/* <Label className="dealExpl">See created deals:</Label> */}
            <Calendar
             messages={{
              showMore: total => (
                <div
                  style={{ cursor: 'pointer' }}
                  onMouseOver={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >See all deals
                </div>
              ),
            }}
              localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            events={this.state.currentMonthList}
              style={{ height: "400px",fontSize:"12px", width: "350" }}
                            //selectable={true}
                            //onSelectSlot={this.selectSlot}
                            components={{
                                dateCellWrapper: ColoredDateCellWrapper,
                            }}
                            onNavigate={this.navigateThroughCalendar}
                        />
                    </Col>
                    <Col lg="6">
                        {/* <Label className="dealExpl">Create deal:</Label> */}
                        <div className="datePicker1" style={style1} onClick={() => {
                            if (!this.state.dp1) this.setState({dp1: !this.state.dp1, dp2: false});
                        }}>
                            <Row className="row-padd">

                <Col className="date-time-picker"  >
                  <Label className="dealExpl">Start date:</Label>
                  {this.props.comesFromBanner === true ?
                    <Input
                      value={ this.props.costumeDateState
                        // this.state.startDate !== null
                          // ? this.props.startDate.year +
                          // "-" +
                          // this.props.startDate.month +
                          // "-" +
                          // this.props.startDate.day
                          // : ""
                      }
                      readOnly={true}
                    />
                    :
                    <DatePicker
                    dateFormat={"YYYY-MM-DD"}

                    value={
                      !this.state.updateDealSt ?
                        this.props.startDate
                        :
                        this.state.startDate
                    }
                    onChange={(date) => this.handleChangeS(date)}
                    inputPlaceholder="Select a date"
                    shouldHighlightWeekends
                    colorPrimary="#007bff"
                  />
                 }

                 
                </Col>
              </Row>
              <Row className="row-padd">
                <Col className="date-time-picker" >
                  <Label className="dealExpl">Start time:</Label>
                  <TimePicker
                    disabled={this.props.comesFromBanner === true ?  true : false}
                    onFocusChange={this.onFocusChange}
                    onTimeChange={this.onStartTimeChange}
                    time={this.state.startTime}
                  />

                </Col>
                <Col className="date-time-picker" >
                  <Label className="dealExpl">End time:</Label>
                  <TimePicker
                  disabled={this.props.comesFromBanner === true ?  true : false}
                    onFocusChange={this.onFocusChange}
                    onTimeChange={this.onEndTimeChange}
                    time={this.state.endTime}
                  />
                </Col>
              </Row>


                        </div>
                        <div className="toolDateCnt-parent">


              <Col lg="10" className="p-0 d-flex toolDateCnt mt-3">
                <Input disabled={this.props.comesFromBanner === true ? true: false} type="select" onChange={this.onChangeInputType}>
                  <option>weekly</option>
                  <option>monthly</option>
                  <option>yearly</option>
                </Input>
                {this.renderToolTipTickets()}


                            </Col>
                        </div>
                        {this.state.inputType === "weekly" ? (
                            <Row className="py-3 daysRow">
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkMonday}
                                        onChange={this.onCheckMonday}
                                        size={2}
                                    />
                                    Monday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkTuesday}
                                        onChange={this.onCheckTuesday}
                                        size={2}
                                    />
                                    Tuesday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkWednesday}
                                        onChange={this.onCheckWednesday}
                                        size={2}
                                    />
                                    Wednesday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkThursday}
                                        onChange={this.onCheckThursday}
                                        size={2}
                                    />
                                    Thursday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkFriday}
                                        onChange={this.onCheckFriday}
                                        size={2}
                                    />
                                    Friday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkSaturday}
                                        onChange={this.onCheckSaturday}
                                        size={2}
                                    />
                                    Saturday
                                </Col>
                                <Col lg="6">
                                    <Checkbox
                                        checked={this.state.checkSunday}
                                        onChange={this.onCheckSunday}
                                        size={2}
                                    />
                                    Sunday
                                </Col>
                            </Row>
                        ) : this.state.inputType === "monthly" ? (
                            <Row className="my-3 p-0  center">
                                <Col lg="7">
                                    <div>every month on day</div>
                                </Col>

                                <Col lg="5">
                                    <Input
                                        type="select"
                                        onChange={this.onChangeDailyInput}
                                        value={this.state.monthlyInput}
                                    >
                                        {this.state.dayInMonthsArray.map((item) => (
                                            <option key={item}>{item}</option>
                                        ))}
                                    </Input>
                                </Col>
                            </Row>
                        ) : this.state.inputType === "yearly" ? (
                            <div>
                                <Row className="my-3 p-0  center">
                                    <Col lg="7" className="p-0 m-0">
                                        <div>every year in</div>
                                    </Col>
                                    <Col lg="5" className="p-0 m-0">
                                        <Input
                                            type="select"
                                            onChange={this.onChangeMonth}
                                            value={this.state.monthY}
                                        >
                                            {monthsArray.map((item) => (
                                                <option
                                                    key={item.id}
                                                    onClick={() => this.onChangeMonth(item.id)}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="8">
                                        <div>on day</div>
                                    </Col>
                                    <Col lg="4" className="p-0 m-0">
                                        <Input
                                            type="select"
                                            onChange={this.onChangeDailyInputY}
                                            value={this.state.dayY}
                                        >
                                            {this.state.dayInMonthsArray.map((item) => (
                                                <option key={item}>{item}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}

                        <div className="datePicker2" style={style2} onClick={() => {
                            if (!this.state.dp2) this.setState({dp2: !this.state.dp2, dp1: false});
                        }}>
                            <Row className="row-padd">

                <Col className="date-time-picker" >
                  <Label className="dealExpl">End date:</Label>
                  {this.props.comesFromBanner === true ?
                    <Input
                      value={ this.props.costumeEndDateState
                        // this.state.startDate !== null
                          // ? this.props.startDate.year +
                          // "-" +
                          // this.props.startDate.month +
                          // "-" +
                          // this.props.startDate.day
                          // : ""
                      }
                      readOnly={true}
                    />
                    :
                  <DatePicker

                    dateFormat={"YYYY-MM-DD"}
                    value={
                      !this.state.updateDealSt ?
                        this.props.endDate
                        :
                        this.state.endDate
                    }
                    // value={this.state.endDate}
                    onChange={(date) => this.handleChangeE(date)}
                    inputPlaceholder="Select a date"

                    shouldHighlightWeekends
                    colorPrimary="#007bff"
                  //  wrapperClassName="calendar"
                  // inputClassName="text-white"
                  />}
                </Col>


              </Row>
            </div>
            <Row xs="3" >
              <Col className="btn-space" lg="4">
                <Button disabled={this.props.comesFromBanner === true ? true: false} className="greenBtn" onClick={ this.clearCalendar && this.applyChanges}>
                  Apply
                </Button>
              </Col>
              {/* <Col className="btn-space" lg="4">
                <Button className="greenBtn" onClick={this.done}>
                  Done
                </Button>
              </Col> */}
              <Col className="btn-space" lg="4">
                <Button disabled={this.props.comesFromBanner === true ? true: false} className="greenBtn" onClick={this.clearCalendar}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
