import React, {Component} from "react";
import {fetchApiLocation} from "../../../fetchApiLocation";
import {fetchApi} from "../../../fetchApi";
import {Button, Col, Label, Row, UncontrolledAlert} from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faFax} from "@fortawesome/free-solid-svg-icons/faFax";
import MapContainer from "../../BothUsers/MapContainer/MapContainer";

export default class ContactUS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: "",
            contactId: null,
            contactCountryId: null,
            contactTitle: null,
            contactAddress: null,
            contactName: null,
            contactCUI: null,
            contactPhone: null,
            contactHour: null,
            contactEmail0: "",
            contactFax: null,
            contactLat: null,
            contactLong: null,

            showAlert: false,
            countries: [],
            countryId: "",
            resetAutoComplete: false
        };
    }

    componentDidMount() {
        this.getContactInfo();
    }


    getElementText = (response, elementName) => {
        return response.getElementsByTagName(elementName)[0].innerHTML;
    }


    getContactInfo = async () => {
        const response1 = await fetchApiLocation("GET").then(response => {
            return response.text();

        }).then(xml => {
            return (new window.DOMParser()).parseFromString(xml, "text/xml");
        }).then(async xmlDoc => {
            let country = this.getElementText(xmlDoc, "countryName");

            const urlC = "listofcountries";
            const responseC = await fetchApi(urlC, "POST", null, null);

            if (responseC.success) {
                this.setState({
                    countries: responseC.listofcountries,
                });

                if (country !== undefined) {
                    country = responseC.listofcountries.filter(
                        item => item.en_name.toLowerCase() === country.toLowerCase()
                    );
                }
                if (country !== null) {
                    if (country[0] !== undefined && country[0] !== null) {
                        const url = "contactus";
                        let data = new FormData();
                        data.append("country", country[0].id);
                        const response = await fetchApi(url, "POST", data, null);
                        if (response.success) {
                            this.setState({
                                country: country[0].en_name,
                                countryId: country[0].id,
                                contactId: response.contactus[0].id,
                                contactCountryId: response.contactus[0].country,
                                contactTitle: response.contactus[0].title,
                                //  contactName: response.contactus[0].company_name,
                                contactAddress: response.contactus[0].address,
                                //  contactCUI: response.contactus[0].cui,
                                contactHour: response.contactus[0].contact_hour,
                                contactPhone: response.contactus[0].phone_number,
                                contactEmail0: response.contactus[0].email,

                                contactFax: response.contactus[0].fax,
                                contactLat: response.contactus[0].latitude,
                                contactLong: response.contactus[0].longitude,
                            });
                        }

                    } else {
                        const url = "contactus";
                        let data = new FormData();
                        data.append("country", 1);
                        const response = await fetchApi(url, "POST", data, null);
                        if (response.success) {
                            this.setState({
                                country: "Romania",
                                countryId: 1,
                                contactId: response.contactus[0].id,
                                contactCountryId: response.contactus[0].country,
                                contactTitle: response.contactus[0].title,
                                //  contactName: response.contactus[0].company_name,
                                contactAddress: response.contactus[0].address,
                                // contactCUI: response.contactus[0].cui,
                                contactHour: response.contactus[0].contact_hour,
                                contactPhone: response.contactus[0].phone_number,
                                contactEmail0: response.contactus[0].email,
                                contactFax: response.contactus[0].fax,
                                contactLat: response.contactus[0].latitude,
                                contactLong: response.contactus[0].longitude,
                            });
                        }
                    }
                } else {
                    const url = "contactus";
                    let data = new FormData();
                    data.append("country", 1);
                    const response = await fetchApi(url, "POST", data, null);

                    if (response.success) {
                        this.setState({
                            country: "Romania",
                            countryId: 1,
                            contactId: response.contactus[0].id,
                            contactCountryId: response.contactus[0].country,
                            contactTitle: response.contactus[0].title,
                            // contactName: response.contactus[0].company_name,
                            contactAddress: response.contactus[0].address,
                            //  contactCUI: response.contactus[0].cui,
                            contactHour: response.contactus[0].contact_hour,
                            contactPhone: response.contactus[0].phone_number,
                            contactEmail0: response.contactus[0].email,

                            contactFax: response.contactus[0].fax,
                            contactLat: response.contactus[0].latitude,
                            contactLong: response.contactus[0].longitude,
                        });
                    }
                }
            }
        });

    };

    searchContactInfo = async (countryId) => {

        const url = "contactus";
        let data = new FormData();
        data.append("country", countryId);
        const response = await fetchApi(url, "POST", data, null);
        if (response.success) {
            if (response.contactus[0]) {
                this.setState({
                    country: this.state.country,
                    countryId: countryId,
                    contactId: response.contactus[0].id,
                    contactCountryId: response.contactus[0].country,
                    contactTitle: response.contactus[0].title,
                    //  contactName: response.contactus[0].company_name,
                    contactAddress: response.contactus[0].address,
                    // contactCUI: response.contactus[0].cui,
                    contactHour: response.contactus[0].contact_hour,
                    contactPhone: response.contactus[0].phone_number,
                    contactEmail: response.contactus[0].email,
                    contactFax: response.contactus[0].fax,
                    contactLat: response.contactus[0].latitude,
                    contactLong: response.contactus[0].longitude,
                });
            } else {
                this.setState({showAlert: true, resetAutoComplete: !this.state.resetAutoComplete})
                setTimeout(() => {
                    this.setState({showAlert: false, countryId: 1, country: "Romania",});
                    this.getContactInfo()
                }, 2500);

            }

        }
    }

    onDismiss = () => {
        this.setState({
            showAlert: false,
        });
        //this.getContactInfo()
    };

    render() {
        let email = this.state.contactEmail0.split(";");
        return (
            <div className="contactUsContainer">

                <Row className="p-0 m-0">
                    <Col lg="8" className="p-0 m-0">
                        <Autocomplete
                            key={this.state.resetAutoComplete}
                            disabled={this.state.showAlert}
                            id="combo-box-demoContact"
                            options={this.state.countries}
                            getOptionLabel={(option) => option.name}

                            className="drop-down-stl-contact text-white autoSearch"
                            onChange={(e, val) => {
                                this.setState({
                                    country: val && val.en_name ? val.en_name : "",
                                    countryId: val ? val.id : -1
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={this.state.country}
                                    variant="outlined"
                                    placeholder={this.state.country}
                                />
                            )}
                        />
                    </Col>
                    <Col lg="4">
                        <Button className={this.state.country ? "searchBtn" : "searchBtnDisabled"} onClick={() => {
                            if (this.state.country) {
                                this.searchContactInfo(this.state.countryId)
                            }
                        }
                        }
                        >
                            Search
                        </Button>
                    </Col>
                </Row>

                <div className="alertContainer">
                    <UncontrolledAlert
                        isOpen={this.state.showAlert}
                        toggle={this.onDismiss}
                    >
                        No locations were found in {this.state.country}!
                    </UncontrolledAlert>
                </div>

                {this.state.showAlert ? null :
                    <div className="contactUsItem">
                        <div key={this.state.contactId}>
                            <Col>
                                <div className="contactInfo">

                                    {this.state.contactTitle ?
                                        <div className="contactInfoItem">
                                            <span className="contactInfoLabel">
                                                {" "}
                                                <FontAwesomeIcon icon={faBuilding}/> Title:
                    </span>{" "}
                                            {this.state.contactTitle}
                                        </div> : null}

                                    {/*                {this.state.contactName ? <div className="contactInfoItem">*/}
                                    {/*<span className="contactInfoLabel">*/}
                                    {/*  <FontAwesomeIcon icon={faBuilding}/> Company:*/}
                                    {/*</span>{" "}*/}
                                    {/*                    {this.state.contactName}*/}
                                    {/*                </div> : null}*/}

                                    {this.state.contactAddress ? <div className="contactInfoItem">
                                        <span className="contactInfoLabel">
                                            {" "}
                                            <FontAwesomeIcon icon={faMapMarkedAlt}/> Address:
                    </span>{" "}
                                        {this.state.contactAddress}
                                    </div> : null}


                                    {/*                {this.state.contactCUI ? <div className="contactInfoItem">*/}
                                    {/*<span className="contactInfoLabel">*/}
                                    {/*  {" "}*/}
                                    {/*    <FontAwesomeIcon icon={facard}/> CUI:*/}
                                    {/*</span>{" "}*/}
                                    {/*                    {this.state.contactCUI}*/}
                                    {/*                </div> : null}*/}


                                    {this.state.contactHour ? <div className="contactInfoItem">
                                        <span className="contactInfoLabel">
                                            {" "}
                                            <FontAwesomeIcon icon={faClock}/> Contact Hours:
                    </span>{" "}
                                        {this.state.contactHour}
                                    </div> : null}


                                    {this.state.contactEmail0 ? <div className="contactInfoItem">
                                        <span className="contactInfoLabel">
                                            {" "}
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                            <Label>
                                                Email:
                                            </Label>
                    </span>{" "}

                                        {email.map((mail, index) => (
                                            <div key={index} className="class1">

                                                <Label className="h6-email">{mail}
                                                </Label>
                                            </div>

                                        ))
                                        }


                                    </div> : null}

                                    {this.state.contactPhone ? <div className="contactInfoItem">
                                        <span className="contactInfoLabel">
                                            {" "}
                                            <FontAwesomeIcon icon={faPhone}/> Phone Number:
                    </span>{" "}
                                        {this.state.contactPhone}
                                    </div> : null}

                                    {this.state.contactFax ? <div className="contactInfoItem">
                                        <span className="contactInfoLabel">
                                            {" "}
                                            <FontAwesomeIcon icon={faFax}/> Fax:
                    </span>{" "}
                                        {this.state.contactFax}
                                    </div> : null}


                                </div>
                            </Col>
                            {this.state.contactLat && this.state.contactLong ? <Col className="mapContainerFooter">
                                <MapContainer lat={this.state.contactLat} lng={this.state.contactLong}/>
                            </Col> : null}

                        </div>

                    </div>}
            </div>
        );
    }
}
