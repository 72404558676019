import React from "react";
import "./EventNotification.scss";
import { userCredentials } from "../../../globalVariables";
import { fetchApi } from "../../../fetchApi";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class EventNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    
  }
  notificationsReadIt = async (notificationId, isreadit) => {
    let response=null;
    if (!isreadit) {
      const data = new FormData();
      const url = "marknotificationread";
      data.append("user_id", userCredentials.userId);
      data.append("notification_id", notificationId);

      response = await fetchApi(url, "POST", data,userCredentials.authKey);
    }
    if ((response && response.success) || isreadit) {
      this.props.updateNotificationState(notificationId);
    }

  }

  render() {
    const notificationClass = this.props.is_checked == "2" ? "notificationsBox notReadit" : "notificationsBox";
    return (
      <div className={notificationClass} onClick={() => { this.notificationsReadIt(this.props.notificationId, this.props.readit) }}>
        <div className="notificationBody">
          <div className="notificationsImg">

            {this.props.logo === ""  ?
            <img src={profilePic}></img>
            
              :
              <img src={ this.props.logo }
              ></img>

              } 

          </div>
          <div className="notificationMsg">
            {ReactHtmlParser(this.props.description)}
          </div>
        </div>
      </div>

    );
  }
}
