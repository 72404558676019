import React, {Children} from "react";

import "./FindPeople.scss";
import {Animated} from "react-animated-css";
import {
    Input,
    Modal,
    ModalHeader,
    Button,
    Row,
    Col,
    ModalBody,
    Container,
} from "reactstrap";
import UserProfile from "../UserProfile/UserProfile";
import {userCredentials} from "../../../globalVariables";
import {fetchApi} from "../../../fetchApi";
import ZingMap from "../../ZingMap/ZingMap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FlatList from "flatlist-react";
import ContentEvents from "../Events/ContentEvents";
import Friends from "../Friends/Friends";
import {Calendar, momentLocalizer} from "react-big-calendar";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import moment from "moment";

const localizer = momentLocalizer(moment);

let profilePic = require("../../../Assets/img/profilePic.jpg");

let SLogo = require("../../../Assets/img/SLogo.png");
export default class FindPeople extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            index: -1,
            follow: false,
            userIdforModal: 0,
            blockedFriendsList: [],
            showBlockedUser: false,
            blockNumber: 0,
            friendsList: [],
            name: "",
            location: "",
            latitude: "",
            longitude: "",
            number: 5,
            textBtn: "See all",
            categories: [],
            categoryId: "",
            createdEventsList: [],
            filterVal: "",
            totalFriendsNo: 8,
            registeredEventsList: [],
            friendsList2: [],
            dealsDateList: [],
            holidayList: [],
            businessOnly: this.props.businessOnly ? this.props.businessOnly : false
        };
        this.refreshFriendsList = this.refreshFriendsList.bind(this);
    }

    selectEvent = (event) => {
        this.setState({profileModal: !this.state.profileModal});
        let dealCalendar = event.resource.deals_calendar.filter(
            (item) =>
                new Date(item.date).getTime() === new Date(event.start).getTime()
        );
        this.setState({
            profileModal: !this.state.profileModal,
            curentDealCalendarId: dealCalendar[0].id,
            curentDealCalendar: dealCalendar[0],
        });
        this.toggleModalShowDetailsD(
            event.resource.deals,
            event.resource.deals_calendar
        );
    };

    toggleModalShowDetailsD = (item, calendar) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: [
                {
                    id: 1,
                    price: calendar.length > 0 ? calendar[0].price : 0,
                    nOf: item.max_persons,
                    indicativePrice: item.currency,
                },
            ],
            eventLogo: item.logo,
            currentCalendar: calendar,
        });
        //console.log(item);
        this.setState({modalShowDetails: !this.state.modalShowDetails});
    };

    toogleModalShowDetails = () => {
        this.setState({modalShowDetails: !this.state.modalShowDetails});
    };

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
            profileModal: true,
            modalTitle: "Event details",
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({showVoucherInput: false});
        }
        if (this.state.showVoucers) {
            this.setState({showVoucers: false});
        }
    };

    openFriends = (name) => {
        this.listoffriends();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " friends",
            showUserFriends: true,
        });
    };

    openEventsCreated = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created events",
            showUsersCreatedEvents: true,
        });
    };

    openEventsRegistered = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " registered events",
            showUserRegisteredEvents: true,
        });
    };

    openDealsCreated = (name) => {
        this.getMyDeals();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created deals",
            showUserDeals: true,
        });
    };

    closeModal = () => {
        this.setState({modal: false});
    };

    toggleProfileModal = () => {
        this.setState({profileModal: !this.state.profileModal});
        if (this.state.showUsersCreatedEvents) {
            this.setState({showUsersCreatedEvents: false});
        }
        if (this.state.showUserRegisteredEvents) {
            this.setState({showUserRegisteredEvents: false});
        }
        if (this.state.showUserFriends) {
            this.setState({showUserFriends: false});
        }
        if (this.state.showUserDeals) {
            this.setState({showUserDeals: false});
        }
    };

    refreshFriendsList() {
        this.props.refreshAllFriends();
    }

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("alias_category_id", "0");
        data.append("user_id", this.state.userIdforModal);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data, authKey);
        // console.log(response);
        if (response.success) {
            let createdEvents = [];
            createdEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) ===
                    parseInt(this.state.userIdforModal)
            );
            // console.log(createdEvents);
            let registeredEvents = [];
            registeredEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) !==
                    parseInt(this.state.userIdforModal)
            );
            // console.log(registeredEvents);
            this.setState({
                createdEventsList: createdEvents.reverse(),
                registeredEventsList: registeredEvents.reverse(),
            });
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length == 0
        ) {
            this.setState({showAlertMyE: true});
        } else {
            this.setState({showAlertMyE: false});
        }
    };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", this.state.userIdforModal);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            // console.log( response, "has enter refresh")
            this.setState({friendsList2: response.list_of_friends});
        }
    };

    // getCategories = async () => {
    //   const url = "get_categories";
    //   const response = await fetchApi(url, "POST", null);
    //   //console.log(response);
    //   if (response.success) {
    //     this.setState({ categories: response.list_of_categories });
    //   }
    // };
    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        let list = [],
            alias = [],
            categs;
        if (response.success) {
            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({categories: list});
        }
    };

    onChangeCategory = (evt, value) => {
        if (value !== null) {
            this.setState({categoryId: value.id});
            // console.log(value.id);
        }
    };

    refreshFriendsList() {
        //this.props.refreshAllFriends();
    }

  search = async () => {
    //const url = this.state.businessOnly ? "searchbusinessusers" : "searchusers";
    const url = "searchbusinessusers";
    let authKey = userCredentials.authKey;
    let data = new FormData();
    data.append(
      "name",
    //   this.props.fromHeader && this.props.selectedText
         this.props.selectedText
        // : this.props.selectedText
    );
    data.append(
      "location",
    //   this.props.fromHeader && this.props.selectedAddress
        this.props.selectedAddress
        // : this.state.location
    );
    data.append(
      "latitude",
      this.props.fromHeader && this.props.selectedLat
        ? this.props.selectedLat
        : this.state.latitude !== 0
        ? this.state.latitude
        : ""
    );
    data.append(
      "longitude",
      this.props.fromHeader && this.props.selectedLng
        ? this.props.selectedLng
        : this.state.longitude !== 0
        ? this.state.longitude
        : ""
    );
    data.append(
      "category",
      this.props.fromHeader && this.props.selectedCategory
        ? this.props.selectedCategory
        : this.state.categoryId
    );

    const response = await fetchApi(url, "POST", data, authKey);

    if (response.success) {
      this.setState({
        friendsList: response.list_of_users,
        allFriends: response.list_of_users,
        refreshFriends: response.list_of_users,
        categoryId: "",
      });
    }
  };

    openBlockUser() {
        this.setState({
            showBlockedUser: !this.state.showBlockedUser
        });
    }

    openFollow() {
        this.setState({follow: !this.state.follow});
    }

    setItem = key => {
        this.setState({index: key});
    };

    toogle = userId => {
        this.setState({userIdforModal: userId});
        this.setState({modal: !this.state.modal});
    };

    onWhereChange = (address, lat, lng) => {
        this.setState({location:address,address: address, latitude: lat, longitude: lng});

    };

    onChangeName = evt => {
        this.setState({name: evt.target.value});
    };

    showAll = () => {
        if (this.state.number === 5) {
            this.setState({
                number: this.state.friendsList.length,
                textBtn: "See less"
            });
        } else {
            this.setState({number: 5, textBtn: "See all"});
        }
    };

    componentDidMount = () => {

        if (this.props.fromHeader) {

            this.setState({
                categories: this.props.categoryList,

            });
            this.search()

        } else {
            this.getCategories();
        }
        this.listoffriends();
    };

    getMyDeals = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofdealsperbusiness";
        const data = new FormData();
        data.append("business_user_id", this.state.userIdforModal);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let arr = [];
            response.deals.forEach((felement) => {
                felement.deals_calendar.forEach((element) => {
                    let endDate =
                        element.date.substring(0, 10) + " " + felement.deals.end_hour;
                    arr.push({
                        start: moment(element.date).toDate(),
                        end: moment(endDate).toDate(),
                        title: felement.deals.name,
                        resource: felement,
                    });
                });
            });
            this.setState({dealsDateList: arr, holidayList: response.holidays});
        }
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {};
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(start);
        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                if (parseInt(element.persons_left) === 0 || date < cd) {
                    style = {
                        backgroundColor: "red",
                    };
                }
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        style = {
                            backgroundColor: "orange",
                        };
                    }
                });
            }
        });
        return {
            style: style,
        };
    };

    renderEvents = (eventInfo, idx) => {
        return (
            <div className="my-1 " key={eventInfo.event.id}>
                <ContentEvents
                    aliasName={eventInfo.event.name_of_aliascategory}

                    categName={eventInfo.event.name_of_subcategory}
                    isClosed={!eventInfo.is_closed}
                    currencyOcta={eventInfo.event.currency}
                    numberOfMsg={eventInfo.number_of_messages}
                    eventType={3}
                    eventId={eventInfo.event.id}
                    userIdE={eventInfo.event.user_id}
                    name={eventInfo.event.name}
                    category={eventInfo.event.category_id}
                    text={eventInfo.event.description}
                    date={
                        eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
                    }
                    area={eventInfo.event.address}
                    time={
                        eventInfo.event.start_hour
                            ? eventInfo.event.start_hour.substring(0, 5)
                            : ""
                    }
                    teams={eventInfo.event.team_number}
                    participants={
                        eventInfo.event.max_persons - eventInfo.event.persons_left
                    }
                    state="closed"
                    price={
                        eventInfo.multiprice.length === 0
                            ? "FREE"
                            : eventInfo.multiprice.length === 1
                            ? eventInfo.multiprice[0].price === "0"
                                ? "FREE"
                                : "PAY"
                            : "PAY"
                    }
                    gender={eventInfo.event.gender}
                    ageCategory={eventInfo.event.age_category}
                    latitude={eventInfo.event.latitude}
                    longitude={eventInfo.event.longitude}
                    team_number={eventInfo.event.team_number}
                    max_persons={eventInfo.event.max_persons}
                    persons_left={eventInfo.event.persons_left}
                    type="free"
                    toogleModalShowDetails={() =>
                        this.toggleModalShowDetails(
                            eventInfo.event,
                            eventInfo.multiprice,
                            eventInfo.user_logo
                        )
                    }
                    reloadEventsCallback={this.reloadEventsCallback}
                    categories={this.state.categories}
                    userLogo={eventInfo.user_logo}
                    showVoucer={this.showVoucer}
                    description={eventInfo.event.name}
                />
            </div>
        );
    };

    render() {
        const CURRENT_DATE = moment().toDate();

        const ColoredDateCellWrapper = ({children, value}) => {
            let evtPresent = this.state.dealsDateList.filter((x) => {
                let curDate = new Date(value);
                curDate.setHours(0, 0, 0, 0);
                let dealDateStart = new Date(x.start);
                dealDateStart.setHours(0, 0, 0, 0);
                let dealDateEnd = moment(x.end);
                if (+curDate === +dealDateStart) {
                    return x;
                }
            });
            return React.cloneElement(Children.only(children), {
                style: {
                    ...children.style,
                    backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                },
            });
        };
        let seeMessage;
        if (this.state.friendsList.length > 5) {
            seeMessage = "See all";
        } else {
            seeMessage = "See less";
        }
        return (

                <div className="container event blBackground">
                    {/* {this.props.fromHeader ? null :
                        <Row className="friendSearchBar ">
                            <Col xs="12" lg={3} className="pl-0 m-0">
                                <Input
                                    placeholder="tags, categories added"
                                    className="messageInput searchSpace nameInput"
                                    onChange={this.onChangeName}
                                    value={this.state.name}
                                />
                            </Col>
                            <Col lg={3} className="zingMapInput">
                                <ZingMap
                                    showSearchTextBox={true}
                                    showOpenMapButton={false}
                                    inputClassName="borderSearchBar text-white"
                                    buttonClassName=""
                                    enableMarkerAdd={false}
                                    selectedLocationHandler={(address, lat, lng) => {
                                        this.onWhereChange(address, lat, lng);
                                    }}
                                ></ZingMap>
                            </Col>
          <Col lg={3} className="categoryInputF">
                                <Autocomplete
                                    id="combo-box-demo5"
                                    options={this.state.categories}
                                    getOptionLabel={option =>
                                        option.name_of_subcategory
                                            ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                            : option.name
                                    }
                                    style={{}}
                                    className="text-white categoryInputF"
                                    onChange={(event, value) => this.onChangeCategory(event, value)}
                                    renderInput={params => (
                                        <TextField
                                            className="categoryAC placeholder1"
                                            {...params}
                                            placeholder="Category..."
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Col>
                            <Col lg="2">
                                <Button onClick={this.search} className="searchButton">
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    } */}
                    <div className="friendRow peopleArea">

                        {this.state.friendsList.slice(0, this.state.number).map(item => (
                            <div
                                className="friendContainer"
                                onClick={() => this.toogle(item.user_id)}
                                key={item.user_id}
                            >
                                <div className="imgBoxFriends">
                                    {/* <img
                                        className="imgBoxFriends"
                                        src={
                                            item.logo !== null &&
                                            item.logo !== "" &&
                                            item.logo !== undefined
                                                ? item.logo
                                                : profilePic
                                        }
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = profilePic;
                                        }}
                                    ></img> */}
                                     { item.logo === undefined || item.logo === null ? (
                      <img
                        src={ item.image !== null ? item.image :  profilePic  }
                        className="usrProfilePic"
                        alt="profilePic"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = profilePic;
                        }}
                      />
                    ) : (
                      <img
                        src={ item.logo !== null ? item.logo : item.image }
                        className="usrProfilePic"
                        alt="profilePic"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = profilePic;
                        }}
                      />
                    )}
                                </div>
                                {item.user_type === "2" && item.company_name ?

                                    <div className="friendName">

                                        {item.company_name}

                                        {/* {item.first_name} {item.last_name} */}
                                    </div>
                                    :
                                    <div className="friendName">

                                        {item.first_name} {item.last_name}
                                        {/* {item.first_name} {item.last_name} */}
                                    </div>
                                }

                                {/* <div className="viewProf">View Profile</div> */}
                                <div className="parent-event-friend">
                                    <div className="child-event-friend1">
                                        <div className="event-text">
                                            Events created:
                                        </div>
                                        <div
                                            className="noOfEvt-friends"
                                            // onClick={() =>
                                            //     this.props.openEventsCreated(this.state.firstName)
                                            // }
                                        >
                                            {item.number_of_created_events}
                                        </div>
                                    </div>

                                    <div className="child-event-friend2">
                                        <div className="event-text">
                                            Events registered:
                                        </div>
                                        <div
                                            className="noOfEvt-friends"
                                            // onClick={() =>
                                            //     this.props.openEventsRegistered(this.state.firstName)
                                            // }
                                        >
                                            {item.number_of_assigned_events}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.props.fromHeader && this.state.friendsList && this.state.friendsList.length === 0 ?
                            <div className={"text-white"}>No business users were found</div> : null}
                    </div>

                    <div className="d-flex see-blocked-friends">
                        {this.state.friendsList.length > 5 ? (
                            <div onClick={this.showAll} className="seeAllBtn">
                                {this.state.textBtn}
                            </div>
                        ) : null}

                        {/* {this.state.blockNumber < 1 ? null : (
            <div className="justify-content-flex-end">
              {" "}
              <div
                onClick={() =>
                  this.listofblockedusers() && this.openBlockUser()
                }
                className="seeAllBtn"
              >
                Block list
              </div>{" "}
            </div>
          )} */}
                    </div>
                    {/*
        <div className="friendRow">
          {this.state.showBlockedUser
            ? this.state.blockedFriendsList.map(item => (
                <div
                  className="friendContainer"
                  onClick={() => this.toogle(item[0].user_id)}
                  key={item.key}
                >
                  <div className="imgBoxFriends">
                    {this.props.userLogo !== null &&
                    this.props.userLogo !== undefined &&
                    this.props.userLogo !== "" ? (
                      <img
                        src={this.props.userLogo}
                        className="usrProfilePic"
                        alt="profilePic"
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = profilePic;
                        }}
                      />
                    ) : (
                      <img
                        src={item[0].logo}
                        className="usrProfilePic"
                        alt="profilePic"
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = profilePic;
                        }}
                      />
                    )}
                  </div>
                  <div className="friendName">{item[0].name}</div>
                  <div className="viewProf">View Profile</div>
                </div>
              ))
            : null}
        </div> */}

                    <Modal
                        className="costume-modal-prof"
                        isOpen={this.state.modal}
                        // toggle={() => this.toogle(this.state.userIdforModal)}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1"/>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={() => this.toogle(this.state.userIdforModal)}
                            />
                        </div>
                        <UserProfile
                            userId={this.state.userIdforModal}
                            refreshBlockedFriends={this.listofblockedusers}
                            refreshFriends={this.refreshFriendsList}
                            openEventsCreated={this.openEventsCreated}
                            openEventsRegistered={this.openEventsRegistered}
                            openFriends={this.openFriends}
                            openDealsCreated={this.openDealsCreated}
                            closeHandler={() => this.toogle(this.state.userIdforModal)}
                            fromHeader={this.props.fromHeader}
                        />

                    </Modal>

                    <Modal
                        isOpen={this.state.profileModal}
                        // toggle={this.toggleProfileModal}
                        size="lg"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                {this.state.modalTitle}
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader className="head-2" toggle={this.toggleProfileModal}/>
                        </div>
                        <ModalBody>
                            {this.state.showUsersCreatedEvents ? (
                                <div className="eventsArea">
                                    <FlatList
                                        list={this.state.createdEventsList}
                                        renderItem={this.renderEvents}
                                    ></FlatList>
                                </div>
                            ) : this.state.showUserRegisteredEvents ? (
                                <div className="eventsArea">
                                    <FlatList
                                        list={this.state.registeredEventsList}
                                        renderItem={this.renderEvents}
                                    ></FlatList>
                                </div>
                            ) : this.state.showUserDeals ? (
                                <Col className="d-flex justify-content-center">
                                    <Calendar
                                        messages={{
                                            showMore: total => (
                                                <div
                                                    style={{cursor: 'pointer'}}
                                                    onMouseOver={e => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                >SEE all deals
                                                </div>
                                            ),
                                        }}
                                        localizer={localizer}
                                        defaultDate={new Date()}
                                        defaultView="month"
                                        events={this.state.dealsDateList}
                                        style={{
                                            fontSize: "12px",
                                            height: "700px",
                                            width: "700px",
                                            backgroundColor: "#fff",
                                        }}
                                        selectable={true}
                                        //onSelectSlot={this.selectSlot}
                                        onSelectEvent={(event) => this.selectEvent(event)}
                                        components={{
                                            dateCellWrapper: ColoredDateCellWrapper,
                                        }}
                                        eventPropGetter={this.eventStyleGetter}
                                    />
                                </Col>
                            ) : this.state.showUserFriends ? (
                                <div>
                                    {/* {console.log(this.state.friendsList)} */}
                                    <Friends
                                        friendsList={this.state.friendsList2}
                                        filterVal={this.state.filterVal}
                                        totalFriendsNo={this.state.totalFriendsNo}
                                        refreshAllFriends={this.listoffriends}
                                        getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                        searchEventHandler={this.searchFriends}
                                    />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={this.state.modalShowDetails}
                        // toggle={this.toogleModalShowDetails}
                        size="lg"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Event details
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toogleModalShowDetails}
                            />
                        </div>

                        <ModalBody>
                            {this.state.currentEvent !== undefined &&
                            this.state.currentEvent.date !== undefined ? (
                                <BannerDetailsNormal
                                    currencyBannner={this.state.currentEvent.currency}
                                    userLogged={true}
                                    id={this.state.currentEvent.id}
                                    userIdE={this.state.currentEvent.user_id}
                                    name={this.state.currentEvent.name}
                                    gender={this.state.currentEvent.gender}
                                    ageCategory={this.state.currentEvent.age_category}
                                    startDate={this.state.currentEvent.date.substring(0, 11)}
                                    endDate={
                                        this.state.currentEvent.end_date !== null
                                            ? this.state.currentEvent.end_date.substring(0, 11)
                                            : ""
                                    }
                                    price={
                                        !this.state.currentMultiprice ||
                                        this.state.currentMultiprice.length === 0
                                            ? "FREE"
                                            : this.state.currentMultiprice.length === 1
                                            ? this.state.currentMultiprice[0].price === "0"
                                                ? "FREE"
                                                : "PAY"
                                            : "PAY"
                                    }
                                    categoryName={this.state.currentEvent.name_of_subcategory}
                                    startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                    endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                    latitude={this.state.currentEvent.latitude}
                                    longitude={this.state.currentEvent.longitude}
                                    team_number={this.state.currentEvent.team_number}
                                    max_persons={this.state.currentEvent.max_persons}
                                    persons_left={this.state.currentEvent.persons_left}
                                    description={this.state.currentEvent.description}
                                    multiprice={this.state.currentMultiprice}
                                    address={this.state.currentEvent.address}
                                    latitude={this.state.currentEvent.latitude}
                                    longitude={this.state.currentEvent.longitude}
                                    userLogo={this.state.eventLogo}
                                    eventLogo={this.state.currentEvent.category_logo}
                                    category={this.state.currentEvent.category_id}
                                    closeModal={this.toggleModalShowDetails}
                                    reloadEventsCallback={this.reloadEventsCallback}
                                    calendar={this.state.currentCalendar}
                                    curentDealCalendarId={this.state.curentDealCalendarId}
                                    isDeal={this.state.currentCalendar !== undefined ? true : false}
                                    endDateEvent={
                                        this.state.currentEvent.end_date}

                                />
                            ) : null}
                        </ModalBody>
                    </Modal>
                </div>
        );
    }
}
