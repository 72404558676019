//import {token} from '../constants/globalConstants';
export async function fetchApi(url, type, params, authKey) {
  // Zing-party
  //dev
  // let stage = "http://192.168.11.104:73/dashboard/api/" + url;
  //prod
  let stage = "https://bo.zing-party.com/dashboard/api/" + url;

  if (type === "GET") {
    try {
      const response = await fetch(stage, {
        method: type,
        headers: {
          auth_key: authKey
        }
      });

      return await response.json();
    } catch (e) {
      return e;
    }
  } else {
    try {
      const response = await fetch(stage, {
        method: type,
        headers: {
          auth_key: authKey
        },
        body: params
      });
      //console.log(response.text());
      return await response.json();
    } catch (e) {
      return await e;
    }
  }
}
