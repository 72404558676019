import React from "react";
import "./AddCategory.scss";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../../index.css"
import { userCredentials } from "../../../globalVariables";
import { fetchApi } from "../../../fetchApi";
import { Button, Col, Input, Label, Row, Tooltip } from "reactstrap";
import swal from 'sweetalert';
import { faExclamationTriangle, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AddCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainCategories: [],
            secondCategories: [],
            city: "",
            cities: "",
            selectedCategories: [],
            adderValue: "",
            adders: [],
            mainCategoryOn: true,
            secondCategoryOn: false,
            choosenCategoryOn: false,
            categoryNames: [],
            tooltipOpen: false,

        };
        this.delete = this.delete.bind(this);
    }

    MainCategoryClick = (main, second, choose) => {
        this.setState({
            mainCategoryOn: main,
            secondCategoryOn: second,
            choosenCategoryOn: choose,
            showAdder: false,
            showAdderOther: false,
            selectedCategId: null,
            selectedCategory: null,
            otherCategoryId: null,
            otherCategory: null,
            adderValue: "",
        });

        this.getSelectedCategories();
        this.getMainCategories();
        this.getSecondCategories();
    };

    async delete(items) {
        let authKey = userCredentials.authKey;
        const url = "deleteassignedcategory";
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("category_id", items.id);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.getSelectedCategories();
        }
    }

    componentDidMount() {
        this.getSelectedCategories();
        this.getMainCategories();
        this.getSecondCategories();
    }

    getSecondCategories = async () => {
        const url = "get_lesscategories";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({secondCategories: response.list_of_categories});
        }
    };

    getMainCategories = async () => {
        const url = "get_firstlevelcategories";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({
                mainCategories: response.list_of_categories,
                otherCategoryId: response.list_of_categories[0].id,
                otherCategory: response.list_of_categories[0]
            });
        }
    };

    addCategory(item) {
        this.setState({selectedCategId: item.id, selectedCategory: item});
        this.pushMainCategory(item, false);
    }

    async pushMainCategory(values, other) {

        let url = "assign_aliascategories";
        let authKey = userCredentials.authKey;
        if (other) {
            let data_other = new FormData();
            data_other.append("user_id", userCredentials.userId);
            data_other.append("parent_category_id", this.state.otherCategoryId);
            data_other.append("type", "3");
            data_other.append("name_of_subcategory", this.state.adderValue);
            const response = await fetchApi(url, "POST", data_other, authKey);
            if (response.success) {
                this.getSelectedCategories();
            } else {
                swal("Error!", response.message, "error");
            }
        } else {
            let data_no_adder = new FormData();
            data_no_adder.append("user_id", userCredentials.userId);
            data_no_adder.append("parent_category_id", values.id);
            data_no_adder.append("type", "1");
            const response = await fetchApi(url, "POST", data_no_adder, authKey);
            if (response.success) {
                this.getSelectedCategories();
            } else {
                swal("Error!", response.message, "error");
            }
        }
    }

    async pushAlliasMain() {
        let url = "assign_aliascategories";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("parent_category_id", this.state.selectedCategId);
        data.append("type", "2");
        data.append("name_of_subcategory", this.state.adderValue);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.getSelectedCategories()
        } else {
            swal("Error!", response.message, "error");
        }
    }

    async getSelectedCategories() {
        this.setState({
            showAdder: false,
            showAdderOther: false,
            selectedCategId: null,
            selectedCategory: null,
            adderValue: "",
        });

        this.setState({selectedCategories: []});
        let newCateg = [];
        this.state.categoryNames = [];
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "get_aliasandcategoriesperuser";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let categ1, categ2, categ3 = [];
            categ1 = response.list_of_categories;
            categ2 = response.list_of_categories_with_alias;
            categ3 = response.list_of_categories_other_with_alias;
            let allCategs = [...categ3, ...categ1, ...categ2];
            // let allCategs = [ ...categ1, ...categ2];

            let categoryGroups = {};
            const items = allCategs;
            for (let i = 0; i < items.length; i++) {

                if (items[i].category_name !== "Other") {


                    let categoryName = items[i].category_name;

                    if (!categoryGroups[categoryName]) {
                        categoryGroups[categoryName] = [];
                    }

                    categoryGroups[categoryName].push({
                        id: items[i].id,
                        user_id: items[i].user_id,
                        parent_category_id: items[i].parent_category_id,
                        name_of_subcategory: items[i].name_of_subcategory,
                        type: items[i].type,
                        category_name: items[i].category_name,
                        isOther: false,
                    });
                } else {

                    let categoryName = items[i].name_of_subcategory;

                    if (!categoryGroups[categoryName]) {
                        categoryGroups[categoryName] = [];
                    }

                    categoryGroups[categoryName].push({
                        id: items[i].id,
                        user_id: items[i].user_id,
                        parent_category_id: items[i].parent_category_id,
                        name_of_subcategory: "",
                        type: items[i].type,
                        category_name: items[i].name_of_subcategory,
                        isOther: true,
                    });
                }
            }

            for (let groupNames in categoryGroups) {
                newCateg.push({category_name: groupNames, data: categoryGroups[groupNames]});
                this.state.categoryNames.push(groupNames);
            }

            //this.setState({selectedCategories: newCateg.sort((a, b) => a.category_name > b.category_name ? 1 : -1)});

            this.setState({selectedCategories: newCateg});
        }
    }

    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    renderToolTip() {
        return (<div>
            <div className="justify-content-center align-bottom">
                <FontAwesomeIcon className="iconTooltip" icon={faExclamationTriangle} id="toolTipCategory" color={"#f5b91c"}/>
            </div>
            <Tooltip
                placement="top"
                isOpen={this.state.tooltipOpen}
                target="toolTipCategory"
                toggle={this.toggleTooltip}
                hideArrow
            >
                <div className="toolTipGeneral">
                    <h6>
                    CATEGORIES
                    </h6>
                    <p  >
                    YOU CAN ADD ANY OTHER CATEGORY, IN ANY LANGUAGE - THIS IS HOW THE SYSTEM WILL READ THEM AND THIS IS HOW YOU CAN FIND THEM IN SEARCH - YOU WILL SEE THEM IN "My Categories". CLICK ON ANY ADDED CATEGORY in "My Categories" IN ORDER TO ADD A SUBCATEGORY. WE RECOMMEND THE USE OF ENGLISH
                    </p>
                </div>
            </Tooltip>
        </div>)
    }

    render() {
        return (
            <div className="container-categ">
                <div className="header-buttons">
                    <Button className={
                        this.state.mainCategoryOn ? "Main-categ-Button2" : "Main-categ-Button1"
                    }
                            onClick={() => this.MainCategoryClick(true, false, false)}>
                        Main Category
                    </Button>

                    <Button className={
                        this.state.secondCategoryOn ? "Main-categ-Button2" : "Main-categ-Button1"
                    }
                            onClick={() => this.MainCategoryClick(false, true, false)}>
                        Secondary Category
                    </Button>

                    <Button className={
                        this.state.choosenCategoryOn ? "Main-categ-Button2" : "Main-categ-Button1"
                    } onClick={() => this.MainCategoryClick(false, false, true)}>
                        My Categories
                    </Button>
                </div>
                {this.state.mainCategoryOn ?
                    <div>
                         <div className="adder-class">
                            <Label>Add an alias for category: <Label
                                className="selected-category-color">Other</Label>?</Label>
                            <div className="d-flex justify-content-center">
                                <Input placeholder="Type" className="adderText" id={"inputID"} type="text"
                                       value={this.state.adderValue}
                                       onChange={(e) => this.setState({
                                           selectedValues: e.target.value,
                                           adderValue: e.target.value
                                       })}/>
                                <Button className="addBtn" onClick={() => {
                                    if (this.state.adderValue.length > 0) {
                                        this.pushMainCategory(this.state.otherCategory, true)
                                    } else {
                                        swal("Info!", "Alias is required for this category!!!", "info");
                                    }
                                }}>Add</Button>
                                {this.renderToolTip()}
                            </div>
                        </div>

                        <div className="select-input">
                            {this.state.mainCategories.map(items => (
                                <div key={items.id}>
                                    {items.name === "Other" ? null :
                                        <Button
                                            key={items.id}
                                            className={this.state.categoryNames.includes(items.name) ? "selected-item" : "not-selected-item"}
                                            onClick={() => {
                                                if (items.name === "Other") {
                                                    this.setState({
                                                        showAdderOther: true,
                                                        selectedCategId: items.id,
                                                        selectedCategory: items
                                                    });
                                                } else {
                                                    this.addCategory(items);
                                                }
                                            }}
                                        > {items.name} </Button>
                                    }
                                </div>
                            ))}
                        </div>
                    </div> : null}
                {this.state.secondCategoryOn ?
                    <div>
                        <div className="select-input">
                            {this.state.secondCategories.map(items => (
                                <Button
                                    key={items.id}
                                    className={this.state.categoryNames.includes(items.name) ? "selected-item" : "not-selected-item"}
                                    onClick={() => {
                                        if (items.name === "Other") {
                                            this.setState({
                                                showAdderOther: true,
                                                selectedCategId: items.id,
                                                selectedCategory: items
                                            });
                                        } else {
                                            this.addCategory(items);
                                        }
                                    }}
                                > {items.name} </Button>
                            ))}
                        </div>
                    </div> : null}
                {this.state.choosenCategoryOn ?
                    <div>
                        <div className="select-input">
                            {this.state.selectedCategories ? this.state.selectedCategories.map(items => (
                                <div key={items.data[0].id} className="parent-selected-items  ">
                                    <div  className="choosen-categ-subcateg">
                                        <div   className="categ-x">
                                            {items.data.length === 1 && items.data[0].name_of_subcategory === "" ?
                                                <a
                                                    className="categ-input"
                                                    // key={items.category_name[0]}
                                                    value={items.name}
                                                    onClick={this.delete.bind(this, items.data[0])}
                                                > X </a> : null}
                                            <Label
                                                // key={items.category_name[0]}

                                                className="main-categ"
                                                onClick={() => {
                                                    if (!items.data[0].isOther) {
                                                        this.setState({
                                                            showAdder: true,
                                                            selectedCategId: items.data[0].parent_category_id,
                                                            selectedCategory: items.data[0]
                                                        });
                                                    }

                                                }}
                                            >
                                                {items.category_name}
                                            </Label>
                                        </div>
                                        {items.data.length !== 0 ?
                                            items.data.map(adder => (
                                                <div key={adder.id}>
                                                    {adder.name_of_subcategory !== "" ?
                                                        <div className="categ-x">
                                                            <a className="subcateg-input"
                                                               onClick={this.delete.bind(this, adder)}>x </a>
                                                            <Label
                                                                key={adder.id}
                                                                className="sub-categ"
                                                            > &nbsp;
                                                                {adder.name_of_subcategory}
                                                            </Label></div> : null}
                                                </div>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            )) : <p>No categories selected for this profile</p>}
                        </div>

                        {this.state.showAdder ?
                            <div className="adder-class">
                                <Label>Add an alias for category: <Label
                                    className="selected-category-color">{this.state.selectedCategory.category_name}</Label>?</Label>
                                <div className="d-flex justify-content-center">
                                    <Input className="adderText" type="text" value={this.state.adderValue}
                                           onChange={(e) => this.setState({
                                               selectedValues: e.target.value,
                                               adderValue: e.target.value
                                           })}/>
                                    <Button className="addBtn" onClick={() => {
                                        if (this.state.adderValue.length > 0) {
                                            this.pushAlliasMain();
                                        } else {
                                            swal("Info!", "Allias can't be empty!!!", "info");
                                        }
                                    }}>Add</Button>
                                </div>
                            </div> : null}
                    </div> : null}
                <div className=" categ-save-btn ">
                    <Row className="parent-categ-save-btn">
                        <Col
                            className="d-flex justify-content-center"
                            sm="12"
                            md={{size: 6, offset: 3}}
                        >
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
