module.exports = function(words) {

    console.log(words)
    if(words === "porn"){
        return false


    }else{
       return true
    }

};