import React from 'react';
import NavBarNormal from "./NavBarNormal/NavBarNormal";
import HomeScreenNormal from "./HomeScreenNormal/HomeScreenNormal";
import Footer from "../FirstPage/Footer/Footer"
import Loader from "react-loaders";
import "loaders.css/src/animations/line-scale-pulse-out.scss";
import LoadingOverlay from 'react-loading-overlay';
import "./HPNormal.scss"
import zIndex from '@material-ui/core/styles/zIndex';

export default class HPnormal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: null,
            modalMakeEvents: false,
            showFriendsPage: 1,
            modalPromoEvent: false,
            HPModal: false,
            eventsNumber: 0,
            showLogIn: true,
            showEventInfoId: null,
            showDealInfoId: null,
            showUserInfoId: null,
        };

        this.changeTagEvent = this.changeTagEvent.bind(this);
        this.changeTagMyEvents = this.changeTagMyEvents.bind(this);
        this.changeTagFriends = this.changeTagFriends.bind(this);
        this.changeTagMyTickets = this.changeTagMyTickets.bind(this);
        this.changeTagCreateEvents = this.changeTagCreateEvents.bind(this);
        this.changeTagPromoEvent = this.changeTagPromoEvent.bind(this);

        this.handleInputValue = this.handleInputValue.bind(this);

    }

  changeTagEvent() {
    this.setState({ open: 1 });
  }
  changeTag = () => {
    this.setState({ open: null });
  };

    changeTagMyEvents() {
        this.setState({open: 2});
    }

    changeTagFriends() {
        this.setState({open: 3});
    }

    // changeTagFind = () => {
    //     this.setState({open: 4});
    // };

    changeTagMyTickets() {
        this.setState({open: 5});
    }

    changeTagCreateEvents(open) {
        this.setState({modalMakeEvents: open});
    }

    changeTagPromoEvent() {
        this.setState({modalPromoEvent: true});
    }

    changeTagEventModal() {
        this.setState({HPModal: true})
    }

    componentDidMount() {
        let getPromo = this.props.promo;

        if (getPromo) {
            this.changeTagPromoEvent();
        }
        let eventModall = this.props.eventIdProps
        if (eventModall) {
            this.changeTagEventModal();
        }

        var orig = document.body.className;
        setTimeout(() => {
            this.setState({showLogIn: false});
            document.body.className = orig + (orig ? " " : "") + "noOverflow";
        }, 2000);

    }

  showFriendsPageH = () => {
    this.setState({ open: 3 });
  };

    openEventById = (eventId) => {
        this.setState({showEventInfoId: eventId});
    };
    openDealById = (dealId) => {
        this.setState({showDealInfoId: dealId});
    };

    openUserById = (userId) => {
        this.setState({showUserInfoId: userId});
    };
    resetShowEventInfoId = () => {
        this.setState({showEventInfoId: null});
    };

    resetShowDealInfoId = () => {
        this.setState({showDealInfoId: null});
    };

    resetShowUserInfoId = () => {
        this.setState({showUserInfoId: null});
    };

    handleInputValue(val) {

        this.setState({eventsNumber: val});


    }
    refreshPage() {
        window.location.reload(false);
      }

    render() {
        const style = {
            height: "100%"
        };
        return (
            <div style={style}>
                <LoadingOverlay
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(131, 126, 223)',
                            zIndex: 999
                        })
                    }}

                    className="MyLoader_wrapper"
                    active={this.state.showLogIn}
                    spinner
                    // wrapper={<BounceLoader className="bounce-size" />}
                    text="Loading..."
                >
                    <NavBarNormal
                    refreshPage={this.refreshPage}

                        handleLog={this.props.handler}
                        changeTagsEvent={this.changeTagEvent}
                        changeFriends={this.changeTagFriends}
                        // changeFind={this.changeTagFind}
                        changeMyEvents={this.changeTagMyEvents}
                        changeMyTickets={this.changeTagMyTickets}
                        changeTagCreateEvents={this.changeTagCreateEvents}
                        showFriendsPage={this.showFriendsPageH}
                        openEventById={this.openEventById}
                        openDealById={this.openDealById}
                        openUserById={this.openUserById}
                        handleInput={this.state.eventsNumber}

                    />



          <HomeScreenNormal

changeTagsEvent={this.changeTagEvent}
                        changeFriends={this.changeTagFriends}
                        // changeFind={this.changeTagFind}
                        changeMyEvents={this.changeTagMyEvents}
                        changeMyTickets={this.changeTagMyTickets}


            handleInput={this.handleInputValue}
            selectedEventId={this.props.selectedEventId}
            handler={this.props.handler}
            eventIdProps={this.props.eventIdProps}
            userLogged={this.props.userLogged}
            // handleLog={this.props.handler}
            openTag={this.state.open}
            openEvent={this.state.modalMakeEvents}
            openEventFromPromo={this.state.modalPromoEvent}
            eventIdModall={this.state.HPModal}
            showContent={this.state.showFriendsPage}
            showEventInfoId={this.state.showEventInfoId}
            showDealInfoId={this.state.showDealInfoId}
            showUserInfoId={this.state.showUserInfoId}
            resetShowEventInfoId={this.resetShowEventInfoId}
            resetShowDealInfoId={this.resetShowDealInfoId}
            resetShowUserInfoId={this.resetShowUserInfoId}
            selectedBusinessId={this.props.userBusinessId}
            changeTag={this.changeTag}
            changeTagEvent={this.changeTagEvent}
          />
       
          <Footer classNamee="HPNormal-Footer" />
        </LoadingOverlay>
      </div>
    );
  }
}
