import React, {Children} from "react";
import {
    Container,
    Col,
    Row,
    Button,
    Input,
    Alert,
    ModalBody,
    Modal,
    ModalHeader,
    Tooltip,
    Label
} from "reactstrap";
import "./BannerDetailsNormal.scss";
import ReactDOM from "react-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faExclamationTriangle,
    faCalendarAlt,
    faFemale,
    faUsers,
    faUserFriends,
    faUserTag,
    faNewspaper,
    faTags,
    faTicketAlt,
    faLink,
    faMale,
    faRestroom,
    faCreditCard,
    faLock,
    faCheck,
    faWheelchair,
    faEuroSign,
    faPoundSign,
    faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import MapContainer from "../../BothUsers/MapContainer/MapContainer.js";
import {fetchApi} from "../../../fetchApi";
import {userCredentials} from "../../../globalVariables";
import {hostDetails} from "../../../globalVariables";
import SignUpLogIn from "../../FirstPage/SignUpLogIn/SignUpLogIn";
import ZingMap from "../../ZingMap/ZingMap.js";
import UserProfile from "../../BothUsers/UserProfile/UserProfile";
import {FacebookIcon, TwitterIcon, WhatsappIcon} from "react-share";
import MakeDeal from "../../BusinessUser/Deals/MakeDeal";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import swal from "sweetalert";
import moment from "moment";
import FlatList from "flatlist-react";
import ContentEvents from "../../BothUsers/Events/ContentEvents";
import Friends from "../../BothUsers/Friends/Friends";
import {Calendar, momentLocalizer} from "react-big-calendar";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import ModalVideo from "react-modal-video";

import LoadingOverlay from "react-loading-overlay";

const localizer = momentLocalizer(moment);

let SLogo = require("../../../Assets/img/SLogo.png");
let videoBg = require("../../../Assets/img/videoBackgroundBussines.png");

let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class BannerDetailsNormal extends React.Component {
    constructor(props) {
        // let nowYear = moment(new Date()).format("YYYY");
        // let nowMonth = moment(new Date()).format("MM");
        // let nowDay = moment(new Date()).format("DD");
        // let today = { nowDay, nowMonth, nowYear };
        let today = moment().format("YYYY-MM-DD HH:mm");
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            todaySDate: today,
            locationList: [{lat: 34, lng: 24}],
            showTeam1: true,
            showTeam2: false,
            showMap: false,
            gender: "",
            ageCategoryList: [],
            ageCategory: "",
            nOfTickets: 0,
            openAlert: false,
            multipriceId: "",
            categories: [],
            category: "",
            logo: "",
            allUsers: [],
            userNumber: "",
            hasProfilePic: false,
            userName: "",
            getLoggedIn: false,
            userType: "normal",
            openModal: false,
            modalProfileFriends: false,
            url: "",
            // selectedUserId:""
            friendId: "",
            multiplyCalendar: [],
            text: "",
            joinRequestsList: [],
            requestAllredySent: false,
            userIsJoined: false,
            friendsList: [],
            createdEventsList: [],
            registeredEventsList: [],
            dealsDateList: [],
            joinRequestsListD: [],
            allUsersD: [],
            showSelectCard: false,
            cardsList: [],
            showAddCard: false,
            show: false,
            userDeactivated: false,
            handicapBanner: "",
            sponsorImg: "",
            hasSponsor: false,
            sponsorVideo: "",
            holidayList: [],
            modalMakeSponsorPhoto: false,
            dealDate: "",
            cardMultiPrice: "",
            cardPrice: "",
            cardFinalPrice: "",
            numberOfT: [],
            tooltipOpen: false,
            toolTipTickets: false,
            poundSign: faPoundSign,
            realPicture: null,
            commissions: this.getCommissions(),
            showTerms: false,
            textLoader: "",
            showUpdateDeal: false,
            comesFromBanner: true,
            eventCheck: null,
            modalFirstImage: false,
            modalSecoundImage: false,
            modalThirdImage: false,
            openModalPhoto: false
        };
        this.handler = this.handler.bind(this);
        this.handlerModal = this.handlerModal.bind(this);
        // this.eventHandler = this.eventHandler.bind(this)
    }
    toggleTooltipTickets = () => {
        this.setState({ toolTipTickets: !this.state.toolTipTickets })
    }
    renderToolTipTickets() {
        return (<div>
            <div className="justify-content-center align-bottom">
                <FontAwesomeIcon className="iconTooltipBanner"
                icon={faExclamationTriangle} id="toolTipCategory"
                color={"#f5b91c"} />
            </div>
            <Tooltip
                placement="top"
                isOpen={this.state.toolTipTickets}
                target="toolTipCategory"
                toggle={this.toggleTooltipTickets}
                hideArrow
            >
                <div className="toolTipGeneral">
                    <h6>
                        Tickets Event
                    </h6>
                    <p className="tooltipC" >
                        YOU CAN BUY MAXIMUM 4(FOUR) TICKETS/PER USER. ZING COMPANY IS AGAINST TICKET EXPLOIT. THANK YOU
                    </p>
                </div>
            </Tooltip>
        </div>)
    }
    getCommissions = async () => {
        let data = new FormData();
        let url = "getcommissionslist";
        const response = await fetchApi(url, "GET", data);
        let commisionsToUse = new Object();
        if (response.success) {
            //this.setState({ commissions: response.commissionslist });
            // commisionsToUse.stripe_commission = parseFloat(response.commissionslist.stripe_commission) / 100;
            commisionsToUse.zing_commission = parseFloat(response.commissionslist.zing_commission) / 100;
            commisionsToUse.voucher_deal_commission = parseFloat(response.commissionslist.voucher_deal_commission) / 100;
            commisionsToUse.realCommission = this.props.isDeal && this.props.isDeal === true ? commisionsToUse.voucher_deal_commission : commisionsToUse.zing_commission;
        } else {
            this.setState({ commissions: null });
        }
        this.setState({ commissions: commisionsToUse });
    }
    deleteDeal = async () => {
        this.setState({ textLoader: "Deleting deal...", show: true })
        let authKey = userCredentials.authKey;
        const url = "deleteunuseddeal";
        const data = new FormData();
        data.append("deal_id", this.props.id);
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({ show: false });
            swal("Success!", "Deal deleted successfully!", "success");
            this.props.reloadEventsCallback();
            this.props.closeModal();
        } else {
            this.setState({ show: false });
            swal("Error!", "Something vent wrong!", "error");
        }
    };

    toggleTooltip = (target) => {
        this.setState({
            ...this.state,
            [target]: !this.state[target]
        });

        //  this.setState({tooltipOpen: !this.state.tooltipOpen});
    };


    showSelectCard = () => {
        this.setState({ showSelectCard: true });
    };
    toggleSelectCard = () => {
        this.setState({ showSelectCard: false });
    };
    showAddCard = () => {

        if (!this.state.userDeactivated) {
            this.setState({ showAddCard: true });
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };

    toggleAddCard = (needRefresh, cardId) => {
        this.setState({ showAddCard: false });
        if (needRefresh === true) {

            this.purchaseTicket(this.state.cardMultiPrice, this.state.cardPrice, cardId);


        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({ cardsList: response.cards });
        } else {
            this.setState({ userDeactivated: true });
        }
    };

    selectEvent = (event) => {
        this.setState({ profileModal: !this.state.profileModal });
        let dealCalendar = event.resource.deals_calendar.filter(
            (item) =>
                new Date(item.date).getTime() === new Date(event.start).getTime()
        );
        this.setState({
            profileModal: !this.state.profileModal,
            curentDealCalendarId: dealCalendar[0].id,
            currentDealCalendar: dealCalendar[0],
        });
        this.toggleModalShowDetailsD(
            event.resource.deals,
            event.resource.deals_calendar
        );
    };

    toggleModalShowDetailsD = (item, calendar) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: [
                {
                    id: 1,
                    price: calendar.length > 0 ? calendar[0].price : 0,
                    nOf: item.max_persons,
                    indicativePrice: item.currency,
                },
            ],
            eventLogo: item.logo,
            currentCalendar: calendar,
        });

        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    toogleModalShowDetails = () => {
        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    getAllLocations = async () => {

        const data = new FormData();
        const url = "getalllocations";
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("category", "");
        const response = await fetchApi(url, "POST", data);
        if (
            response.success &&
            response.locations &&
            response.locations.length > 0
        ) {
            this.setState({ zingMapList: response.locations });
        }
    };

    openFriends = (name) => {
        this.listoffriends();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " friends",
            showUserFriends: true,
        });
    };

    openEventsCreated = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created events",
            showUsersCreatedEvents: true,
        });
    };

    openEventsRegistered = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " registered events",
            showUserRegisteredEvents: true,
        });
    };

    openDealsCreated = (name) => {
        this.getMyDeals();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created deals",
            showUserDeals: true,
        });
    };

    toggleProfileModal = () => {
        this.setState({ profileModal: !this.state.profileModal });
        if (this.state.showUsersCreatedEvents) {
            this.setState({ showUsersCreatedEvents: false });
        }
        if (this.state.showUserRegisteredEvents) {
            this.setState({ showUserRegisteredEvents: false });
        }
        if (this.state.showUserFriends) {
            this.setState({ showUserFriends: false });
        }
        if (this.state.showUserDeals) {
            this.setState({ showUserDeals: false });
        }
    };

    refreshFriendsList() {
        // if (this.props.refreshAllFriends) {
        //     this.props.refreshAllFriends();
        // }
    }

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("user_id", this.state.friendId);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            let createdEvents = [];
            createdEvents = response.events.filter(
                (element) => element.is_assigned === false
            );
            let registeredEvents = [];
            registeredEvents = response.events.filter(
                (element) => element.is_assigned === true
            );
            this.setState({
                createdEventsList: createdEvents.reverse(),
                registeredEventsList: registeredEvents.reverse(),
            });
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length == 0
        ) {
            this.setState({ showAlertMyE: true });
        } else {
            this.setState({ showAlertMyE: false });
        }
    };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", this.state.friendId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {

            this.setState({ friendsList: response.list_of_friends });
        }
    };

    closeModal = () => {
        this.setState({ modalProfileFriends: false });
    };

    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            let catName = response.list_of_categories.filter(
                (element) => parseInt(element.id) === parseInt(this.props.category)
            );
            if (catName[0] !== undefined) {
                this.setState({ category: catName[0].name });
            }
        }
    };

    getAgeCategory = async () => {
        const url = "agecategorylist";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            let category = "all ages";
            if (this.props.ageCategory !== "0" && this.props.ageCategory !== null) {
                let cat = response.age_category.filter(
                    (item) => item.id === this.props.ageCategory
                );
                category = cat[0].name;
            }
            this.setState({
                ageCategoryList: response.age_category,
                ageCategory: category,
            });
        }
    };

    toggleMap = () => {
        this.setState({ showMap: !this.state.showMap });
    };

    team1ClickHandler = () => {
        this.setState({ showTeam1: true, showTeam2: false });
    };

    team2ClickHandler = () => {
        this.setState({ showTeam2: true, showTeam1: false });
    };

    seeAllClickHandler = () => {
        this.setState({ showTeam2: true, showTeam1: true });
    };

    componentDidMount = () => {
        this.checkEventExpired()
        this.getUserCards();
        if (this.props.gender === "1") {
            this.setState({ gender: "Male" });
        } else if (this.props.gender === "2") {
            this.setState({ gender: "Female" });
        } else {
            this.setState({ gender: "Unisex" });
        }
        // if (this.props.handicap_facilities === "0") {
        //     this.setState({handicapBanner: this.state.handicapBanner});
        // } else {
        //     this.setState({handicapBanner: this.state.handicapBanner});
        // }
        this.getVoucherInfo();
        if (this.props.curentDealCalendarId) {
            this.getUsersAssignedToDeal();
        }
        this.getAllLocations();
        this.getEventImages();
        this.getJoinRequests();
        this.getJoinRequestsD();
        this.getAgeCategory();
        this.getCategories();
        this.listofusersassignedtoevent();
        if (this.props.isLogged === true) {
            this.getAccountInfo();
        }

        this.getProfile();
        if (this.props.userIdE) {
            this.getEventCreatorProfile();
        }
        this.setState({
            url: hostDetails.url + "?snid=" + this.props.id,
        });
        let arr = new Set();
        let text = "";
        let arr2 = new Set();
        let arr3 = new Set();
        if (this.props.calendar !== undefined) {
            this.props.calendar.forEach((element) => {
                if (element.id === this.props.curentDealCalendarId) {
                    this.setState({ dealDate: element.date });
                }
            });
        }

        this.setState({ text });
    };

    onChangeTickets = (evt, id) => {


        if (evt.target !== undefined) {
            let nof = this.state.nOfTickets;
            nof = evt.target.value;
            this.setState({ nOfTickets: nof });
            let num = this.state.numberOfT;
            num[id] = evt.target.value;
            this.setState({ numberOfT: num });
            if (parseInt(evt.target.value) > 4) {
                this.setState({ openAlert: true });
            }
        }
    };
    toggleAlert = () => {
        this.setState({ openAlert: !this.state.openAlert });
    };

    getProfile = async (multipriceId) => {
        let authKey = userCredentials.authKey;
        const url = "getprofile";
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.userprofile !== null && response.userprofile !== undefined)
            if (response.userprofile[0] !== undefined) {
                if (response.userprofile[0].logo !== null || response.userprofile[0].image !== null) {
                    this.setState({ hasProfilePic: true });
                }
            }
    };

    getEventCreatorProfile = async () => {
        let authKey = userCredentials.authKey;
        const url = "getprofile";
        let data = new FormData();
        data.append("user_id", this.props.userIdE);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.userprofile !== null && response.userprofile !== undefined)
            if (response.userprofile[0] !== undefined) {

                this.setState({ realPicture: response.userprofile[0].img_makeitheader == 2 ? response.userprofile[0].logo : response.userprofile[0].image });
            }
    };

    getVoucherInfo = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperlocation";
        const data = new FormData();
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("address", "");
        // data.append("category_id", "");
        data.append("country", -1);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            response.vouchers.forEach((element) => {
                element.request_to_assign.forEach((request) => {
                    if (parseInt(request.event_id) === parseInt(this.props.id) && request.is_approved === "1") {
                        this.setState({
                            sponsorImg: element.voucher.voucher_image,
                            sponsorVideo: element.voucher.video,
                            hasSponsor: true,
                        });
                    }
                });
            });
        }
    };

    toggleVideoModal = () => {
        this.setState({ videoModal: !this.state.videoModal });
    };

    getAccountInfo = async (multipriceId) => {
        let authKey = userCredentials.authKey;
        if (authKey != null && authKey != "undefined" && authKey != "") {
            const url = "getselecteduserprofile";
            let data = new FormData();
            data.append(
                "user_id",
                userCredentials.userId ? userCredentials.userId : "0"
            );
            data.append("fub_user_id", this.props.userIdE);
            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
                this.setState({
                    userName: response.name,
                });
            } else {
                swal("Error!", "Invalid request!", "error");
            }
        }
    };

    getCardFirst(multiPriceId, priceId,) {

        const moneySign = (
            <div>
                {this.props.currencyBannner === "GBP" ? <FontAwesomeIcon icon={faPoundSign} /> :
                    this.props.currencyBannner === "lira" ? <FontAwesomeIcon icon={faPoundSign} /> :
                        this.props.currencyBannner === "euro" ? <FontAwesomeIcon icon={faEuroSign} /> :
                            this.props.currencyBannner === "EURO" ? <FontAwesomeIcon icon={faEuroSign} /> :
                                this.props.currencyBannner === "EUR" ? <FontAwesomeIcon icon={faEuroSign} /> :
                                    this.props.currencyBannner === "USD" ? <FontAwesomeIcon icon={faDollarSign} /> :
                                        this.props.currencyBannner === "RON" ?
                                            <div className="RonSign"> RON </div> : (null)}

            </div>
        );

        const finalPrice =
            parseInt(priceId) * parseInt(this.state.nOfTickets) +
            parseInt(priceId) *
            parseInt(this.state.nOfTickets) *
            (this.state.commissions.realCommission)
        //+ this.state.commissions.stripe_commission);

        this.setState({ cardMultiPrice: multiPriceId, cardPrice: priceId, cardFinalPrice: finalPrice });

        let contentObj = (<div className="swalElement">Total amount to pay:&nbsp; {moneySign} {finalPrice}  </div>);
        let card = this.state.cardsList;
        let wrapper = document.createElement('div');
        ReactDOM.render(contentObj, wrapper);
        let element = wrapper.firstChild;


        if (card && card.length > 0) {
            swal({
                title: "Do you want to use an existing card?",
                content: element,
                icon: "warning",
                buttons: {
                    add: {
                        text: "Yes",
                        value: 2
                    },
                    select: {
                        text: "No",
                        value: 1
                    }
                }
            }).then(val => {
                if (val === 1) {
                    this.showAddCard();
                } else if (val === 2) {
                    this.showSelectCard();
                }
            });
        } else {
            this.showAddCard();
        }


    }

    purchaseTicket = async (multipriceId, price, cardId) => {

        let authKey = userCredentials.authKey;

        const url = "taketickets";
        let data = new FormData();
        let card = this.state.cardsList.filter(
            (element) => element.default === true
        );

        const finalPrice =
            parseInt(price) * parseInt(this.state.nOfTickets) +
            parseInt(price) *
            parseInt(this.state.nOfTickets) *
            this.state.commissions.realCommission;
        //+ this.state.commissions.stripe_commission);
        if (parseInt(this.props.persons_left) >= 1) {
            if (this.props.isDeal) {
                data.append("user_id", userCredentials.userId);
                data.append("event_id", 0);
                data.append("multiprice_event_id", 0);
                data.append("deal_id", this.props.id);
                data.append("deal_calendar_id", this.props.curentDealCalendarId);
                data.append("deal_multiprice_id", multipriceId);
                data.append("tickets_number", this.state.nOfTickets);
                data.append("type_transaction", 2);
            } else {
                data.append("user_id", userCredentials.userId);
                data.append("event_id", this.props.id);
                data.append("multiprice_event_id", multipriceId);
                data.append("deal_id", 0);
                data.append("deal_calendar_id", 0);
                data.append("deal_multiprice_id", 0);
                data.append("tickets_number", this.state.nOfTickets);
                data.append("type_transaction", 1);
            }
            data.append("offert_id", 0);
            data.append("business_id", this.props.userIdE);
            data.append("card_id", cardId ? cardId : card[0].id);
            data.append(
                "invoice_amount",
                parseInt(price) * parseInt(this.state.nOfTickets)
            );
            data.append(
                "commision_amount",
                parseInt(price) *
                parseInt(this.state.nOfTickets) *
                this.state.commissions.realCommission
            );
            data.append("amount", finalPrice);
            data.append("price_per_ticket", price);
            data.append("currency", this.props.currencyBannner);
            this.setState({ textLoader: "Getting tickets...", show: true });

            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
                this.setState({ show: false });
                swal("Success!", "Your request has been sent", "success");
                this.props.closeModal();
                if (!this.props.isDeal) {
                    this.props.reloadEventsCallback();
                }
            } else {
                this.setState({ show: false });
                swal("Error!", response.message, "error");
            }

        } else {
            swal("Error!", "Event is full!", "error");
        }
    };

    openFullscreen = () => {
        if (this.mapRef.current.requestFullscreen) {
            this.mapRef.current.requestFullscreen();
        } else if (this.mapRef.current.mozRequestFullScreen) {
            /* Firefox */
            this.mapRef.current.mozRequestFullScreen();
        } else if (this.mapRef.current.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            this.mapRef.current.webkitRequestFullscreen();
        } else if (this.mapRef.current.msRequestFullscreen) {
            /* IE/Edge */
            this.mapRef.current = window.top.document.body; //To break out of frame in IE
            this.mapRef.current.msRequestFullscreen();
        }
    };

    leaveEvent = async () => {
        const authKey = userCredentials.authKey;
        const url = "cancelrequest";
        const data = new FormData();
        if (this.state.requestId !== null && this.state.requestId !== undefined) {
            data.append("request_id", this.state.requestId);
        } else {
        }
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.props.closeModal();
            this.props.reloadEventsCallback();
            this.listofusersassignedtoevent();
            swal("Success!", "Your left the event", "success");
        } else {
            swal("Error!", "Something went wrong!", "error");
        }
    };

    joinDeal = async (id) => {

        if (this.state.hasProfilePic) {
            if (!this.state.requestAllredySentD) {
                if (parseInt(this.props.persons_left) >= 1) {
                    const url = "applytodeal";
                    const data = new FormData();
                    data.append(
                        "business_deal_calendar_id",
                        this.props.curentDealCalendarId
                    );
                    data.append("deal_id", this.props.id);
                    data.append("user_id", userCredentials.userId);
                    data.append("tickets_number", 1);
                    data.append("comment", "comment");
                    data.append("deal_calendar_id", this.props.curentDealCalendarId);
                    const response = await fetchApi(
                        url,
                        "POST",
                        data,
                        userCredentials.authKey
                    );

                    if (response.success) {
                        // this.props.reloadEventsCallback();
                        swal("Success!", "Your request has been sent", "success");
                        this.props.closeModal();
                    } else {
                        swal(
                            "Error!",
                            "Failed to send request! Please try later!",
                            "error"
                        );
                    }
                } else {
                    swal("Error!", "Event is full!!!", "error");
                }
            } else {
                swal("Error!", "You already sent a request!", "error");
            }
        } else {
            swal("Error!", "Please add a profile image before joining deal", "error");

        }
    };

    joinEvent = async (id) => {
        let curentDate = moment();
        let d2;
        let count = 0;

        if (this.props.endDate !== null && this.props.endTime !== null) {
            let newDate = this.props.endDate.replace(/-/g, '/');
            d2 = new Date(newDate + "  " + this.props.endTime);

        } else {
            count++;
        }
        let lastDate = moment(d2);

        if (curentDate.isBefore(lastDate)) {
            if (this.state.hasProfilePic) {
                if (!this.state.requestAllredySent) {
                    if (parseInt(this.props.persons_left) >= 1) {
                        const url = "assigntoevent";
                        const data = new FormData();
                        data.append("event_id", this.props.id);
                        data.append("user_id", userCredentials.userId);
                        data.append("multiprice_event_id", id);
                        data.append("tickets_number", 1);
                        data.append("comment", "commnet");
                        const response = await fetchApi(
                            url,
                            "POST",
                            data,
                            userCredentials.authKey
                        );
                        if (response.success) {
                            this.props.reloadEventsCallback();
                            swal("Success!", "Your request has been sent", "success");
                            this.props.closeModal();
                        } else {
                            swal(
                                "Error!",
                                "Failed to send request! Please try later!",
                                "error"
                            );
                        }
                    } else {
                        swal("Error!", "Event is full!!!", "error");
                    }
                } else {
                    swal("Error!", "You already sent a request!", "error");
                }
            } else {
                swal(
                    "Error!",
                    "Please add a profile image before joining event",
                    "error"
                );
            }
        } else {
            swal("Error!", "This event has expired!", "error");
        }
    };

    getJoinRequests = async () => {
        const authKey = userCredentials.authKey;
        const url = "listofassignedusertoevent";
        const data = new FormData();
        data.append("event_id", this.props.id);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {

            let arr = [];
            response.assigned_users.forEach((element) => {
                if (parseInt(element.user_id) === parseInt(userCredentials.userId)) {
                    this.setState({ requestAllredySent: true, requestId: element.id });
                } else {
                    this.setState({ requestAllredySent: false });
                }
                if (element.is_approved === "2" && element.is_rejected === "2") {
                    arr.push(element);
                }
            });

            this.setState({ joinRequestsList: arr });
        }
    };

    getJoinRequestsD = async () => {
        const authKey = userCredentials.authKey;
        const url = "listofassignedusertodeal";
        const data = new FormData();
        data.append("deal_calendar_id", this.props.curentDealCalendarId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let arr = [];
            response.assigned_users.forEach((element) => {
                if (parseInt(element.user_id) === parseInt(userCredentials.userId)) {
                    this.setState({ requestAllredySentD: true, requestId: element.id });
                } else {
                    this.setState({ requestAllredySentD: false });
                }
                if (element.is_approved === "2" && element.is_rejected === "2") {
                    arr.push(element);
                }
            });

            this.setState({ joinRequestsListD: arr });
        }
    };

    getEventImages = async () => {
        const authKey = userCredentials.authKey;
        const url = "geteventimages";
        const data = new FormData();
        data.append("event_id", this.props.id);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            if (response.event_images[0] !== undefined) {
                this.setState({ firstImage: response.event_images[0].image });
            }
            if (response.event_images[1] !== undefined) {
                this.setState({ secondImage: response.event_images[1].image });
            }
            if (response.event_images[2] !== undefined) {
                this.setState({ thirdImage: response.event_images[2].image });
            }
        }
    };

    aproveUser = async (multipriceId, requestId, userId) => {
        const authKey = userCredentials.authKey;
        const url = "approverejectevent";
        const data = new FormData();
        data.append("event_id", this.props.id);
        data.append("multiprice_event_id", multipriceId);
        data.append("event_assigned_id", requestId);
        data.append("is_approved", "1");
        data.append("is_rejected", "2");
        data.append("user_id", userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            swal("Success!", "You approved this user!", "success");
            this.getJoinRequests();
            this.listofusersassignedtoevent();
            this.props.reloadEventsCallback();
        } else {
            swal("Error!", "Something went wrong!", "error");
        }
    };

    rejectUser = async (multipriceId, requestId, userId) => {
        const authKey = userCredentials.authKey;
        const url = "approverejectevent";
        const data = new FormData();
        data.append("event_id", this.props.id);
        data.append("multiprice_event_id", multipriceId);
        data.append("event_assigned_id", requestId);
        data.append("is_approved", "2");
        data.append("is_rejected", "1");
        data.append("user_id", userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            swal("Success!", "You rejected this user!", "success");
            this.getJoinRequests();
        } else {
            swal("Error!", "Something went wrong!", "error");
        }
    };

    aproveUserD = async (calendarId, requestId, userId) => {
        const authKey = userCredentials.authKey;
        const url = "approverejectdeal";
        const data = new FormData();
        data.append("deal_id", this.props.id);
        data.append("deal_calendar_id", calendarId);
        data.append("deal_assigned_id", requestId);
        data.append("is_approved", "1");
        data.append("is_rejected", "2");
        data.append("user_id", userId);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal("Success!", "You approved this user!", "success");
            this.getJoinRequestsD();
            this.getUsersAssignedToDeal();
        } else {
            swal("Error!", "Something went wrong!", "error");
        }
    };

    rejectUserD = async (calendarId, requestId, userId) => {
        const authKey = userCredentials.authKey;
        const url = "approverejectdeal";
        const data = new FormData();
        data.append("deal_id", this.props.id);
        data.append("deal_calendar_id", calendarId);
        data.append("deal_assigned_id", requestId);
        data.append("is_approved", "2");
        data.append("is_rejected", "1");
        data.append("user_id", userId);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal("Success!", "You rejected this user!", "success");
            this.getJoinRequestsD();
        } else {
            swal("Error!", "Something went wrong!", "error");
        }
    };

    toggle = () => {
        if (this.props.price === "PAY") {
            this.setState({ showPopover: !this.state.showPopover });
        }
    };

    getUsersAssignedToDeal = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofusersassignedtodeal";
        let data = new FormData();
        data.append("deal_calendar_id", this.props.curentDealCalendarId);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success && this.props.curentDealCalendarId !== 0) {
            response.list_of_users.forEach((element) => {
                if (element.user_informations !== null) {
                    if (
                        parseInt(element.user_informations.user_id) ===
                        parseInt(userCredentials.userId)
                    ) {
                        this.setState({ userIsJoinedD: true });
                    }
                }
            });
            this.setState({ allUsersD: response.list_of_users });
            this.setState({ selectedUserIdD: response.list_of_users.user_id });
        }
    };

    listofusersassignedtoevent = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofusersassignedtoevent";
        let data = new FormData();
        data.append("user_id", this.props.userId);
        data.append("event_id", this.props.id);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            response.list_of_users.forEach((element) => {
                if (
                    parseInt(element.user_informations.user_id) ===
                    parseInt(userCredentials.userId)
                ) {
                    this.setState({ userIsJoined: true });
                }
            });
            this.setState({
                allUsers: response.list_of_users,
                userNumber: response.list_of_users,
            });
            this.setState({ selectedUserId: response.list_of_users.user_id });
        }
    };

    handlerModalFriends = (val) => {

        this.setState({
            friendId: val,
            modalProfileFriends: !this.state.modalProfileFriends,
        });
    };

    copyToClipboard = (text, elementId) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        const parentElement = document.getElementById(elementId);
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        parentElement.removeChild(textField);
        swal("Success!", "Event link copied to clipboard!", "success");
    };

    handler(val) {
        this.setState({ getLoggedIn: true, userType: val });
        this.props.handler(val, true, false);
    }

    handlerModal(val) {
        if (val !== undefined) {
            this.handler(val);
        }
    }

    getMyDeals = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofdealsperbusiness";
        const data = new FormData();
        data.append("business_user_id", this.state.friendId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {

            let arr = [];
            response.deals.forEach((felement) => {
                felement.deals_calendar.forEach((element) => {
                    let endDate =
                        element.date.substring(0, 10) + " " + felement.deals.end_hour;
                    arr.push({
                        start: moment(element.date).toDate(),
                        end: moment(endDate).toDate(),
                        title: felement.deals.name,
                        resource: felement,
                    });
                });
            });
            this.setState({ dealsDateList: arr, holidayList: response.holidays });
        }
    };

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
            profileModal: true,
            modalTitle: "Event details",
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({ showVoucherInput: false });
        }
        if (this.state.showVoucers) {
            this.setState({ showVoucers: false });
        }
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {};
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(start);
        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                if (parseInt(element.persons_left) === 0 || date < cd) {
                    style = {
                        backgroundColor: "red",
                    };
                }
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        style = {
                            backgroundColor: "orange",
                        };
                    }
                });
            }
        });
        return {
            style: style,
        };
    };
    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({ showTerms: !doesShow });
        this.setState({ isTerms: isTermsWindow });
    };
    renderEvents = (eventInfo, idx) => {
        return (
            <div className="my-1 " key={eventInfo.event.id}>
                <ContentEvents
                    categName={eventInfo.event.name_of_subcategory}
                    aliasName={eventInfo.event.name_of_aliascategory}

                    isClosed={!eventInfo.is_closed}
                    numberOfMsg={eventInfo.number_of_messages}
                    eventType={3}
                    eventId={eventInfo.event.id}
                    userIdE={eventInfo.event.user_id}
                    name={eventInfo.event.name}
                    category={eventInfo.event.category_id}
                    text={eventInfo.event.description}
                    date={
                        eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
                    }
                    area={eventInfo.event.address}
                    time={
                        eventInfo.event.start_hour
                            ? eventInfo.event.start_hour.substring(0, 5)
                            : ""
                    }
                    teams={eventInfo.event.team_number}
                    participants={
                        eventInfo.event.max_persons - eventInfo.event.persons_left
                    }
                    state="closed"
                    price={
                        eventInfo.multiprice.length === 0
                            ? "FREE"
                            : eventInfo.multiprice.length === 1
                                ? eventInfo.multiprice[0].price === "0"
                                    ? "FREE"
                                    : "PAY"
                                : "PAY"
                    }
                    gender={eventInfo.event.gender}
                    ageCategory={eventInfo.event.age_category}
                    latitude={eventInfo.event.latitude}
                    longitude={eventInfo.event.longitude}
                    team_number={eventInfo.event.team_number}
                    max_persons={eventInfo.event.max_persons}
                    persons_left={eventInfo.event.persons_left}
                    type="free"
                    toogleModalShowDetails={() =>
                        this.toggleModalShowDetails(
                            eventInfo.event,
                            eventInfo.multiprice,
                            eventInfo.user_logo
                        )
                    }
                    reloadEventsCallback={this.reloadEventsCallback}
                    categories={this.state.categories}
                    userLogo={eventInfo.user_logo}
                    showVoucer={this.showVoucer}
                    description={eventInfo.event.name}
                />
            </div>
        );
    };

    selectCardHandler = () => {
        //afiseaza lista
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? { ...el, default: false } : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? { ...el, default: true } : el
                    ),
                }));
            }
            swal({
                title: "Card successfully set as default!",
                icon: "success",
                buttons: {
                    Ok: {
                        text: "Ok",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    //  this.toggleSelectCard();
                    //  this.purchaseTicket(this.state.cardMultiPrice, this.state.cardPrice);
                }
            });

        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    toggleModalSponsorPhoto = () => {
        this.setState({ modalMakeSponsorPhoto: !this.state.modalMakeSponsorPhoto });
        // if (this.state.updateEvent) {
        //     this.setState({updateEvent: false});
        // }
    };

    toggleModalfirstImage = () => {
        this.setState({ openModalPhoto: !this.state.openModalPhoto });
    };

    check1 = () => {
        this.toggleModalfirstImage()
        this.setState({
            modalFirstImage: true,
            modalSecoundImage: false,
            modalThirdImage: false
        })
    }
    check2 = () => {
        this.toggleModalfirstImage()
        this.setState({
            modalSecoundImage: true,
            modalThirdImage: false,
            modalFirstImage: false,
        })
    }
    check3 = () => {
        this.toggleModalfirstImage()
        this.setState({
            modalThirdImage: true,
            modalSecoundImage: false,
            modalFirstImage: false,
        })
    }

    // showUpdateDeal
    toggleshowUpdateDeal = () => {

        this.setState({ showUpdateDeal: !this.state.showUpdateDeal })
    }
    CloseModal = () => {
        // this.getMyEvents();
        this.getMyDeals();
        // this.setState({updateEvent: false});
        this.setState({ showUpdateDeal: false });
    };
    checkEventExpired = () => {
        if (this.state.todaySDate <= this.props.endDateEvent) {
            this.setState({ eventCheck: true })
        } else {

            this.setState({ eventCheck: false })
        }
    }

    render() {
        const CURRENT_DATE = moment().toDate();
        const ColoredDateCellWrapper = ({ children, value }) => {
            let evtPresent = this.state.dealsDateList.filter((x) => {
                let curDate = new Date(value);
                curDate.setHours(0, 0, 0, 0);
                let dealDateStart = new Date(x.start);
                dealDateStart.setHours(0, 0, 0, 0);
                let dealDateEnd = moment(x.end);
                if (+curDate === +dealDateStart) {
                    return x;
                }
            });
            return React.cloneElement(Children.only(children), {
                style: {
                    ...children.style,
                    backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                },
            });
        };
        return (
            <LoadingOverlay
                active={this.state.show}
                spinner
                text={this.state.textLoader}
            >
                <Container className="">
                    {this.state.modalProfileFriends ? (
                        <Modal
                            className="costume-modal-prof"
                            isOpen={true}
                        // toggle={() => this.handlerModalFriends()}
                        >
                            <div className="header-p">
                                <ModalHeader className="head-1" />
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo" />
                                </div>
                                <ModalHeader
                                    className="head-2"
                                    toggle={() => this.handlerModalFriends()}
                                />
                            </div>

                            <UserProfile
                                userId={this.state.friendId}
                                // userId={this.props.userIdE}
                                refreshBlockedFriends={this.listofblockedusers}
                                refreshFriends={this.refreshFriendsList}
                                openEventsCreated={this.openEventsCreated}
                                openEventsRegistered={this.openEventsRegistered}
                                openFriends={this.openFriends}
                                openDealsCreated={this.openDealsCreated}
                                closeHandler={this.handlerModalFriends}
                            />
                        </Modal>
                    ) : null}

                    <Alert color="info" isOpen={this.props.inHoliday === true}>
                        The deal is in holiday
                    </Alert>
                    <Row className="profileAlign">
                        <Col xs="3" lg={2} className="p-0 d-flex justify-content-center">
                            {this.props.userLogo !== null &&
                                this.props.userLogo !== undefined &&
                                this.props.userLogo !== "" ? (
                                    <img
                                        src={this.state.realPicture ? this.state.realPicture : profilePic}
                                        className="evtPopupPic"
                                        alt="profilePic"
                                        onError={(e) => {

                                            e.target.onerror = null;
                                            e.target.src = profilePic;
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={profilePic}
                                        className=" evtPopupPic   "
                                        alt="profilePic"
                                    />
                                )}
                        </Col>
                        <Col xs="7" lg={4} className="p-0">
                            <div className="evtDetailsUsername">
                                {this.state.userName === null ? this.props.name : this.state.userName}
                            </div>

                        </Col>
                        <Col className="d-flex justify-content-end">
                            <div>
                                {this.props.price === "PAY" &&
                                    !this.state.requestAllredySentD &&
                                    !this.state.userIsJoinedD &&
                                    this.props.isDeal &&
                                    parseInt(this.props.userIdE) !== userCredentials.userId ? (
                                        <Button
                                            className="joinButton"
                                            onClick={() => this.joinDeal(this.props.multiprice[0].id)}
                                            disabled={this.props.inHoliday}
                                        >
                                            Reserve
                                        </Button>
                                    ) : null}

                                {this.props.isDeal ? (
                                    this.props.price === "FREE" &&
                                        !this.state.requestAllredySentD &&
                                        !this.state.userIsJoinedD &&
                                        parseInt(this.props.userIdE) !== userCredentials.userId ? (
                                            <Button
                                                className="joinButton"
                                                onClick={() => this.joinDeal(this.props.multiprice[0].id)}
                                            >
                                                I WANT IN
                                            </Button>
                                        ) : null
                                ) : !this.props.userLogged && !this.props.isLogged ? (
                                    <SignUpLogIn
                                        eventId={this.props.eventId}
                                        text="I WANT IN "
                                        class="iWantIn-Sign-btn"
                                        handlerModal={this.handlerModal}
                                        eventHandler={this.props.eventHandler}
                                        showSignUp={this.props.showSignUp}
                                        setFalseSignUp={this.props.setFalseSignUp}
                                    />
                                ) : // null
                                        this.props.price === "FREE" &&
                                            !this.state.requestAllredySent &&
                                            !this.state.userIsJoined &&
                                            parseInt(this.props.userIdE) !== userCredentials.userId ? (
                                                <Button
                                                    className="joinButton"
                                                    onClick={() => {
                                                        this.joinEvent(this.props.multiprice[0].id);
                                                    }}
                                                >
                                                    I WANT IN
                                                </Button>
                                            ) : null}

                                {this.props.price === "FREE" &&
                                    this.state.userIsJoined &&
                                    !this.props.isDeal ? (
                                        <div>
                                            {/* { this.state.requestId !== undefined  &&
                  this.props.endDate >= this.state.todaySDate ? */}
                                            {this.state.requestId !== undefined ? (
                                                <Button
                                                    className="joinButton"
                                                    onClick={() => this.leaveEvent()}
                                                >
                                                    Leave
                                                </Button>
                                            ) : //  <Button
                                                //    className="joinButton"
                                                //    onClick={() => swal("Success!", "You can't leave this event ", "success")}
                                                //  >
                                                //    Leave
                                                //  </Button>
                                                null}
                                        </div>
                                    ) : null}
                            </div>
                        </Col>
                    </Row>

                    <Col xs="12" lg={12} className="eventTopInfo">
                        <Col xs="3" lg={2} className="p-0">
                            <div className="EventsPicture d-flex justify-content-center">
                                <img src={this.props.eventLogo} />
                            </div>
                        </Col>

                        <Col xs="9" lg={10} className="pl-0">
                                            <div className="EventsTitle">{this.props.name}  {this.props.categoryName !== undefined?"- "+this.props.categoryName:null}</div>

                            <div className="detailsEventUnderTitle mt-2">
                                <div className="EventLabelDesc">
                                    <span className="EventLabel">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    {this.props.startDate} {this.props.endDate}

                                </div>
                                <div className="EventLabelDesc">
                                    <span className="EventLabel">
                                        <FontAwesomeIcon icon={faClock} />
                                    </span>
                                    {this.props.startTime}{this.props.endTime ? " - " + this.props.endTime : ""}
                                </div>
                                <div className="EventLabelDesc">
                                    <span className="EventLabel">
                                        {this.props.gender === "1" ? (
                                            <FontAwesomeIcon icon={faMale} />
                                        ) : this.props.gender === "2" ? (
                                            <FontAwesomeIcon icon={faFemale} />
                                        ) : (
                                                    <FontAwesomeIcon icon={faRestroom} />
                                                )}
                                    </span>{" "}
                                    {this.state.gender}
                                </div>
                                <div className="EventLabelDesc">
                                    <span className="EventLabel">
                                        <FontAwesomeIcon icon={faUsers} />
                                    </span>{" "}
                                    {this.state.ageCategory}
                                </div>

                                {/* {this.props.handicap_facilities === "0" ? (
                                    <div className="EventLabelDesc">
                                        <span className="EventLabel">
                                            <FontAwesomeIcon icon={faWheelchair}/> YES
                                        </span>{" "}
                                        {this.state.handicapBanner}
                                    </div>
                                ) : (<div className="EventLabelDesc">
                                    <span className="EventLabel">
                                        <FontAwesomeIcon icon={faWheelchair}/> NO
                                    </span>
                                </div>)} */}
                            </div>
                        </Col>
                    </Col>

                    <Col lg={12} className="my-3 pl-0">
                        <Row>
                            <Col lg={6} className="pl-0">
                                <div className="EventLabelLocation d-flex">
                                    <div className="d-flex EventLabel">
                                        <ZingMap
                                            showSearchTextBox={false}
                                            showOpenMapButton={true}
                                            inputClassName="hpSearchLocation"
                                            buttonClassName="greenBtnNew round"
                                            enableMarkerAdd={false}
                                            selectedLocationHandler={() => void 0}
                                            disableSelect={true}
                                            list={this.state.zingMapList}
                                            markerLat={this.props.latitude}
                                            markerLng={this.props.longitude}
                                            centerLng={this.props.longitude}
                                            centerLat={this.props.latitude}
                                        />
                                        Location:&nbsp;
                                    </div>
                                    <div> {this.props.address}</div>
                                </div>
                            </Col>

                            <Col xs="12" lg={3} className="p-0">
                                <div className="EventLabelTeams d-flex">
                                    <div className="d-flex align-items-center EventLabel">
                                        <FontAwesomeIcon icon={faUserFriends} className="mr-2" />
                                        Teams :&nbsp;
                                    </div>
                                    <div>{this.props.team_number}</div>
                                </div>
                            </Col>

                            <Col xs="12" lg={3} className="p-0">
                                <div className="EventLabelTeams d-flex">
                                    <div className="d-flex align-items-center EventLabel">
                                        <FontAwesomeIcon icon={faUserTag} className="mr-2" />
                                        Users :&nbsp;
                                    </div>
                                    <div>
                                        {this.props.max_persons - this.props.persons_left}/
                                        {this.props.max_persons}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <div ref={this.mapRef} className="mapContainer">
                        <MapContainer
                            lat={this.props.latitude}
                            lng={this.props.longitude}
                        />
                    </div>
                    <Modal
                        isOpen={this.state.openModalPhoto}
                        size="lg"
                        toggle={this.toggleModalfirstImage}
                    >
                         <div className="header-p">
            <ModalHeader className="head-1  text-white">Image</ModalHeader>
            <div className="logo_image">
              <img className="my_logo" src={SLogo} alt="my_logo" />
            </div>
            <ModalHeader className="head-2" toggle={this.toggleModalfirstImage} />
          </div>
                        <ModalBody className="modalbody-center">
                            <img
                                onClick={this.toggleModalfirstImage}
                                src={
                                    this.state.modalFirstImage === true ?
                                    (this.state.firstImage ) :
                                    this.state.modalSecoundImage === true ?
                                    (this.state.secondImage) :
                                    this.state.modalThirdImage === true ?
                                    (this.state.thirdImage) :
                                    (this.state.thirdImage)
                                }
                                className={"biggerSize"}
                                alt="profilePic"
                            />
                        </ModalBody>
                    </Modal>

                    <Row lg="12" className="">
                        <Col
                            lg="4"
                            className="d-flex justify-content-center imageBoxMyProfile"
                        >
                            <img
                                onClick={this.check1}
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.firstImage}
                            />
                        </Col>
                        <Col
                            lg="4"
                            className="d-flex justify-content-center imageBoxMyProfile"
                        >
                            <img
                                onClick={this.check2}
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.secondImage}
                            />
                        </Col>
                        <Col
                            lg="4"
                            className="d-flex justify-content-center imageBoxMyProfile"
                        >
                            <img
                                onClick={this.check3}
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.thirdImage}
                            />
                        </Col>
                    </Row>

                    <Row className="py-3 EventLabelDescBg">
                        <Col>
                            <div className="EventLabelDesc">
                                <span className="EventLabel-desc">
                                    <FontAwesomeIcon icon={faNewspaper} className="mr-2"/>
                  Description:{" "}
                                </span>
                                {this.props.description}
                            </div>
                        </Col>
                    </Row>
                    {/* {this.state.dealDate !== "" ? (
                        <Col>
                            <div>{this.state.dealDate.substring(0, 11)} </div>
                        </Col>
                    ) : null} */}
                    <Row className="EventLabel mb-3 shareParent" id="shareParent">
                        Share:
                        <FacebookShareButton url={this.state.url} quote={this.props.name}>
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={this.state.url} title={this.props.name}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton url={this.state.url} title={"Check this event!"}
                            description={this.state.name}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <div
                            className="copyLink"
                            onClick={() => this.copyToClipboard(this.state.url, "shareParent")}
                        >
                            <FontAwesomeIcon icon={faLink} />
                        </div>
                    </Row>

                    <div className="d-flex joinedPersons">
                        <div className="participants-parinte">

                            {!this.props.isDeal ?
                                <div>
                                    {this.state.userNumber <= 0 ?
                                        <Row className="EventLabel mb-3">No Participants Yet</Row> :
                                        <Row className="EventLabel mb-3"> Participants:</Row>
                                    }

                                    <div className="d-flex">
                                        {!this.props.isDeal
                                            ? this.state.allUsers.map((item, index) => (
                                                <div key={index} className="participants-col  mr-2">
                                                    {item.userprofile[0] !== undefined ? (
                                                        <img
                                                            onClick={() => {

                                                                if (parseInt(item.is_deleted) === 2) {
                                                                    this.handlerModalFriends(item.user_informations.user_id)
                                                                }
                                                            }
                                                            }
                                                            className={parseInt(item.is_deleted) === 2 ? "teamMember" : "teamMember imageOpacity"}
                                                            src={item.userprofile[0].logo}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = profilePic;
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>

                                            ))
                                            : null}
                                    </div>
                                </div> : null}

                            {this.props.isDeal ? (
                                <div>
                                    {this.state.allUsersD.length <= 0 ? (
                                        <Row className="EventLabel mb-3">No Participants Yet</Row>
                                    ) : (
                                            <Row className="EventLabel mb-3"> Participants:</Row>
                                        )}
                                    <div className="d-flex">
                                        {this.props.isDeal
                                            ? this.state.allUsersD.map((item, index) => (
                                                <div key={index} className="friends-col mr-2">
                                                    {item.userprofile[0] !== undefined ? (
                                                        <img
                                                            onClick={() =>
                                                                this.handlerModalFriends(
                                                                    item.user_informations.user_id
                                                                )
                                                            }
                                                            className="teamMember "
                                                            src={item.userprofile[0].logo}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = profilePic;
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                            ))
                                            : null}
                                    </div>
                                    {" "}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalMakeSponsorPhoto}
                        size="lg"
                        toggle={this.toggleModalSponsorPhoto}
                    >
                        <ModalBody className="modalbody-center">
                            <img
                                onClick={this.toggleModalSponsorPhoto}
                                src={this.state.sponsorImg}
                                className={"biggerSize"}
                                alt="profilePic"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = profilePic;
                                }}
                            />
                        </ModalBody>
                    </Modal>

                    {parseInt(this.props.userIdE) === userCredentials.userId &&
                        !this.props.isDeal ? (
                            <div>
                                <Row className="EventLabel mb-3">Request:</Row>
                                <div className="friendRow">
                                    {this.state.joinRequestsList.map((item, index) => (
                                        <div className="friendContainer" key={item.user_id}>
                                            <div className="imgBoxFriends">
                                                <img
                                                    className="imgBoxFriends"
                                                    src={
                                                        item.logo !== null &&
                                                            item.logo !== "" &&
                                                            item.logo !== undefined
                                                            ? item.logo
                                                            : profilePic
                                                    }
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = profilePic;
                                                    }}
                                                ></img>
                                            </div>

                                            <div className="friendName">{item.name}</div>
                                            <Col className="p-0 m-0 d-flex">
                                                <Col lg="6" className="p-0 d-flex m-0">
                                                    <Button
                                                        className="viewProf p-1"
                                                        onClick={() =>
                                                            this.aproveUser(
                                                                item.multiprice_event_id,
                                                                item.id,
                                                                item.user_id
                                                            )
                                                        }
                                                    >
                                                        Aprove
                                                </Button>
                                                </Col>
                                                <Col lg="6" className="p-0 d-flex m-0">
                                                    <Button
                                                        className="viewProf p-1"
                                                        onClick={() =>
                                                            this.rejectUser(
                                                                item.multiprice_event_id,
                                                                item.id,
                                                                item.user_id
                                                            )
                                                        }
                                                    >
                                                        Reject
                                                </Button>
                                                </Col>
                                            </Col>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}

                    {parseInt(this.props.userIdE) === userCredentials.userId &&
                        this.props.isDeal ? (
                            <div>
                                <Row className="EventLabel mb-3">Request:</Row>
                                <div className="friendRow">
                                    {this.state.joinRequestsListD.map((item, index) => (
                                        <div className="friendContainer" key={item.user_id}>
                                            <div className="imgBoxFriends">
                                                <img
                                                    className="imgBoxFriends"
                                                    src={
                                                        item.logo !== null &&
                                                            item.logo !== "" &&
                                                            item.logo !== undefined
                                                            ? item.logo
                                                            : profilePic
                                                    }
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = profilePic;
                                                    }}
                                                ></img>
                                            </div>

                                            <div className="friendName">{item[0].name}</div>
                                            <Row className="p-0 m-0">
                                                <Col lg="6" className="p-0 m-0">
                                                    <Button
                                                        className="viewProf p-1"
                                                        onClick={() =>
                                                            this.aproveUserD(
                                                                item.deal_calendar_id,
                                                                item.id,
                                                                item.user_id
                                                            )
                                                        }
                                                    >
                                                        Aprove
                                                </Button>
                                                </Col>
                                                <Col lg="6" className="p-0 m-0">
                                                    <Button
                                                        className="viewProf p-1"
                                                        onClick={() =>
                                                            this.rejectUserD(
                                                                item.deal_calendar_id,
                                                                item.id,
                                                                item.user_id
                                                            )
                                                        }
                                                    >
                                                        Reject
                                                </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    {this.state.hasSponsor ? (
                        this.state.sponsorImg !== null ? (
                            <Col
                                xs="12"
                                lg={12}
                                className="p-0 d-flex justify-content-end align-items-center"
                            >
                                <div className="evtDetailsUsername">Sponsored by:</div>
                                <img
                                    onClick={this.toggleModalSponsorPhoto}
                                    src={this.state.sponsorImg}
                                    className={"evtPopupPic"}
                                    alt="profilePic"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = profilePic;
                                    }}
                                />
                            </Col>
                        ) : this.state.sponsorVideo !== "" ? (
                            <Col
                                xs="12"
                                lg={12}
                                className="p-0 d-flex justify-content-end align-items-center"
                            >
                                <a
                                    className="evtDetailsUsername"
                                    onClick={this.toggleVideoModal}
                                >
                                    See sponsor video...
                                </a>
                            </Col>
                        ) : null
                    ) : null}

                    {parseInt(this.props.userIdE) !== userCredentials.userId &&
                        this.props.multiprice !== undefined ? (
                            this.props.multiprice.length !== 0 &&
                                this.props.multiprice[0] !== "0" ? (
                                    <Col className="p-0 mt-4">
                                        <div>
                                            {this.props.price === "PAY"
                                                ? this.props.multiprice.map((item, index) => (
                                                    <Container
                                                        key={index}
                                                        className=" cont-space   align-items-center"
                                                    >
                                                        <div className=" EventLabelDescBg-tickets">
                                                            <Row className="py-2 ">
                                                                <Col className="">
                                                                    <div className="d-flex EventLabelDesc">
                                                                        <Label lg={3} className="EventLabel">
                                                                            <FontAwesomeIcon
                                                                                icon={faTicketAlt}
                                                                                className="mr-2"
                                                                            />
                                                                    Ticket type:
                                                                </Label>
                                                                        <Input
                                                                            className="textarea-style"
                                                                            lg={8} disabled
                                                                            type="textarea"
                                                                            value={
                                                                                item.indicative_price ||
                                                                                item.indicativePrice
                                                                            }
                                                                        ></Input>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-2">
                                                                <Col
                                                                    xs="8"
                                                                    lg="3"
                                                                    className="pl-0 mt-1  EventLabel"
                                                                >
                                                                    <FontAwesomeIcon icon={faTags} className="" />{" "}
                                                            Price:{" "}
                                                                    <Label className=" lable-style ">
                                                                        {this.props.currencyBannner === "GBP" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faPoundSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "lira" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faPoundSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "euro" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "EURO" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "EUR" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "USD" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faDollarSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "RON" ? (
                                                                            <Label className="RonSign"> RON </Label>
                                                                        ) : null}
                                                                    </Label>{" "}
                                                                    {item.price}
                                                                </Col>
                                                                <Col xs="4" lg="2" className="pl-0 mt-1 ">
                                                                    <Input
                                                                        value={this.state.nOfTickets[item.id]}
                                                                        onChange={(e) => {
                                                                            if (
                                                                                e.target.value.length > e.target.maxLength
                                                                            ) {
                                                                                e.target.value = e.target.value.slice(
                                                                                    0,
                                                                                    e.target.maxLength
                                                                                );
                                                                            } else {
                                                                                this.onChangeTickets(e, index);
                                                                            }
                                                                        }}
                                                                        placeholder="No. of tickets"
                                                                        className="eventTicketsInput"
                                                                        type="number"
                                                                        maxLength="1"
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    xs="9"
                                                                    lg="4"
                                                                    className="pl-0 mt-1  EventLabel"
                                                                >
                                                                    <FontAwesomeIcon icon={faTags} />
                                                            Total Price:{" "}
                                                                    <Label className=" lable-style ">
                                                                        {this.props.currencyBannner === "GBP" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faPoundSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "lira" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faPoundSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "euro" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "EURO" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "EUR" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faEuroSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "USD" ? (
                                                                            <FontAwesomeIcon
                                                                                className="RonSign-money"
                                                                                icon={faDollarSign}
                                                                            />
                                                                        ) : this.props.currencyBannner === "RON" ? (
                                                                            <Label className="RonSign"> RON </Label>
                                                                        ) : null}
                                                                    </Label>
                                                            &nbsp;
                                                            <Label
                                                                        className="price-clculat"
                                                                        href="#"
                                                                        key={index}
                                                                        id={"Tooltip-" + item.id}
                                                                    >
                                                                        {" "}
                                                                        {this.state.numberOfT[index]
                                                                            ? (parseInt(item.price) *
                                                                                parseInt(this.state.numberOfT[index]) +
                                                                                parseInt(item.price) *
                                                                                parseInt(this.state.numberOfT[index]) *
                                                                                (this.state.commissions.realCommission)) : ""}
                                                                        {/*+ this.state.commissions.stripe_commission)).toFixed(2)*/}

                                                                    </Label>{" "}
                                                                    <Tooltip
                                                                        placement="bottom"
                                                                        key={item.id}
                                                                        // isOpen={this.state.tooltipOpen}
                                                                        isOpen={this.state[item.id]}
                                                                        target={"Tooltip-" + item.id}
                                                                        toggle={() => this.toggleTooltip(item.id)}
                                                                    >
                                                                        <Col>
                                                                            <p>
                                                                                Zing Commission:{" "}
                                                                                {(parseInt(item.price) *
                                                                                    parseInt(this.state.numberOfT[index])
                                                                                    * this.state.commissions.realCommission).toFixed(2)}{" "}
                                                                            </p>
                                                                            {/*<p>*/}
                                                                            {/*    Bank Commission:{" "}*/}
                                                                            {/*    {(parseInt(item.price) **/}
                                                                            {/*        parseInt(this.state.numberOfT[index]))}*/}
                                                                            {/*    /!*   * this.state.commissions.stripe_commission).toFixed(2)}{" "} *!/*/}
                                                                            {/*</p>*/}
                                                                        </Col>
                                                                    </Tooltip>{" "}
                                                                </Col>
                                                                {this.props.isDeal ?
                                                                    <Col
                                                                        xs="8"
                                                                        lg="2"
                                                                        className="pl-0 mt-1  getTicketsBtn"
                                                                    >
                                                                        {
                                                                            // +new Date(
                                                                            //     this.state.dealDate === ""
                                                                            //         ? this.props.endDateEvent
                                                                            //         : this.state.dealDate
                                                                            // ) >= +new Date() ?
                                                                            this.state.todaySDate <= this.state.dealDate
                                                                                ?
                                                                                (
                                                                                    <div className="getTicketsDivv">
                                                                                        <Button
                                                                                            disabled={this.props.inHoliday}
                                                                                            onClick={() => {
                                                                                                if (this.state.nOfTickets > 0) {
                                                                                                    // if (this.state.nOfTickets > 0 ){
                                                                                                    this.getCardFirst(item.id, item.price);
                                                                                                } else {
                                                                                                    swal(
                                                                                                        "Warning!",
                                                                                                        "Please selected the number of tickets!",
                                                                                                        "warning"
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className="greenBtnNew-tickets"
                                                                                        >
                                                                                            Get tickets
                                                            </Button>
                                                                                        {this.renderToolTipTickets()}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="getTicketsDivv">
                                                                                        <Button
                                                                                            disabled={this.props.inHoliday}
                                                                                            onClick={() => {
                                                                                                swal(
                                                                                                    "Warning!",
                                                                                                    "This event has expired! ",
                                                                                                    "warning"
                                                                                                );
                                                                                            }}
                                                                                            className="greenBtnNew-tickets"
                                                                                        >
                                                                                            Get tickets
                                                                </Button>
                                                                                        {this.renderToolTipTickets()}
                                                                                    </div>

                                                                                )}
                                                                    </Col>
                                                                    : <Col
                                                                        xs="8"
                                                                        lg="2"
                                                                        className="pl-0 mt-1  getTicketsBtn"
                                                                    >
                                                                        {
                                                                            // +new Date(
                                                                            //     this.state.dealDate === ""
                                                                            //         ? this.props.endDateEvent
                                                                            //         : this.state.dealDate
                                                                            // ) >= +new Date() ?
                                                                            this.state.eventCheck === true
                                                                                ?
                                                                                (
                                                                                    <div className="getTicketsDivv">
                                                                                        <Button
                                                                                            disabled={this.props.inHoliday}
                                                                                            onClick={() => {
                                                                                                if (this.state.nOfTickets > 0) {
                                                                                                    // if (this.state.nOfTickets > 0 ){
                                                                                                    this.getCardFirst(item.id, item.price);
                                                                                                } else {
                                                                                                    swal(
                                                                                                        "Warning!",
                                                                                                        "Please selected the number of tickets!",
                                                                                                        "warning"
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className="greenBtnNew-tickets"
                                                                                        >
                                                                                            Get tickets
                                                        </Button>
                                                                                        {this.renderToolTipTickets()}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="getTicketsDivv">
                                                                                        <Button
                                                                                            disabled={this.props.inHoliday}
                                                                                            onClick={() => {
                                                                                                swal(
                                                                                                    "Warning!",
                                                                                                    "This event has expired! ",
                                                                                                    "warning"
                                                                                                );
                                                                                            }}
                                                                                            className="greenBtnNew-tickets"
                                                                                        >
                                                                                            Get tickets
                                                            </Button>
                                                                                        {this.renderToolTipTickets()}
                                                                                    </div>

                                                                                )}
                                                                    </Col>}

                                                            </Row>
                                                        </div>
                                                        <hr></hr>
                                                    </Container>
                                                ))
                                                : null}{" "}
                                        </div>

                                        <Alert
                                            color="info"
                                            isOpen={this.state.openAlert}
                                            toggle={this.toggleAlert}
                                        >
                                            You can purchase maximum four tickets!
                                </Alert>
                                    </Col>
                                ) : null
                        ) : null}
                    {parseInt(this.props.userIdE) === parseInt(userCredentials.userId) &&
                        this.props.isDeal &&
                        parseInt(this.props.persons_left) ===
                        parseInt(this.props.max_persons) ? (
                            <Col className="d-flex justify-content-end">
                                <Button className="greenBtnEvt" onClick={this.toggleshowUpdateDeal}>
                                    Update deal
                            </Button>
                                <Button className="greenBtnEvt" onClick={this.deleteDeal}>
                                    Delete deal
                            </Button>

                            </Col>

                        ) : null}
                    <Modal
                        isOpen={this.state.profileModal}
                        // toggle={this.toggleProfileModal}
                        size="lg"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                {this.state.modalTitle}
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleProfileModal}
                            />
                        </div>
                        <ModalBody>
                            {this.state.showUsersCreatedEvents ? (
                                <div className="eventsArea">
                                    <FlatList
                                        list={this.state.createdEventsList}
                                        renderItem={this.renderEvents}
                                    ></FlatList>
                                </div>
                            ) : this.state.showUserRegisteredEvents ? (
                                <div className="eventsArea">
                                    <FlatList
                                        list={this.state.registeredEventsList}
                                        renderItem={this.renderEvents}
                                    ></FlatList>
                                </div>
                            ) : this.state.showUserDeals ? (
                                <Col className="d-flex justify-content-center">
                                    <Calendar
                                        messages={{
                                            showMore: total => (
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseOver={e => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                >SEE all deals
                                                </div>
                                            ),
                                        }}
                                        localizer={localizer}
                                        defaultDate={new Date()}
                                        defaultView="month"
                                        events={this.state.dealsDateList}
                                        style={{
                                            fontSize: "12px",
                                            height: "700px",
                                            width: "700px",
                                            backgroundColor: "#fff",
                                        }}
                                        selectable={true}
                                        // onSelectSlot={this.selectSlot}
                                        onSelectEvent={(event) => this.selectEvent(event)}
                                        components={{
                                            dateCellWrapper: ColoredDateCellWrapper,
                                        }}
                                        eventPropGetter={this.eventStyleGetter}
                                    />
                                </Col>
                            ) : this.state.showUserFriends ? (
                                <div>
                                    <Friends
                                        friendsList={this.state.friendsList}
                                        filterVal={this.state.filterVal}
                                        totalFriendsNo={this.state.totalFriendsNo}
                                        refreshAllFriends={this.listoffriends}
                                        getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                        searchEventHandler={this.searchFriends}
                                    />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>







                    <Modal
                        isOpen={this.state.showUpdateDeal}
                        size="lg"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Event details
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleshowUpdateDeal}
                            />
                        </div>
                        {this.state.currentMultiprice}
                        <ModalBody>
                            <MakeDeal


                                price={
                                    !this.state.currentMultiprice ||
                                        this.state.currentMultiprice.length === 0
                                        ? "FREE"
                                        : this.state.currentMultiprice.length === 1
                                            ? this.state.currentMultiprice[0].price === "0"
                                                ? "FREE"
                                                : "PAY"
                                            : "PAY"
                                }
                                CloseModal={this.CloseModal}

                                dealsDateList={this.props.dealsDateList}
                                currentEvent={this.props.currentEvent}
                                // dealName={this.props.name}
                                comesFromBanner={true}
                            // adress={this.props.address}
                            // reloadEventsCallback={this.reloadEventsCallback}
                            // startDate={this.state.startDateMakeDeal}
                            // endDate={this.state.endDateMakeDeal}
                            />
                        </ModalBody>
                    </Modal>












                    <Modal
                        isOpen={this.state.modalShowDetails}
                        // toggle={this.toogleModalShowDetails}
                        size="lg"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Event details
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toogleModalShowDetails}
                            />
                        </div>
                        {this.state.currentMultiprice}
                        <ModalBody>
                            {this.state.currentEvent !== undefined &&
                                this.state.currentEvent.date !== undefined ? (
                                    <BannerDetailsNormal
                                    categoryName={this.state.currentEvent.name_of_subcategory}
                                        currencyBannner={this.state.currentEvent.currency}
                                        userLogged={true}
                                        id={this.state.currentEvent.id}
                                        userIdE={this.state.currentEvent.user_id}
                                        name={this.state.currentEvent.name}
                                        gender={this.state.currentEvent.gender}
                                        ageCategory={this.state.currentEvent.age_category}
                                        startDate={this.state.currentEvent.date.substring(0, 11)}
                                        endDate={
                                            this.state.currentEvent.end_date !== null
                                                ? this.state.currentEvent.end_date.substring(0, 11)
                                                : ""
                                        }
                                        price={
                                            !this.state.currentMultiprice ||
                                                this.state.currentMultiprice.length === 0
                                                ? "FREE"
                                                : this.state.currentMultiprice.length === 1
                                                    ? this.state.currentMultiprice[0].price === "0"
                                                        ? "FREE"
                                                        : "PAY"
                                                    : "PAY"
                                        }
                                        startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                        endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                        latitude={this.state.currentEvent.latitude}
                                        longitude={this.state.currentEvent.longitude}
                                        team_number={this.state.currentEvent.team_number}
                                        max_persons={this.state.currentEvent.max_persons}
                                        persons_left={this.state.currentEvent.persons_left}
                                        description={this.state.currentEvent.description}
                                        multiprice={this.state.currentMultiprice}
                                        address={this.state.currentEvent.address}
                                        latitude={this.state.currentEvent.latitude}
                                        longitude={this.state.currentEvent.longitude}
                                        userLogo={this.state.eventLogo}
                                        eventLogo={this.state.currentEvent.category_logo}
                                        category={this.state.currentEvent.category_id}
                                        closeModal={this.toggleModalShowDetails}
                                        reloadEventsCallback={this.reloadEventsCallback}
                                        calendar={this.state.currentCalendar}
                                        calendar={this.state.currentCalendar}
                                        curentDealCalendarId={this.state.curentDealCalendarId}
                                        isDeal={
                                            this.state.currentCalendar !== undefined ? true : false
                                        }
                                        endDateEvent={this.state.currentEvent.end_date}
                                    />
                                ) : null}
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={this.state.showSelectCard}
                        size="lg"
                    // toggle={this.toggleSelectCard}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Select card
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader className="head-2" toggle={this.toggleSelectCard} />
                        </div>
                        <ModalBody>
                            <div className="cardDetailsParent">
                                <Row>
                                    <Col>
                                        {this.state.cardsList.length > 0 ? (
                                            this.state.cardsList.map((item) => (
                                                <Row
                                                    key={item.id}
                                                    className="d-flex align-items-center voucherContainer cardsListContainer"
                                                    onClick={() => {
                                                        this.setDefaultCard(item.id);
                                                    }}
                                                >
                                                    <Col xs="6">
                                                        <div>{item.name}</div>
                                                    </Col>
                                                    <Col xs="2">
                                                        {item.exp_month}/{item.exp_year}
                                                    </Col>
                                                    <Col xs="2">{item.last4}</Col>
                                                    <Col xs="2">
                                                        {item.default ? (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                className="mr-2"
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            ))
                                        ) : (
                                                <div>No cards saved</div>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="my-5">
                                    <Col lg="6" className="leftPart">
                                        <div>
                                            <Button
                                                className="addCardBtn"
                                                onClick={() => {
                                                    this.toggleSelectCard();
                                                    this.purchaseTicket(this.state.cardMultiPrice, this.state.cardPrice);
                                                }}
                                            >
                                                Pay
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text">
                                            <span className="cardImg">
                                                <FontAwesomeIcon icon={faLock} className="mr-2" />
                                            </span>
                                            Safe and in control
                                        </div>
                                        <div className="text">
                                            <span className="cardImg">
                                                <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                                            </span>{" "}
                                            Fast and simple
                                        </div>
                                    </Col>
                                </Row>
                                <div className="terms text-center">
                                    <a>
                                        I accept Zing Sport &nbsp;
                                        <a
                                            href="javascript:void(0);"
                                            onClick={() => this.showTermsAndCondition(true)}
                                        >
                                            Terms and Conditions
                                        </a>
                                        &nbsp; by adding a card
                                    </a>
                                </div>
                                <Modal
                                    className="costume-modal-prof"
                                    isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                                >
                                    <div className="header-p">
                                        <ModalHeader className="head-1" />
                                        <div className="logo_image">
                                            <img className="my_logo" src={SLogo} alt="my_logo" />
                                        </div>
                                        <ModalHeader
                                            className="head-2"
                                            toggle={() => this.showTermsAndCondition(false)}
                                        />
                                    </div>
                                    <ModalBody>
                                        <TermsAndConditions
                                            isTerms={this.state.isTerms}
                                        ></TermsAndConditions>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={this.state.showAddCard}
                        size="lg"
                    // toggle={this.toggleAddCard}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader className="head-2" toggle={this.toggleAddCard} />
                        </div>
                        <ModalBody>
                            <AddCard handleCancel={this.toggleAddCard}></AddCard>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={this.state.videoModal}
                        // toggle={this.toggleVideoModal}
                        fade={false}
                    >
                        <ModalVideo
                            channel="youtube"
                            youtube={{ autoplay: 1, mute: 1 }}
                            isOpen={this.state.videoModal}
                            videoId={this.state.sponsorVideo ? this.state.sponsorVideo.split("v=")[1] : ""}
                            onClose={() => this.setState({ videoModal: false })}
                        />
                    </Modal>
                </Container>
            </LoadingOverlay>
        );
    }
}
