import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import "./BannerDetailsBusiness.scss";

export default class BannerDetailsBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  team1ClickHandler = () => {
    this.setState({ showTeam1: true, showTeam2: false });
  };

  team2ClickHandler = () => {
    this.setState({ showTeam2: true, showTeam1: false });
  };

  seeAllClickHandler = () => {
    this.setState({ showTeam2: true, showTeam1: true });
  };
  render() {
    return (
      <Container className="">
        <Row className="row">
          <Col lg="1" className="imageBox" />
          <Col lg="11" className="">
            {this.props.name}
          </Col>
        </Row>

        <Row className="">
          <Col lg="3" className="">
            <Col className="d-flex justify-content-center border  px-3">5E</Col>
            <Col className="d-flex justify-content-center">Price</Col>
          </Col>
          <Col lg="3" className="">
            <Col className="d-flex justify-content-center border px-3">0</Col>
            <div className="d-flex justify-content-center">Teams</div>
          </Col>
          <Col lg="3" className="">
            <Col className="d-flex justify-content-center border px-3">40</Col>
            <Col className="d-flex justify-content-center">Participants</Col>
          </Col>
          <Col lg="3" className="">
            <Col className="d-flex justify-content-center border  px-3" />
            <Col className=" d-flex justify-content-center">Unisex</Col>
          </Col>
        </Row>

        <Row className="row">
          <Col lg="3" className="">
            <Col className="d-flex justify-content-center border  px-3">
              16 nov 2019
            </Col>
            <Col className="d-flex justify-content-center">Date</Col>
          </Col>
          <Col lg="3" className="">
            <Col className="justify-content-center border  px-3">08:00 am</Col>
            <Col className="d-flex justify-content-center">start</Col>
          </Col>
          <Col lg="3" className="">
            <Col className=" d-flex justify-content-center border  px-3">
              anytime
            </Col>
            <Col className="d-flex justify-content-center">finish</Col>
          </Col>
        </Row>

        <Col className="px-1">
          <Col lg="4" className="">
            {/* <Col className="d-flex justify-content-center border  px-3">
              ...
            </Col> */}
            <Col className="d-flex justify-content-center">
              Calendar manager
            </Col>
          </Col>
        </Col>
        <Col className="px-3 py-1 border">
          {/* <Input type="textarea" /> */}
          Ne intalnim la 4 ....
        </Col>
        <Col className="px-3 py-3">
          <Row className="">
            <Col lg="3" className="">
              <Col className="col-lg-12 d-flex justify-content-center border  px-3">
                28
              </Col>
              <Col className="col-lg-12 d-flex justify-content-center">
                Enrolled
              </Col>
            </Col>
            <Col lg="6" className="" />
            <Col lg="3" className="">
              <Col className=" d-flex justify-content-center border  px-3">
                140E
              </Col>
              <Col className="d-flex justify-content-center">Total price</Col>
            </Col>
          </Row>
        </Col>
        <Col className="col-lg-12 px-1">
          <Row className="">
            <Col lg="10" className="0">
              <Team
                team={[
                  { key: 1, name: "Andrei" },
                  { key: 2, name: "Viviana" },
                  { key: 3, name: "Ghita" },
                  { key: 4, name: "Maria" }
                ]}
              />{" "}
            </Col>

            <Col
              lg="2"
              className="d-flex justify-content-center align-items-center"
            >
              See all
            </Col>
          </Row>
        </Col>
        <Col className="px-3 py-3">
          <Row className="">
            <Col lg="3" className="">
              <Button>PROMOTE</Button>
            </Col>
            <Col lg="6" className="p-1" />
            <Col lg="3" className="">
              <Button>MULTIPLY</Button>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }
}

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: ""
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <div className="container">
        <div className="row py-3">
          {this.props.team.map(item => (
            <div
              className="col-lg-2"
              onClick={() =>
                this.setState({ modal: !this.state.modal, name: item.name })
              }
              key={item.key}
            >
              <div className="participant h-100" />
              <div className="d-flex justify-content-center">{item.name}</div>
            </div>
          ))}
        </div>
        <Modal
          isOpen={this.state.modal}
          // toggle={this.toggle}
          size="sm"
          centered={true}
        >
          <ModalBody>
            <div className="row">
              <div className="participant col-lg-3 h-100" />
              <div className="col-lg-9">{this.state.name} Dumitrescu</div>
              <div className="col-lg-12">05 / 01 / 1984</div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
