import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "./HowItWorks.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import {fetchApi} from '../../../fetchApi';
import vidImage from '../../../Assets/img/imageWorks.png';
import {
    Container,
    Row,
    Col,
    Media,
} from 'reactstrap';
import {Markup} from 'interweave';
import ModalVideo from 'react-modal-video';
import {sliderState} from "../../../globalVariables"
import ScrollableAnchor from 'react-scrollable-anchor'
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

export default class HowItWorks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isOpen: false,
            videoUrl: "",
        };

        this.openModal = this.openModal.bind(this)

    }

    openModal(videoID) {
        let vidParse = videoID.split("v=")[1];
        sliderState.canUseSlider = false;
        this.setState({isOpen: true, videoUrl: vidParse});
    }

    toggleModal = () => {
        sliderState.canUseSlider = !this.state.isOpen;
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
    getHowItWorks = async () => {
        const url = 'get_howitworks';
        const response = await fetchApi(url, 'POST', null);
        if (response.success) {
            this.setState({items: response.how_it_works_details})
        }
    };

    componentDidMount() {
        this.getHowItWorks();
    }
    renderItem(item,index){

        let title = item.title.split(';')
        return( 
            <div className="content" key={index}>
            <Container fluid={true}>
            <Row className="p-0 m-0 noMarginRow">


            <Col xs="12" sm={6} lg={8} className="p-0">
                    <div className="rightVideoImage">

                        {/* <div className="playBtnVid"
                             onClick={() => this.openModal(item.video)}/> */}
                        <Media onClick={() => this.openModal(item.video)} className="vidImg" object src={vidImage} alt="image"/>
                    </div>
                </Col>

                <Col xs="12" sm={6} lg={4} className="pr-5 parentCol ">
                    <ScrollAnimation animateIn='fadeIn' duration={2} animateOnce={true}>
                        <div className="sliderTitle">

                            <div className="sliderTitleBold"><Markup content={title[0]}/>
                        </div>
                        <div className="secTitle"><Markup content={title[1]}/>
                        </div>
                        </div>

                            <div className="sliderDesc">{item.description}</div>
                       
                    </ScrollAnimation>


                    </Col>

                </Row>
            </Container>

    </div>
    )
}




    render() {

        return (
            <div 
            className={this.props.isLogged === false ?
            "videoSlider"  : "videoSliderLogged"}
            >
                <div  className={this.props.isLogged === false ?
            "roundBg"  : "roundBgLogged"}>

                    <Container  fluid={true}>
                        <Row className="p-0 m-0 noMarginRow">

                            <AwesomeSlider
                             mobileTouch={false} >

                                {this.state.items && this.state.items.map((item,index ) => (


                                   this.renderItem(item, index)

                                ))}
                            </AwesomeSlider>

              <div className="modalBodySmall">
                            
                                <ModalVideo
                                  channel='youtube' youtube={{autoplay: 1, mute: 1}}
                                            isOpen={this.state.isOpen} videoId={this.state.videoUrl}
                                            onClose={() => this.setState({isOpen: false})}/>
                            </div>
                        </Row>
                    </Container>
                </div>


            </div>


        );


    }


}
