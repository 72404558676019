import React from 'react';
import './PromoBar.scss'
import google from '../../../Assets/img/google.png';
import apple from '../../../Assets/img/appStoreNew.png';
import phone from '../../../Assets/img/phoneImage.png';
import _ from 'lodash'
import {
    Button,
    Container,
    Row,
    Col,
    Media

} from 'reactstrap';
import SignUpLogIn from "../SignUpLogIn/SignUpLogIn"

import {fetchApi} from "../../../fetchApi.js";
import queryString from "query-string";

export default class PromoBar extends React.Component {


    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
        this.handler = this.handler.bind(this);
        this.eventHandler = this.eventHandler.bind(this)
        this.eventHandlerModal = this.eventHandlerModal.bind(this)
        this.handlerModal = this.handlerModal.bind(this);
        this.state = {
            getLoggedIn: false,
            userType: "normal",
            menuOpen: false,
            appStoreLink: '',
            googlePlayLink: "",
            fromWrapper: false
        };

    }


    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({fromWrapper: false})
            } else {
                this.setState({fromWrapper: true})
            }
        }
    }

    getStoreLinks = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({
                googlePlayLink: response.googlePlayLink && response.googlePlayLink.length > 0 ? response.googlePlayLink[0].googlePlayLink : "",
                appStoreLink: response.appStoreLink && response.appStoreLink.length > 0 ? response.appStoreLink[0].appStoreLink : ""
            });
        }
    };
    componentDidMount = () => {
        this.fromWrapper();
        this.getStoreLinks()
    }

    handler(val) {
        this.setState({getLoggedIn: true, userType: val});
        this.props.handleLog(val, true, true);
    }

    handlerModal(val) {
        this.setState({openModal: !this.state.openModal});
        if (val !== undefined) {
            this.handler(val);
        }
    }

    eventHandlerModal(val) {
        // this.setState({ openModal: !this.props.openModal });
        if (val !== undefined) {
            this.eventHandler(val);
        }
    }

    eventHandler(val) {
        this.setState({getLoggedIn: false, userType: val});
        this.props.eventHandler(val, false, false);
    }

  render() {
    return (
      <div className="promoBarBack">
      <div className="promoBar1">
        <Container fluid={true}>
          <Row>
            <div className="contentPromo">
              <Col xs="5" sm={4} lg={5}>
                <div className="backgroundLeft">
                  <div className="promotexts">
                    <div className="promoTextB">Create your event</div>
                    <div className="promoTextL">with ease.</div>
                  </div>

                                    <SignUpLogIn
                                        eventHandlerModal={this.eventHandlerModal}
                                        eventHandler={this.props.eventHandler}
                                        handlerModal={this.handlerModal}
                                        openModal={this.state.openModal}
                                        class="promoBtn"
                                        text="Start your event"
                                    />
                </div>
              </Col>

              <Col xs="4" sm={4} lg={4}>
              <Media
                    className="mobilePhone w-100"
                    object
                    src={phone}
                    alt="image"
                  />
               </Col>

              <Col xs="3" sm={4} lg={3}>
                <div className="promoButtons">
                                    {this.state.fromWrapper ? <a href={this.state.appStoreLink}>
                                        <Media
                                            className="appBtn  mb-3"
                                            object
                                            src={apple}
                                            alt="image"
                                        />
                                    </a> : null}
                                    {this.state.fromWrapper ?
                                        <a href={this.state.googlePlayLink}>
                                            <Media className="appBtn" object src={google} alt="image"/>
                                        </a> : null}
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
      </div>
    );
  }
}
