import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: this.props.address
        };
        //console.log(this.props.placeHolder,"this.props.placeHolder")
    }

    handleChange = address => {
        this.setState({address});
        if (address.length > 2 || address.length == 0) {
            this.props.onChangeLocation(address, 0, 0);
        }
    };

    handleSelect = address => {
        this.setState({address});
        if (address !== undefined) {
            if (address.length > 2) {
                geocodeByAddress(address)
                    .then(results => {
                        getLatLng(results[0]);
                        const latval = results[0].geometry.location.lat()
                            ? results[0].geometry.location.lat()
                            : 0;
                        const lngval = results[0].geometry.location.lng()
                            ? results[0].geometry.location.lng()
                            : 0;
                        this.props.onChangeLocation(address, latval, lngval);
                    })
                    .catch(error => console.error("Error", error));
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.address !== this.props.address) {
            if (this.props.address !== prevProps.address) {
                this.setState({address: this.props.address});
            }
        }

    }

    resetLocation = () => {
        this.setState({address: ""});
    }

    render() {

        return (
            <div>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                >
                    {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading
                      }) => (
                        <div style={{width: "100%"}}>
                            <input

                                id={this.props.isLoggedSearch === true ? "zing-map" : null } 

                                //placeHolder={this.props.placeHolder}
                                {...getInputProps({
                                    placeholder: this.props.placeHolder ? this.props.placeHolder :  "Search Places ...",
                                    className: "location-search-input"
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                        : {backgroundColor: "#ffffff", cursor: "pointer"};
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            key={(suggestion.index && suggestion.id) ? (suggestion.index && suggestion.id) : suggestion.index}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        );
    }
}

export default LocationSearchInput;