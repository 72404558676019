import React from 'react';
import termsStyle from './TermsAndConditions.scss'
let SLogo = require("../../Assets/img/logo.png");

const TermsAndConditions=(props)=>{
    let textToDisplay = '';
    let fileToDownload = '';
    // if(props.isTerms === true){
        fileToDownload='Terms.pdf';
        textToDisplay = (
            <div className="cookieMain">
            <img className="logoImg" src={SLogo} alt="my_logo" />

            <div className="fullCentered">
                ZING PARTY
            </div>
            <div className="fullCentered">
                By S.C. WE ZING S.R.L.
            </div>
            <div className="fullCentered">
                <i>Headquarters – Petru Rares Street, N.68, 720012, Suceava, Romania</i>
            </div>
            <div className="fullCentered">
                <a href="mailto:contact@zing-party.com ">contact@zing-party.com </a>
            </div>
            <div className="row cookiesMargins">
                Effective: July 7, 2020
            </div>
            <div className="fullCentered cookiesMargins">
                <b>TERMS OF USE</b>
            </div>
            <p className="leftalign">
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING ZING PARTY
            </p>



           <div className="fullCenteredTerms cookiesMargins">
                <b>Application</b>
            </div>









            <p className="leftalign">
            These Terms of Use (“Terms”) are a contract between you, the user of this website or mobile application, referred to herein as the <b>“Customer”</b>, <b>“you”</b> or the <b>“user”</b> and SC We Zing SRL, the owners of Zing Party’s website and mobile application, and Zing Scanner’s mobile application, referred to herein as <b>“the Supplier”</b> or <b>“us”</b> or <b>“we”</b>. They govern your use of Zing Party’s and Zing Scanner’s sites, services, mobile apps, products, and content (“Services”).
            </p>

            <p className="leftalign">
            These Terms of Use, together with our <a href="/privacypolicy" >Privacy Policy</a> and <b>Refunds, Cancellations and Complaints Policy</b> set out the ways in which you may access, use and browse <a href="https://zing-party.com" >www.zing-party.com</a>  (our <b>site</b>), applications, services or tool (collectively <b>ZING PARTY</b>).  

            </p>
            
            <p className="leftalign">
            By using ZING PARTY, you agree to all Terms set out in this document and any documents referred to herein. Please read all these terms and conditions below. If you do not agree to these Terms, you should not use ZING PARTY.

            </p>



            <p className="leftalign">
            By using our content and Services, you will be agreeing to Zing Party’s terms and conditions. Before creating an account on our mobile application or website, you will be asked to agree to these Terms and Conditions. If you do not agree to these terms, you should not use our Services. You can only receive services from our mobile application or website if you are eligible to enter into a contract and are at least 16 years old. Nothing in these Terms can be applied or transferred to any third party.
            </p>
            
            <p className="leftalign">
            We reserve the right to change these Terms at any time. If a change is material to your use of our services, we will let you know before they take effect. By using Zing Party and Zing Scanner on or after the effective date of a new Terms of Service contract, you agree to the new Terms. If you don’t agree to them, you should delete your account before they take effect, otherwise your use of the site and content will be subject to the new Terms. In the event of any conflict between these Terms and Conditions and any prior versions thereof, the provisions of these Terms and Conditions shall prevail unless it is expressly stated otherwise.
            </p>





            <div className="fullCenteredTerms cookiesMargins">
                <b>1. DEFINITIONS</b>
            </div>

            <p className="leftalign">
            1.1. In these Terms of Use when we refer to the capitalised words in bold below, we mean them to have the corresponding meanings:
            </p>
        
        <p className="leftalign">
        <b>Applicable Laws</b> -  all relevant laws, statutes, rules, regulations, guidelines, directives and codes;
            </p>

            

            <p className="leftalign">
           <b> User </b> &nbsp;	&nbsp; -  means any third party that accesses the mobile application or website and it is not employed by SC We Zing SRL / ZING PARTY and acting in the course of their employment;

            </p>
            <p className="leftalign">
           <b> Business Users  </b> &nbsp;	&nbsp; - means any third party that accesses the mobile application or website and is not employed by SC We Zing SRL / ZING PARTY and acting in the course of their employment and is a company  or a legal person (solo or as a group) registered with Zing Party and offering Services for Events, Rent Party Spaces, Organize a Party Event / Concert / Festival with Tickets, Booking a Dance Crew / Band / Singer / Stand Up-er / Limousine , Organize an Office Party / Kids Party / Bachelor Party / City Break, Organize a Boys Night / Girl Night / Home Party / Friends Barbeque , Offers any type of Services related to a Party;
            </p>




            <p className="leftalign">
           <b> User Terms  </b> &nbsp;	&nbsp; - any terms and conditions of a user or Business User relating to their provision of Services, including any cancelation terms;
            </p>

            <p className="leftalign">
           <b> Booking Contract  </b> &nbsp;	&nbsp;  the contract between an Event Host and User / Business User pursuant to which Services are provided to an Event Host in respect of an Event for a Fee;
            </p>
            <p className="leftalign">
           <b> Event  </b> &nbsp;	&nbsp; - an Event or occasion hosted by a User for which the Event Host requires the Services of a Business User or Not; the Event Host can invite / accept / refuse users or customers; 
            </p>
            <p className="leftalign">
           <b> Ticket Event  </b> &nbsp;	&nbsp; -  an Event or occasion hosted by a Business User for which the Event Host requires Tickets. The Event Host will assume all the responsibilities for organizing in the best conditions the Event in accordance with the laws in force of the country where the event is hosted. The SC We Zing SRL / Zing Party does not assume any responsibility for an event host in Zing Party website and mobile application;
            </p>

            <p className="leftalign">
           <b> Virtual Manager  </b> &nbsp;	&nbsp; - The place where a Business User can organize and sell services on days / months / years using the Deals option (Zing Party recommend watching the tutorials).
            </p>

            <p className="leftalign">
           <b> Deals  </b> &nbsp;	&nbsp; - are the Services offered or sold by a Business User in Virtual Manager. It is ideal for a company or a legal person (solo or as a group) registered with Zing Party and offering Services for Renting Party Equipment, Renting Sports Courts, GYM, Spa, Trainers and so on. The Business User will assume all the responsibilities for organizing in the best conditions the Deals in accordance with the laws in force of the country where the event is hosted. The SC We Zing SRL / Zing Party does not assume any responsibility for a Deal host in Zing Party website and mobile application;
            </p>

            <p className="leftalign">
           <b> Ticket  </b> &nbsp;	&nbsp; - The Ticket represents the evidence / proof for a user / customer who bought a Ticket for entrance on a Ticket Event, or who bought a Deal for any Services offered by a Business User on Virtual Manager. The Users will receive the Tickets on the email and on the User Account registered on Zing Party website and mobile applications, it’s unique, secure by Zing Party, it has a code bar, and the following data from the User Accounts: logo / photo, birthdate, first name, last name, and from Business Account the following data: Name and Place of Event, date of the event, the start and end hour of the Event / Deal.
            </p>




            <p className="leftalign">
            &nbsp;	&nbsp; A User / Customer can buy maximum 4 (four) Tickets per Event /Deal. In case when a User / Customer buy more than one ticket, the User must accompany the group for entrance on the Event (all the tickets will have a different series and unique code bar) <b>SC. We Zing SRL. / Zing Party is against the ticket exploit, and set the website and mobile application to sell maximum 4 (four) tickets on each Event / Deal per User Account.  </b>
            </p>


            <p className="leftalign">
            &nbsp;	&nbsp;  The Ticket can be validated with Zing Scanner mobile application from SC. We Zing SRL, directly from email or Zing Party Account using User gadget. The Event Host / Business User can ask you for an ID for ticket validation.
            </p>
            <p className="leftalign">
            &nbsp;	&nbsp;  The Tickets cannot be duplicated or resold by the User / Customer.
            </p>
            <p className="leftalign">
           <b> Trust user and Fraud Prevent   </b> – Through the managers of SC We ZING SRL, in case they have any doubt, will contact the Business Users / Companies Event Host for more information. Will ask for copies of the license and permissions which proves the rights to organize the Event. In case it is a big Event with International Stars, we can ask for copies of agreement and contract for prove to us the rights and reality of the Event and will contact the local Authorities for ask /prove the reality of the Event. In all the cases the managers can block for an indefinite period the fees of users in Payment System of  Zing Account of them, until the clarification of the case. All the users will be notice via email and phone for this.
            </p>

            <p className="leftalign">
            &nbsp;	&nbsp;  In time, when will be very clear for us, and a user was check few times, we set on the Zing System of mobile applications and platforms a “Trust” User, in a visible space on the Business User Account, for be see for our Users.
            </p>
            <p className="leftalign">
            &nbsp;	&nbsp;  Also, we will do the same check for the Star Users Account, and after we will have all the prove for be a real Zing Account, we will fit the same “Trust” on them accounts.
            </p>
            <p className="leftalign">
            &nbsp;	&nbsp;  As we have in the Terms & Conditions, in case we find a fake account and a fake Event / Deal on our Zing Platforms and mobile applications, we will block that accounts and we will report to the competent authority about this. Always we will stay available to any authority if they ask to us for fraud activities to prevent it.
            </p>

            <p className="leftalign">
           <b> Zing Scanner Application  </b> – Is a mobile application property of SC. We Zing SRL. The Zing Scanner Application is connected to Zing Party website and mobile application to the Business User Account with the same password and ID account. All the tickets which are sold on Zing Party can be found on the scanner application organized on Events or Deals. It is a free option / mobile application for all the Business Users of Zing Party.
            </p>
            <p className="leftalign">
           <b> Bookkeeper  </b> &nbsp; &nbsp; - Is an Option for Business Users of Zing Party where the total sales are kept by Event / Deals or Months / Years. All the Business Users have the obligation to pay the taxes in accordance with the laws of the country in which the company is registered / legal personal registration. The SC. We Zing SRL. /Zing Party does not assume any responsibility for the payment of taxes and fees of the Business Users registered on Zing Party website and mobile application for the countries where the companies are registered. The SC We Zing SRL / Zing Party reserves the right, in case of a Government Authority asking us, with a legal order, for the earnings of a Business User, to give them a full report. <b>Sc We Zing SRL / ZING PARTY is in favor of paying state taxes and is against of tax evasion.</b>
            </p>
            <p className="leftalign">
           <b> Total Fee   </b>  &nbsp; &nbsp; - In case of Tickets Events, Deals and Voucher, it means the total amount payable by a Customer to complete a booking; it includes Business User Fee, Zing Party Service Fee, and Transaction Fee. In case of Promotion and Zing Map it means the total amount payable by a Customer to complete a booking including the Zing Party Services Fee and Transaction Fee (VAT included);
            </p>
            <p className="leftalign">
           <b> Business User Fee  </b> &nbsp; &nbsp;- represents the Business User payment directly transferred from Customers bank account via a third party payment system. The Business User’s fee for providing Services for an Event as agreed between an Event Host and a User / Customer which shall be inclusive of any applicable local taxes and the currency applicable to the location of the Event (including if applicable VAT);
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;  -  The Business User will receive 100 % from the price fixed on Zing Party Event / Deal,
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;  - The total Fee of a Transaction for a Ticket / Deal include the Business User Fee and the SC We ZING SRL Fee which is 7 %. The SC We ZING Fee include the Financial Entity Fee (in case of Stripe it will be 1,4 % for EU countries and 2,9 % for outside EU countries. In case of MangoPay it will be 1,8 %). The final price for a Ticket / Deal will be 107 % ,
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;  -  the money collected from Events / Deals by a Business User account through Zing Party Application will be automatically transferred in 48 hours in the bank account provided on the forms;
            </p>

            <p className="leftalign">
           <b> ZING PARTY Commission or Service Fee     </b> 
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;   – means the % (incl. applicable VAT) of Total Fee, which represents Zing Party
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;   net revenue incl. VAT. The % fee for Zing Party will be 7% for Tickets, 7% for Deals and 7% for Vouchers. The SC We ZING / Zing Party Fee include the Financial Entity Fee.
            </p>



            <p className="leftalign">
           <b> Transaction Fee  </b> &nbsp;	&nbsp; - means the % of Total Fee, which represents Zing Party’s net revenue including VAT, from which we will compensate the third party for managing online payments and refund transactions. In case of Stripe it will be 1,4 % for EU countries and 2,9 % for outside EU countries. In case of MangoPay it will be 1,8 % . The Financial Entity Fee is include in SC We ZING / Zing Party Fee;
            </p>
            <p className="leftalign">
           <b> Refunds, Cancellations and Complaints Policy  </b> 
            </p>

            <p className="leftalign">
           &nbsp; &nbsp;  - means the policy relating to the handling of refunds, cancellations and complaints between Event Hosts / Deals Hosts and User / Customer relating to Booking Contracts that both Event / Deals Hosts and User / Customer must comply with; and
            </p>
            <p className="leftalign">
           &nbsp; &nbsp;   - Zing Party / SC We Zing SRL will keep the fees in the financial account for 48 hours maximum after a User / Customer pay a service on the website or mobile application, and can refund directly the fee in case they are requested. (
               <a href="mailto:contact@zing-party.com " >contact@zing-party.com</a> );
            </p>

            <p className="leftalign">
           <b> Services  </b> &nbsp;	&nbsp;  - A live Event organized by a Business User which requires an entrance Ticket; Rented VIP Entrance Club, Rented Spa Offers, Cash prizes and items offered by a Voucher; Generally all services  offered by a Business User related to a party / show / festival / concert ;
            </p>
            <p className="leftalign">
           <b> User content  </b> &nbsp;	&nbsp;   any and all content uploaded to Zing Party by Users including but not limited to any text, information, reviews, images, software, code, audio recordings, videos, links to third party

            </p>
            <p className="leftalign">
            websites or content;
            </p>
            <p className="leftalign">
           <b> 2. ABOUT ZING PARTY AND US  </b> 
            </p>


            <p className="leftalign">
            2.1 – Zing Party is an online booking platform and mobile application designed to connect Event Hosts and Users, and facilitate the Event Host’s booking the Business User’s Services. 
            </p>
            <p className="leftalign">
            2.2 - We are a company registered in Romania under company number 41061633 and our office is registered on Petru Rares Street, No.68, 720012, Suceava, Romania.
            </p>



            <p className="leftalign">
            2.3 - You can contact us as follows:
            </p>

            <div className="leftalignFollow" >

            
<p className="leftalignFollow">
FAO: &nbsp;	&nbsp;	&nbsp;S.C. We Zing S.R.L.
</p>
<p className="leftalignFollow">
Address: &nbsp;	&nbsp;	&nbsp; Petru Raes Street, N.68, 720012, Suceava, Romania.
</p>
<p className="leftalignFollow">
Email: &nbsp;	&nbsp;	&nbsp; <a href="mailto:contact@zing-party.com" >contact@zing-party.com </a>
</p>
</div>


            <p className="leftalign">
            2.4 - We do not control any User Content, nor do we approve, check or endorse any Event Hosts or Business User / Company. We make no representations, warranties or guarantees, whether expressed or implied that the User Content or any other content on our site is accurate, complete or up-to date.
            </p>
            <p className="leftalign">
            2.5 - We are not party to any bookings between Event Hosts and Users, the contract is made directly between each Event Host and the relevant Business User / Company (s).
            </p>
            <p className="leftalign">
            2.6 – Zing Party enables  <b> Event Hosts / Deals Hosts </b> to:
            </p>


            <p className="leftalign">
            2.6.1 &nbsp;	&nbsp;	&nbsp;browse the online catalogue of Users and their profiles;
            </p>
            <p className="leftalign">
            1. &nbsp; &nbsp;	&nbsp; communicate with User and enquire about their Services;
            </p>
            <p className="leftalign">
            2.&nbsp; &nbsp;	&nbsp; enter into a Booking Contract with an Business User;
            </p>
            <p className="leftalign">
            3.&nbsp; &nbsp;	&nbsp;make payment for Services performed pursuant to a Booking Contract. 
            </p>
            <p className="leftalign">
            2.7  - ZING PARTY enables <b>Users</b> to:
            </p>



            <p className="leftalign">
            4. &nbsp; &nbsp;	&nbsp; create profiles to advertise and promote their Services;
            </p>
            <p className="leftalign">
            5.&nbsp; &nbsp;	&nbsp; offer Quotes to Event Hosts;
            </p>
            <p className="leftalign">
            6.&nbsp; &nbsp;	&nbsp;communicate with Event Hosts about providing Services;
            </p>
            <p className="leftalign">
            7.&nbsp; &nbsp;	&nbsp; enter into a Booking Contract with an Event Host; and
            </p>
            <p className="leftalign">
            8.&nbsp; &nbsp;	&nbsp;receive payment for Services performed pursuant to a Booking Contract.
            </p>








            <p className="leftalign">
           <b> 3. REGISTRATION  </b> 
            </p>



            <p className="leftalign">
            3.1 - You may browse Zing Party as an unregistered user but you must register a profile with Zing Party in order to: Post User Content; Follow a User / Business User; Offer Services as a Business User/or; enquire about or book the Services of a Business User as an Event Host / Deal; enquire about or book the Services of a Voucher; to register on Zing Map; to promote an Event / Deal;
            </p>
            <p className="leftalign">
            3.2 - <b> You must have parental consent to create a registered profile with Zing Party if you are below 16 (sixteen) years of age, but must be 18 (eighteen) years of age or over to enter into a Booking Contract; </b>
            </p>

            <p className="leftalign">
            3.3 - You may register with Zing Party directly, by providing to Zing Party certain details via our site and mobile application, or where we provide the functionality, by using certain third-party accounts (for example Facebook).
            </p>
            <p className="leftalign">
            3.4 – You acknowledge and agree that by registering with Zing Party using a Third-Party Account you grant Zing Party access to certain information from your Third-Party Account. You can control what information Zing Party may access from your Third-Party Account by adjusting your Third - Party Account privacy settings in your Third-Party Account, however you consent to Zing Party accessing from your Third-Party Account the information available in accordance with the privacy settings in place at the time;
            </p><p className="leftalign">
            3.5 - When registering the information you provide must be complete and accurate information and to keep the information up to date;
            </p>

            <p className="leftalign">
            3.6 - Once you have a registered account, it is your responsibility to keep your password and any other security information confidential. We will be entitled to assume that any person that logs into or uses your account is either you or has your consent. You must notify us immediately if you know or suspect that anyone other than you have had access to or knows your password using the email set out at paragraph 2.3;
            </p>




            <p className="leftalign">
           <b>   4. AVAILABILITY OF ZING PARTY  </b> 
            </p>

            <p className="leftalign">
            4.1 - We do not guarantee that Zing Party or any content on it, including any User Content, will always be available, uninterrupted, up to date, or otherwise free from errors, omissions, bugs or viruses;
            </p>

            <p className="leftalign">
            4.2 -Access to Zing Party is permitted on a temporary basis. We may suspend, withdraw discontinue or change all or any part of Zing Party without notice. We will not be liable to you if for any reason our site is unavailable at any time or for any period;
            </p>
            <p className="leftalign">
            4.3 - You are responsible for your access to ZING PARTY including:
            </p>

            <p className="leftalign">
            4.3.1 - ensuring that all persons who access Zing Party through your Internet connection are aware of these Terms of Use and other applicable terms and conditions, and that they comply with them; and

            </p>
            <p className="leftalign">
            4.3.2 - that your Internet enabled device and telecommunications systems carry the appropriate anti-virus software necessary to minimize the risk of any harmful viruses infecting your internet enabled device;

            </p>
            <p className="leftalign">
           <b>   5. CONDUCT  </b> 
            </p>

            <p className="leftalign">
            5.1 - At all times when using or accessing ZING PARTY you represent, warrant and undertake that you will:
            </p>
            <p className="leftalign">
            5.1.1 - Not breach any Applicable Laws;
            </p>

            <p className="leftalign">
           5.1.2 - Have all necessary rights and/or permissions to post the User Content you upload to Zing Party;
            </p>
            <p className="leftalign">
            5.1.3 -  Not post or upload any User Content that infringes the rights (including any Intellectual Property Rights (as defined in paragraph 8 below)) of any third - party or which are protected by any third -  party’s rights of privacy or publicity without having received all necessary consents from such third - party;
            </p>
            <p className="leftalign">
            5.1.4 - Not publish, post, upload, store, distribute or disseminate any unlawful, fraudulent, defamatory, infringing, obscene, pornographic, harmful, confidential, libelous, hateful, racist, threatening or otherwise illegal or offensive material or information or anything which might constitute a criminal or civil offence;
            </p>
            <p className="leftalign">
            5.1.5 - Not post any false or misleading information or material;
            </p>
            <p className="leftalign">
            5.1.6 - Not post html code except where instructed in any area of your profile on ZING PARTY;
            </p><p className="leftalign">
            5.1.7 - Not reverse engineer, decompile, disassemble or otherwise attempt to obtain Zing Party’s source code; 
            </p>
            <p className="leftalign">
            5.1.8 - Not misuse Zing Party by knowingly introducing viruses, Trojans, worms, logic bombs or other malicious or harmful material; 
            </p>
            <p className="leftalign">
            5.1.9 - Not attempt to gain unauthorised access to ZING PARTY the server on which ZING PARTY is stored or any server, computer or database connected to our site; or
            </p>
            <p className="leftalign">
            5.1.10 - Not attack ZING PARTY via a denial of service attach or a distributed denial-of- service attach;
            </p>
            <p className="leftalign">
            5.2 -  Failure to comply with ZING PARTY’s conduct requirements set out in paragraph 5.1 above, may result in deletion or removal of your User Content or termination of your account and right to use Zing Party; 
            </p>
            <p className="leftalign">
            5.3 - We reserve the right in our sole discretion to determine whether you have failed to comply with the terms of paragraph 5.1. and / or to terminate your account and right to use Zing Party (whether or not as a result of a breach of the conduct requirements set out in paragraph 5.1.);
            </p>


            <p className="leftalign">
           <b>   6. BOOKING CONTRACTS  </b> 
            </p>
            <p className="leftalign">
            6.1 – Zing Party is a platform / mobile application that connects Event Hosts and Business Users and facilitates the conclusion of Booking Contracts between them. When an Event Host books a User for an Event, the contract for Services is between the Event Host and the User.  These Terms of Use are your contract with us solely for your use of our services, Zing Party; 

            </p>
            <p className="leftalign">
            6.2 – Users may have additional terms and conditions relating to the provision of their Services <b>(User Terms)</b> and Event Hosts must contact Users for details of any Business User Terms or refer to their website or profile for more information;
            </p>
            <p className="leftalign">
            6.3 - Event Hosts can contact Users privately via Zing Party to enquire about Services and to agree between them the arrangements for User to provide Services at an Event including the applicable Total Fee; 
            </p>
            <p className="leftalign">
            6.4 - A Booking Contract between an Event Host and User is formed as follows:
            </p>
            <p className="leftalign">
            6.4.1 - In case of a direct enquiry or reverse enquiry (“open Event / Deal”)
            </p>
            <p className="leftalign">
            &nbsp;(a) - following an enquiry by the Event Host, the Users shall provide the Event Host with a formal offer and quote for their Services at the Event <b>'Quote'</b>
            </p>
            <p className="leftalign">
            &nbsp;(b) - if the Event Host is not happy with the terms of the Quote it can engage with further private exchanges with the User, following which a User may provide a revised Quote;
            </p>
            <p className="leftalign">
            &nbsp;(c) - a Quote is only accepted and a Booking Contract formed when the Even Host clicks <b>‘Pay’</b> after which both the Event Host and Users will receive confirmation of their Booking Contract by Ticket;
            </p>
            <p className="leftalign">
           <b>   7. FEES, ZING PARTY COMMISSION AND PAYMENT  </b> 
            </p>
            <p className="leftalign">
            7.1 - Fees payable by an Event Host under a Booking Contract shall be as set out in an accepted Quote.
            </p>
            <p className="leftalign">
            7.1.1	- The Total Fee is payable by the Event Host in accordance with the accepted Quote;
            </p>
            <p className="leftalign">
            7.2 - Following the receipt of the Fee from the Event Host, the integrated third-party payment system will automatically allocate and transfer the User Fee, the Zing Party Service Fee, and the Transaction Fee, directly to the accounts of the Receivers.
            </p>
            <p className="leftalign">
            7.2.1 - When the Event Host confirms a booking more than 2 (two) days in advance of the event date, the Zing Party Service Fee and Transaction Fee will be charged immediately to hold the booking. This transaction equals a total % of the total fee that the Event Host has agreed for the booking.
            </p>
            <p className="leftalign">
            &nbsp;(a) &nbsp; 2 (two) days before the date of the event, the Event Host’s payment card will automatically be charged the User Fee (remaining % of the Total Fee) which will be transferred directly to the User’s Account via the third-party payment system.
            </p>

            <p className="leftalign">
            &nbsp;7.3 - When the Event Host confirms a booking within 2 (two) days of the event date, the Total Fee including User Fee, Zing Party Service Fee, and Transaction Fee, will be transferred immediately and directly to the bank accounts of the Receivers via the third-party payment system.
            </p>
            <p className="leftalign">
            &nbsp; &nbsp; 7.4 - Please note that we are not able to issue VAT invoices in respect of Services under a Booking Contract. Users and Event Hosts are responsible for calculating and accounting for any VAT which may be payable in respect of the Services.
            </p>
            <p className="leftalign">
            7.5 -Service Fees and Transaction Fees are payable by the Event Host to us using the third party payment application  (<b>Stripe</b> and Mangopay) integrated on our platform. Stripe / MangoPay is operated by third party payment service provider <b>Stripe Payments Europe, Ltd / Mangopay SA</b>  (<a href="https://stripe.com/en-gb-ro" >www.stripe.com</a> / <a href="https://www.mangopay.com" >www.mangopay.com</a> ), who processed Fees on behalf of Zing Party in accordance with the terms of our agreement with them. Event Hosts and Users must register an account with Stripe / MangoPay in order to make and receive payments under their Booking Contracts;
            </p>
            <p className="leftalign">
            7.6 – Business Users Fees are payable by the Event Host to the User using the third-party payment application <b>(Stripe / Mangopay)</b> integrated on our platform;
            </p>

            <p className="leftalign">
            7.7 – Business Users Fees are refundable by the Users to Event Host using the third party payment application <b>(Stripe / Mangopay)</b> integrated on our platform;
            </p>
            <p className="leftalign">
            7.7.1 – User agrees that Zing Party has the absolute right to force a refund from User to Event Host using the third party payment application <b>(Stripe / Mangopay)</b> integrated on our platform, if we believe such refund is applicable according to the <b>Refunds Cancellations and Complaints Policy</b> (please see paragraph 10 for more information); 
            </p>
            <p className="leftalign">
           <b> 8. AUTOMATIC RIGHT TO CANCEL UNDER THE CONSUMER CONTRACTS REGULATIONS  </b> 
            </p>
            <p className="leftalign">
            &nbsp; 8.1 - Please note that Event Hosts that are not businesses do not have automatic rights to cancel a Booking Contract under the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 or other equivalent or replacement legislation that may apply from time to time. The applicable parts of this legislation do not apply to contracts for the provision of services related to leisure activities if the Booking Contract provides for a specific date or period of performance.
            </p>
            <p className="leftalign">
            &nbsp; 8.2 - Event Hosts and/or User may cancel or amend their Booking Contracts in accordance with the <b>Refunds, Cancellations and Complaints Policy </b> (please see paragraph 10 for more information);
            </p>
            <p className="leftalign">
            &nbsp;<b>9 . EVENT REQUIREMENTS </b> 
            </p>

            <p className="leftalign">
            &nbsp;9.1 - Event Hosts / Deal Host are responsible for all aspects of Events they host pursuant to a Booking Contract including compliance with all Applicable Laws, obtaining and maintaining all and any licenses (including if applicable a PRS license or equivalent), permissions, consents and arranging appropriate insurance coverage and all costs incurred by it or on its behalf in connection with the Event / Deal;
            </p>
            <p className="leftalign">
            <b>10 . REFUNDS, CANCELLATIONS AND COMPLAINTS </b> 
            </p>
            <p className="leftalign">
            10.1 - Booking Contracts are made between Event Users and User and therefore refunds cancellations and complaints in relation to the Total Fee, Services or any other aspect of the Booking Contract are between the Event User and Users;

            </p>
            <p className="leftalign">
            10.2 - All refunds, cancellations and complaints between an Event User and Users shall be subject to the <b> Refunds, Cancellations and Complaints Policy, </b> which shall apply to and be incorporated into all Booking Contracts as a condition of use of Zing Party. For the avoidance of doubt, to the extent of any conflict between the terms of the <b> Refunds, Cancellations and Complaints Policy</b> and any Users Terms, the <b>Refunds, Cancellations and Complaints Policy </b> shall apply unless the Users  Terms are more favorable to the Event Host on the subject matter in which case the Users Terms shall apply;

            </p>
            <p className="leftalign">
            &nbsp;<b>11 - INTELLECTUAL PROPERTY RIGHTS </b> 
            </p>
            <p className="leftalign">
            11.1 - For the purposes of these Terms of Use, by <b> Intellectual Property Rights,</b> we mean any patents, registered and unregistered trade-marks and service marks, domain names, registered designs and design rights, copyright (including such rights in computer software and databases), database rights and moral rights (in each case for the full period thereof and extensions, revivals and renewals thereof), applications for the foregoing and the right to apply for any of the foregoing anywhere in the world, and all similar rights anywhere in the world including those subsisting in inventions, designs, drawings and computer programs.

            </p>


            <p className="leftalign">
            11.2 - All Intellectual Property Rights in Zing Party and any content, materials or information made available by us on Zing Party will remain owned by us or our licensors at all times.

            </p>
            <p className="leftalign">
            11.3 - You grant, and you represent and warrant that you have the right to grant, to us a non- exclusive, irrevocable, perpetual, transferable, sub-licensable, worldwide, royalty-free license to publicly perform, copy, reproduce, display, communicate to the public, modify, edit, manage, distribute and store any and all of your User Content on Zing Party;

            </p>


            <p className="leftalign">
            11.4 - You must not use any part of our content Zing Party without obtaining a license to do so from us or our licensors;

            </p>
            <p className="leftalign">
            11.5 - If you print off, copy or download any part of our site in breach of these Terms of Use, your right to use Zing Party will cease immediately and you must, at our option, return or destroy any copies of the materials you have made;

            </p>

            <p className="leftalign">
            &nbsp;<b>12. REPORTING USER CONTENT </b> 
            </p>
            <p className="leftalign">
            You acknowledge that, other than as set out in these Terms of Use, we do not monitor the User Content and /or any of the other content that is made available on Zing Party. If you believe that any User Content available on Zing Party:
            </p>
            <p className="leftalign">
            1. &nbsp; infringes Your Intellectual Property Rights or other proprietary rights;
            </p>
            <p className="leftalign">
            2. &nbsp; is defamatory to you; and/or
            </p>
            <p className="leftalign">
            3. &nbsp; is otherwise in breach of these Terms of Use,
            </p>
            <p className="leftalign">
            please notify us by writing to us using the email <a href="mailto:privicy@zing-party.com" >privicy@zing-party.com</a> ;
            </p>
            <p className="leftalign">
            <b>13. THIRD PARTY LINKS AND RESOURCES ON ZING PARTY </b> 
            </p>
            <p className="leftalign">
            &nbsp;13.1 – Zing Party may contain links to other sites and resources provided by registered users and not under our control. These links are provided for your information only and we make no warranties or representations whatsoever about any third-party websites which you may access through Zing Party.
            </p>
            <p className="leftalign">
            &nbsp;13.2 - Third - party websites are in no way approved, vetted, checked or endorsed by us and you agree that we shall not be responsible or in any way liable for the content, accuracy, compliance with relevant laws or accessibility or any information, data, advice or statements or for the quality of any products or services available on such sites. Links do not necessarily imply that we are or that Zing Party is affiliated to or associated with such third-party sites. If you decide to visit any other site, you do so at your own risk. In addition, use of any other site may be subject to your acceptance of additional terms and conditions which we suggest you read carefully before proceeding.
            </p>
            <p className="leftalign">
            &nbsp;13.3 - We assume no responsibility for the content of sites linked to Zing Party. We will not be liable for any loss or damage that may arise from your use of any such third-party site;
            </p>
            <p className="leftalign">
            &nbsp;<b>14 - LINKING TO ZING PARTY</b> 
            </p>
            <p className="leftalign">
            &nbsp;14.1 - You may link to our site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. However, you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists and you must not establish a link to our site in any site that is not owned by you.
            </p>
            <p className="leftalign">
            &nbsp;14.2 -  We reserve the right to withdraw linking permission without notice.
            </p>
            <p className="leftalign">
            &nbsp;<b>15 - LIABILITY</b> 
            </p>
            <p className="leftalign">
            &nbsp;15.1 - Nothing in these Terms of Use excludes or limits our liability for anything that cannot be excluded or limited by EU laws.
            </p>
            <p className="leftalign">
            &nbsp;15.2 - To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to Zing Party or any content on it, whether express or implied.
            </p>
            <p className="leftalign">
            &nbsp;15.3 - Subject to paragraph 17.1 you agree that we will not be liable for any loss or damage, (whether direct or indirect or arising under contract, tort (including negligence), breach of statutory duty, or otherwise) even if foreseeable, arising under or in connection with any:
            </p>

            <p className="leftalign">
            &nbsp;15.3.1 - use of, or inability to use Zing Party;
            </p>
            <p className="leftalign">
            &nbsp;15.3.2 -  use of or reliance on any content displayed on Zing Party (including any User Content); or
            </p>
            <p className="leftalign">
            &nbsp;15.3.3 - any Booking Contracts or Events.
            </p>
            <p className="leftalign">
            &nbsp;15.4 - Without limiting the effect of paragraph 15.3, due to the inherent risks of using the Internet, we cannot be liable for any damage to, or viruses that may infect, your internet enabled device or any other property when you are using Zing Party. The uploading, posting, downloading or accessing of any content (including User Content), material and / or other information made available by Zing Party is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your device or loss of data that results from the downloading or acquisition of any such content, material and /or information.
            </p>
            <p className="leftalign">
            &nbsp;15.5 - You agree to indemnify us against any claims or legal proceedings that may arise through your use of Zing Party, any Event, Booking Contract or from any breach of these Terms of Use by you.
            </p>
            <p className="leftalign">
            <b>16 - GENERAL </b> 
            </p> <p className="leftalign">
            &nbsp;16.1 - Any failure or delay by us to enforce any of our rights under these Terms of Use will not be taken as or deemed to be a waiver of that or any other right unless we acknowledge and agree to such a waiver in writing.
            </p>
            <p className="leftalign">
            &nbsp;16.2 - These Terms of Use are not intended to be for the benefit of, and will not be exercisable by, any person who is not a party to these Terms of Use.
            </p>
            <p className="leftalign">
            &nbsp;16.3 - If a court deems any part of the terms set out in these Terms of Use to be invalid, illegal or unenforceable, the remainder of the terms will remain unaffected.
            </p>
            <p className="leftalign">
            16.3.1 - These <b>Terms of Use</b> and our <a href="/privacypolicy" ><b>Privacy Policy</b></a> set out the full extent of our obligations and liabilities concerning Zing Party and replace any previous agreements and understandings between us and you.

            </p>
            <p className="leftalign">
            &nbsp;16.4 -  We may assign our rights under this agreement and transfer our obligations under this agreement in our sole discretion by providing notice to you via the email address that you provide to us or by notifying you on our site.
            </p>
            <p className="leftalign">
            <b>17 - APPLICABLE LAW </b> 
            </p>
            <p className="leftalign">
            &nbsp;17.1 - These Terms of Use, its subject matter and its formation (and any non-contractual disputes or claims arising out of or in connection with these Terms of Use), are governed and construed in accordance with EU law. You and we both agree to that the courts of EU counties will have exclusive jurisdiction.
            </p>
            <p className="leftalign">
            &nbsp;<b>18 - Refunds, Cancellations and Complaints Policy </b> 
            </p>
          


            <div className="leftalign">
                <table>
                    <tbody>
                        <tr>
                            {/* <td>When</td>
                            <td>Event</td>
                            <td>Fees</td>
                            <td>Duration</td> */}
                        </tr>
                        <tr className="tr-big" >
                            <td>When
</td>
                            <td>Event</td>
                            <td>Fees</td>
                            {/* <td>2 years</td> */}
                        </tr>
                        <tr className="tr-big" >
                            <td>More than 2 full days before the agreed Event Date / Deal.
</td>
                            <td>The <b>Customer</b> or the <b>User</b> cancels the Booking.</td>
                            <td>The Customer will be fully refunded (100% of Total Fee).</td>
                            {/* <td>10 mins</td> */}
                        </tr>
                        <tr className="tr-big" >
                            <td>Within 2 full days before the agreed Event Date / Deal.
</td>
                            <td>The <b>Customer</b> cancels the Booking.
</td>
                            <td>The Total Fee is not refundable.

ZING PARTY Service Fee, Transaction Fee, and User Fee will be fully paid to the respective parties.</td>
                            {/* <td>N/A</td> */}
                        </tr>
                        <tr className="tr-big"  >
                            <td>Within 2 full days before the agreed Event Date.
</td>
                            <td>The <b>Users</b> cancels the Booking.</td>
                            <td  >The Customer will be fully refunded (100% of Total Fee).

                            Zing Party Service Fee and Transaction Fee, of Total Fee, will be charged as a fine to the Users unless there are proven extenuating circumstances. The total fine may not exceed 400 Euro.

                            Zing Party reserves the absolute right to subjectively determine whether the provided proof of an extenuating circumstance is sufficient to avoid the fine.
</td>
                            {/* <td>N/A</td> */}
                        </tr>
                    </tbody>
                </table>
            </div> 



            <p className="leftalign">
            &nbsp;<b>Complaints </b> 
            </p>
            <p className="leftalign">
            &nbsp;All and any complaints must be reported within 2 full days after the Event Date using the appropriate related functionality that will be automatically available to both the User and the Customer via the associated booking form on the Zing Party platform and mobile applications from the agreed time of the Event.
            </p>






            <p className="leftalign">
                <a target='_blank' href={process.env.PUBLIC_URL + fileToDownload}>Save as PDF</a>
            </p>
        </div>
);

    return(
            <div className="terms">
                <a target='_blank' href={process.env.PUBLIC_URL + fileToDownload}>Save as PDF</a>
                {textToDisplay}
            </div>
    );
}

export default TermsAndConditions;