import React, {Component} from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../Assets/img/footerLogo.png";
import {
  faBuilding,
  faMapMarkedAlt,
  faPaste
} from "@fortawesome/free-solid-svg-icons";
// import logo from "../../../Assets/img/footerLogo.png";
import { Row, Col, Media, ModalBody, Modal, ModalHeader } from "reactstrap";
import MapContainer from "../../BothUsers/MapContainer/MapContainer";
import { fetchApiLocation } from "../../../fetchApiLocation";
import {fetchApi} from "../../../fetchApi";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import ContactUS from "./ContactUS";

let SLogo = require("../../../Assets/img/SLogo.png");

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalContact: false,
            companyName: "",
            address: "",
            cui: "",
            lat: "",
            lng: "",
            modalContent: "",
            isTerms: true,
            facebookLink: "",
            instagramLink: "",
            twitterLink: "",
            youtubeLink: "",
        };
    }

    toggleModalContact = () => {
        this.setState({
            modalContent: "contactUs",
            modalContact: !this.state.modalContact
        });
    };

    toggleModalTerms = () => {
        this.setState({
            modalContent: "terms",
            modalContact: !this.state.modalContact
        });
    };

    getFollow = async () => {
        const url = "get_followus";

        let data = new FormData();

        const response = await fetchApi(url, "POST", null, null);


        if (response.success) {
            this.setState({
                facebookLink: response.follow_us_details[0].link,
                twitterLink: response.follow_us_details[1].link,
                instagramLink: response.follow_us_details[2].link,
                youtubeLink: response.follow_us_details[3].link,
            });
        }
    };

    componentDidMount = () => {
        this.getFollow();
    };

    render() {
        return (
      <footer className="Footer">

                <Col lg={12} className="footerItm">
                    <Row>
           

                        <Col xs="10" lg={8} className="linksRight">
                            <ul className="footerLinks">
                                <li className="Linkitem">
                                    <a href={this.state.instagramLink} target="_blank">
                                        Instagram
                                    </a>
                                </li>
                                <li className="Linkitem">
                                    <a href={this.state.facebookLink} target="_blank">
                                        Facebook
                                    </a>
                                </li>
                                <li className="Linkitem">
                                    <a href={this.state.twitterLink} target="_blank">
                                        Twitter
                                    </a>
                                </li>
                                <li className="Linkitem">
                                    <a href={this.state.youtubeLink} target="_blank">
                                        YouTube
                                    </a>
                                </li>
                            </ul>

                            <ul className="footerLinks">
                                <li onClick={this.toggleModalTerms} className="Linkitem">
                                    Terms & Conditions
                                </li>
                                <a  onClick={this.toggleModalContact} className="Linkitem">
                                {/* <a  href="/contact" className="Linkitem"> */}

                                
                                    Contact
                                </a>
                            </ul>
                        </Col>

                        <Col xs="2" lg={4} className="d-flex justify-content-end">
              <Media className="logoFooter" object src={logo} alt="image" />
            </Col> 

            
                        <Modal
                            isOpen={this.state.modalContact}
                            size="lg"
                            // toggle={this.toggleModalContact}
                        >
                            <div className="header-p">
                                <ModalHeader className="head-1 text-white">
                                    {this.state.modalContent === "contactUs"
                                        ? "Contact us"
                                        : "Terms & Conditions"}
                                </ModalHeader>
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo"/>
                                </div>
                                <ModalHeader
                                    className="head-2"
                                    toggle={this.toggleModalContact}
                                />
                            </div>
                            <ModalBody className="modalFooter">
                                {this.state.modalContent === "contactUs" ? (
                                    <ContactUS/>
                                ) : (
                                    <TermsAndConditions isTerms={this.state.isTerms}/>
                                )}
                            </ModalBody>
                        </Modal>
                    </Row>
                </Col>
            </footer>
        );
    }
}

