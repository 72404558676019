import React, { Component } from "react";
import { Input, Container, Col, Button, Row } from "reactstrap";

import { fetchApi } from "../../../fetchApi.js";
import { userCredentials } from "../../../globalVariables";
import swal from 'sweetalert';
export default class MakeVideoEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: ""
    };
  }

  onChangeUrl = evt => {
    this.setState({ videoUrl: evt.target.value });
  };

  createVideoEvent = async () => {
    let authKey = userCredentials.authKey;
    let list = [];
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("user_type", 2);
    data.append("type", 1);
    data.append("description", "");
    data.append("video_url", this.state.videoUrl);
    const url = "addoffert";
    const response = await fetchApi(url, "POST", data, authKey);
    // console.log(response);
    if (!response.success) {
      swal("Error!", "Could not add video!", "error");
    } else {
      swal("Success!", "Video added with success", "success");
    }
  };

  render() {
    return (
      <Container className="rounded-lg  py-1">
        <Col className="mt-4 mb-4">
          <Input
            type="text"
            onChange={this.onChangeUrl}
            placeholder="Video url..."
            value={this.state.videoUrl}
            className="customInput"
          />
        </Col>

          <Col>
            <Button onClick={this.createVideoEvent} className="greenBtnEvt">Done</Button>
          </Col>

      </Container>
    );
  }
}
