import React, {Component} from "react";
import {Container, Row, Modal, ModalBody, Col, ModalHeader, ModalFooter} from "reactstrap";
import ResponsiveEmbed from "react-responsive-embed";
import {fetchApi} from "../../../fetchApi";
import {
    faMapMarkerAlt,
    faCalendarAlt,
    faBiking,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BannerDetailsBusiness from "../../BusinessUser/BannerDetailsBusiness/BannerDetailsBusiness";
import ModalVideo from "react-modal-video";
import moment from "moment";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import swal from "sweetalert";
import {sliderState} from "../../../globalVariables"
import UserProfile from "../../BothUsers/UserProfile/UserProfile.js";

let videoBg = require("../../../Assets/img/videoBackgroundBussines.png");
let btnBg = require("../../../Assets/img/playBtn.png");
let SLogo = require("../../../Assets/img/SLogo.png");

export default class BusinessOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalBusiness: false,
            videoModal: false,
            videoModal1: false,
            businessOffertsList: [
                {
                    type: 1,
                    content: "https://www.youtube.com/embed/watch?v=Yqbl2QIYDPg",
                },
                {type: 2, content: "Aceasta este o oferta"},
                {
                    type: 3,
                    content: {title: "Tenis", date: "31 FEB", location: "Suceava"},
                },
            ],
            content: "",
            videoBackground: '',

            type: 1,
            isDeal: 0,
        };
    }

    toggleModalBusiness = () => {

        if (this.state.modalBusiness) {
            sliderState.canUseSlider = true
        } else {
            sliderState.canUseSlider = false
        }
        this.setState({modalBusiness: !this.state.modalBusiness});
    };

    toggleVideoModal = () => {

        this.setState({videoModal: !this.state.videoModal});
    };

    toggleVideoModal1 = () => {
        this.setState({videoModal1: !this.state.videoModal1});
    };

    signUpHandler = () => {
        if (this.props.frontPage) {
            this.setState({modalBusiness: false});
            this.props.signUpHandler();
        }
    };

    render() {

        if (this.state.videoModal) {
            sliderState.canUseSliderVideo = false;
        } else {
            sliderState.canUseSliderVideo = true;
        }
        return (
            <div>
                {/* {this.props.offertsList
          ? this.props.offertsList.map((item) => (
              <div key={item.id}>
                {item.video_url === "" ? (
                  <Row
                    className="m-0"
                    onClick={() => {
                      this.setState({
                        content: item.description,
                        type: 1,
                        userId: item.user_id,
                        isDeal: item.is_deal,
                      });
                      if (
                        !this.props.frontPage ||
                        parseInt(item.is_deal) !== 1
                      ) {
                        this.toggleModalBusiness();
                      } else {
                        swal(
                          "Error",
                          "You're not allowed to see this profile",
                          "error"
                        );
                      }
                    }}
                  >
                    <BusinessOfferText text={item.description} />
                  </Row>
                ) : (
                  <Row
                    className="m-0"
                    onClick={() => {
                      this.setState({
                        content: item.video_url,
                        type: 2,
                        videoModal: true,
                      });
                    }}
                  >
                    <BusinessOfferVideo />
                  </Row>
                )}
            </div>
          ))
          : null} */}
                {this.props.a51List
                    ? this.props.a51List.map((item) => (
                        <div key={item.id}>
                            {item.video === "" ? (
                                <Row
                                    className="m-0"
                                    onClick={() => {
                                        this.setState({
                                            content: item.description,
                                            type: 1,
                                            userId: item.user_id,
                                            isDeal: item.is_deal,
                                            img: item.image,
                                            videoBackground: item.video_background
                                        });
                                        if (
                                            !this.props.frontPage ||
                                            parseInt(item.is_deal) !== 1
                                        ) {
                                            this.toggleModalBusiness();
                                        } else {
                                            swal(
                                                "Error",
                                                "You're not allowed to see this profile",
                                                "error"
                                            );
                                        }

                                    }}
                                >
                                    <BusinessOfferText src={item.image}/>
                                </Row>
                            ) : (
                                <Row
                                    className="m-0"
                                    onClick={() => {

                                        this.setState({
                                            content: item.video,
                                            type: 2,
                                            videoModal: true,
                                            description: item.description,
                                            videoBackground: item.video_background
                                        });

                                    }}
                                >
                                    <BusinessOfferVideo
                                        videoBackground={item.video_background}
                                        descriptionProp={item.description}

                                        videoImg={item.video}/>
                                </Row>
                            )}
                        </div>
                    ))
                    : null}
                {this.props.a52List
                    ? this.props.a52List.map((item) => (
                        <div key={item.id}>
                            {item.video === "" ? (
                                <Row
                                    className="m-0"
                                    onClick={() => {
                                        this.setState({
                                            content: item.description,
                                            videoBackground: item.video_background,
                                            type: 1,
                                            userId: item.user_id,
                                            isDeal: item.is_deal,
                                            img: item.image,
                                        });
                                        if (
                                            !this.props.frontPage ||
                                            parseInt(item.is_deal) !== 1
                                        ) {
                                            this.toggleModalBusiness();
                                        } else {
                                            swal(
                                                "Error",
                                                "You're not allowed to see this profile",
                                                "error"
                                            );
                                        }
                                    }}
                                >
                                    <BusinessOfferText src={item.image}/>
                                </Row>
                            ) : (
                                <Row
                                    className="m-0"
                                    onClick={() => {
                                        this.setState({
                                            content: item.video,
                                            type: 2,
                                            videoModal: true,
                                            description: item.description,
                                            videoBackground: item.video_background
                                        });

                                    }}
                                >

                                    <BusinessOfferVideo
                                        descriptionProp={item.description}

                                        videoBackground={item.video_background}
                                        videoImg={item.video}/>
                                </Row>
                            )}
                        </div>
                    ))
                    : null}
                {this.props.businessEventList !== null
                    ? this.props.businessEventList.map((item) => (
                        <div
                            key={item.id}
                            onClick={() => {
                                this.setState({content: item, type: 3});
                                this.toggleModalBusiness();

                            }}
                        >
                            <Event event={item}/>
                        </div>
                    ))
                    : null}
                <Modal
                    isOpen={this.state.modalBusiness}
                    // toggle={this.toggleModalBusiness}
                    className="hpEvtModal"
                    size="lg"
                >
                    {this.state.type === 1 ? (
                        <div>

                            <div className="header-p">
                                <ModalHeader className="head-1  text-white">
                                    Event details
                                </ModalHeader>
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo"/>
                                </div>
                                <ModalHeader
                                    className="head-2"
                                    toggle={this.toggleModalBusiness}
                                />
                            </div>
                            {parseInt(this.state.isDeal) !== 1 ? (
                                <ModalBody>
                                    {/* {this.state.img ? (
                    <img src={this.state.img}> */}
                                    <div className="textOfferPopup">
                                        {this.state.content}

                                        <img className="imgBuss" src={this.state.img}/>
                                    </div>
                                    {/* </img>
                  ) : null} */}
                                </ModalBody>
                            ) : (
                                <UserProfile userId={this.state.userId}></UserProfile>
                            )}
                        </div>
                    ) : this.state.type === 3 ? (
                        <div>

                            <div className="header-p">
                                <ModalHeader className="head-1  text-white">
                                    Event details
                                </ModalHeader>
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo"/>
                                </div>
                                <ModalHeader
                                    className="head-2"
                                    toggle={this.toggleModalBusiness}
                                />
                            </div>
                            <ModalBody>
                                <BusinessOfferNormal
                                    reloadEventsCallback={this.props.reloadEventsCallback}
                                    modalBusiness={() => this.toggleModalBusiness(false)}
                                    businessEventList={this.props.businessEventList}
                                    offer={this.state.content}
                                    signUpHandler={this.signUpHandler}
                                    isLogged={this.props.isLogged}
                                    eventHandler={this.props.eventHandler}
                                    handler={this.props.handler}
                                    setFalseSignUp={this.props.setFalseSignUp}
                                    showSignUp={this.props.showSignUp}
                                />
                            </ModalBody>
                        </div>
                    ) : null}
                </Modal>
                {this.state.type === 2 ? (
                    <div>

                        <Modal
                            isOpen={this.state.videoModal}
                            toggle={this.toggleVideoModal}
                            fade={false}
                            style={{maxWidth: '100%'}}
                            className="videoModal "
                            id="idModal"
                            // size="lg"
                        >
                            <ModalBody className="modalBodySmall">
                                <ModalVideo
                                    className="modalVideo modalVideoBody"
                                    channel="youtube"
                                    youtube={{autoplay: 1, mute: 1}}
                                    isOpen={this.state.videoModal}
                                    videoId={this.state.content.split("v=")[1]}
                                    onClose={() => this.setState({videoModal: false})}
                                >
                                </ModalVideo>
                                <h1 className="descriptionStl">
                                    {this.state.description}
                                </h1>
                            </ModalBody>
                        </Modal>
                    </div>
                ) : null}

            </div>
        );
    }
}

class BusinessOfferVideo extends Component {

    render() {
        return (
            <div className="vidBox">

                {this.props.videoBackground !== "" && this.props.videoBackground !== null ?


                    <div className="parDiv">
                        <h1 className="descriptionAbso">
                            {this.props.descriptionProp}
                        </h1>
                        <img className="vidBoxImg" src={this.props.videoBackground}/>
                    </div>

                    :
                    <div style={{
                        position: 'relative', width: '100%'
                    }}
                    >
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 100,
                            width: '100%'
                        }}/>
                        <iframe className="vidBoxImg"
                                onClick={this.props.toggleVideoModal}
                                aria-disabled
                                src={
                                    this.props.videoImg.substring(0, 23) +
                                    "/" +
                                    "embed/" +
                                    this.props.videoImg.substring(
                                        32,
                                        this.props.videoImg.length
                                    )
                                }
                                frameBorder="0"
                                allowFullScreen/>
                    </div>

                }


            </div>
        );
    }
}

class BusinessOfferText extends Component {
    render() {
        return (
            <div className="yellowBox">
                <div className="col-12 p-0">
                    {/* <div className="textOffer">{this.props.text}</div> */}
                    <img src={this.props.src}/>
                </div>
            </div>
        );
    }
}

class BusinessOfferNormal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEventCategoryName: ""
        };
    }

    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({categories: response.list_of_categories});
            this.getCategoryName();
        }

    };

    componentDidMount() {
        this.getCategories();
    }

    getCategoryName() {
        let event = this.props.offer;
        this.state.categories.forEach(element => {
            if (element.id === event.category_id) {
                this.setState({selectedEventCategoryName: element.name});
            }
        });
    }

    signUpHandler = () => {
        this.props.signUpHandler();
    };


    render() {
        return (
            <div>
                <BannerDetailsNormal
                    reloadEventsCallback={this.props.reloadEventsCallback}

                    closeModal={this.props.modalBusiness}

                    price={
                        this.props.isLogged === true
                            ?
                            this.props.offer.multiprice
                                ? this.props.offer.multiprice.length === 0
                                ? "FREE"
                                : this.props.offer.multiprice.length === 1
                                    ? this.props.offer.multiprice[0].price === "0"
                                        ? "FREE"
                                        : "PAY"
                                    : "PAY"
                                : null
                            : null
                    }
                    categoryName={this.state.selectedEventCategoryName}
                    multiprice={this.props.offer.multiprice}
                    currencyBannner={this.props.offer.currency}
                    eventHandler={this.props.eventHandler}
                    handler={this.props.handler}
                    showSignUp={this.props.showSignUp}
                    setFalseSignUp={this.props.setFalseSignUp}
                    id={this.props.offer.id}
                    name={this.props.offer.name}
                    gender={this.props.offer.gender}
                    ageCategory={this.props.offer.age_category}
                    startDate={this.props.offer.date.substring(0, 11)}
                    startTime={this.props.offer.start_hour.substring(0, 5)}
                    endDateEvent={this.props.offer.end_date}
                    endDate={this.props.offer.end_date.substring(0, 11)}
                    endTime={this.props.offer.end_hour.substring(0, 5)}
                    team_number={this.props.offer.team_number}
                    max_persons={this.props.offer.max_persons}
                    persons_left={this.props.offer.persons_left}
                    description={this.props.offer.description}
                    // multiprice={this.props.offer.currentMultiprice}
                    address={this.props.offer.address}
                    latitude={this.props.offer.latitude}
                    longitude={this.props.offer.longitude}
                    // userLogo={this.props.offer.user_event_logo}
                    //userLogo={this.props.offer.eventLogo}
                    userLogo={this.props.offer.logo}
                    eventLogo={this.props.offer.logo}
                    category={this.props.offer.category_id}
                    showSignUp={true}
                    signUpHandler={this.signUpHandler}
                    userIdE={this.props.offer.user_id}
                    isLogged={this.props.isLogged}
                    eventId={this.props.offer.id}
                />
            </div>
            // <Container className="">
            //     <Row className="row">
            //         <Col lg="1" className="imageBox"/>
            //         <Col lg="11" className="">
            //             {this.props.name}
            //         </Col>
            //     </Row>
            //
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Price
            //         </Col>
            //         <Col lg="2" className="d-flex justify-content-center border  px-3">
            //             5E
            //         </Col>
            //     </Row>
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Teams
            //         </Col>
            //         <Col lg="2" className="d-flex justify-content-center border px-3">
            //             0
            //         </Col>
            //     </Row>
            //
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Participants
            //         </Col>
            //         <Col lg="2" className="d-flex justify-content-center border px-3">
            //             40
            //         </Col>
            //     </Row>
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Unisex
            //         </Col>
            //     </Row>
            //
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Date
            //         </Col>
            //         <Col lg="3" className="d-flex justify-content-center border  px-3">
            //             16 nov 2019
            //         </Col>
            //     </Row>
            //     <Row lg="6" className="">
            //         <Col lg="3" className="">
            //             Time
            //         </Col>
            //         <Col lg="3" className="d-flex justify-content-center border  px-3">
            //             08:00 am
            //         </Col>
            //     </Row>
            // </Container>
        );
    }
}

class Event extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventInfo: this.props.event,
            date: new Date(),
        };
    }

    render() {
        let date = this.state.eventInfo.date;
        let newDate;

        if (date) {
            newDate = date.replace(/-/g, '/');

        }
        return (
            <div className="box col-12 col-lg-12">
                <div className="row">
      
                    <div className="col-9 col-sm-9 col-lg-9 no-padding p-0">
                        <div className="textbox">
                            <div className="title">{this.state.eventInfo.name}</div>

                            <div className="location">
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>{" "}
                                {this.state.eventInfo.address}
                            </div>
                            <div className="date">
                                {moment(new Date(newDate))
                                    .format("DD MMM")
                                    .toUpperCase()}
              </div>
            </div>
          </div>

          <div className="col-3 col-sm-3 col-lg-3 p-0">
            <div className="bgbox ">
              <div className="icon">
                <img className="gender-img" src={this.state.eventInfo.logo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function ConvertDateToStringddMMyyyy(stringVal) {
    let delimiter = "/";
    let months = ["IAN", ""];
    if (stringVal != null && stringVal != undefined) {
        let dt = stringVal;

        let valueDateFormat =
            (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()) +
            delimiter +
            (dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) +
            delimiter +
            dt.getFullYear();
        return valueDateFormat;
    }
    return "";
}
