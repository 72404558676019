import React from "react";
import "./OurPartners.scss";
import { Container, Row, Col } from "reactstrap";
import { fetchApi } from "../../../fetchApi";

export default class OurPartners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parternsList: []
    };
  }

  getParters = async () => {
    const url = "get_partners";
    let data = new FormData();

    const response = await fetchApi(url, "POST", null, null);

    if (response.success) {
      this.setState({
        parternsList: response.partners
      });
    }
  };

  componentDidMount = () => {
    this.getParters();
  };

  render() {
    return (
      <div className="ourPartners">
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <div className="ourPartnersTitle">
                <b>Zing</b> partners
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="ourParent">
           
                {this.state.parternsList.map(item => (
                  <div key={item.id} className="partners">
                    <a href={item.link} target="_blank">
                      <img src={item.logo} />
                    </a>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
