import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "typeface-montserrat"
import '../src/Assets/css/_custom.scss';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import CookiesAgreement from './Components/CookiesAgreement/CookiesAgreement.js';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions.js';
import PrivacyPolicy from './Components/TermsAndConditions/PrivacyPolicy.js';
import PageNotFound from './Components/PageNotFound/PageNotFound.js';

import PrivacyScanner from './Components/TermsAndConditions/PrivacyScanner';
import TermsScanner from './Components/TermsAndConditions/TermsScanner';

import HowItWorks from './Components/FirstPage/HowItWorks/HowItWorks.js';
import ContactUS from './Components/FirstPage/Footer/ContactUS.js';

const routing = (
    <Router>
        <Switch>
            <Route exact path="/" component={App} />
            <Route path="/cookies" component={CookiesAgreement} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />

            <Route path="/termsscanner" component={TermsScanner} />
            <Route path="/privacyscanner" component={PrivacyScanner} />

            <Route path="/contact" component={ContactUS} />
            <Route path="/howitworks" component={HowItWorks} />
            <Route component={PageNotFound} />
        </Switch>
    </Router>
)
ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
