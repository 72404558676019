import React, { Component } from 'react';
import './ZingMapMarker.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {

  Col,
  Row,
  Button
} from "reactstrap";
import { Link } from '@material-ui/core';



export default class ZingMapMarker extends Component {
  constructor(props) {
    super(props);
  }

  getCompanyBanner = (event, compId) => {
    event.persist();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    ReactTooltip.hide();
    this.props.closeMap(compId);
  };

  closeToolTip = () => {
    ReactTooltip.hide();
  };
  markerSelected = (id) => {
    this.props.markerClickHandler(
      this.props.MarkerText,
      this.props.lat,
      this.props.lng
    );
    if (this.props.showDeals) {
      this.props.openUserDeals(id);
    }
  };

  render() {
    return (
      <div
        className={
          this.props.markerLat && this.props.markerLng
            ? "zingMapMarkerSelected"
            : "zingMapMarker"
        }
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          size={this.props.markerLat && this.props.markerLng ? "3x" : "2x"}
          data-tip="tooltip"
          data-event="click"

          data-for={"markerTooltip" + this.props.markerKey}
        />
        {this.props.MarkerText}
        <ReactTooltip
          place="top"
          type="dark"
          effect="float"
          clickable={true}
          id={"markerTooltip" + this.props.markerKey}
        >
          <span className="closeLocation" onClick={this.closeToolTip}>
            X
          </span>
          <div className="">
            <Row className="buImage">
              <Col>
                <img className="image-nav" src={this.props.locItem.logo} />
              </Col>
            </Row>
            <Row className="buText">
              <Col className="tooltipCompName">
                <Link
                  onClick={(e) => {
                    this.getCompanyBanner(
                      e,
                      this.props.locItem.business_user_id
                    );
                  }}
                >
                  {this.props.locItem.company_name}
                </Link>
              </Col>
            </Row>
            {this.props.disableSelect ? null : (
              <Row className="buText">
                <Col>
                  <Button
                    className="selectLocationBtn"
                    onClick={() =>
                      this.markerSelected(this.props.locItem.business_user_id)
                    }
                  >
                    Select location
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </ReactTooltip>
      </div>
    );
  }
}