import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./MessagesNotifications.scss";
export default class MessagesNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessagesFromFriends: false,
      showMessagesFromMyEvents: false,
      showMessagesFromOngoingEvents: false,
      messageList: [
        { id: "1", name: "Asd", message: "how are you?", timeAgo: "58m" }
      ]
    };
  }

  toggleShowFMessages = () => {
    this.setState({
      showMessagesFromFriends: !this.state.showMessagesFromFriends
    });
  };

  toggleShowEMessages = () => {
    this.setState({
      showMessagesFromMyEvents: !this.state.showMessagesFromMyEvents
    });
  };
  toggleShowOMessages = () => {
    this.setState({
      showMessagesFromOngoingEvents: !this.state.showMessagesFromOngoingEvents
    });
  };

  render() {
    return (
      <Container className="">
        <Col className="massageCategory1" onClick={this.toggleShowFMessages}>
          Messages from friends
        </Col>

        {this.state.showMessagesFromFriends
          ? this.state.messageList.map(item => (
              <Row className="p-0 my-1" key={item.id}>
                <Col lg="auto" className="messageImg"></Col>
                <Col lg="auto" className="">
                  <Col className="" className="message">
                    {item.message}
                  </Col>
                  <Col className=" messageDetails">
                    {item.name}-{item.timeAgo}
                  </Col>
                </Col>
              </Row>
            ))
          : null}
        <Col className="massageCategory2" onClick={this.toggleShowEMessages}>
          Messages from my events
        </Col>

        {this.state.showMessagesFromMyEvents
          ? this.state.messageList.map(item => (
              <Row className="p-0 my-1" key={item.id}>
                <Col lg="auto" className="messageImg"></Col>
                <Col lg="auto" className="">
                  <Col className="" className="message">
                    {item.message}
                  </Col>
                  <Col className=" messageDetails">
                    {item.name}-{item.timeAgo}
                  </Col>
                </Col>
              </Row>
            ))
          : null}

        <Col className="massageCategory3" onClick={this.toggleShowOMessages}>
          Messages from ongoings
        </Col>

        {this.state.showMessagesFromOngoingEvents
          ? this.state.messageList.map(item => (
              <Row className="p-0 my-1" key={item.id}>
                <Col lg="auto" className="messageImg"></Col>
                <Col lg="auto" className="">
                  <Col className="" className="message">
                    {item.message}
                  </Col>
                  <Col className=" messageDetails">
                    {item.name}-{item.timeAgo}
                  </Col>
                </Col>
              </Row>
            ))
          : null}
      </Container>
    );
  }
}
