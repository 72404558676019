import React, { Component, Children } from "react";
import {
    Input,
    Col,
    Row,
    Container,
    Button,
    Popover,
    PopoverBody,
    ModalBody,
    Modal,
    ModalHeader, Label,
} from "reactstrap";
import "./ContentEvents.scss";
import "react-datepicker/dist/react-datepicker.css";
import { fetchApi } from "../../../fetchApi";
import swal from "sweetalert";
import { userCredentials } from "../../../globalVariables";
import ShowMoreText from 'react-show-more-text';
import {
    faTimes,
    faPen,
    faShare,
    faShoppingCart,
    faStar,
    faLink,
    faEuroSign,
    faPoundSign,
    faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProfile from "../UserProfile/UserProfile";
import Messages from "../../NormalUser/Messages/Messages";
import FlatList from "flatlist-react";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import Friends from "../Friends/Friends";
import Tooltip from "@material-ui/core/Tooltip";
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { hostDetails } from "../../../globalVariables";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Promotions from "../../BusinessUser/Promotions/Promotions";
import ModalVideo from "react-modal-video";

const localizer = momentLocalizer(moment);
let SLogo = require("../../../Assets/img/SLogo.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class ContentEvents extends Component {
    constructor(props) {

        let today = moment().format();
        super(props);
        this.state = {
            width: window.innerWidth,
            todayDay: today,
            modalShowDetails: false,
            messages: false,
            messagesList: [],
            userType: 3,
            category: "",
            categories: [],
            showPopover: false,
            voucher: "",
            modalProfileFriends: false,
            messageText: "",
            messagesModal: false,
            messageType: 1,
            messageEventId: null,
            profileModal: false,
            showUsersCreatedEvents: false,
            showUserRegisteredEvents: false,
            showUserFriends: false,
            showUserDeals: false,
            modalTitle: "",
            createdEventsList: [],
            registeredEventsList: [],
            friendsList: [],
            totalFriendsNo: 8,
            filteredList: [],
            filterVal: "",
            sPopover: false,
            dealsDateList: [],
            openPromoteEvent: false,
            curentDealCalendarId: 0,
            currentDealCalendar: [],
            holidayList: [],
            description: ""
        };

        this.handlerModalFriends = this.handlerModalFriends.bind(this);
    }

    copyToClipboard = (text, elementId) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        const parentElement = document.getElementById(elementId);
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        parentElement.removeChild(textField);
        swal("Success!", "Event link copied to clipboard!", "success");
    };

    openFriends = (name) => {
        this.listoffriends();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " friends",
            showUserFriends: true,
        });
    };

    openEventsCreated = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created events",
            showUsersCreatedEvents: true,
        });
    };

    openEventsRegistered = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " registered events",
            showUserRegisteredEvents: true,
        });
    };

    openDealsCreated = (name) => {
        this.getMyDeals();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created deals",
            showUserDeals: true,
        });
    };

    toggleProfileModal = () => {
        this.setState({ profileModal: !this.state.profileModal });
        if (this.state.showUsersCreatedEvents) {
            this.setState({ showUsersCreatedEvents: false });
        }
        if (this.state.showUserRegisteredEvents) {
            this.setState({ showUserRegisteredEvents: false });
        }
        if (this.state.showUserFriends) {
            this.setState({ showUserFriends: false });
        }
        if (this.state.showUserDeals) {
            this.setState({ showUserDeals: false });
        }
    };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", this.props.userIdE);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({
                friendsList: response.list_of_friends,
                allFriends: response.list_of_friends,
            });
        }
    };

    getAllFriendsEventHandler = (event) => {
        this.setState({ filterVal: "" });

        if (this.state.filteredList.length <= 5) {
            let currentDisplayedFriends = this.state.friendsList;
            let friend = this.state.friendsList;
            if (!currentDisplayedFriends.some((friend) => friend.key === "5"))
                // currentDisplayedFriends = [
                //     ...currentDisplayedFriends,
                //     {key: "5", name: "Alina"}
                // ];
                // friend = {key: "6", name: "Cornel"};
                // if (!currentDisplayedFriends.some(friend => friend.key === "6"))
                //   currentDisplayedFriends = [...currentDisplayedFriends, friend];
                // friend = { key: "7", name: "Adrian", modal: false };
                // if (!currentDisplayedFriends.some(friend => friend.key === "7"))
                //   currentDisplayedFriends = [...currentDisplayedFriends, friend];

                this.setState({ friendsList: currentDisplayedFriends });
            this.setState({ filteredList: currentDisplayedFriends });
        } else {
            this.setState({ filteredList: this.state.friendsList.slice(0, 5) });
            this.setState({ friendsList: this.state.friendsList.slice(0, 5) });
        }
    };

    searchFriends = (event) => {
        let friend = this.state.allFriends;
        this.setState({ filterVal: event.target.value.substr(0, 10) });
        if (event.target.value !== "") {
            const filteredList = friend.filter(
                (friendsList) =>
                    friendsList[0].name
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1
            );
            this.setState({ friendsList: filteredList });
        } else {
            // this.listoffriends();

            this.setState({ friendsList: friend });
        }
    };

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("user_id", this.props.userIdE);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let createdEvents = [];
            createdEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) === parseInt(this.props.userIdE)
            );
            let registeredEvents = [];
            registeredEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) !== parseInt(this.props.userIdE)
            );

            this.setState({
                createdEventsList: createdEvents.reverse(),
                registeredEventsList: registeredEvents.reverse(),
            });
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length == 0
        ) {
            this.setState({ showAlertMyE: true });
        } else {
            this.setState({ showAlertMyE: false });
        }
    };

    onChangeVoucher = (evt) => {
        this.setState({ voucher: evt.target.value });
    };

    findCategory = (id) => {
        if (this.state.categories !== undefined) {
            let category = this.state.categories.filter(
                (element) => parseInt(element.id) === parseInt(id)
            );

            if (category[0] !== undefined) {
                this.setState({ category: category[0].name });
            } else {
                this.setState({ category: "category" });
            }
        }
    };

    toogleModalShowDetails = () => {
        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    showMessages = () => {
        this.setState({ messages: !this.state.messages });
    };

    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);
        if (response.success && response.list_of_categories) {
            let catName = response.list_of_categories.filter(
                (element) => parseInt(element.id) === parseInt(this.props.category)

            );

            if (catName[0] !== undefined) {
                this.setState({ category: catName[0].name });

            }
        }
    };

    componentDidMount = () => {
        this.getCategories();
        this.setState({
            url: hostDetails.url + "?snid=" + this.props.eventId,
            description: this.props.description
        });
    };

    joinEvent = async () => {
        if (this.props.price === "PAY") {
            this.setState({ showPopover: !this.state.showPopover });
        } else {
            if (
                userCredentials.userId !== this.props.userIdE &&
                this.props.persons_left <= this.props.max_persons
            ) {
                const url = "assigntoevent";
                const data = new FormData();
                //data.append("user_type", this.state.userType);
                data.append("event_id", this.props.eventId);
                data.append("user_id", userCredentials.userId);
                data.append("multiprice_event_id", 0);
                // data.append("deal_id", 0);
                // data.append("deal_calendar_id", 0);
                // data.append("deal_multiprice_id", 0);
                data.append("tickets_number", 1);
                data.append("comment", "commnet");

                const response = await fetchApi(url, "POST", data, this.state.authKey);

                if (response.success) {
                    this.props.reloadEventsCallback();
                }
            } else {
                //console.log(this.props.userIdE);
            }
        }
    };

    selectEvent = (event) => {
        let dealCalendar = event.resource.deals_calendar.filter(
            (item) =>
                new Date(item.date).getTime() === new Date(event.start).getTime()
        );

        this.setState({
            profileModal: !this.state.profileModal,
            curentDealCalendarId: dealCalendar[0].id,
            currentDealCalendar: dealCalendar[0],
        });
        this.toggleModalShowDetailsD(
            event.resource.deals,
            event.resource.deals_calendar
        );
    };

    toggleModalShowDetailsD = (item, calendar) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: [
                {
                    id: 1,
                    price: calendar.length > 0 ? calendar[0].price : 0,
                    nOf: item.max_persons,
                    indicativePrice: item.currency,
                },
            ],
            eventLogo: item.logo,
            currentCalendar: calendar,
        });
        //console.log(item);
        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    toggle = () => {
        if (this.props.price === "PAY") {
            this.setState({ showPopover: !this.state.showPopover });
        }
    };

    deleteEvent = async () => {
        let authKey = userCredentials.authKey;
        const url = "deleteevent";
        let data = new FormData();
        data.append("event_id", this.props.eventId);
        if (
            this.props.max_persons === this.props.persons_left &&
            parseInt(this.props.userIdE) === userCredentials.userId
        ) {
            const response = await fetchApi(url, "POST", data, authKey);
            //console.log(response);
            if (response.success) {
                this.props.reloadEventsCallback();
            }
        } else {
            swal("Error!", "This event can't be deleted", "error");
        }
    };


    toggleModalProfile = () => {
        this.setState({ modalProfile: !this.state.modalProfile });

    };

    handlerModalFriends(val) {
        // console.log('modal open')
        this.setState({ modalProfileFriends: !this.state.modalProfileFriends });

    }

    async sendMessage() {

        const url = "sendmessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("message_type", this.props.userIdE.toString() === userCredentials.userId.toString() ? 2 : 3);
        data.append("friend_id", 0);
        data.append("event_id", this.props.eventId);
        data.append("message", this.state.messageText);


        const response = await fetchApi(url, "POST", data, authKey);


        if (response.success) {
            this.setState({ messageText: "" });
            swal("Success!", "Message sent!", "success");
            if (this.props.userIdE.toString() === userCredentials.userId.toString()) {
                this.openMessages(1, this.props.eventId);
            } else {
                this.openMessages(2, this.props.eventId);
            }
        } else {
            swal(
                "Error!",
                "Message was not able to be sent! Please try again later...",
                "error"
            );
        }
    }

    openMessages(eventType, eventId) {
        this.setState({
            messagesModal: true,
            messageType: eventType,
            messageEventId: eventId,
        });
    }

    toggleMessages = () => {
        this.setState({ messagesModal: !this.state.messagesModal });
    };

    closeModal = () => {
        this.setState({ modalProfileFriends: false });
    };

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.toggleProfileModal();
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
            profileModal: true,
            modalTitle: "Event details",
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({ showVoucherInput: false });
        }
        if (this.state.showVoucers) {
            this.setState({ showVoucers: false });
        }
    };

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
            profileModal: true,
            modalTitle: "Event details",
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({ showVoucherInput: false });
        }
        if (this.state.showVoucers) {
            this.setState({ showVoucers: false });
        }
    };

    toggleSPopover = () => {
        this.setState({ sPopover: !this.state.sPopover });
    };

    getMyDeals = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofdealsperbusiness";
        const data = new FormData();
        data.append("business_user_id", this.props.userIdE);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let arr = [];
            response.deals.forEach((felement) => {
                felement.deals_calendar.forEach((element) => {
                    let endDate =
                        element.date.substring(0, 10) + " " + felement.deals.end_hour;
                    arr.push({
                        start: moment(element.date).toDate(),
                        end: moment(endDate).toDate(),
                        title: felement.deals.name,
                        resource: felement,
                    });
                });
            });
            this.setState({ dealsDateList: arr, holidayList: response.holidays });
        }
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {};
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(start);
        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                if (parseInt(element.persons_left) === 0 || date < cd) {
                    style = {
                        backgroundColor: "red",
                    };
                }
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        style = {
                            backgroundColor: "orange",
                        };
                    }
                });
            }
        });
        return {
            style: style,
        };
    };

    renderEvents = (eventInfo, idx) => {

        return (
            <div className="my-1 " key={eventInfo.event.id}>
                <ContentEvents
                    aliasName={eventInfo.event.name_of_aliascategory}
                    categName={eventInfo.event.name_of_subcategory}

                    isClosed={!eventInfo.is_closed}
                    //categName={eventInfo.name_of_subcategory}
                    // eventId={eventInfo.deals.id}
                    numberOfMsg={eventInfo.number_of_messages}
                    eventType={3}
                    eventId={eventInfo.event.id}
                    userIdE={eventInfo.event.user_id}
                    name={eventInfo.event.name}
                    category={eventInfo.event.category_id}
                    text={eventInfo.event.description}
                    date={
                        eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
                    }
                    area={eventInfo.event.address}
                    time={
                        eventInfo.event.start_hour
                            ? eventInfo.event.start_hour.substring(0, 5)
                            : ""
                    }
                    teams={eventInfo.event.team_number}
                    participants={
                        eventInfo.event.max_persons - eventInfo.event.persons_left
                    }
                    state="closed"
                    price={
                        eventInfo.multiprice.length === 0
                            ? "FREE"
                            : eventInfo.multiprice.length === 1
                                ? eventInfo.multiprice[0].price === "0"
                                    ? "FREE"
                                    : "PAY"
                                : "PAY"
                    }
                    gender={eventInfo.event.gender}
                    ageCategory={eventInfo.event.age_category}
                    latitude={eventInfo.event.latitude}
                    longitude={eventInfo.event.longitude}
                    team_number={eventInfo.event.team_number}
                    max_persons={eventInfo.event.max_persons}
                    persons_left={eventInfo.event.persons_left}
                    type="free"
                    toogleModalShowDetails={() =>
                        this.toggleModalShowDetails(
                            eventInfo.event,
                            eventInfo.multiprice,
                            eventInfo.user_logo
                        )
                    }
                    reloadEventsCallback={this.reloadEventsCallback}
                    categories={this.state.categories}
                    userLogo={eventInfo.user_logo}
                    showVoucer={this.showVoucer}
                    description={eventInfo.event.name}
                />
            </div>
        );
    };


    togglePromoteEvent = () => {
        this.setState({ openPromoteEvent: !this.state.openPromoteEvent });
        this.props.reloadEventsCallback();
    };

  openVideoModal = () => {
    this.setState({ videoModal: true });
  };
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  renderMobile =  () => {
    const {width} = this.state;
    const isMobile = width <= 500;
    if (isMobile) {
      return(
        null
      )
    }else{
      return(
          
        <Button
        className={
            this.props.price === "FREE"
                ? "payButton  free"
                : "payButton  pay"
        }
        //onClick={this.props.showVoucer}
    >
        
        <FontAwesomeIcon
            icon={faShoppingCart}
            className="mr-2"
        />{" "}
        {this.props.price}
    </Button>
            )
        }
    }
    executeOnClick(isExpanded) {
            // this.setState({this.props.toogleModalShowDetails})
        
        // console.log(isExpanded);
    }
    renderDeleteAndEdit = () => {
        return (
            <Col className=" pr-0 mr-0 " >
                {this.props.showButtons ? (
                    <Col className="absolute-btns">
                        <div className={ this.props.isPromoted === "1" ? "parentButtonsProm" : "parentButtons"} >

            <Button
                className="Close-pen-btn"
                onClick={this.props.updateEvent}
            >
                <FontAwesomeIcon icon={faPen} />
            </Button>
            <Button
                className="closeEventBtn"
                onClick={(e) => {
                    swal({
                        title: "Are you sure you wish to delete this item?",
                        icon: "warning",
                        buttons: {
                            Ok: {
                                text: "Ok",
                                value: true,
                            },
                            Cancel: {
                                text: "Cancel",
                                value: false,
                            },
                        },
                    }).then((val) => {
                        if (val) this.deleteEvent();
                    });
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </Button>
            </div>

        </Col>
    ) : null}
        </Col>
    
    )}
  render() {
    const CURRENT_DATE = moment().toDate();

        const ColoredDateCellWrapper = ({ children, value }) => {
            let evtPresent = this.state.dealsDateList.filter((x) => {
                let curDate = new Date(value);
                curDate.setHours(0, 0, 0, 0);
                let dealDateStart = new Date(x.start);
                dealDateStart.setHours(0, 0, 0, 0);
                let dealDateEnd = moment(x.end);
                if (+curDate === +dealDateStart) {
                    return x;
                }
            });
            return React.cloneElement(Children.only(children), {
                style: {
                    ...children.style,
                    backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                },
            });
        };
        return (
            <Container className="my-3 p-0 mr-2 each-events">
                <Modal
                    isOpen={this.state.messagesModal}
                    // toggle={this.toggleMessages}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Inbox</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleMessages} />
                    </div>
                    <ModalBody>
                        <Messages
                            messageType={this.state.messageType}
                            messageEventId={this.state.messageEventId}
                        />
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.openPromoteEvent}
                    // toggle={this.togglePromoteEvent}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader
                            className="head-1  text-white">Promote your event</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader className="head-2" toggle={this.togglePromoteEvent} />
                    </div>
                    <ModalBody>
                        <Promotions openPromoteEvent={this.state.openPromoteEvent}
                            closePromoteEvent={this.togglePromoteEvent} promoteEventId={this.props.eventId} />
                    </ModalBody>

                </Modal>
                {this.state.modalProfileFriends ? (
                    <Modal
                        className="costume-modal-prof"
                        isOpen={true}
                        // toggle={() => this.handlerModalFriends()}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1" />
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo" />
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={() => this.handlerModalFriends()}
                            />
                        </div>

                        <UserProfile
                            businessLogo={this.props.userLogo}
                            userId={this.props.userIdE}
                            openEventsCreated={this.openEventsCreated}
                            openEventsRegistered={this.openEventsRegistered}
                            openFriends={this.openFriends}
                            openDealsCreated={this.openDealsCreated}
                            closeHandler={this.handlerModalFriends}
                        />
                    </Modal>
                ) : null}
                <Container className="eventContainerTop py-2">
                
               {this.renderDeleteAndEdit()}

                    {this.props.isPromoted === "1" ? <div className="promotionBanner">
                        <div>
                            <Label>Promoted</Label>
                        </div>


                    </div> : null}
                    {/* </Container><div onClick={this.toogleModalShowDetails}> */}

          <Row>
            <Col
              xs="1"
              sm={1}
              md={1}
              lg={1}
              className="d-flex align-items-center justify-content-center p-0 mobMargin"
            >
              <Col className="p-0">
                {this.props.userLogo !== null &&
                this.props.userLogo !== undefined &&
                this.props.userLogo !== "" ? (
                  <div onClick={this.handlerModalFriends}>
                    <img
                      src={this.props.userLogo}
                      className="eventProfilePic evtPic mobMargin"
                      alt="profilePic"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = profilePic;
                      }}
                    />
                  </div>
                ) : (
                  <div onClick={this.handlerModalFriends}>
                    <img
                      src={profilePic}
                      className="eventProfilePic evtPic"
                      alt="profilePic"
                    />
                  </div>
                )}
                {this.props.voucherLogo && this.props.vocherLogo !== "" ? (
                  <div>
                    <Col
                      xs="12"
                      lg={12}
                      className="p-0 d-flex justify-content-start align-items-center m-0"
                    >
                      <a className="sponsoredBy">Sponsored by</a>
                    </Col>
                    <img
                      src={this.props.voucherLogo}
                      className="eventProfilePic evtPic mt-3"
                      alt="profilePic"
                    />
                  </div>
                ) : this.props.voucherVideo &&
                  this.props.voucherVideo !== "" ? (
                  <Col
                    xs="12"
                    lg={12}
                    className="p-0 d-flex justify-content-start align-items-center"
                  >
                    <a className="seeVideo" onClick={this.openVideoModal}>
                      See sponsor video...
                    </a>
                                            </Col>
                                        ) : null}
                            </Col>
                        </Col>
                        <Col 
                        
                         xs="11" sm="10" lg="9" className=" haha pt-3">
                            <Col onClick={this.props.toogleModalShowDetails} className="name nameAndCategory p-0">
                                <Row>
                                    <Col xs="9" sm={7} md={7} lg={8} className="p-0 categText">
                                        {" "}

                                       <Label className="lbl-evt-name" > {this.props.name} </Label> 
                                       <Label className="lbl-evt-categ"> -   
                                       {/* {this.state.category} */}
                                            {this.props.categName !== undefined ? this.props.categName : this.state.category}

                                            {/* {this.props.aliasName !== undefined ? this.props.aliasName  : this.state.category} */}

                                        </Label>

                                        {
                                            this.props.userTypeStars == 2 ? (
                                                <Tooltip title="Business">
                                                    <Button className="costume-star-btn2"> <FontAwesomeIcon
                                                        icon={faStar} /> </Button>
                                                </Tooltip>
                                            ) :
                                                this.props.userTypeStars == 3 ? (
                                                    <Tooltip title="Normal">
                                                        <Button className="costume-star-btn3"> <FontAwesomeIcon
                                                            icon={faStar} /> </Button>
                                                    </Tooltip>)
                                                    :
                                                    this.props.userTypeStarsMyE == 2 ? (
                                                        <Tooltip title="Business">
                                                            <Button className="costume-star-btn2"> <FontAwesomeIcon
                                                                icon={faStar} /> </Button>
                                                        </Tooltip>)
                                                        :
                                                        this.props.userTypeStarsMyE == 3 ? (
                                                            <Tooltip title="Normal">
                                                                <Button className="costume-star-btn3"> 
                                                                
                                                                <FontAwesomeIcon
                                                                    icon={faStar} /> 
                                                                    </Button>
                                                            </Tooltip>)
                                                            :
                                                            this.props.userTypeBusiness === 2 ?
                                                                (<Tooltip title="Business">
                                                                    <Button className="costume-star-btn2">
                                                                        <FontAwesomeIcon icon={faStar} />{" "} 
                                                                        </Button>
                                                                </Tooltip>)
                                                                :
                                                                this.props.userTypeBusiness === 3 ?
                                                                    (<Tooltip title="Normal">
                                                                        <Button className="costume-star-btn3">
                                                                            <FontAwesomeIcon icon={faStar} /> 
                                                                            </Button>
                                                                    </Tooltip>)
                                                                    :
                                                                    this.props.userTypeBusinessMyE === 2 ?
                                                                        (<Tooltip title="Business">
                                                                            <Button className="costume-star-btn2">
                                                                                <FontAwesomeIcon icon={faStar} />
                                                                            </Button>
                                                                        </Tooltip>)
                                                                        :
                                                                        this.props.userTypeBusinessMyE === 3 ?
                                                                            (<Tooltip title="Normal">
                                                                                <Button className="costume-star-btn3">
                                                                                    <FontAwesomeIcon icon={faStar} />
                                                                                </Button>
                                                                            </Tooltip>) :

                                                                            (null)
                                        }

                                    </Col>

                                    <Col
                                        xs="3"
                                        sm={5}
                                        md={5}
                                        lg={4}
                                        className="d-flex align-items-center justify-content-end p-0"
                                    >
                                        {" "}
                                        {/* <span className="eventOpenClosed mr-2">This event is:</span> */}
                                        <div
                                            className={this.props.isClosed
                                                ? "btnStatusEv open"
                                                : "btnStatusEv closed"}>

                                            {this.props.isClosed
                                                ? <Label> <span className="dot"></span> Open</Label>
                                                : <Label> <span className="dot-red"></span> Closed</Label>}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                                    

                            
                            <Col
                                className="description"
                            // onClick={this.props.toogleModalShowDetails}
                            >
                                {/* {this.props.text}  */}
                                                <div onClick={this.props.toogleModalShowDetails} className="posFake" >

                                                </div>
                                <ShowMoreText
                                    lines={2}
                                    more='Read more'
                                    less='Read less'
                                    anchorClass='classAth'
                                    onClick={this.executeOnClick }
                                    expanded={false}
                                    width={0}
                                >
                                    {this.props.text}
                                </ShowMoreText>


                            </Col>
                            <div onClick={this.props.toogleModalShowDetails} className="detailsBoxs">
                                <Row>
                                    <div className="details">{this.props.area}</div>
                                    <div className="details">{this.props.date}</div>
                                    <div className="details">{this.props.time}</div>
                                    {parseInt(this.props.teams) === 0 ? null : parseInt(
                                        this.props.teams
                                    ) > 1 ? (
                                            <div className="details">{this.props.teams} Teams</div>
                                        ) : (
                                            <div className="details">{this.props.teams} Team</div>
                                        )}

                                    <div className="details">
                                        {this.props.participants}/{this.props.max_persons}{" "}
                                        Participants
                                    </div>

                                    {this.props.multiprice !== undefined ? (
                                        this.props.multiprice.length !== 0 &&
                                            this.props.multiprice[0] !== "0" ? (
                                                <div className="d-flex priceDetails">
                                                    {this.props.price === "PAY" ? (
                                                        this.props.multiprice.map((item) => (
                                                            <div key={item.id} className="details d-flex">
                                                                <div className="EventLabel-content d-flex align-items-center">
                                                                    Price:
                                                            </div>
                                                            &nbsp;
                                                                <div>
                                                                    {this.props.currencyOcta === "GBP" ? (
                                                                        <FontAwesomeIcon icon={faPoundSign} />
                                                                    ) : this.props.currencyOcta === "lira" ? (
                                                                        <FontAwesomeIcon icon={faPoundSign} />
                                                                    ) : this.props.currencyOcta === "euro" ? (
                                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.props.currencyOcta === "EURO" ? (
                                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.props.currencyOcta === "EUR" ? (
                                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                                    ) : this.props.currencyOcta === "USD" ? (
                                                                        <FontAwesomeIcon icon={faDollarSign} />
                                                                    ) : this.props.currencyOcta === "RON" ? (
                                                                        "RON"
                                                                    ) : null}
                                                                &nbsp; {item.price}
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                            <div className="details"> Free </div>
                                                        )}
                                                </div>
                                            ) : null
                                    ) : null}
                                </Row>
                            </div>
                        </Col>
                        <Col className=" colPosition p-0 m-0">
                            {/* {this.props.showButtons ? (
                                <Col className="d-flex  justify-content-end  edtButtons">
                                    <Button
                                        className="Close-pen-btn"
                                        onClick={this.props.updateEvent}
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                    <Button
                                        className="closeEventBtn"
                                        onClick={(e) => {
                                            swal({
                                                title: "Are you sure you wish to delete this item?",
                                                icon: "warning",
                                                buttons: {
                                                    Ok: {
                                                        text: "Ok",
                                                        value: true,
                                                    },
                                                    Cancel: {
                                                        text: "Cancel",
                                                        value: false,
                                                    },
                                                },
                                            }).then((val) => {
                                                if (val) this.deleteEvent();
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </Col>
                            ) : null} */}
                            {parseInt(this.props.userIdE) !== userCredentials.userId ? (
                                <Col   className="joinShit pl-1 pr-1" >
                                {!this.props.showJoin ? (
                                    <Col xs={14} sm={12} lg={14} className=" noPadding ">
                                    <div className="  mt-2 mb-2 ">
                                        
                                            <Button
                                                className="joinButton "
                                                onClick={this.props.toogleModalShowDetails}
                                            >
                                                Join
                                            </Button>
                                       
                                    </div>
                                    </Col>
                                    
                                //     <Col xs={6} sm={6} lg={12}>
                                //         <div  className="  mt-2 mb-2">
                                //         <Button
                                //             // id={"promoteEvent" + this.props.eventId}
                                //             className="joinButton"
                                //             onClick={this.props.toogleModalShowDetails}
                                //         >
                                //             Join
                                //     </Button>
                                //     </div>
                                // </Col>

                                     ) : null}
                                     <Col xs={6} sm={6} lg={12} className="noPadding">
                                        <div  className="  mt-2 mb-2">
                                    {this.renderMobile()}
                                    </div>
                                </Col>
                                    </Col>
                                    
                            ) : (
                                <div >

                                        
                                            {!this.props.hideVoucherButton ? (
                                                <Col  className=" noPadding " >
                                                <div className="mt-2 mb-2">
                                                    <Button
                                                        className="voucherButton mb-1"
                                                        onClick={this.props.toggleModalGetVouchers}
                                                    >
                                                        Get Voucher
                                                </Button>
                                                </div>
                                            </Col>
                                        ) : null}
                                        {/* <Button
                                                className={
                                                    this.props.price === "FREE"
                                                        ? "payButton free"
                                                        : "payButton pay"
                                                }
                                                //onClick={this.props.showVoucer}
                                            >
                                                {" "}
                                                <FontAwesomeIcon
                                                    icon={faShoppingCart}
                                                    className="mr-2"
                                                />{" "}
                                                {this.props.price}
                                            </Button> */}
                                            <Col xs={6} sm={6} lg={12} className="noPadding">
                                        {/* <div  className="  mt-2 mb-2"> */}
                                             {this.renderMobile()}
                                             {/* </div> */}
                                             </Col>
                                             </div>
                                        

                                )}
                            {this.props.showButtons &&
                                this.props.isPromoted === "2" &&
                                userCredentials.userType == "2" ? (
                                    <Col xs={6} sm={6} lg={12} className="noPadding">
                                        <div  className="  mt-2 mb-2">
                                            <Button 
                                                id={"promoteEvent" + this.props.eventId}
                                                className="promoteEvtBtn"
                                                onClick={() => this.togglePromoteEvent()}
                                            >
                                                Promote
                                        </Button>
                                        </div>
                                    </Col>
                                ) : null}
                            <Col xs={6} sm={5} lg={12} className="noPadding" >
                                <div  className="mt-2 mb-2">
                                    <Button 
                                        id={"spopover" + this.props.eventId}
                                        className="voucherButton "
                                        onClick={this.props.toggleSPopover}
                                    >
                                        Share
                                    </Button>
                                </div>
                            </Col>


                            <Popover
                                placement="bottom"
                                isOpen={this.state.sPopover}
                                target={"spopover" + this.props.eventId}
                                toggle={this.toggleSPopover}
                                trigger="legacy"
                            >

                                <PopoverBody>
                                    <Row className="EventLabel mb-3 shareParent" id="shareParent">

                                        <FacebookShareButton url={this.state.url} quote={this.state.description}>
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={this.state.url} title={this.state.description}>
                                            <TwitterIcon size={32} round={true} />
                                        </TwitterShareButton>
                                        <WhatsappShareButton url={this.state.url} title={"Check this event!"}
                                            description={this.state.description}>
                                            <WhatsappIcon size={32} round={true} />
                                        </WhatsappShareButton>
                                        <div
                                            className="copyLink"
                                            onClick={() =>
                                                this.copyToClipboard(this.state.url, "shareParent")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faLink} />
                                        </div>
                                    </Row>
                                </PopoverBody>
                            </Popover>
                        </Col>
                    </Row>
                </Container>
                {this.state.messages ? (
                    <div className="bottomMessages">
                        <div className="fullWidth">
                            {this.state.messagesList.map((item, index) => (
                                <Row className=" messageInput" key={index}>
                                    <Col lg="3" className="border py-1">
                                        <Row className="bottomMessageText">
                                            <Col className="text-white">{item.name}</Col>
                                        </Row>
                                    </Col>
                                    <Col lg="9" className="border py-1">
                                        <Row className="bottomMessageText">
                                            <Col className="text-white">{item.message}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </div>
                ) : null}
                <Container className="eventContainerBottom py-2">
                    <div className="bottomTextInput">
                        <Row>
                            <Col
                                xs="1"
                                sm="1"
                                lg="1"
                                className="d-flex justify-content-center messageDot align-items-center"
                                onClick={() => {
                                    if (
                                        this.props.eventType === 2 ||
                                        (this.props.eventType === 3 && this.props.joinedEvent)
                                    ) {
                                        if (this.props.numberOfMsg !== 0) {
                                            if (
                                                this.props.userIdE.toString() ===
                                                userCredentials.userId.toString()
                                            ) {
                                                this.openMessages(1, this.props.eventId);
                                            } else {
                                                this.openMessages(2, this.props.eventId);
                                            }
                                        }
                                    } else {
                                        swal(
                                            "Info!",
                                            "You need to join to this event in order to send a message!!!",
                                            "info"
                                        );
                                    }
                                }}
                            >
                                {this.props.numberOfMsg}
                            </Col>
                            <Col xs="8" sm="8" lg="8">
                                <Input
                                    placeholder={
                                        this.props.eventType === 3 && !this.props.joinedEvent
                                            ? "You need to join this event in order to chat..."
                                            : "Start typing..."
                                    }
                                    className="messageInput"
                                    disabled={
                                        this.props.eventType === 3 && !this.props.joinedEvent
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            if (this.state.messageText.length !== 0) {
                                                if (
                                                    this.props.eventType === 3 &&
                                                    !this.props.joinedEvent
                                                ) {
                                                    swal(
                                                        "Info!",
                                                        "You need to join to this event in order to send a message!!!",
                                                        "info"
                                                    );
                                                } else {
                                                    this.sendMessage();
                                                }
                                            } else {
                                                swal("Info!", "Can't send an empty message", "info");
                                            }
                                        }
                                    }}
                                    value={this.state.messageText}
                                    onChange={(val) =>
                                        this.setState({ messageText: val.target.value })
                                    }
                                />
                            </Col>
                            <Col xs="2" className="justify-content-between">
                                <Button
                                    className="MessageBtn"
                                    onClick={() => {
                                        if (this.state.messageText.length !== 0) {
                                            if (
                                                this.props.eventType === 3 &&
                                                !this.props.joinedEvent
                                            ) {
                                                swal(
                                                    "Info!",
                                                    "You need to join to this event in order to send a message!!!",
                                                    "info"
                                                );
                                            } else {
                                                this.sendMessage();
                                            }
                                        } else {
                                            swal("Info!", "Can't send an empty message", "info");
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faShare} />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Modal
                    isOpen={this.state.profileModal}
                    // toggle={this.toggleProfileModal}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleProfileModal} />
                    </div>
                    <ModalBody>
                        {this.state.showUsersCreatedEvents ? (
                            <div className="eventsArea">
                                <FlatList
                                    list={this.state.createdEventsList}
                                    renderItem={this.renderEvents}
                                ></FlatList>
                            </div>
                        ) : this.state.showUserRegisteredEvents ? (
                            <div className="eventsArea">
                                <FlatList
                                    list={this.state.registeredEventsList}
                                    renderItem={this.renderEvents}
                                ></FlatList>
                            </div>
                        ) : this.state.showUserDeals ? (
                            <Col className="d-flex justify-content-center">
                                <Calendar
                                    messages={{
                                        showMore: total => (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onMouseOver={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >SEE all deals
                                            </div>
                                        ),
                                    }}
                                    localizer={localizer}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    events={this.state.dealsDateList}
                                    style={{
                                        fontSize: "12px",
                                        height: "700px",
                                        width: "700px",
                                        backgroundColor: "#fff",
                                    }}
                                    selectable={true}
                                    //onSelectSlot={this.selectSlot}
                                    onSelectEvent={(event) => this.selectEvent(event)}
                                    components={{
                                        dateCellWrapper: ColoredDateCellWrapper,
                                    }}
                                    eventPropGetter={this.eventStyleGetter}
                                />
                            </Col>
                        ) : this.state.showUserFriends ? (
                            <div>
                                <Friends
                                    openEventsRegistered={this.openEventsRegistered}
                                    openEventsCreated={this.openEventsCreated}
                                    // openDealsCreated={this.openDealsCreated}
                                    friendsList={this.state.friendsList}
                                    filterVal={this.state.filterVal}
                                    totalFriendsNo={this.state.totalFriendsNo}
                                    refreshAllFriends={this.listoffriends}
                                    getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                    searchEventHandler={this.searchFriends}
                                />
                            </div>
                        ) : this.state.currentEvent !== undefined &&
                            !this.state.showVoucers &&
                            this.state.currentMultiprice !== null &&
                            this.state.currentEvent.date !== undefined ? (
                                                <BannerDetailsNormal
                                                    isClosed={this.props.isClosed}
                                                    userLogged={this.props.userLogged}
                                                    handler={this.props.handler}
                                                    // handleLog={this.props.handler}

                                                    price={
                                                        !this.state.currentMultiprice ||
                                                            this.state.currentMultiprice.length === 0
                                                            ? "FREE"
                                                            : this.state.currentMultiprice.length === 1
                                                                ? this.state.currentMultiprice[0].price === "0"
                                                                    ? "FREE"
                                                                    : "PAY"
                                                                : "PAY"
                                                    }
                                                    categoryName={this.state.currentEvent.name_of_subcategory}
                                                    id={this.state.currentEvent.id}
                                                    userIdE={this.state.currentEvent.user_id}
                                                    name={this.state.currentEvent.name}
                                                    gender={this.state.currentEvent.gender}
                                                    ageCategory={this.state.currentEvent.age_category}
                                                    startDate={this.state.currentEvent.date.substring(0, 11)}
                                                    endDate={this.state.currentEvent.end_date.substring(0, 11)}
                                                    endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                                    startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                                    latitude={this.state.currentEvent.latitude}
                                                    longitude={this.state.currentEvent.longitude}
                                                    team_number={this.state.currentEvent.team_number}
                                                    max_persons={this.state.currentEvent.max_persons}
                                                    persons_left={this.state.currentEvent.persons_left}
                                                    description={this.state.currentEvent.description}
                                                    multiprice={this.state.currentMultiprice}
                                                    address={this.state.currentEvent.address}
                                                    latitude={this.state.currentEvent.latitude}
                                                    longitude={this.state.currentEvent.longitude}
                                                    userLogo={this.state.eventLogo}
                                                    eventLogo={this.state.currentEvent.category_logo}
                                                    category={this.state.currentEvent.category_id}
                                                    closeModal={this.toggleModalShowDetails}
                                                    reloadEventsCallback={this.reloadEventsCallback}
                                                />
                                            ) : null}
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.modalShowDetails}
                    // toggle={this.toogleModalShowDetails}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Event details
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={this.toogleModalShowDetails}
                        />
                    </div>

          <ModalBody>
            {this.state.currentEvent !== undefined &&
            this.state.currentEvent.date !== undefined ? (
              <BannerDetailsNormal
              isClosedCostume={this.props.isClosed}
              categoryName={this.state.currentEvent.name_of_subcategory}
                currencyBannner={this.state.currentEvent.currency}
                userLogged={true}
                id={this.state.currentEvent.id}
                userIdE={this.state.currentEvent.user_id}
                name={this.state.currentEvent.name}
                gender={this.state.currentEvent.gender}
                ageCategory={this.state.currentEvent.age_category}
                startDate={this.state.currentEvent.date.substring(0, 11)}
                endDate={
                  this.state.currentEvent.end_date !== null
                    ? this.state.currentEvent.end_date.substring(0, 11)
                    : ""
                }
                price={
                  !this.state.currentMultiprice ||
                  this.state.currentMultiprice.length === 0
                    ? "FREE"
                    : this.state.currentMultiprice.length === 1
                    ? this.state.currentMultiprice[0].price === "0"
                      ? "FREE"
                      : "PAY"
                    : "PAY"
                }
                endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                latitude={this.state.currentEvent.latitude}
                longitude={this.state.currentEvent.longitude}
                team_number={this.state.currentEvent.team_number}
                max_persons={this.state.currentDealCalendar.number_of_persons}
                persons_left={this.state.currentDealCalendar.left_persons}
                description={this.state.currentEvent.description}
                multiprice={this.state.currentMultiprice}
                address={this.state.currentEvent.address}
                latitude={this.state.currentEvent.latitude}
                longitude={this.state.currentEvent.longitude}
                userLogo={this.state.eventLogo}
                eventLogo={this.state.currentEvent.category_logo}
                category={this.state.currentEvent.category_id}
                closeModal={this.toggleModalShowDetails}
                reloadEventsCallback={this.reloadEventsCallback}
                calendar={this.state.currentCalendar}
                curentDealCalendarId={this.state.curentDealCalendarId}
                isDeal={this.state.currentCalendar !== undefined ? true : false}
              />
            ) : null}
          </ModalBody>
        </Modal>
        {this.props.voucherVideo ? (
          <Modal
            isOpen={this.state.videoModal}
            // toggle={this.toggleVideoModal}
            fade={false}
          >
            <ModalVideo
              channel="youtube"
              youtube={{ autoplay: 1, mute: 1 }}
              isOpen={this.state.videoModal}
              videoId={this.props.voucherVideo.split("v=")[1]}
              //videoId="Mun_kUi9dx4"
              onClose={() => this.setState({ videoModal: false })}
            />
          </Modal>
        ) : null}
      </Container>
    );
  }
}

class Message extends Component {
    render() {
        return (
            <Row className="">
                <Col className="">{this.props.name}</Col>
            </Row>
        );
    }
}
