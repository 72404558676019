import React from 'react';
import './PromoBar.scss'
import google from '../../../Assets/img/google.png';
import apple from '../../../Assets/img/appStoreNew.png';
import {fetchApi} from "../../../fetchApi.js";

import {
    Button,
    Container,
    Row,
    Col,
    Media
} from 'reactstrap';
import SignUpLogIn from "../SignUpLogIn/SignUpLogIn"
import queryString from "query-string";

export default class PromoBar extends React.Component {

    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
        this.handler = this.handler.bind(this);

        this.handlerModal = this.handlerModal.bind(this);
        this.state = {
            getLoggedIn: false,
            userType: "normal",
            menuOpen: false,
            appStoreLink: '',
            googlePlayLink: "",
            fromWrapper:false
        };

    }

    handler(val) {

        this.setState({getLoggedIn: true, userType: val})
        this.props.handleLog(val);
    }

    handlerModal(val) {
        this.setState({openModal: !this.state.openModal});
        if (val !== undefined) {
            this.handler(val);
        }
    }

    toggle(val) {
        this.setState({modal: !this.state.modal});

        if (val !== undefined) {
            this.handler(val);
        }
    }

    getStoreLinks = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({
                googlePlayLink: response.googlePlayLink && response.googlePlayLink.length > 0 ? response.googlePlayLink[0].googlePlayLink : "",
                appStoreLink: response.appStoreLink && response.appStoreLink.length > 0 ? response.appStoreLink[0].appStoreLink : ""
            });
        }
    };

    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({fromWrapper: false})
            } else {
                this.setState({fromWrapper: true})
            }
        }
    }

    componentDidMount = () => {
        this.fromWrapper();
        this.getStoreLinks();
    }

    render() {
        return (
            <div className="promoBar2">
                <Container fluid={true}>
                    <Row>
                        <div className="contentPromo">
                            <Col xs="12" sm={12} lg={12} className="backgroundLeft">

                                <div className="promotexts">
                                    <div className="promoTextB">Create your</div>
                                    <div className="promoTextL">business account.</div>
                                </div>

                                <div className="promoButtons2">

                                    {this.state.fromWrapper ?
                                        <a href={this.state.appStoreLink}>
                                        <Media className="appBtn  mb-3" object src={apple} alt="image"/>

                                    </a> : null }
                                    {this.state.fromWrapper ?
                                        <a href={this.state.googlePlayLink}>
                                        <Media className="appBtn" object src={google} alt="image"/>
                                    </a>
                                        :null}
                                </div>

                                <div className="text-center"><SignUpLogIn handlerModal={this.handlerModal}
                                                                          openModal={this.state.openModal}
                                                                          class="promoBtn" text="Create account"/></div>

                            </Col>


                        </div>

                    </Row>
                </Container>

            </div>
        );
    }
}
