import React from "react";
import {
    Navbar,
    NavbarBrand,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledPopover,
    PopoverBody,
    Col,
    Button,
    ButtonGroup,
    Media
} from "reactstrap";
import {slide as Menu} from "react-burger-menu";
import {userCredentials} from "../../../globalVariables";
import {notificationInterval} from "../../../globalVariables";

import "./NavBarBusiness.scss";
import AccountSettings from "../../BothUsers/AccountSettings/AccountSettings";
import MyProfile from "../../BothUsers/MyProfile/MyProfile";
import AddCategory from "../../BothUsers/AddCategory/AddCategory";
import ManagerProfile from "../ManagerProfile/ManagerProfile";
import {fetchApi} from "../../../fetchApi";
import MessagesNotifications from "../../BothUsers/MessagesNotifications/MessagesNotifications";
import Messages from "../../NormalUser/Messages/Messages";
import EventNotification from "../../BothUsers/EventNotifications/EventNotification";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPoundSign, faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import {DialogContent} from "@material-ui/core";
import HowItWorks from "../../FirstPage/HowItWorks/HowItWorks";
import Dialog from "@material-ui/core/Dialog";
import ContactUS from "../../FirstPage/Footer/ContactUS";
import queryString from 'query-string'
import google from '../../../Assets/img/google.png';
import apple from '../../../Assets/img/appStoreNew.png';
import Stripe from 'stripe';

const stripe = new Stripe('sk_test_xcYTb6VxuK9rBshw1tUJ6I2Z00tGXjXhZ4');

let SLogo = require("../../../Assets/img/SLogo.png");
let logo = require("../../../Assets/img/logo_zing_mic_login.png");
let icons8 = require("../../../Assets/img/icons8.png");
let notification1 = require("../../../Assets/img/notification1.png");
let message1 = require("../../../Assets/img/message1.png");
let manager3 = require("../../../Assets/img/manager3.png");
let download4 = require("../../../Assets/img/download4.png");
let rightMenu = require("../../../Assets/img/rightMenu.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class NavBarBusiness extends React.Component {
    documentStyle = document.documentElement.style;
    initalNavbarBackgroundColor = "rgba(95, 108, 255, 0)";
    scrolledNavbarBackgroundColor = "#ffff";

    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.openProfile = this.openProfile.bind(this);
        this.openManager = this.openManager.bind(this);
        this.openCategory = this.openCategory.bind(this);
        this.openAccountSettings = this.openAccountSettings.bind(this);
        this.refreshNumberMessages = this.refreshNumberMessages.bind(this);
        this.container = React.createRef();
        this.state = {
            dropdownOpen: false,
            getLoggedIn: false,
            isToggleOn: false,
            setProfile: false,
            setManager: false,
            userType: "buss",
            setCategory: false,
            setAccountSettings: false,
            popoverNotifications: false,
            eventNotificationList: [],
            popoverMessages: false,
            messagesFromCreatedEvents: 4,
            messagesFromFriends: 3,
            messagesFromOngoingEvents: 1,
            openModal: false,
            messagesF: false,
            messagesO: false,
            messagesC: false,
            messagesFromFriendsList: [
                {id: 0, from: "Rebeca", message: "hello..."},
                {id: 1, from: "Ana", message: "buna.."},
                {id: 2, from: "Ion", message: "ok..."}
            ],
            messagesFromOngoingList: [
                {id: 0, from: "Ruben", message: "where..."},
                {id: 1, from: "Vlad", message: "how.."}
            ],
            messagesFromCreatedList: [
                {id: 0, from: "Jhon", message: "ok.."},
                {id: 1, from: "Tim", message: "idk.."},
                {id: 2, from: "Steve", message: "something..."}
            ],
            mainSponsor: "",
            openMenu: false,
            imagePreviewUrl: require("../../../Assets/img/logo.png"),
            modalMessages: false,
            numberOfMessages: 0,
            notificationsNewNo: 0,
            modalHowItWorks: false,
            modalContactUs: false,
            showMyManager: true,
            fromWrapper: false,
            refreshChat: false,
            appsAndroidLink:"",
            appsIosLink:"",

        };
    }

    handleScroll = () => {
        if (window.scrollY === 0) {
            this.documentStyle.setProperty(
                "--navbar-background-color",
                this.initalNavbarBackgroundColor
            );
        } else {
            this.documentStyle.setProperty(
                "--navbar-background-color",
                this.scrolledNavbarBackgroundColor
            );
        }
    };


    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({fromWrapper: false})
            } else {
                this.setState({fromWrapper: true})
            }
        }
    }

    getCodeUrl = async () => {
        const link = queryString.parse(window.location.search);
        let currentLink = window.location.href;

        if (link) {
            const snId = link.code;
            const fromWrap = link.from;


            if (snId) {

                if (fromWrap) {
                    currentLink = currentLink.split("&")[0]
                } else {
                    currentLink = window.location.origin;
                }
                const data = new FormData();
                const url = "addbusinessstripeid";

                data.append("user_id", userCredentials.userId);
                data.append("code", snId.toString());
                const response = await fetchApi(url, "POST", data, userCredentials.authKey);

                if (response.success) {
                    setTimeout(() => {
                        swal({
                            title: "Success",
                            text: "Stripe account successfully connected!",
                            icon: "success",
                            buttons: {
                                add: {
                                    text: "Ok",
                                    value: 1
                                },
                            }
                        }).then(val => {
                            if (val === 1) {
                                window.location.replace(currentLink);
                            } else {
                                window.location.replace(currentLink);
                            }
                        });
                    }, 3500);
                } else {
                    setTimeout(() => {
                        swal({
                            title: "Error",
                            text: "Could not connect Stripe account. Please try again!",
                            icon: "error",
                            buttons: {
                                add: {
                                    text: "Ok",
                                    value: 1
                                },
                            }
                        }).then(val => {
                            if (val === 1) {
                                window.location.replace(currentLink);
                            } else {
                                window.location.replace(currentLink);
                            }
                        });
                    }, 5000);

                }


            }
        }

    }

    componentDidMount() {
        this.fromWrapper();
        this.getStoreLinks()
        this.getMainSponsor();
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getProfile();
        this.getNewNotificationsNo();
        this.getNotificationsList();
        this.intervalID = setInterval(() => {
            this.getNumberOfMessages();
            this.getNewNotificationsNo();
        }, notificationInterval.value);
        this.getCodeUrl();
    }

    getNumberOfMessages = async () => {
        const data = new FormData();
        const url = "numberofnewmessages";
        //  data.append("auth_key", userCredentials.authKey);
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            let friends = response.friends_messages;
            let myEvents = response.events_messages;
            let myOngoings = response.ongoing_messages;
            let total = friends + myEvents + myOngoings;
            this.setState({numberOfMessages: total});
            if (total > 0) {
                this.setState({refreshChat: true})
            } else {
                this.setState({refreshChat: false})
            }
        }


    };

    refreshNumberMessages() {
        this.getNumberOfMessages();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }


    getStoreLinks = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({
                appsAndroidLink: response.googlePlayLink && response.googlePlayLink.length > 0 ? response.googlePlayLink[0].googlePlayLink : "",
                appsIosLink:response.appStoreLink && response.appStoreLink.length > 0 ? response.appStoreLink[0].appStoreLink : "",

                googlePlayLink: response.googlePlayLinkScanner && response.googlePlayLinkScanner.length > 0 ? response.googlePlayLinkScanner[0].googlePlayLinkScanner : "",
                appStoreLink: response.appStoreLinkScanner && response.appStoreLinkScanner.length > 0 ? response.appStoreLinkScanner[0].appStoreLinkScanner : ""
            });
        }
    };

    openUserEvents() {
        this.props.changeTagsEventUser();
    }

    openEvents() {
        this.props.changeTagsEvent();
    }

    openDeals() {
        this.props.changeDeals();
    }

    openBookKiper() {
        this.props.changeBookKeeper();
    }

    openVouchers() {
        this.props.changeVouchers();
    }

    // openFindPeople() {
    //     this.props.changeFindPeople();
    // }

    openMyTickets() {
        this.props.changeMyTickets();
    }

    openHowItWorks() {
        this.toggleHowItWorks();
    }

    openContactUs() {
        this.toggleContactUs()
    }

    toggleHowItWorks = () => {
        this.setState({
            modalHowItWorks: !this.state.modalHowItWorks
        });
    };

    toggleContactUs = () => {
        this.setState({
            modalContactUs: !this.state.modalContactUs
        });
    };

    handleStateChange(state) {
        this.setState({openMenu: state.isOpen});
        // if (!state.isOpen) {
        //     this.openCreateEvents(false)
        // }
    }

    handleClickOutside = event => {
        if (
            this.container.current &&
            !this.container.current.contains(event.target)
        ) {
            this.setState({openMenu: false});

            // console.log("outside");
        }
    };
    getNewNotificationsNo = async () => {
        const data = new FormData();
        const url = "getnewnotificationsnumber";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            this.setState({notificationsNewNo: response.number_of_notifications});
        }
    };

    getNotificationsList = async () => {
        const data = new FormData();
        const url = "getusernotifications";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            this.setState({eventNotificationList: response.notifications});
        }
    };

    updateNotificationItem = (notificationId) => {
        const notificationIndex = this.state.eventNotificationList.findIndex(s => {
            return s.notification_id === notificationId;
        });
        const notifications = [...this.state.eventNotificationList];
        notifications[notificationIndex].is_checked = 1;
        let elem = notifications[notificationIndex];
        if (elem) {
            let notType = parseInt(elem.notification_type);
            let entityId = -1;
            switch (notType) {
                case 1:
                    entityId = notifications[notificationIndex].event_id;//event created
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 2:
                    entityId = notifications[notificationIndex].deal_user_id; //deal created
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 3:
                    entityId = notifications[notificationIndex].event_id;//apply to free event - wait for approve/reject
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 4:
                    entityId = notifications[notificationIndex].event_id; //assigned to event with pay
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 5:
                    entityId = notifications[notificationIndex].event_id; //approve la case 3
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 6:
                    entityId = notifications[notificationIndex].event_id; //reject la case 3
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 7:
                    entityId = notifications[notificationIndex].deal_user_id; //apply free deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 8:
                    entityId = notifications[notificationIndex].deal_user_id; //apply deal with pay
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 9:
                    entityId = notifications[notificationIndex].deal_user_id; //approve reject deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 10:
                    entityId = notifications[notificationIndex].deal_user_id; //approve reject deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 11:
                    entityId = notifications[notificationIndex].fub_user_id; //follow user
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 12:
                    entityId = notifications[notificationIndex].event_id;//create voucher
                    swal("Info", "You can apply to vouchers from your event.", "info");
                    break;
                case 13:
                    entityId = notifications[notificationIndex].event_id;//approve voucher la case 12
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 14:
                    entityId = notifications[notificationIndex].event_id;//reject voucher la case 12
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 15:
                    entityId = notifications[notificationIndex].event_id;//apply voucher
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
            }
        }
    };

    refreshNumberMessages() {
        this.getNumberOfMessages();
    }

    duplicateUser = async () => {
        let authKey = userCredentials.authKey;
        let url = "duplicateuser";
        const response = await fetchApi(url, "POST", null, authKey);

        if (response.success) {
            if (response.is_activ === true) {
                swal(
                    "Info!",
                    "You already have a normal account for this email, we will restore it",
                    "info"
                );
            }
            if (localStorage.getItem("react-cookie-consent-notification")) {
                localStorage.clear();
                localStorage.setItem("react-cookie-consent-notification", true);
            } else {
                localStorage.clear();
            }
            this.props.handleLog(false, null);

            let userInfo = {
                auth_key: response.auth_key,
                user_id: response.user_id,
                user_type: parseInt(response.user_type),
            };

            localStorage.setItem("userIsLogged", "true");
            localStorage.setItem("userCredentials", JSON.stringify(userInfo));
            //userCredentials.authKey = userInfo.auth_key;
            //userCredentials.userId = userInfo.user_id;
            //userCredentials.userType = userInfo.user_type;

            this.props.handleLog("normal", true);
        }
    };

    toggleMenu = () => {
        this.setState({openMenu: !this.state.openMenu});
    };


    removeFromFList = id => {
        let newList = this.state.messagesFromFriendsList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromFriendsList: newList});
    };
    removeFromOList = id => {
        let newList = this.state.messagesFromOngoingList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromOngoingList: newList});
    };
    removeFromCList = id => {
        let newList = this.state.messagesFromCreatedList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromCreatedList: newList});
    };

    toggleModal = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    };

    toggleModalF = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: true,
            messagesO: false,
            messagesC: false
        });
    };

    toggleModalO = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: false,
            messagesO: true,
            messagesC: false
        });
    };

    toggleModalC = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: false,
            messagesO: false,
            messagesC: true
        });
    };

    togglePopoverMessages = () => {
        this.setState({
            popoverMessages: !this.state.popoverMessages
        });
    };

    togglePopoverNotifications = async () => {
        this.setState({
            popoverNotifications: !this.state.popoverNotifications
        });

        const data = new FormData();
        const url = "checknotification";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            this.setState({notificationsNewNo: 0});
        }
        this.getNotificationsList();
    };

    openCategory(value) {
        this.setState({setCategory: value});
    }

    openManager(value) {
        this.setState({setManager: value});
    }

    openProfile(value) {
        this.setState({setProfile: value});
    }

    openAccountSettings(value) {
        this.setState({setAccountSettings: value});
    }

    toggle(e) {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    getMainSponsor = async () => {
        const url = "get_officialpartners";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({
                mainSponsor: response.list_of_partners[0].logo,
                mainSponsorLink: response.list_of_partners[0].video,
            });
        }
    };

    closeModal = () => {
        this.setState({setProfile: false});
        setTimeout(() => {
            this.getProfile();
        }, 1500);
    };

    refreshProfile = () => {
        this.getProfile();
    }

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success && response.userprofile != null) {
            if (response.userprofile[0] !== undefined) {
                userCredentials.userLogo = response.userprofile[0].img_makeitheader == 2 ? response.userprofile[0].logo : response.userprofile[0].image;
                this.setState({
                    imagePreviewUrl: response.userprofile[0].img_makeitheader == 2 ? response.userprofile[0].logo : response.userprofile[0].image
                });
            }
        }
    };

    toggleMessages = () => {
        this.setState({modalMessages: !this.state.modalMessages});
    };

    showMyManager = () => {
        this.setState({showMyManager: true});
    };

    showProfile = () => {
        this.setState({showMyManager: false});
    };
    resetPage = () => {
        window.location.reload();
        this.openUserEvents()
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div ref={this.container}>
                <Dialog
                    open={this.state.modalHowItWorks ? this.state.modalHowItWorks : false}
                    fullWidth
                    maxWidth={"xl"}
                    // maxWidth={}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleHowItWorks}/>
                    </div>
                    <DialogContent>
                        <div className="modal_how_it_works">
                            <ErrorBoundary>
                                <HowItWorks/>
                            </ErrorBoundary>
                        </div>
                    </DialogContent>
                </Dialog>

                <Modal
                    isOpen={this.state.modalContactUs}
                    size="lg"
                    // toggle={() => this.toggleContactUs()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1 text-white">
                            Contact us
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toggleContactUs()}
                        />
                    </div>
                    <ModalBody className="modalFooter">
                        <ContactUS/>
                    </ModalBody>
                </Modal>
                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.setProfile}
                    // toggle={() => this.openProfile()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1 text-white">My profile</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.openProfile(false)}
                        />
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            <MyProfile
                                userId={userCredentials.userId}
                                closeModal={this.closeModal}
                                showModificationsOptions={true}
                            />
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>

                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.setAccountSettings}
                    // toggle={() => this.openAccountSettings(false)}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.openAccountSettings(false)}
                        />
                    </div>
                    <ErrorBoundary>
                        <AccountSettings
                            handleLogOutBus={this.props.handleLog}
                            handleInputBus={this.props.handleInputBus}
                        />
                    </ErrorBoundary>
                </Modal>

                <Navbar className="Navbar-business navbar-dark navbar-custom fixed-top">
                    <NavbarBrand className="logo-flex navbar-brand">
                        <img onClick={() => this.resetPage()} className="logo logoBussines" src={logo} alt="logo"/>
                    </NavbarBrand>
                    <Col className="d-flex justify-content-true sponsorTop ">
                        {this.state.mainSponsorLink ? (
                            <a href={this.state.mainSponsorLink} target="_blank">
                                <img src={this.state.mainSponsor}/>
                            </a>
                        ) : (
                            <img src={this.state.mainSponsor}/>
                        )}
                    </Col>

                    <div className="d-flex d-flex-costume mobileMen">
                        <div href="#" className="navbar-div logo-image">

                            <NavbarBrand onClick={() => this.openCategory(true)}>
                                {" "}
                                <img className="nav-icons" src={download4} alt="logo"/>
                            </NavbarBrand>


                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.setCategory}
                                // toggle={() => this.openCategory(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1 text-white">
                                        Add category
                                    </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.openCategory(false)}
                                    />
                                </div>
                                <ErrorBoundary>
                                    <AddCategory/>
                                </ErrorBoundary>
                                {/*      <ModalFooter className=" categ-save-btn ">
                  <Button color="primary">Save & Close</Button>
                </ModalFooter> */}
                            </Modal>


                            <NavbarBrand
                                onClick={() => {
                                    this.openManager(true);
                                }}
                            >
                                {" "}
                                <img className="nav-icons" src={manager3} alt="logo"/>{" "}
                            </NavbarBrand>


                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.setManager}
                                // toggle={() => this.openManager(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.openManager(false)}
                                    />
                                </div>
                                <ErrorBoundary>
                                    <div>
                                        <ButtonGroup className="w-100 modalMakeEvt mt-2 px-1">
                                            <Button
                                                className="greenBtn"
                                                onClick={this.showMyManager}
                                                active={this.state.showMyManager}
                                            >
                                                My Manager
                                            </Button>
                                            <Button
                                                className="greenBtn centerGreenBtn"
                                                onClick={this.showProfile}
                                                active={!this.state.showMyManager}
                                            >
                                                My Profile
                                            </Button>
                                        </ButtonGroup>
                                        {this.state.showMyManager ? (
                                            <ManagerProfile
                                            fromWrapperProp={this.props.fromWrapperProp}
                                                toggleSelfModal={this.openManager}
                                                handleLogOutBus={this.props.handleLog}
                                                refreshProfile={this.refreshProfile}
                                            />
                                        ) : (
                                            <ModalBody>
                                                <MyProfile
                                                    userId={userCredentials.userId}
                                                    closeModal={this.openManager}
                                                    showModificationsOptions={true}
                                                />
                                            </ModalBody>
                                        )}
                                    </div>
                                </ErrorBoundary>
                            </Modal>

                            <NavbarBrand>
                                <div className="notificationsContainerBus">
                                    {" "}
                                    <img
                                        className="nav-icons"
                                        src={message1}
                                        alt="logo"
                                        id="messages"
                                        onClick={this.toggleMessages}
                                    />
                                    {this.state.numberOfMessages > 0 ? (
                                        <div className="numberCircleBus">
                                            <div className="onlyNumberBus">
                                                {this.state.numberOfMessages}
                                            </div>
                                        </div>
                                    ) : null}{" "}
                                </div>
                            </NavbarBrand>
                            <Modal
                                isOpen={this.state.modalMessages}
                                // toggle={this.toggleMessages}
                                size="lg"
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1  text-white">
                                        Inbox
                                    </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={this.toggleMessages}
                                    />
                                </div>
                                <ModalBody>
                                    <ErrorBoundary>
                                        <Messages refreshNumber={this.refreshNumberMessages}
                                                  needToRefreshChat={this.state.refreshChat}/>
                                    </ErrorBoundary>
                                </ModalBody>
                            </Modal>
                            <Modal
                                isOpen={this.state.openModal}
                                // toggle={this.toggleModal}
                                centered={true}
                                size="lg"
                            >
                                <ModalBody>
                                    <ErrorBoundary>
                                        {this.state.messagesF ? (
                                            <div>
                                                <Messages/>
                                            </div>
                                        ) : null}
                                        {this.state.messagesO ? (
                                            <div>
                                                <Messages/>
                                            </div>
                                        ) : null}
                                        {this.state.messagesC ? (
                                            <div>
                                                <Messages/>
                                            </div>
                                        ) : null}
                                    </ErrorBoundary>
                                </ModalBody>
                            </Modal>
                            <NavbarBrand onClick={this.togglePopoverNotifications}>
                                {" "}
                                <div className="notificationsContainer">
                                    <img
                                        className="nav-icons"
                                        src={notification1}
                                        alt="logo"
                                        id="notification"
                                    />
                                    {this.state.notificationsNewNo > 0 ? (
                                        <div className="numberCircle">
                                            <div className="onlyNumber">
                                                {this.state.notificationsNewNo}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {" "}
                            </NavbarBrand>
                            <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target="notification"
                                isOpen={this.state.popoverNotifications}
                                className="popoverNotifications"
                                toggle={this.togglePopoverNotifications}
                            >
                                <PopoverBody>
                                    <ErrorBoundary>
                                        {this.state.eventNotificationList.map((item, index) => (
                                            <EventNotification
                                                name={item.name}
                                                category={item.category}
                                                key={index}
                                                is_checked={item.is_checked}
                                                logo={item.logo}
                                                notificationId={item.notification_id}
                                                updateNotificationState={this.updateNotificationItem}
                                                readit={item.is_checked == "1"}
                                                description={item.description}
                                            />
                                        ))}
                                    </ErrorBoundary>
                                </PopoverBody>
                            </UncontrolledPopover>
                            {/* <NavbarBrand onClick={() => this.openProfile(true)}>
                {" "}
                <div className="profile-img-navbar">
                  <img
                    className="image-nav"
                    src={
                      this.state.imagePreviewUrl !== undefined &&
                      this.state.imagePreviewUrl !== null &&
                      this.state.imagePreviewUrl !== ""
                        ? this.state.imagePreviewUrl
                        : profilePic
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = profilePic;
                    }}
                  ></img>
                </div>
              </NavbarBrand> */}
                            <NavbarBrand onClick={this.duplicateUser}>
                                <FontAwesomeIcon
                                    icon={faExchangeAlt}
                                    className="switchAccount"
                                    title={"Swap account"}
                                />
                            </NavbarBrand>
                        </div>
                    </div>

                    <div ref={this.dropdown}>
                        <Menu
                            right
                            className="rightMen fixMenu"
                            noOverlay
                            isOpen={this.state.openMenu}
                            onStateChange={(state) => this.handleStateChange(state)}
                        >
                            <a className="menu-item" onClick={this.duplicateUser}>
                                Swap account
                            </a>
                            {/* <a className="menu-item" onClick={() => this.openProfile(true)}>
                My Profile
              </a> */}
                            <a className="menu-item" onClick={this.openAccountSettings}>
                                Settings
                            </a>
                            <a
                                className="menu-item" onClick={() => {
                                this.openManager(true);
                            }}>
                                Businsess account
                            </a>

                            <a className="menu-item" onClick={() => this.openUserEvents()}>
                                Events
                            </a>
                            <a className="menu-item" onClick={() => this.openEvents()}>
                                My Events
                            </a>
                            <a className="menu-item" onClick={() => this.openDeals()}>
                                Virtual manager
                            </a>
                            <a onClick={() => this.openBookKiper()} className="menu-item">
                                Bookkeeper
                            </a>
                            <a onClick={() => this.openVouchers()} className="menu-item">
                                Vouchers
                            </a>
                            {/* <a onClick={() => this.openFindPeople()} className="menu-item">
                Find Business
                            </a> */}
                            <a onClick={() => this.openMyTickets()} className="menu-item">
                                My Tickets
                            </a>
                            <a className="menu-item" onClick={() => this.openHowItWorks()}>
                                {/* <a className="menu-item"  href="/howitworks"> */}

                                How it Works{" "}
                            </a>
                            <a className="menu-item" onClick={() => this.openContactUs()}>
                                {/* <a className="menu-item" href="/contact"> */}

                                Contact Us{" "}
                            </a>
                            <a
                                className="menu-item"
                                onClick={() => {
                                    if (localStorage.getItem("react-cookie-consent-notification")) {
                                        localStorage.clear();
                                        localStorage.setItem("react-cookie-consent-notification", true);
                                    } else {
                                        localStorage.clear();
                                    }
                                    this.props.handleLog(false, null);
                                }}
                            >
                                Logout
                            </a>
                            {this.state.fromWrapper ?
                                <a href={this.state.appsIosLink} target="_blank" className="menu-item">
                                    <Media
                                        className="appBtnScannerMenu"
                                        object
                                        src={apple}
                                        alt="image"
                                    />
                                </a> : null}
                            {this.state.fromWrapper ?
                                <a href={this.state.appsAndroidLink} target="_blank" className="menu-item">
                                    <Media className="appBtnScannerMenu" object src={google} alt="image"/>
                                </a> : null}
                        </Menu>
                    </div>
                </Navbar>
            </div>
        );
    }
}
