import React from 'react';
import './CookiesAgreement.scss'
let SLogo = require("../../Assets/img/logo.png");
const CookiesAgreement = (props) => {

    let fileToDownload = 'Cookies.pdf';




    return (
        <div className="cookieMain">
            <img className="logoImg" src={SLogo} alt="my_logo" />

            <div className="fullCentered">
                    ZING PARTY
            </div>
            <div className="fullCentered">
                By S.C. WE ZING S.R.L.
            </div>
            <div className="fullCentered">
                <i>Headquarters – Petru Rares Street, N.68, 720012, Suceava, Romania</i>
            </div>
            <div className="fullCentered">
                <a href="mailto:contact@zing-sport.com ">contact@zing-sport.com </a>
            </div>
            <div className="row cookiesMargins">
                Effective: July 7, 2020
            </div>
            <div className="fullCentered cookiesMargins">
                <b>COOKIES</b>
            </div>
            <p className="leftalign">
            1.1 - We use cookies to ensure that you get the most out of Zing Party. Cookies are small amounts of information in the form of text files which Zing Party stores on your internet enabled device. Cookies allow us to monitor Zing Party’s traffic, simplify your visits to Zing Party. A temporary cookie is also used to keep track of your "session". Without that temporary cookie (which is not stored after you quit your browser) You would have had to log on again on each page. For further information on the types of information that we collect using cookies, please refer to paragraph 2 above.
            </p>
            <p className="leftalign">
            1.2 - If you do not wish Zing Party to install cookies on your internet enabled device, you can change the settings on your internet browser to reject cookies. For more information about how to reject cookies using your internet browser settings please consult the “Help” section of your internet browser (or alternatively visit http://www.aboutcookies.org ). Please note that, if you do set your Internet browser to reject cookies, you may not be able to access all of the functions on Zing Party and your experience whilst using Zing Party may be less satisfying without using cookies.
            </p>
            <p className="leftalign">
            1.3 - The first time you visit Zing Party you will be presented with a notification that Zing Party uses cookies. By proceeding to use Zing Party you are consenting to our use of cookies as described in this Privacy Policy.
            </p>
            <p className="leftalign">
            1.4 - The names of the cookies used by Zing Party and the purposes for which these cookies are used are set out in the table below:

            </p>
            <div className="fullCentered cookiesMargins">
                <b>2 - HOW WE USE YOUR INFORMATION</b>
            </div>
            <div className="leftalign">
                <table>
                    <tbody>
                        <tr>
                            <td>Cookie</td>
                            <td>Purpose</td>
                            <td>Type</td>
                            <td>Duration</td>
                        </tr>
                        <tr>
                            <td>Google Analytics
                            Name: _ga
</td>
                            <td>Distinguish users</td>
                            <td>Persistent</td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>Google Analytics
                            Name: _gat
</td>
                            <td>Throttle request rate</td>
                            <td>Persistent</td>
                            <td>10 mins</td>
                        </tr>
                        <tr>
                            <td>Azure Cloud Service
                            Name: ARRAffinity
</td>
                            <td>Stabilize	and	optimize	server
                            infrastructure
</td>
                            <td>Persistent</td>
                            <td>N/A</td>
                        </tr>
                        <tr>
                            <td>Zing Party Name: cookies.js
</td>
                            <td>Cookie management</td>
                            <td>Persistent</td>
                            <td>N/A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="leftalign">
            2.1 - We may use your information for the following purposes:
            </p>
            <p className="leftalign">
            2.1.1 - To ensure that content in Zing Party is presented in the most effective manner for you and for your internet enabled device;
            </p>
            <p className="leftalign">
            2.1.2 - To ensure that Zing Party presents or suggests artists and/or musicians local and relevant to you;
            </p>
            <p className="leftalign">
            2.1.3 - To provide customer service in relation to your use of Zing Party to contact you, to deal with enquiries and complaints relating to the use of our site and to notify you about changes to Zing Party
            </p>
            <p className="leftalign">
            2.1.4 - To gather usage statistics;
            </p>
            <p className="leftalign">
            2.1.5 - To administer, support, develop, improve and optimize Zing Part (for example so as to improve your browsing and viewing experience);
            </p>
            <p  className="leftalign">
                2.1.6 - To inform you about changes to our <a href="/terms" > <b>Terms of Use</b></a>  or this <a href="/privacypolicy"> <b>Privacy Policy</b> </a>;
            </p>
            <p className="leftalign">
            2.1.7 - To send you marketing materials provided that you have expressly consented to us doing so;
            </p>
            <p className="leftalign">
            2.1.8 - To provide third parties with anonymous, aggregated, statistical information about our users; and
            </p>
            <p className="leftalign">
            2.1.9 - For security purposes.
            </p>
            <p className="leftalign">
                <a target='_blank' href={process.env.PUBLIC_URL + fileToDownload}>Save as PDF</a>
            </p>
        </div>

    );
}

export default CookiesAgreement;